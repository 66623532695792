import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import entities from './entitiesReducer';
import ui from './uiReducer';

let reducer = (history) => combineReducers({
	router: connectRouter(history),
	entities,
	ui
});

export default reducer