import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import withAuth from '../auth/withAuth';

// action files (get data from server and set up redux store)
import { getBrands } from '../../actions/brands'

// selector files (transform data from redux store in to what we need for presentation)
import { getBrandsSelector, getCurrentClientID } from '../../selectors/brandsSelector'
import { setCurrentClientID } from '../../actions/ui'

// presentation component
import Overview from './overview'

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		getBrands: () => dispatch(getBrands()),
		setCurrentClientID: (payload) => setCurrentClientID(payload)
	}, dispatch);
};

const mapStateToProps = (state, ownProps) => {
	
	return {
		brands: getBrandsSelector(state, ownProps),
		clientID: getCurrentClientID(state, ownProps)
	}
}

const OverviewContainer = connect(mapStateToProps, mapDispatchToProps)(Overview);
//export default withAuth(OverviewContainer);
export default OverviewContainer;