import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../../services/AuthService'
import { getAgencies } from '../../actions/agencies'
import dayjs from 'dayjs'

// PrimeReact Components
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';

// BreadCrumbs component
import BreadCrumbs from '../../components/breadCrumbs/breadCrumbs-container'

const ClientOverview = (props) => {
	const [agencies, setAgencies] = useState([]);
	const [filters, setFilters] = useState({
       name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    brands: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
	
	const { getBrands, setCurrentClientID } = props
	const Auth = new AuthService();
	useEffect(() => {
		getBrands()
		setCurrentClientID(null)
	},[getBrands,setCurrentClientID]);

	useEffect(() => {
		getAgencies().then((results) => {
			setAgencies(results)
		})
	},[getAgencies]);

	const breadCrumbItems = [
        {label: 'Client List'}
    ];

	const clientTemplate = (rowData) => {
		return (
			<div>
				<Link to={"/overview/" + rowData.clientID}>
					{rowData.clientName}
				</Link>
			</div>
		)
	}

	const fiscalTemplate = (rowData) => {
		let strStartFiscal = dayjs(rowData.fiscalDates.startOfYear).format('MMM D, YYYY')
		let strEndFiscal = dayjs(rowData.fiscalDates.endOfYear).format('MMM D YYYY')
		
		return (
			<div className="flex flex-column infoBody">
				{strStartFiscal} - {strEndFiscal}
			</div>
		)
	}

	const brandTemplate = (rowData) => {
		if (!rowData.brands || rowData.brands.length === 0 ) {
			debugger
			return
		}
		return (
			<div className="flex flex-column">
				<Fieldset legend={rowData.brands.length + " Brands"} toggleable collapsed >
					{rowData.brands.map(cur => {
						return (
							<div>{cur}</div>
						)
					})}
				</Fieldset>
			</div>
		)
	}

	const curQuarterTemplate = (rowData) => {
		
		let strQuarter = "First"
		if (rowData.fiscalDates.currentQuarter === 2) strQuarter = "Second"
		else if (rowData.fiscalDates.currentQuarter === 3) strQuarter = "Third"
		else if (rowData.fiscalDates.currentQuarter === 4) strQuarter = "Fourth"
		return (
			<div className="flex flex-column infoBody">
				{strQuarter} Quarter
			</div>
		)
	}

	const prevQuarterTemplate = (rowData) => {
		let strStartFiscal = dayjs(rowData.fiscalDates.startOfPrevQuarter).format('MMM D, YYYY')
		let strEndFiscal = dayjs(rowData.fiscalDates.endOfPrevQuarter).format('MMM D YYYY')
		
		return (
			<div className="flex flex-column infoBody">
				{strStartFiscal} - {strEndFiscal}
			</div>
		)
	}

	

	const prevFiscalTemplate = (rowData) => {
		let strStartFiscal = dayjs(rowData.fiscalDates.startOfPrevYear).format('MMM D, YYYY')
		let strEndFiscal = dayjs(rowData.fiscalDates.endOfPrevYear).format('MMM D YYYY')
		
		return (
			<div className="flex flex-column infoBody">
				{strStartFiscal} - {strEndFiscal}
			</div>
		)
	}

	const agencyTemplate = (rowData) => {
		return (
			<div className="link" onClick={(e) => Auth.loginHybrid(rowData.agencyID) }>
				<Button>Access Agency View</Button>
			</div>
		)
	}

	const tableHeader = <div className="tableHeader">Client List</div>
	const agencyTableHeader = <div className="tableHeader">Agencies</div>

    return (
		<div className="grid">
			<div className="col-12">
				<BreadCrumbs items={breadCrumbItems} />
				<div className="card">
					<div className="flex flex-column">
						<Link to={"/manageComments/"} className="mb-2">
							<Button label="Manage Agency Comments" className="p-button-outlined" />
						</Link>
						<div>
							
						</div>
						<div className="mb-4">
							<DataTable value={props.clients} header={tableHeader} paginator rows={20} className="p-datatable-products">
								<Column field="clientName" header="Name" body={clientTemplate} sortable style={{width:'20%'}}></Column>
								<Column field="clientFiscalStartMonth" header="Current Fiscal Year" body={fiscalTemplate}  style={{width:'15%'}}></Column>
								<Column header="Previous Fiscal Year" body={prevFiscalTemplate} style={{width:'15%'}}></Column>
								<Column body={curQuarterTemplate} header="Current Quarter"  style={{width:'15%'}}></Column>
								<Column body={prevQuarterTemplate} header="Previous Quarter"  style={{width:'15%'}}></Column>
								<Column field="brandCount" body={brandTemplate} sortable header="# of Brands" style={{width:'10%'}}></Column>
							</DataTable>
						</div>
						<div>
							<DataTable value={agencies} header={agencyTableHeader}  filters={filters} onFilter={(e) => setFilters(e.filters)}
								paginator rows={20} className="p-datatable-products" filterDisplay="row" >
								<Column field="name"  header="Agency Name" filterField="name" showFilterMenu={false}  filter filterPlaceholder="Search by Agency Name"  style={{width:'30%'}}></Column>
								<Column field="brands"  header="# of Brands" sortable body={brandTemplate} filterField="brands" showFilterMenu={false}  filter filterPlaceholder="Search by Brand" style={{width:'30%'}}></Column>
								<Column field="projectCount"  header="Number of Projects" sortable style={{textAlign:'right',width:'20%'}}></Column>
								<Column field="" body={agencyTemplate} header="Agency Access" style={{textAlign:'right',width:'20%'}}></Column>
							</DataTable>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
}

export default ClientOverview;