import React, { useState, useEffect, useRef } from 'react';

// Form validation library
import { useForm, Controller } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Messages } from 'primereact/messages';
import { ScrollPanel } from 'primereact/scrollpanel';
import { classNames } from 'primereact/utils';

import { dollarTemplate, monthTemplate } from '../utilities/CommonSnippets'

const ProjectAddOOPCostsModal = (props) => {
	const { displayOOP, currentProject, fncHideModal, addOOPCosts, showToast, passThrough, oopCosts } = props
	const defaultValues = {
		currentDate: new Date(),
		costs: '', 
		comments: ''
	}

	useEffect(() => {
		// When we close the modal, reset all the form field values
		if (!displayOOP) reset()
	},[displayOOP])
	
	// These are the needed items for the form validation component
	const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

	const toast = useRef(null);
	const msgs = useRef(null);

	const renderFooter = () => {
		return (
			<div className="flex justify-content-end">
				<div className="flex">
					<div className="mr-2">
						<Button label="Cancel" icon="pi pi-times" onClick={() => fncHideModal()} className="p-button-text" />
					</div>
					<div>
						<Button label="Add" icon="pi pi-plus" onClick={handleSubmit(onSubmit)} />
					</div>
				</div>
			</div>
		);
	}
	
	const onSubmit = async (data) => {
       let blnAddResults = await addOOPCosts({
			projectID: currentProject.projectID, 
			costs: data.costs,
			date: data.currentDate,
			comments: data.comments
		})

		if (blnAddResults) {
			fncHideModal()
			showToast('success', 'Success','Costs were added successfully.')
			reset();
		}
		else {
			msgs.current.show([
				{ severity: 'error', detail: 'There was an issue adding costs.', life: 3000}
			]);
		}
    };

	return (
		<Dialog header={"Add OOP Costs for: " + currentProject.projectName} visible={displayOOP} className="addHoursModal"  footer={renderFooter()} onHide={() => fncHideModal()}>
			<Toast ref={toast} />
			
			<div className="grid">
				<div className="col-6">
					<form className="p-fluid">
						
						<div>
							Date for When OOP Costs*: 
							<Controller name="currentDate" control={control} rules={{ required: true }}  render={({ field, fieldState }) => (
                                <Calendar 
									id={field.name} 
									value={field.value} 
									onChange={(e) => field.onChange(e.value)} 
									view="month" dateFormat="MM yy"
									mask="99/99/9999" 
									showIcon 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
                            )} />
							{errors.currentDate && 
								<small className="p-error">Date is required.</small>
							}
							
						</div>
						<br/>
						<div>
							OOP Costs*: 
							<Controller name="costs" control={control}  render={({ field, fieldState }) => (
                                <InputText 
									id={field.name} 
									type="number"
									value={field.value} 
									{...field}
									autoFocus 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
                            )} />
							{errors.costs && 
								<small className="p-error">Costs are required.</small>
							}
						</div>
						<br/>
						<div>
							Comments: 
							<Controller name="comments" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
								<InputTextarea 
									rows={5}
									id={field.name} 
									value={field.value} 
									{...field}
									autoFocus
								/>
							)} />
						</div>
					</form>
				</div>
				<div className="col-6 verticalDividerLeft">
					
					<div className="flex flex-column currentStaff">
						<div className="flex justify-content-between">
							<div className="name">
								Pass-through (OOPs) Budget
							</div>
							<div className="rate">{dollarTemplate(parseFloat(passThrough))}</div>
						</div>
						<div className="flex justify-content-between">
							<div className="title">
								Total Costs
							</div>
							<div className="rate">
								{dollarTemplate(parseFloat(oopCosts.reduce((acc,cur) => {
									acc += cur.costs
									return acc
								},0)))}
							</div>
						</div>
						
						
						<div className="mt-4">
							<ScrollPanel style={{width: '100%', height: '200px'}}  className="hoursScroller">
								<DataTable value={oopCosts} className="hoursTable">
									<Column field="dateEntered" body={monthTemplate} header="Date" style={{width: '70%'}}></Column>
									<Column field="costs" header="Cost" body={dollarTemplate} style={{width: '30%',textAlign:'right'}}></Column>
								</DataTable>
							</ScrollPanel>
						</div>
						
					</div>
					
				</div>
				<Messages ref={msgs} className="modalMessage"></Messages>
			</div>
		</Dialog>
	)
}

export default ProjectAddOOPCostsModal;