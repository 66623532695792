import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import ExcelJS  from 'exceljs'
import saveAs from "file-saver";

import { getProjectByBrand } from '../../selectors/dashboardWidgetsSelector'
import { dollarTemplateNoDecimal, dateStringsMap } from '../../utilities/CommonSnippets'
import { fncDrawHeaderCellBudget, fncDrawFooterCellBudget, fncDrawSubCatBudget, fncDrawTitleCell } from '../../utilities/commonXslxFunctions'

const SpendByAudienceStaff = (props) => {
	const { spendSummary, dates, downloadClicked, fileName, franchiseName } = props

	useEffect(() => {
		if (downloadClicked > 0) exportExcel()
	},[downloadClicked])


	const budgetTotal = () => {
        let total = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				total += curStatus.budget;
			}
		}

        return dollarTemplateNoDecimal(total);
    }

	const spendTotal = () => {
        let total = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				total += curStatus.spend - curStatus.outside;
			}
		}

        return dollarTemplateNoDecimal(total);
    }

	const outsideTotal = () => {
        let total = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				total += curStatus.outside;
			}
		}

        return dollarTemplateNoDecimal(total);
    }

	const yearTotal = () => {
        let total = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				total += curStatus.yearBudget;
			}
		}

        return dollarTemplateNoDecimal(total);
    }

	const differenceTotal = () => {
        let total = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				total += curStatus.difference;
			}
		}

        return dollarTemplateNoDecimal(total);
    }

	const differencePercentTotal = () => {
		let _budgetTotal = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				_budgetTotal += curStatus.budget;
			}
		}

		let _spendTotal = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				_spendTotal += curStatus.spend;
			}
		}
		let percentDiff = (((_budgetTotal - _spendTotal)  / ((_budgetTotal + _spendTotal) / 2)) * 100).toFixed(2)
		return percentDiff + '%'
    }

	

	let footerGroup = <ColumnGroup>
	<Row>
		<Column footer="Total" footerStyle={{color: 'white',textAlign: 'right'}}/>
		<Column footer={yearTotal} footerStyle={{color: 'white',textAlign: 'right'}}/>
		<Column footer={budgetTotal} footerStyle={{color: 'white',textAlign: 'right'}}/>
		<Column footer={spendTotal} footerStyle={{color: 'white',textAlign: 'right'}}/>
		<Column footer={differenceTotal} footerStyle={{color: 'white',textAlign: 'right'}}/>
		<Column footer={differencePercentTotal} footerStyle={{color: 'white',textAlign: 'right'}}/>
	</Row>
	</ColumnGroup>;

	let brandTemplate = (rowData) => {
		return (
			<div className="flex flex-column">
				<div style={{textTransform:'capitalize'}}>
					{rowData.brand}
				</div>
			</div>
		)
	}

	const exportExcel = () => {
       
		
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Brand Summary");
		worksheet.properties.defaultColWidth = 30;
		worksheet.properties.defaultRowHeight = 16;

		let gradientColor = "FF90EE90"

		worksheet.mergeCells("A1:F1");
		fncDrawTitleCell(worksheet, 'A1', "Spend by Brand (" + dateStringsMap()[dates] + ")")

		//worksheet.mergeCells("D1:F1");
		//fncDrawTitleYearCell(worksheet, 'D1', dateStringsMap()[dates])
	
		fncDrawHeaderCellBudget(worksheet, 'A3', 'Brand')
		fncDrawHeaderCellBudget(worksheet, 'B3', dateStringsMap()['fullYear'] + ' Budget')
		fncDrawHeaderCellBudget(worksheet, 'C3', dateStringsMap()[dates] + ' Budget')
		fncDrawHeaderCellBudget(worksheet, 'D3', dateStringsMap()[dates] + ' Used')
		fncDrawHeaderCellBudget(worksheet, 'E3', "Difference")
		fncDrawHeaderCellBudget(worksheet, 'F3', "Percent Difference")
		let curCell
		for(let curRow of spendSummary) {
			let dataRow = worksheet.addRow();
			
			curCell = dataRow.getCell(1);
			curCell.value = curRow.brand
		
			curCell = dataRow.getCell(2);
			curCell.value = curRow.yearBudget
			curCell.numFmt = '"$"#,##0'

			curCell = dataRow.getCell(3);
			curCell.value = curRow.budget
			curCell.numFmt = '"$"#,##0'

			curCell = dataRow.getCell(4);
			curCell.value = curRow.spend
			curCell.numFmt = '"$"#,##0'


			curCell = dataRow.getCell(5);
			curCell.value = curRow.difference
			curCell.numFmt = '"$"#,##0'

			let percentDiff = (((curRow.budget - curRow.spend)  / ((curRow.budget + curRow.spend) / 2)))
			curCell = dataRow.getCell(6);
			curCell.value = percentDiff
			curCell.numFmt =  '0.00%'
			
		}

		let dataFooterRow = worksheet.addRow();
		fncDrawFooterCellBudget(dataFooterRow,1,'Total')
		curCell = fncDrawFooterCellBudget(dataFooterRow,2,yearTotal())
		curCell.numFmt = '"$"#,##0'
		curCell = fncDrawFooterCellBudget(dataFooterRow,3,budgetTotal())
		curCell.numFmt = '"$"#,##0'
		curCell = fncDrawFooterCellBudget(dataFooterRow,4,spendTotal())
		curCell.numFmt = '"$"#,##0'
		curCell = fncDrawFooterCellBudget(dataFooterRow,5,differenceTotal())
		curCell.numFmt = '"$"#,##0'
		
		let _budgetTotal = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				_budgetTotal += curStatus.budget;
			}
		}

		let _spendTotal = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				_spendTotal += curStatus.spend;
			}
		}

		
		let percentDiff = (((_budgetTotal - _spendTotal)  / ((_budgetTotal + _spendTotal) / 2)) * 100).toFixed(2) + '%'
		curCell = fncDrawFooterCellBudget(dataFooterRow,6,percentDiff)
		curCell.numFmt =  '0.00%'

		let worksheet2 = ExcelJSWorkbook.addWorksheet("Project Breakdown");
		worksheet2.properties.defaultColWidth = 20;
		worksheet2.properties.defaultRowHeight = 16;

		worksheet2.getColumn(1).width = 60
		worksheet2.getColumn(5).width = 30

		fncDrawHeaderCellBudget(worksheet2, 'A1', 'Active Projects')
		fncDrawHeaderCellBudget(worksheet2, 'B1', 'Project Number')
		fncDrawHeaderCellBudget(worksheet2, 'C1', 'Priority')
		fncDrawHeaderCellBudget(worksheet2, 'D1', 'Start Date')
		fncDrawHeaderCellBudget(worksheet2, 'E1', 'End Date')
		fncDrawHeaderCellBudget(worksheet2, 'F1', 'Budget')
		fncDrawHeaderCellBudget(worksheet2, 'G1', 'Spend')
		fncDrawHeaderCellBudget(worksheet2, 'H1', 'Outside')
		fncDrawHeaderCellBudget(worksheet2, 'I1', "Progress")

		for(let curRow of spendSummary) {
			let dataRow = worksheet2.addRow();
			worksheet2.mergeCells(dataRow['_number'],1,dataRow['_number'],9);
			curCell = fncDrawSubCatBudget(dataRow,1,curRow.brand)

			let curProjectTotals = {
				budget: 0,
				spend: 0, 
				outside: 0
			}
			for (let curProject of curRow.projects) {
				curProjectTotals.budget += curProject.budget
				curProjectTotals.spend += curProject.spend - curProject.outside
				curProjectTotals.outside += curProject.outside
				let projectRow = worksheet2.addRow();
				curCell = projectRow.getCell(1);
				curCell.value = curProject.projectNumber + ' ' + curProject.projectName

				curCell = projectRow.getCell(2);
				curCell.value = curProject.projectNumber

				curCell = projectRow.getCell(3);
				curCell.value = curProject.priority

				curCell = projectRow.getCell(4);
				curCell.value = dayjs(curProject.startDate).format('MMMM YYYY') 

				curCell = projectRow.getCell(5);
				curCell.value = dayjs(curProject.endDate).format('MMMM YYYY') 

				curCell = projectRow.getCell(6);
				curCell.value = curProject.budget
				curCell.numFmt = '"$"#,##0'

				curCell = projectRow.getCell(7);
				curCell.value = curProject.spend - curProject.outside
				curCell.numFmt = '"$"#,##0'

				curCell = projectRow.getCell(8);
				curCell.value = curProject.outside
				curCell.numFmt = '"$"#,##0'

				

				let percentDiff = (((curProject.budget - curProject.spend)  / ((curProject.budget + curProject.spend) / 2)))
				let percent = 0
				if (curProject.budget > 0) percent = (curProject.spend / curProject.budget)
				curCell = projectRow.getCell(9);
				curCell.value = percent
				let gradientPercent = Math.min(percent.toFixed(1),1)
				let gradientStops = [
					{position:0, color:{argb: gradientColor}},
					{position:Math.min(percent.toFixed(1),1), color:{argb:'FFFFFFFF'}},
					{position:1, color:{argb:'FFFFFFFF'}}
				]

				if (gradientPercent === 1) {
					gradientStops = [
						{position:0, color:{argb:gradientColor}},
						{position:1, color:{argb:gradientColor}}
					]
				}

				if (gradientPercent <= 0) {
					gradientStops = [
						{position:0, color:{argb:'FFFFFFFF'}},
						{position:1, color:{argb:'FFFFFFFF'}}
					]
				}

				curCell.fill = {
					type: 'gradient',
					gradient: 'angle',
					degree: 0,
					stops: gradientStops
				}
				curCell.border = {
					left: {style:'thick', color: {argb:'FFFFFFFF'}},
					bottom: {style:'thin', color: {argb:'FFFFFFFF'}},
					right: {style:'thick', color: {argb:'FFFFFFFF'}}
				};
				curCell.numFmt =  '0.00%'
			}
			let projectTotalRow = worksheet2.addRow();
			curCell = projectTotalRow.getCell(1);
			curCell.alignment = { vertical: 'middle', horizontal: 'right' };
			curCell.font = {bold: true}
			curCell.value = 'Sub Totals'

			curCell = projectTotalRow.getCell(6);
			curCell.font = {bold: true}
			curCell.value = curProjectTotals.budget
			curCell.numFmt = '"$"#,##0'

			curCell = projectTotalRow.getCell(7);
			curCell.font = {bold: true}
			curCell.value = curProjectTotals.spend 
			curCell.numFmt = '"$"#,##0'

			curCell = projectTotalRow.getCell(8);
			curCell.font = {bold: true}
			curCell.value = curProjectTotals.outside
			curCell.numFmt = '"$"#,##0'
			
			let percent = 0
			if (curProjectTotals.budget > 0) percent = ((curProjectTotals.spend + curProjectTotals.outside) / curProjectTotals.budget)
			curCell = projectTotalRow.getCell(9);
			curCell.font = {bold: true}
			curCell.value = percent

			let gradientPercent = Math.min(percent.toFixed(1),1)
			let gradientStops = [
				{position:0, color:{argb: gradientColor}},
				{position:Math.min(percent.toFixed(1),1), color:{argb:'FFFFFFFF'}},
				{position:1, color:{argb:'FFFFFFFF'}}
			]

			if (gradientPercent === 1) {
				gradientStops = [
					{position:0, color:{argb:gradientColor}},
					{position:1, color:{argb:gradientColor}}
				]
			}

			if (gradientPercent <= 0) {
				gradientStops = [
					{position:0, color:{argb:'FFFFFFFF'}},
					{position:1, color:{argb:'FFFFFFFF'}}
				]
			}

			curCell.fill = {
				type: 'gradient',
				gradient: 'angle',
				degree: 0,
				stops: gradientStops
			}
			curCell.border = {
				left: {style:'thick', color: {argb:'FFFFFFFF'}},
				bottom: {style:'thin', color: {argb:'FFFFFFFF'}},
				right: {style:'thick', color: {argb:'FFFFFFFF'}}
			};
			curCell.numFmt =  '0.00%'
		}

		let dataFooterRow2 = worksheet2.addRow();
		
		fncDrawFooterCellBudget(dataFooterRow2,1,franchiseName + ' Totals')
		worksheet2.mergeCells(dataFooterRow2['_number'],2,dataFooterRow2['_number'],5);
		fncDrawFooterCellBudget(dataFooterRow2,2,'')
		curCell = fncDrawFooterCellBudget(dataFooterRow2,6,budgetTotal())
		curCell.numFmt = '"$"#,##0'
		curCell = fncDrawFooterCellBudget(dataFooterRow2,7,spendTotal())
		curCell.numFmt = '"$"#,##0'
		curCell = fncDrawFooterCellBudget(dataFooterRow2,8,outsideTotal())
		curCell.numFmt = '"$"#,##0'

		let percentTotal = ((_spendTotal / _budgetTotal) * 100).toFixed(2) + '%'
		curCell = fncDrawFooterCellBudget(dataFooterRow2,9,percentTotal)
		curCell.numFmt =  '0.00%'
		

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  fileName + `.xlsx`
			);
		  });
    }

	const percentDifferenceTE = (rowData) => {
		
		
		let percentDiff = (((rowData.budget - rowData.spend)  / ((rowData.budget + rowData.spend) / 2)) * 100).toFixed(2)
		if (percentDiff < 0 ) {
			return <div style={{color:'red'}}>
				{percentDiff}%
			</div>
		} else {
			return percentDiff + '%'
		}
	}

	return (
		<div className="flex spend">
			<DataTable className="tables" value={spendSummary} footerColumnGroup={footerGroup} style={{width:'100%'}}> 
				<Column field="brand" body={brandTemplate} header="Brand" style={{width: '25%'}}/>
				<Column field="yearBudget" body={dollarTemplateNoDecimal} header={dateStringsMap()['fullYear'] + ' Budget'} style={{textAlign:'right',width: '15%'}} />	
				<Column field="budget" body={dollarTemplateNoDecimal} header={dateStringsMap()[dates] + ' Budget'} style={{textAlign:'right',width: '15%'}} />
				<Column field="spend" body={dollarTemplateNoDecimal} header={dateStringsMap()[dates] + ' Spend'} style={{textAlign:'right',width: '15%'}} />
				<Column field="difference" body={dollarTemplateNoDecimal} header="Difference" style={{textAlign:'right',width: '15%'}} />
				<Column field="" body={percentDifferenceTE} header="Percent Difference" style={{textAlign:'right',width: '15%'}} />
			</DataTable>
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		spendSummary: getProjectByBrand(state, ownProps)
	}
}

const SpendByAudienceStaffContainer = connect(mapStateToProps, null)(SpendByAudienceStaff);
export default SpendByAudienceStaffContainer;