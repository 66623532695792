import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs'

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import ExcelJS  from 'exceljs'
import saveAs from "file-saver";

// Selectors
import { getOutOfPocketBudget} from '../../selectors/dashboardWidgetsSelector'

// helpers
import { dollarTemplate, dateStringsMap } from '../../utilities/CommonSnippets'
import { fncDrawHeaderCellOOP, fncDrawFooterCellOOP, fncDrawTitleCell} from '../../utilities/commonXslxFunctions'

const OOPCostsTable = (props) => {
	const {  outOfPocketBudget, downloadClicked, fileName, dates } = props

	useEffect(() => {
		if (downloadClicked > 0) exportExcel()
	},[downloadClicked])

	const projectNameTemplate = (rowData) => {
		return (
			<div className="flex flex-column">
				<div>
					{rowData.projectName}
				</div>
				<div style={{fontWeight:'600'}}>
					{dayjs(rowData.startDate).format('MMMM YYYY')} - {dayjs(rowData.endDate).format('MMMM YYYY')}
				</div>
			</div>
		)
	}

	const getTotals = (field) => {
        let total = 0;
        if (outOfPocketBudget) {
			for(let curTotal of outOfPocketBudget) {
				total += curTotal[field];
			}
		}

        return dollarTemplate(total)
    }

	let footerGroup = () => {
		
		return (
			<ColumnGroup>
			<Row>
				<Column footer="Totals" colSpan={3} footerStyle={{color: 'white',textAlign: 'right'}}/>
				<Column footer={getTotals('adjustedBudget')} footerStyle={{color: 'white',textAlign: 'right'}}/>
				<Column footer={getTotals('costs')} footerStyle={{color: 'white',textAlign: 'right'}}/>
			</Row>
			</ColumnGroup>
		)
	}

	const exportExcel = () => {
       
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Out-of-Pocket Spend");
		worksheet.properties.defaultColWidth = 20;
		worksheet.properties.defaultRowHeight = 16;
		worksheet.getColumn(2).width = 40
		worksheet.getColumn(3).width = 60
		
		worksheet.mergeCells("A1:E1");
		fncDrawTitleCell(worksheet, 'A1', "Out-of-Pocket Budget (" + dateStringsMap()[dates] + ")")
		
		// TOP BAR
		fncDrawHeaderCellOOP(worksheet, 'A3', 'Brand')
		fncDrawHeaderCellOOP(worksheet, 'B3', 'Project')
		fncDrawHeaderCellOOP(worksheet, 'C3', 'Description of OOPs')
		fncDrawHeaderCellOOP(worksheet, 'D3', 'Budget')
		fncDrawHeaderCellOOP(worksheet, 'E3', 'Amount Spent')

		let cell = null
		for (let curRow of outOfPocketBudget) {
			let dataRow = worksheet.addRow();
			cell = dataRow.getCell(1).value = curRow.brandName
			cell = dataRow.getCell(2).value = curRow.projectName
			cell = dataRow.getCell(3).value = curRow.passThroughDesc
			
			cell = dataRow.getCell(4)
			cell.numFmt = '"$"#,##0'
			cell.value = curRow.adjustedBudget

			cell = dataRow.getCell(5)
			cell.numFmt = '"$"#,##0'
			cell.value = curRow.costs

		}

		let dataFooterRow = worksheet.addRow();
		worksheet.mergeCells(dataFooterRow['_number'],1,dataFooterRow['_number'],3);
		fncDrawFooterCellOOP(dataFooterRow,1,'Totals')
		fncDrawFooterCellOOP(dataFooterRow,4, getTotals('adjustedBudget'))
		fncDrawFooterCellOOP(dataFooterRow,5, getTotals('costs'))


		

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  fileName + `.xlsx`
			);
		  });
    }

	const allFieldsTemplate = (rowData,column) => {

		let strClassName = ""
		if (rowData.costs > rowData.adjustedBudget) strClassName = "redFlagColumn"

		return (
			<div className={strClassName}> 
				{column.field === 'brandName' && rowData.brandName}
				{column.field === 'projectName' && projectNameTemplate(rowData,column)}
				{column.field === 'passThroughDesc' && rowData.passThroughDesc}
				{column.field === 'adjustedBudget' && dollarTemplate(rowData,column)}
				{column.field === 'costs' && dollarTemplate(rowData,column)}
			</div>
		)


	}
	
	return (
		<div className="flex oop">
			<DataTable className="tables" value={outOfPocketBudget} footerColumnGroup={footerGroup()} style={{width:'100%'}}
					paginator paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,50,100]}
			>
				<Column field="brandName" header="Brand" body={allFieldsTemplate}  style={{width:'15%'}} />
				<Column field="projectName" sortable header="Project" body={allFieldsTemplate} style={{width:'25%'}} />
				<Column field="passThroughDesc" header="Description of OOPs" body={allFieldsTemplate} style={{width:'30%'}} />
				<Column field="adjustedBudget" sortable header="Budget" body={allFieldsTemplate} style={{textAlign: 'right', width:'15%'}} />
				<Column field="costs" sortable header="Amount Spent" body={allFieldsTemplate} style={{textAlign: 'right', width:'15%'}} />
				
			</DataTable>
		</div>
	)
}

/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	return {
		outOfPocketBudget: getOutOfPocketBudget(state, ownProps)
	}
}

const OOPCostsTableContainer = connect(mapStateToProps, null)(OOPCostsTable);
export default OOPCostsTableContainer;