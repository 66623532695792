import axios from 'axios';
import decode from 'jwt-decode';
import {GET_USER_AUTH, RESET_PASSWORD, CHECK_UUID, UPDATE_PASSWORD, GET_USER_AUTH_SSO, LOGIN_HYBRID, LOGOUT_HYBRID} from '../constants/URLS';


export default class AuthService {
    // Initializing important variables
    constructor() {
        this.login = this.login.bind(this);
        this.getToken = this.getToken.bind(this);
        this.getProfile = this.getProfile.bind(this);
    } 

    login(username, password) {
		var newPWD = password
		
		// Get a token from api server
		return axios({
			method: 'post',
            url: GET_USER_AUTH,
            data:{
                u: username,
                p: newPWD
            }
		    }).then(res => {
				if (!res.data.success) {
                    return Promise.reject(res.data.message);
                }else{
					axios.defaults.headers.common['Authorization'] = res.data.token;
					this.setToken(res.data.token) // Setting the token in localStorage
					
					//These are just stored here for convience if need something for frontend but not worried about user toggling the value. User can not change actual value
					localStorage.setItem('userData', JSON.stringify(res.data.user))
                    return Promise.resolve(res);
                }
        })
    }

	loginSSO(uuid) {
		// Get a token from api server
		
		return axios({
			method: 'post',
            url: GET_USER_AUTH_SSO,
            data:{
                uuid: uuid
            }
		    }).then(res => {
				if (!res.data.success) {
                    return Promise.reject(res.data.message);
                }else{
					axios.defaults.headers.common['Authorization'] = res.data.token;
					this.setToken(res.data.token) // Setting the token in localStorage
					
					//These are just stored here for convience if need something for frontend but not worried about user toggling the value. User can not change actual value
					localStorage.setItem('userData', JSON.stringify(res.data.user))
					document.location.href='/'
                    return Promise.resolve(res);
                }
        })
    }

	loginHybrid(agencyID) {
		// Get a token from api server
		
		return axios({
			method: 'post',
            url: LOGIN_HYBRID,
            data:{
                agencyID: agencyID
            }
		    }).then(res => {
				if (!res.data.success) {
                    return Promise.reject(res.data.message);
                }else{
					axios.defaults.headers.common['Authorization'] = res.data.token;
					this.setToken(res.data.token) // Setting the token in localStorage
					
					//These are just stored here for convience if need something for frontend but not worried about user toggling the value. User can not change actual value
					localStorage.setItem('userData', JSON.stringify(res.data.user))
					document.location.reload()
                    return Promise.resolve(res);
                }
        })
    }

	logoutHybrid(props) {
		// Get a token from api server
		
		return axios({
			method: 'post',
            url: LOGOUT_HYBRID
		    }).then(res => {
				if (!res.data.success) {
                    return Promise.reject(res.data.message);
                }else{
					axios.defaults.headers.common['Authorization'] = res.data.token;
					this.setToken(res.data.token) // Setting the token in localStorage
					
					//These are just stored here for convience if need something for frontend but not worried about user toggling the value. User can not change actual value
					localStorage.setItem('userData', JSON.stringify(res.data.user))
					document.location.reload()
                    return Promise.resolve(res);
                }
        })
    }
	

	resetPassword(username) {
		// Get a token from api server
		return axios({
			method: 'post',
            url: RESET_PASSWORD,
            data:{
                u: username
            }
		    }).then(res => {
				if (!res.data.success) {
                    return Promise.reject(res.data.message);
                }else{
					return Promise.resolve(res);
                }
        })
    }

	checkUUID(uuid) {
		// Get a token from api server
		return axios({
			method: 'post',
            url: CHECK_UUID,
            data:{
                uuid: uuid
            }
		    }).then(res => {
				if (!res.data.success) {
                    return Promise.reject(res.data.message);
                }else{
					return Promise.resolve(res);
                }
        })
    }

	updatePassword(uuid, password) {
		// Get a token from api server
		return axios({
			method: 'post',
            url: UPDATE_PASSWORD,
            data:{
                uuid: uuid, 
				password: password
            }
		    }).then(res => {
				if (!res.data.success) {
                    return Promise.reject(res.data.message);
                }else{
					return Promise.resolve(res);
                }
        })
    }

	

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // Getting token from localstorage
        axios.defaults.headers.common['Authorization'] = token;
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
           	const decoded = decode(token);
            
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

	setToken(idToken) {
		// Saves user token to localStorage
		localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
		localStorage.removeItem('id_token');
		return Promise.resolve();
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

}