const headerFontDetails = {
	size: 14,
	bold: true,
	color: { argb: 'FFFFFF' },
}

const titleDetails = {
	size: 14,
	bold: true,
	color: { argb: '000000' },
}

const titleDetailsImport = {
	size: 12,
	bold: true,
	color: { argb: '000000' },
}

const titleYearDetails = {
	size: 12,
	bold: false,
	color: { argb: '000000' },
}



const footerFontDetails = {
	size: 12,
	bold: true,
	color: { argb: 'FFFFFF' },
}

const footerFontDetailsWhite = {
	size: 12,
	bold: true,
	color: { argb: '000000' },
}

const headerFillDetails = {
	type: 'pattern',
	pattern:'solid',
	fgColor:{argb:'D983CB'}
}

const headerFillDetailsBudget = {
	type: 'pattern',
	pattern:'solid',
	fgColor:{argb:'ACDAE1'}
}

const headerFillDetailsOOP = {
	type: 'pattern',
	pattern:'solid',
	fgColor:{argb:'7BCC77'}
}



const footerFillDetails = {
	type: 'pattern',
	pattern:'solid',
	fgColor:{argb:'A93977'}
}

const footerFillDetailsBudget = {
	type: 'pattern',
	pattern:'solid',
	fgColor:{argb:'79C1CC'}
}

const footerFillDetailsOOP = {
	type: 'pattern',
	pattern:'solid',
	fgColor:{argb:'1FB437'}
}



const footerFillDetailsWhite = {
	type: 'pattern',
	pattern:'solid',
	fgColor:{argb:'FFFFFF'}
}

const headerBorderDetails = {
	style:'thin',
	color: {argb:'FFFFFF'}
}

export const fncDrawTitleCell = (worksheet, cell, value) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'center' };
	tempCell.font = titleDetails;
	tempCell.value = value;
	
}

export const fncDrawTitleCellImport = (worksheet, cell, value) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'left' };
	tempCell.font = titleDetailsImport;
	tempCell.value = value;
	
}



export const fncDrawTitleYearCell = (worksheet, cell, value) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'right' };
	tempCell.font = titleYearDetails;
	tempCell.value = value;
	
}



export const fncDrawHeaderCell = (worksheet, cell, value) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'center' };
	tempCell.font = headerFontDetails;
	tempCell.fill = headerFillDetails
	tempCell.value = value;
	tempCell.border = {
		top: headerBorderDetails,
		left: headerBorderDetails,
		bottom: headerBorderDetails,
		right: headerBorderDetails
	  };
}

export const fncDrawHeaderCellBudget = (worksheet, cell, value) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'center' };
	tempCell.font = headerFontDetails;
	tempCell.fill = headerFillDetailsBudget
	tempCell.value = value;
	tempCell.border = {
		top: headerBorderDetails,
		left: headerBorderDetails,
		bottom: headerBorderDetails,
		right: headerBorderDetails
	  };
}

export const fncDrawHeaderCellOOP = (worksheet, cell, value) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'center' };
	tempCell.font = headerFontDetails;
	tempCell.fill = headerFillDetailsOOP
	tempCell.value = value;
	tempCell.border = {
		top: headerBorderDetails,
		left: headerBorderDetails,
		bottom: headerBorderDetails,
		right: headerBorderDetails
	  };
}



export const fncDrawFooterCellOOP = (worksheet, cell, value, formula) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'right' };
	tempCell.font = footerFontDetails;
	tempCell.fill = footerFillDetailsOOP

	if (!formula) tempCell.value = value;
	else tempCell.value = { formula: formula, result: value }  

	
	tempCell.border = {
		top: headerBorderDetails,
		left: headerBorderDetails,
		bottom: headerBorderDetails,
		right: headerBorderDetails
	  };

	  return tempCell
}


export const fncDrawFooterCell = (worksheet, cell, value, formula) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'right' };
	tempCell.font = footerFontDetails;
	tempCell.fill = footerFillDetails

	if (!formula) tempCell.value = value;
	else tempCell.value = { formula: formula, result: value }  

	
	tempCell.border = {
		top: headerBorderDetails,
		left: headerBorderDetails,
		bottom: headerBorderDetails,
		right: headerBorderDetails
	  };

	  return tempCell
}

export const fncDrawFooterCellBudget = (worksheet, cell, value, formula) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'right' };
	tempCell.font = footerFontDetails;
	tempCell.fill = footerFillDetailsBudget

	if (!formula) tempCell.value = value;
	else tempCell.value = { formula: formula, result: value }  

	
	tempCell.border = {
		top: headerBorderDetails,
		left: headerBorderDetails,
		bottom: headerBorderDetails,
		right: headerBorderDetails
	  };

	  return tempCell
}

export const fncDrawSubCatBudget = (worksheet, cell, value) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'left' };
	tempCell.font = footerFontDetails;
	tempCell.fill = footerFillDetailsBudget

	tempCell.value = value; 

	tempCell.border = {
		top: headerBorderDetails,
		left: headerBorderDetails,
		bottom: headerBorderDetails,
		right: headerBorderDetails
	  };

	  return tempCell
}



export const fncDrawFooterCellWhite = (worksheet, cell, value, formula) => {
	const tempCell = worksheet.getCell(cell);
	tempCell.alignment = { vertical: 'middle', horizontal: 'right' };
	tempCell.font = footerFontDetailsWhite;
	tempCell.fill = footerFillDetailsWhite;

	if (!formula) tempCell.value = value;
	else tempCell.value = { formula: formula, result: value }  

	
	tempCell.border = {
		top: headerBorderDetails,
		left: headerBorderDetails,
		bottom: headerBorderDetails,
		right: headerBorderDetails
	  };

	return tempCell
}