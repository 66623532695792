import React, { useState, useEffect, useRef  } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs'

import { Chart as PrimeChart   } from 'primereact/chart';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';

import ExcelJS  from 'exceljs'
import saveAs from "file-saver";

// Selectors
import { getSpendSummary, getPriorityProjects } from '../../selectors/dashboardWidgetsSelector'

// helpers
import { monthTemplate, dollarTemplateNoDecimal, dateStringsMap } from '../../utilities/CommonSnippets'
import { fncDrawHeaderCellBudget, fncDrawTitleCell} from '../../utilities/commonXslxFunctions'

import { BUDGET_COLOR_ARRAY} from '../../constants/colors'

const ProjectSpendSummary = (props) => {
	const Chart = (props) => <PrimeChart { ...props } />
	const { spendSummary, dates, priorityProjects, entityName } = props
	const [displayDetails, setDisplayDetails] = useState(false);
	const [displayPriority, setDisplayPriority] = useState(false);
	const [detailsDataSmall, setDetailsDataSmall] = useState({});
	const [detailsDataLarge, setDetailsDataLarge] = useState({});
	const [activeIndex, setActiveIndex] = useState(0);
	const [smallHeight, setSmallHeight] = useState(0);
	const [largeHeight, setLargeHeight] = useState(0); 
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [statuses, setStatuses] = useState(null);
	const [selectedPriority, setSelectedPriority] = useState('high');
	const [priorities, setPriorities] = useState(null);
	const [filters, setFilters] = useState({'priority': {value: 'high'}});
	const [filteredData, setFilteredData] = useState([]);
	

	const dt = useRef(null);
	useEffect(() => {
		setFilters({'priority': {value: 'high'}})
		setSelectedPriority('high')
		let _filteredData = priorityProjects.filter((cur) => cur.priority === 'high')
		setFilteredData(_filteredData)
	}, [displayPriority]);

	useEffect(() => {
    	let _statuses = priorityProjects.reduce((acc,cur) => {
			acc[cur.status] = true
			return acc
		},{})

		setStatuses(Object.keys(_statuses).map((cur) => {
			const _status = cur.charAt(0).toUpperCase() + cur.slice(1)
			
			return {
				value: cur, 
				label: _status.replace('_',' ')
			}
		}))

		let _priorities = priorityProjects.reduce((acc,cur) => {
			acc[cur.priority] = true
			return acc
		},{})

		setPriorities(Object.keys(_priorities).map((cur) => {
			const _priority = cur.charAt(0).toUpperCase() + cur.slice(1)
			
			return {
				value: cur, 
				label: _priority
			}
		}))
    }, [priorityProjects]);

	const budgetTotal = () => {
        let total = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				total += curStatus.budget;
			}
		}

        return dollarTemplateNoDecimal(total);
    }

	const spendTotal = () => {
        let total = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				total += curStatus.spend;
			}
		}

        return dollarTemplateNoDecimal(total);
    }

	const yearTotal = () => {
        let total = 0;
        if (spendSummary) {
			for(let curStatus of spendSummary) {
				total += curStatus.yearBudget;
			}
		}
		
        return dollarTemplateNoDecimal(total);
    }

	let footerGroup = <ColumnGroup>
	<Row>
		<Column footer="Total" footerStyle={{color: 'white',textAlign: 'right'}}/>
		<Column footer={budgetTotal} footerStyle={{color: 'white',textAlign: 'right'}}/>
		<Column footer={spendTotal} footerStyle={{color: 'white',textAlign: 'right'}}/>
		{dates !== 'fullYear' && dates !== 'lastYear' && 
			<Column footer={yearTotal} footerStyle={{color: 'white',textAlign: 'right'}}/>
		}
	</Row>
	</ColumnGroup>;

	const fncViewDetails = (rowData) => {
		
		const { dates } = props
		let arrSmall = []
		let arrLarge = []


		for (let curProj of rowData.projects) {
			
			if (curProj.fiscalYearTotal >= 100000) {
				arrLarge.push(curProj)
			} else {
				arrSmall.push(curProj)
			}
		}

		const chartDataSmall = {
			labels: arrSmall.map(cur => {
				return [
					cur.projectName,
					dayjs(cur.startDate).format('MMMM YYYY') + ' - ' + dayjs(cur.endDate).format('MMMM YYYY')
				]
			}),
			datasets: [
				{
				  label: 'Used',
				  data: arrSmall.map(cur => {
					return cur.spend
					}),
				  borderColor: BUDGET_COLOR_ARRAY[0],
				  backgroundColor: BUDGET_COLOR_ARRAY[0],
				},
				{
				  label: 'Budget',
				  data: arrSmall.map(cur => {
					return cur.budget
					}),
				  borderColor: BUDGET_COLOR_ARRAY[3],
				  backgroundColor: BUDGET_COLOR_ARRAY[3]
				}
			  ]
		};

		const chartDataLarge = {
			labels: arrLarge.map(cur => {
				return [
					cur.projectName,
					dayjs(cur.startDate).format('MMMM YYYY') + ' - ' + dayjs(cur.endDate).format('MMMM YYYY')
				]
			}),
			datasets: [
				{
				  label: 'Used',
				  data: arrLarge.map(cur => {
					return cur.spend
					}),
				  borderColor: BUDGET_COLOR_ARRAY[0],
				  backgroundColor: BUDGET_COLOR_ARRAY[0],
				},
				{
				  label: 'Budget',
				  data: arrLarge.map(cur => {
					return cur.budget
					}),
				  borderColor: BUDGET_COLOR_ARRAY[3],
				  backgroundColor: BUDGET_COLOR_ARRAY[3],
				}
			  ]
		};

		if (dates !== 'fullYear' && dates !== 'lastYear') {
			chartDataLarge.datasets.push({
				label: dateStringsMap()['fullYear'],
				data: arrLarge.map(cur => {
					return cur.fiscalYearTotal
					}),
				borderColor: BUDGET_COLOR_ARRAY[5],
				backgroundColor: BUDGET_COLOR_ARRAY[5]
			})
			chartDataSmall.datasets.push({
				label: dateStringsMap()['fullYear'],
				data: arrSmall.map(cur => {
				  return cur.fiscalYearTotal
				  }),
				borderColor: BUDGET_COLOR_ARRAY[5],
				backgroundColor: BUDGET_COLOR_ARRAY[5]
			  })
		}
	
		const chartOptions = {
			layout: {
				padding: {
					right: 100,
					left: 20
				}
			},
			indexAxis: 'y',
			maintainAspectRatio: false,
			scales: {
				x: {
				  ticks: {
					// For a category axis, the val is the index so the lookup via getLabelForValue is needed
					callback: function(val, index) {
					  // Hide every 2nd tick label
						if (index !== 0 && index % 2 === 0) return '$' + this.getLabelForValue(val)
					},
				  }
				}
			  },



			  

			
			plugins: {
				// Change options for ALL labels of THIS CHART
				datalabels: {
					anchor: 'end',
					align: 'end',
					font: {
						size: 12,
						weight: 'bold',

					},
					formatter: function(value, context) {
						//return context.dataset.data[context.dataIndex] 
						return '$' + Intl.NumberFormat('en-US').format(Math.round(context.dataset.data[context.dataIndex]))
					},
					display: function(context) {
						return context.dataset.data[context.dataIndex] > 0;
					}
				},
				tooltip: {
					callbacks: {
						title: (context) =>{
							let arrLabelElements = context[0].label.split(',')
							 return [arrLabelElements[0],arrLabelElements[1]]	
						},
						label: function(context) {
							var label = context.dataset.label || '';
							if (label) {
								label += ': ';
							}
							if (context.parsed.y !== null) {
								label += '$' + Intl.NumberFormat('en-US').format(Math.round(context.parsed.x));
							}
							return label;
							}
					}
				}
			  }
		};

		let strTitle = <div>Project Spend Detail: <span style={{textTransform:'capitalize'}}>{rowData.status.replace('_',' ')}</span> Projects ({dateStringsMap()[dates]})</div>

		let _detailsDataSmall = {
			title: strTitle,
			chartOptions: chartOptions,
			chartData: chartDataSmall
		}

		let _detailsDataLarge = {
			title: strTitle,
			chartOptions: chartOptions,
			chartData: chartDataLarge
		}

		let _smallHeight = _detailsDataSmall.chartData.labels.length * 60
		let _largeHeight = _detailsDataLarge.chartData.labels.length * 60

		if (_smallHeight < 160 ) _smallHeight = 160
		if (_largeHeight < 160 ) _largeHeight = 160

		setSmallHeight(_smallHeight)
		setLargeHeight(_largeHeight)
		

		setDetailsDataSmall(_detailsDataSmall)
		setDetailsDataLarge(_detailsDataLarge)
		setDisplayDetails(true)
	}

	let statusTemplate = (rowData) => {
		return (
			<div className="flex flex-column" style={{cursor:'pointer'}} onClick={(e) => {fncViewDetails(rowData)}}>
				<div style={{textTransform:'capitalize'}}>
					{rowData.status.replace('_',' ')}
				</div>
				<div>
					({rowData.totalCount} projects)
				</div>
			</div>
		)
	}

	let priorityTemplate = (rowData, column) => {
		
		let strClassName = ""
		return (
			<div className={"flex flex-column " + strClassName}>
				<div style={{textTransform:'capitalize'}}>
					{rowData.priority}
				</div>
			</div>
		)
	}

	const renderFooter = () => {
		return (
			
			<div>
				<Button label="OK" icon="pi pi-checkmark" onClick={(e) => fncCloseModals()} />
			</div>
		);
	}

	const renderFooterPriority = () => {
		return (
			
			<div className="flex justify-content-between">
				<div className="align-self-end downloadIcon" title="Download Report" onClick={(e) => exportExcel()}>
					<i className="fa fa-download"></i>
				</div>
				<Button label="OK" icon="pi pi-checkmark" onClick={(e) => fncCloseModals()} />
			</div>
		);
	}

	const fncCloseModals = () => {
		setDisplayDetails(false)
		setDisplayPriority(false)
	}

	const brandTemplate = (rowData) => {
		return (
			<div className="flex flex-column" >
				<div className="mb-2 description">{rowData.brandName}</div>
				{rowData.audienceID && 
					<div className="mb-2 description">({rowData.audienceName})</div>
				}
			</div>
		)
	}

	const exportExcel = () => {
       
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Priority");
		worksheet.properties.defaultRowHeight = 16;
		worksheet.getColumn(1).width = 20
		worksheet.getColumn(2).width = 60
		worksheet.getColumn(3).width = 30
		worksheet.getColumn(4).width = 15
		worksheet.getColumn(5).width = 20
		worksheet.getColumn(6).width = 20
		worksheet.getColumn(7).width = 15

		worksheet.mergeCells("A1:D1");
		fncDrawTitleCell(worksheet, 'A1', entityName + " Priority Projects")
	
		fncDrawHeaderCellBudget(worksheet, 'A3', 'Project Number')
		fncDrawHeaderCellBudget(worksheet, 'B3', 'Project')
		fncDrawHeaderCellBudget(worksheet, 'C3', 'Brand/Audience')
		fncDrawHeaderCellBudget(worksheet, 'D3', 'Priority')
		fncDrawHeaderCellBudget(worksheet, 'E3', 'Start Date')
		fncDrawHeaderCellBudget(worksheet, 'F3', 'Finish Date')
		fncDrawHeaderCellBudget(worksheet, 'G3', 'Status')

		let curCell
		let curFillDetails_odd = {
			type: 'pattern',
			pattern:'solid',
			fgColor:{argb:'d3e4f6'}
		}
		for(let curProjIndex in filteredData) {
			let curProj = filteredData[curProjIndex]

			let dataRow = worksheet.addRow();
			let curMod = curProjIndex % 2

			
			
			

			curCell = dataRow.getCell(1);
			curCell.value = curProj.projectNumber
			curCell.numFmt = '@'

			if (curMod) {
				curCell.fill =  curFillDetails_odd
			}

			curCell = dataRow.getCell(2);
			curCell.value = curProj.projectName
			if (curMod) {
				curCell.fill =  curFillDetails_odd
			}

			curCell = dataRow.getCell(3);
			curCell.value = curProj.brandName
			if (curMod) {
				curCell.fill =  curFillDetails_odd
			}

			curCell = dataRow.getCell(4);
			curCell.value = curProj.priority
			if (curMod) {
				curCell.fill =  curFillDetails_odd
			}

			curCell = dataRow.getCell(5);
			curCell.value = dayjs(curProj.startDate).format('MMMM YYYY')
			if (curMod) {
				curCell.fill =  curFillDetails_odd
			}

			curCell = dataRow.getCell(6);
			curCell.value = dayjs(curProj.endDate).format('MMMM YYYY')
			if (curMod) {
				curCell.fill =  curFillDetails_odd
			}

			curCell = dataRow.getCell(7);
			curCell.value = curProj.status
			if (curMod) {
				curCell.fill =  curFillDetails_odd
			}
		}

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  entityName + `_priority_projects.xlsx`
			);
		  });
    }

	const onStatusChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatus(e.value);
    }

	const statusFilter = <Dropdown value={selectedStatus} options={statuses} onChange={onStatusChange} placeholder="Select a Status" className="p-column-filter" showClear />;

	const onPriorityChange = (e) => {
        dt.current.filter(e.value, 'priority', 'equals');
        setSelectedPriority(e.value);
    }

	const priorityFilter = <Dropdown value={selectedPriority} options={priorities} onChange={onPriorityChange} placeholder="Select a Priority" className="p-column-filter" showClear />;

	return (
		<div className="flex spend">
			<div className="flex flex-column" style={{width:'100%'}}> 
				<DataTable className="tables" value={spendSummary} footerColumnGroup={footerGroup}>
					<Column field="status" body={statusTemplate} header="" style={{width: '31%'}}/>
					<Column field="budget" body={dollarTemplateNoDecimal} header="Budget" style={{textAlign:'right',width: '23%'}} />
					<Column field="spend" body={dollarTemplateNoDecimal} header="Used" style={{textAlign:'right',width: '23%'}} />
					{dates !== 'fullYear' && dates !== 'lastYear' && 
						<Column field="yearBudget" body={dollarTemplateNoDecimal} header={dateStringsMap()['fullYear']} style={{textAlign:'right',width: '23%'}} />
					}
					
				</DataTable>
				<div className="flex justify-content-end priority mt-4" onClick={(e) => setDisplayPriority(true)}>
					<i className="mr-2 pi pi-exclamation-triangle"></i>Project Priority Report
				</div>
			</div>
			{displayPriority === true  && 
				<Dialog header={entityName + " Projects"} blockScroll={true} modal={true} visible={displayPriority} className="projectSpendModal newOverview" footer={renderFooterPriority()} onHide={() => setDisplayPriority(false)}>
					<div className="flex spend">
						<DataTable className="tables" ref={dt}  paginator rows={10} stripedRows value={priorityProjects} 
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" paginatorPosition="both"
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							filters={filters} onFilter={(e) => setFilters(e.filters)} onValueChange={filteredData => setFilteredData(filteredData)}
						>
							<Column field="projectNumber" header="Project Number" style={{width: '15%'}}/>
							<Column field="projectName"  header="Project" style={{width: '40%'}}/>
							<Column field="brandName" filter filterMatchMode="contains" sortable body={brandTemplate} header="Brand/Audience" style={{width: '30%'}} />
							<Column field="priority" filter filterElement={priorityFilter} sortable header="Priority" body={priorityTemplate} style={{width: '15%'}} />
							<Column field="startDate" header="Start Date" body={monthTemplate} style={{width: '15%'}} />
							<Column field="endDate" header="Finish Date" body={monthTemplate} style={{width: '15%'}} />
							<Column field="status" filter filterElement={statusFilter} sortable header="Status" body={statusTemplate} style={{width: '15%'}} />
						</DataTable>
					</div>
				</Dialog>
			}
			{displayDetails === true  && 
				<Dialog header={detailsDataSmall.title} modal={true} visible={displayDetails} className="projectSpendModal" footer={renderFooter()} onHide={() => setDisplayDetails(false)}>
					<div className="flex flex-column">
						{detailsDataLarge.chartData.labels.length > 0 && detailsDataSmall.chartData.labels.length > 0 && 
							<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
								<TabPanel header="Projects $100k and Over">
									<div className="flex flex-column align-items-center" >
										<Chart 
											type="bar" 
											data={detailsDataLarge.chartData} 
											className={"artCharts-bar"} 
											options={detailsDataLarge.chartOptions} 
											style={{height:largeHeight + 'px'}}
										/>
									</div>
								</TabPanel>
								<TabPanel header="Projects Under $100k">
									<div className="flex flex-column align-items-center">
										<Chart 
											type="bar" 
											data={detailsDataSmall.chartData} 
											className={"artCharts-bar"} 
											options={detailsDataSmall.chartOptions} 
											style={{height:smallHeight + 'px'}}
										/>
									</div>
								</TabPanel>
							</TabView>
						}
						{detailsDataLarge.chartData.labels.length > 0 && detailsDataSmall.chartData.labels.length === 0 && 
							<div className="flex flex-column align-items-center" >
								<div className="tableHeading">
									Projects $100k and Over
								</div>
								<Chart 
									type="bar" 
									data={detailsDataLarge.chartData} 
									className={"artCharts-bar"} 
									options={detailsDataLarge.chartOptions} 
									style={{height:largeHeight + 'px'}}
								/>
							</div>
						}	
						{detailsDataLarge.chartData.labels.length === 0 && detailsDataSmall.chartData.labels.length > 0 && 
							<div className="flex flex-column align-items-center" >
								<div className="tableHeading" >
									Projects Under $100k
								</div>
								<Chart 
									type="bar" 
									data={detailsDataSmall.chartData} 
									className={"artCharts-bar"} 
									options={detailsDataSmall.chartOptions} 
									style={{height:smallHeight + 'px'}}
									
								/>
							</div>
						}	
						
						
					
					</div>
				</Dialog>
			}
			
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		spendSummary: getSpendSummary(state,ownProps),
		priorityProjects: getPriorityProjects(state,ownProps),
	}
}

const ProjectSpendSummaryContainer = connect(mapStateToProps, null)(ProjectSpendSummary);
export default ProjectSpendSummaryContainer;