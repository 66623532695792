
/* 
	I believe columns will change position as we develop this and there are rules in place for columns (widths). 
	Easy to just have a little mapping that I can change instead of trying to keep track of all the places
*/
export const COLUMN_MAPPINGS = {
	
	"projectID": {
		display: 'Art Internal ID',
		locked: true,
		width: 15
	}, 
	"projectName": {
		display: 'Project Name',
		locked: false,
		width: 35
	},
	"brandName": {
		display: 'Brand',
		locked: false,
		list: 'brand',
		width: 35
	},
	"poNumber": {
		display: 'PO Number',
		locked: false,
		width: 25
	},
	"projectNumber": {
		display: 'Project Number',
		locked: false,
		width: 25
	},
	"status": {
		display: 'Status',
		locked: false,
		list: 'status'
	},
	"priority": {
		display: 'Priority',
		locked: false,
		list: 'priority'
	},
	"startDate": {
		display: 'Start Date (mm/yyyy)',
		locked: false,
		width: 35,
		numFmt: '@'
	},
	"endDate": {
		display: 'End Date (mm/yyyy)',
		locked: false,
		width: 35,
		numFmt: '@'
	},
	"description": {
		display: 'Notes',
		locked: false,
		width: 35
	},
	"totalProjectBudget": {
		display: 'Cost',
		locked: false,
		width: 35,
		numFmt: '"$"#,##0.00'
	},
	"passThrough": {
		display: 'Pass-through (OOPS)',
		locked: false,
		width: 35,
		numFmt: '"$"#,##0.00'
	},
	"passThrough": {
		display: 'Pass-through (OOPS)',
		locked: false,
		width: 35,
		numFmt: '"$"#,##0.00'
	},
	"passThroughDesc": {
		display: 'Pass-through Desc',
		locked: false,
		width: 45
	},
	"oopCosts": {
		display: 'Pass-through Costs',
		locked: false,
		width: 35,
		clear: true,
		numFmt: '"$"#,##0.00'
	},
	"projectTypes": {
		display: 'Project Types',
		locked: false,
		width: 45
	},
}

export const COLUMN_MAPPINGS_STAFF = {
	
	"staffID": {
		display: 'Art Internal ID',
		locked: true,
		width: 15
	}, 
	"name": {
		display: 'Staff Name',
		locked: false,
		width: 35
	},
	"title": {
		display: 'Title',
		locked: false,
		width: 25
	},
	"category": {
		display: 'Category (unnamed)',
		locked: false,
		width: 25
	},
	"status": {
		display: 'Status',
		locked: false,
		list: 'status'
	},
	"isSenior": {
		display: 'Senior Staff',
		locked: false,
		list: 'priority'
	},
	
	"department": {
		display: 'Department',
		locked: false,
		width: 35,
		list: 'department'
	},
	"rate": {
		display: 'Rate',
		locked: false,
		width: 35,
		numFmt: '"$"#,##0.00'
	}
}