import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import ExcelJS  from 'exceljs'
import saveAs from "file-saver";
import dayjs from 'dayjs'

// Selectors
import { getOutOfPocketChanges} from '../../selectors/dashboardWidgetsSelector'

// helpers
import { dollarTemplate, dateStringsMap } from '../../utilities/CommonSnippets'
import { fncDrawHeaderCellOOP, fncDrawTitleCell} from '../../utilities/commonXslxFunctions'

const OOPCostsTable = (props) => {
	const {  outOfPocketChanges, downloadClicked, fileName, dates } = props
	const [ displayWarning, setDisplayWarning] = useState(false);

	useEffect(() => {
		if (downloadClicked > 0) exportExcel()
	},[downloadClicked])

	const projectNameTemplate = (rowData) => {
		return (
			<div className="flex flex-column">
				<div>
					{rowData.projectName}
				</div>
			</div>
		)
	}

	const allFieldsTemplate = (rowData,column) => {
		if (rowData.outsideCost === true) {
			setDisplayWarning(true)
		}

		return (
			<div> 
				{column.field === 'dateEntered' && rowData.dateEntered}
				{column.field === 'projectName' && projectNameTemplate(rowData,column)}
				{column.field === 'passThroughDesc' && rowData.passThroughDesc}
				{column.field === 'comments' && rowData.comments}
				{column.field === 'costs' && 
					<div className="flex">
						<div>
							{dollarTemplate(rowData,column)}
						</div>
						{rowData.outsideCost === true && 
							<div>*</div>
						}
					</div>
				}
			</div>
		)

	}

	const exportExcel = () => {
       
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Out-of-Pocket Spend");
		worksheet.properties.defaultColWidth = 20;
		worksheet.properties.defaultRowHeight = 16;
		worksheet.getColumn(2).width = 40
		worksheet.getColumn(3).width = 60
		worksheet.getColumn(4).width = 60
		
		worksheet.mergeCells("A1:F1");
		fncDrawTitleCell(worksheet, 'A1', "Out-of-Pocket Spend (" + dateStringsMap()[dates] + ")")
		
		// TOP BAR
		fncDrawHeaderCellOOP(worksheet, 'A3', 'Date')
		fncDrawHeaderCellOOP(worksheet, 'B3', 'Project')
		fncDrawHeaderCellOOP(worksheet, 'C3', 'Description of OOPs')
		fncDrawHeaderCellOOP(worksheet, 'D3', 'Notes')
		fncDrawHeaderCellOOP(worksheet, 'E3', 'Amount Spent')
		fncDrawHeaderCellOOP(worksheet, 'F3', 'Remaining')

		let cell = null
		for (let curRow of outOfPocketChanges) {
			let dataRow = worksheet.addRow();
			cell = dataRow.getCell(1)
			cell.numFmt = '@'
			cell.value = dayjs(curRow.dateEntered).format('MMM DD, YYYY')
			cell = dataRow.getCell(2).value = curRow.projectName
			cell = dataRow.getCell(3).value = curRow.passThroughDesc
			cell = dataRow.getCell(4).value = curRow.comments

			cell = dataRow.getCell(5)
			cell.numFmt = '"$"#,##0'
			cell.value = curRow.costs

			cell = dataRow.getCell(6)
			cell.numFmt = '"$"#,##0'
			cell.value = curRow.remaining
		}

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  fileName + `.xlsx`
			);
		  });
    }
	
	return (
		<div className="flex flex-column oop">
			<DataTable className="tables" value={outOfPocketChanges}  style={{width:'100%'}}
				paginator paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,50,100]}
			>
				<Column field="dateEntered" header="Date" body={allFieldsTemplate} style={{width:'12%'}} />
				<Column field="projectName" header="Project" body={allFieldsTemplate} style={{width:'23%'}} />
				<Column field="passThroughDesc" header="Description of OOPs" body={allFieldsTemplate} style={{width:'25%'}} />
				<Column field="comments" header="Notes" style={{width:'25%'}} body={allFieldsTemplate} />
				<Column field="costs"  body={allFieldsTemplate} header="Amount Spent" style={{textAlign:'right',width:'15%'}} />
				
			</DataTable>
			{displayWarning && 
				<div>
					*Amount spent outside of the project's date range.
				</div>
			}
		</div>
	)
}

/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	return {
		outOfPocketChanges: getOutOfPocketChanges(state, ownProps)
	}
}

const OOPCostsTableContainer = connect(mapStateToProps, null)(OOPCostsTable);
export default OOPCostsTableContainer;