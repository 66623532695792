import React, { useState, useEffect, useRef } from 'react';

// PrimeReact Components
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import { Dialog } from 'primereact/dialog';

import AuthService from '../../services/AuthService'

// BreadCrumbs component
import BreadCrumbs from '../../components/breadCrumbs/breadCrumbs-container'

// Modals
import ProjectDetailsModal from '../../modals/projectDetails';
import ProjectAddHours from '../../modals/projectAddHours'
import AddStaff from '../../modals/addStaff'
import StaffAddHours from '../../modals/staffAddHours'

// Components 
import AgencyProjectList from '../../components/agencyProjectList'
import AgencyStaffList from '../../components/agencyStaffList'

const AgencyWrapper = (props) => {
	const [displayEntry, setDisplayEntry] = useState(false)
	const [displayHours, setDisplayHours] = useState(false)
	const [displayStaff, setDisplayStaff] = useState(false)
	const [displayStaffHours, setDisplayStaffHours] = useState(false)
	const [displayConfirmDelete, setDisplayConfirmDelete] = useState(false)
	const [currentProject, setCurrentProject] = useState({})
	const [currentStaffID, setCurrentStaffID] = useState(null)
	const [projectNumbers, setProjectNumbers] = useState([])
	const [currentView, setCurrentView] = useState('projects')

	const [staffDropdown, setStaffDropdown] = useState([]);
	const [currentStaff, setCurrentStaff] = useState({});

	const dt = useRef(null);
	const toast = useRef(null);
	const { projects, staff, brandsAudienceExport } = props
	const objUserData = JSON.parse(localStorage.getItem('userData'))
	const Auth = new AuthService();
	let isAdmin = false
	if (Auth.loggedIn()) {
		const profile = Auth.getProfile()
		isAdmin = profile.isAdmin
	}
	
	useEffect(() => {
		props.getAgencyProjects()
		props.getBrandsByAgency()
		props.getAgencyFunctions()
		props.getAgencyStaff()
	},[]);

	useEffect(() => {
		let projectNumbers = projects.map((cur) => {
			return cur.projectNumber
		})
		setProjectNumbers(projectNumbers)
	},[projects]);

	useEffect(() => {
		window.scrollTo(0, 0)
	},[currentView,projects]);

	useEffect(() => {
		if (!displayStaff) setCurrentStaff({})
	},[displayStaff]);

	// If we have the Add Hours window open and we add hours but leave open, we need to refresh the current project with the latest data
	useEffect(() => {
		if (currentProject && currentProject.projectID) {
			let _currentProject = {...currentProject}
			for (let i in projects) {
				if (projects[i].projectID === _currentProject.projectID) setCurrentProject(projects[i])
			}
		}
	},[projects]);

	useEffect(() => {
		let _staffDropdown = props.staff.map((cur) => {
			
			let curLabel = cur.name
			if (cur.staffType === 'unNamed') curLabel = cur.category
			
			return {
				label: curLabel,
				value: cur.staffID
			}
		})

		setStaffDropdown(_staffDropdown)

	},[props.staff]);
	
	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0)
		  }, 200);
		
	},[]);

	const breadCrumbItems = [
		{label: objUserData.agencyName + (currentView === 'projects'?' Projects':' Staff')}
	];

	const addProject = async (currentNewProject) => {
		let blnAddResults = await props.addAgencyProject(currentNewProject)
		fncHideModal()
		if (blnAddResults) showToast('success', 'Success','Project was Added.')
		else showToast('error', 'Error','There was an issue adding project.')
	}

	const fncDisplayModal = () => {
		setDisplayEntry(true)
	}

	const fncEditStaff = (rowData) => {
		setCurrentStaff(rowData)
		setDisplayStaff(true)
	}

	const fncDisplayStaff = () => {
		setDisplayStaff(true)
	}

	const fncHideModal = () => {
		setDisplayEntry(false)
		setDisplayHours(false)
		setDisplayStaff(false)
		setDisplayStaffHours(false)
		setDisplayConfirmDelete(false)
	}

	const fncHideDelete = () => {
		setDisplayConfirmDelete(false)
	}

	const fncAddStaffHours = (rowData) => {
		if (rowData) setCurrentStaffID(rowData.staffID)
		else setCurrentStaffID(null)
		setDisplayStaffHours(true)
	}

	const showToast = (severity, summary, detail) => {
		toast.current.show({severity: severity, summary: summary, detail: detail, life: 3000});
	}

	const viewOptions = [
		{label: 'Projects', value: 'projects'},
		{label: 'Staff', value: 'staff'}
	]

	const fncDeleteAgencyStaff = () => {
		setDisplayConfirmDelete(true)
	}

	const fncDeleteStaff = async () => {
		let blnAddResults = await props.deleteAgencyStaff(currentStaff.staffID)
		fncHideModal()
		if (blnAddResults) showToast('success', 'Success','Staff was deleted.')
		else showToast('error', 'Error','There was an issue deleting the staff.')
	}

	const renderFooter = () => {
		return (
			
			<div>
				<Button label="Cancel" icon="pi pi-times" onClick={() => fncHideDelete()} className="p-button-text" />
				<Button label="Delete" icon="pi pi-trash" onClick={(e) => fncDeleteStaff()} />
			</div>
		);
	}

	return (
		<div className="grid projectList">
			<Toast ref={toast} />
			<div className="col-12">
				<div className="flex justify-content-between">
					<div>
						<BreadCrumbs items={breadCrumbItems} />
					</div>
					<div>
						<SelectButton unselectable={false} value={currentView} options={viewOptions} onChange={(e) => setCurrentView(e.value)} />
					</div>
				</div>

				{currentView === 'projects' && 
					<AgencyProjectList 
						projects={projects}
						showToast={showToast}
						setCurrentProject={setCurrentProject}
						setDisplayHours={setDisplayHours}
						fncDisplayModal={fncDisplayModal}
						agencyName={objUserData.agencyName}
						addAgencyProject={props.addAgencyProject}
						updateAgencyProject={props.updateAgencyProject}
						brandsDropdown={props.brandsDropdown}
						agencyFunctions={props.agencyFunctions}
						addAgencyProjectHours={props.addAgencyProjectHours}
						addOOPCosts ={props.addOOPCosts}
						deleteAgencyProject={props.deleteAgencyProject}
						getAgencyProjects={props.getAgencyProjects}
					/>
				}

				{currentView === 'staff' && 
					<AgencyStaffList 
						staff={staff}
						showToast={showToast}
						updateAgencyStaff={props.updateAgencyStaff}
						fncAddStaffHours={fncAddStaffHours}
						fncEditStaff={fncEditStaff}
						fncDisplayStaff={fncDisplayStaff}
						isAdmin={isAdmin}
						agencyName={objUserData.agencyName}
						agencyFunctions={props.agencyFunctions}
						brandsDropdown={brandsAudienceExport}
						functionsDropdown={props.functionsDropdown}
						addAgencyStaff={props.addAgencyStaff}
						addAgencyStaffHours={props.addAgencyStaffHours}
						deleteAgencyStaff={props.deleteAgencyStaff}
						getAgencyStaff={props.getAgencyStaff}
					/>
				}

				<ProjectDetailsModal
					displayEntry={displayEntry}
					brandsDropdown={props.brandsDropdown}
					agencyFunctions={props.agencyFunctions}
					fncHideModal={fncHideModal}
					addProject={addProject}
					showToast={showToast}
					projectNumbers={projectNumbers}
				/>
				
				<ProjectAddHours
					displayHours={displayHours}
					fncHideModal={fncHideModal}
					currentProject={currentProject}
					addAgencyProjectHours={props.addAgencyProjectHours}
					showToast={showToast}
				/>

				<AddStaff
					brandsDropdown={props.brandsAudienceDropdown}
					displayStaff={displayStaff}
					fncHideModal={fncHideModal}
					showToast={showToast}
					addAgencyStaff={props.addAgencyStaff}
					deleteAgencyStaff={fncDeleteAgencyStaff}
					functionsDropdown={props.functionsDropdown}
					currentStaff={currentStaff}
				/>

				<StaffAddHours
					displayStaffHours={displayStaffHours}
					fncHideModal={fncHideModal}
					showToast={showToast}
					staff={props.staff}
					staffDropdown={staffDropdown}
					currentStaffID={currentStaffID}
					addAgencyStaffHours={props.addAgencyStaffHours}
				/>

				<Dialog header="Delete Staff" visible={displayConfirmDelete} className="confirmModal"  footer={renderFooter()} onHide={() => fncHideDelete()}>
					<div className="flex">
						Are you sure you want to delete '{(currentStaff.staffType === 'named'?currentStaff.name:currentStaff.category)}'?
					</div>
					
				</Dialog>
			</div>
		</div>
	);
}

export default AgencyWrapper;