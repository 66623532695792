import axios from 'axios';
import { GET_AGENCIES_BY_BRAND, GET_AGENCY_FUNCTIONS_BY_BRAND, GET_AGENCY_PROJECTS_BY_BRAND, GET_AGENCY_PROJECT_STAFF_BY_BRAND, 
	GET_AGENCY_FUNCTIONS, GET_AGENCY_PROJECTS, GET_AGENCY_STAFF, ADD_AGENCY_PROJECTS_HOURS, GET_BRANDS_BY_AGENCY, 
	ADD_AGENCY_PROJECT, UPDATE_AGENCY_PROJECT, UPDATE_AGENCY_STAFF, ADD_AGENCY_STAFF, GET_AGENCY_STATUS_LOGS_BY_BRAND, 
	UPDATE_BASELINE_HOURS, ADD_AGENCY_STAFF_HOURS, GET_AGENCIES, DELETE_AGENCY_STAFF, ADD_AGENCY_OOP_COSTs, DELETE_AGENCY_PROJECT}  from '../constants/URLS';

//Ajax call to the backend to get the different info for agency.
export const getAgenciesByBrandPromise = async (apiCall, brandID) => {
	const apiResults  = await axios({ method: 'get', url: apiCall + '/' + brandID})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return {byId: {},allIds: []}
	} else return apiResults.data.results
}

//Call that comes in to request the Agencies and update the store.
export const getAgenciesByBrand = (brandID) => () => {
	return async (dispatch,getState) => {
		
		let curBrandID = getState().entities.agencies.brandID || 0
		if (curBrandID === brandID) return
		const apiResults = await getAgenciesByBrandPromise(GET_AGENCIES_BY_BRAND, brandID)
		return dispatch({
			type: 'SET_AGENCIES',
			payload: {
				...apiResults,
				brandID:brandID
			}
		})
	}
};

//Ajax call to the backend to get the different info for agency.
export const getBrandsByAgencyPromise = async (apiCall, agencyID) => {
	const apiResults  = await axios({ method: 'get', url: apiCall})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return {byId: {},allIds: []}
	} else return apiResults.data.results
}

//Call that comes in to request the Agencies and update the store.
export const getBrandsByAgency = () => () => {
	return async (dispatch) => {
		
		const apiResults = await getBrandsByAgencyPromise(GET_BRANDS_BY_AGENCY)
		dispatch({
			type: 'SET_BRAND_AGENCIES',
			payload: apiResults
		})
	}
};

//Call that comes in to request the Agency Functions and update the store.
export const getAgencyFunctionsByBrand = (brandID) => () => {
	return async (dispatch,getState) => {
		
		let curBrandID = getState().entities.agencyFunctions.brandID || 0
		if (curBrandID === brandID) return
		
		const apiResults = await getAgenciesByBrandPromise(GET_AGENCY_FUNCTIONS_BY_BRAND, brandID)
		dispatch({
			type: 'SET_AGENCY_FUNCTIONS',
			payload: {
				...apiResults,
				brandID: brandID
			}
		})
	}
};


//Call that comes in to request the Agency Status Logs and update the store.
export const getAgencyStatusLogsByBrand = (brandID) => () => {
	return async (dispatch,getState) => {
		
		let curBrandID = getState().entities.agencyStatusLogs.brandID || 0
		if (curBrandID === brandID) return
		
		const apiResults = await getAgenciesByBrandPromise(GET_AGENCY_STATUS_LOGS_BY_BRAND, brandID)
		return dispatch({
			type: 'SET_AGENCY_STATUS_LOGS',
			payload: {
				...apiResults,
				brandID: brandID
			}
		})
	}
};

//Call that comes in to request the Agency Projects and update the store.
export const getAgencyProjectsByBrand = (brandID) => () => {
	return async (dispatch,getState) => {
		
		let curBrandID = getState().entities.agencyProjects.brandID || 0
		if (curBrandID === brandID) return
		
		const apiResults = await getAgenciesByBrandPromise(GET_AGENCY_PROJECTS_BY_BRAND, brandID)
		return dispatch({
			type: 'SET_AGENCY_PROJECTS',
			payload: {
				...apiResults,
				brandID: brandID
			}
		})
	}
};

//Call that comes in to request the Agency Project Staff and update the store.
export const getAgencyProjectStaffByBrand = (brandID) => () => {
	return async (dispatch,getState) => {
		
		let curBrandID = getState().entities.agencyStaff.brandID || 0
		if (curBrandID === brandID) return
		
		const apiResults = await getAgenciesByBrandPromise(GET_AGENCY_PROJECT_STAFF_BY_BRAND, brandID)
		return dispatch({
			type: 'SET_AGENCY_STAFF',
			payload: {
				...apiResults,
				brandID: brandID
			}
		})
	}
};

//Ajax call to the backend to get the different info for agency.
export const getAgenciesPromise = async (apiCall) => {
	const apiResults  = await axios({ method: 'get', url: apiCall})
	if ( !apiResults.data.success ) {
		return {byId: {},allIds: []}
	} else return apiResults.data.results
}

//Call that comes in to request the Agency Functions and update the store.
export const getAgencyFunctions = () => () => {
	return async (dispatch) => {
		const apiResults = await getAgenciesPromise(GET_AGENCY_FUNCTIONS)
		dispatch({
			type: 'SET_AGENCY_FUNCTIONS',
			payload: apiResults
		})
	}
};

//Call that comes in to request the Agency Projects and update the store.
export const getAgencyProjects = () => () => {
	return async (dispatch) => {
		const apiResults = await getAgenciesPromise(GET_AGENCY_PROJECTS)
		dispatch({
			type: 'SET_AGENCY_PROJECTS',
			payload: apiResults
		})
	}
};

//Call that comes in to request the Agency Projects and update the store.
export const getAgencies = async () => {
	const apiResults = await getAgenciesPromise(GET_AGENCIES)
	return apiResults.allIds.map(cur => apiResults.byId[cur])
};


//Call that comes in to request the Agency Projects and update the store.
export const getAgencyStaff = () => () => {
	return async (dispatch) => {
		const apiResults = await getAgenciesPromise(GET_AGENCY_STAFF)

		dispatch({
			type: 'SET_AGENCY_STAFF',
			payload: apiResults
		})
	}
};

//Ajax call to the backend to get the different info for agency.
export const addAgencyProjectHoursPromise = async (addHoursDetails) => {
	const apiResults  = await axios({ 
		method: 'post', 
		url: ADD_AGENCY_PROJECTS_HOURS,
		data: {...addHoursDetails}
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return true
}

//Call that comes in to request the Agency Projects and update the store.
export const addAgencyProjectHours = (addHoursDetails) => {
	return async (dispatch) => {
		const addResults = await addAgencyProjectHoursPromise(addHoursDetails)
		const apiResults = await getAgenciesPromise(GET_AGENCY_PROJECTS)
		dispatch({
			type: 'SET_AGENCY_PROJECTS',
			payload: apiResults
		})
		return addResults
	}
};

//Call that comes in to request the Agency Projects and update the store.
export const addOOPCosts = (objDetails) => {
	return async (dispatch) => {
		try {
			const addResults  = await axios({ 
				method: 'post', 
				url: ADD_AGENCY_OOP_COSTs,
				data: {...objDetails}
			})

			const apiResults = await getAgenciesPromise(GET_AGENCY_PROJECTS)
			dispatch({
				type: 'SET_AGENCY_PROJECTS',
				payload: apiResults
			})
			return addResults
		} catch (err) {
			return false
		}
	}
};




//Ajax call to the backend to get the different info for agency.
export const addAgencyStaffHoursPromise = async (addHoursDetails) => {
	const apiResults  = await axios({ 
		method: 'post', 
		url: ADD_AGENCY_STAFF_HOURS,
		data: {...addHoursDetails}
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return true
}

//Call that comes in to request the Agency Projects and update the store.
export const addAgencyStaffHours = (addHoursDetails, getAfterAdd = true) => {
	return async (dispatch) => {
		const addResults = await addAgencyStaffHoursPromise(addHoursDetails)
		if (getAfterAdd) {
			const staffResults = await getAgenciesPromise(GET_AGENCY_STAFF)
			dispatch({
				type: 'SET_AGENCY_STAFF',
				payload: staffResults
			})
		}
		return addResults
	}
};



//Ajax call to the backend to delete (soft) a project.
export const deleteAgencyProjectPromise = async (projectID) => {
	const apiResults  = await axios({ 
		method: 'get', 
		url: DELETE_AGENCY_PROJECT + '/' + projectID
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return true
}

export const deleteAgencyProject = (projectID, getAfterAdd = true) => {
	return async (dispatch) => {
		const addResults = await deleteAgencyProjectPromise(projectID)
		if (getAfterAdd) {
			const projectResults = await getAgenciesPromise(GET_AGENCY_PROJECTS)
		
			dispatch({
				type: 'SET_AGENCY_PROJECTS',
				payload: projectResults

			})

			const staffResults = await getAgenciesPromise(GET_AGENCY_STAFF)
			dispatch({
				type: 'SET_AGENCY_STAFF',
				payload: staffResults
			})
		}

		return addResults
	}
};

//Ajax call to the backend to add a project.
export const addAgencyProjectPromise = async (projectDetails) => {
	const apiResults  = await axios({ 
		method: 'post', 
		url: ADD_AGENCY_PROJECT,
		data: {...projectDetails}
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return apiResults.data
}

//Call to add a new project
export const addAgencyProject = (projectDetails, getAfterAdd = true) => {
	return async (dispatch) => {
		const addResults = await addAgencyProjectPromise(projectDetails)
		if (getAfterAdd) {
			const projectResults = await getAgenciesPromise(GET_AGENCY_PROJECTS)
		
			dispatch({
				type: 'SET_AGENCY_PROJECTS',
				payload: projectResults

			})
			const staffResults = await getAgenciesPromise(GET_AGENCY_STAFF)
			dispatch({
				type: 'SET_AGENCY_STAFF',
				payload: staffResults
			})
		}
		return addResults
	}
};

//Ajax call to the backend to add a project.
export const updateAgencyProjectPromise = async (projectDetails) => {
	const apiResults  = await axios({ 
		method: 'post', 
		url: UPDATE_AGENCY_PROJECT,
		data: {...projectDetails}
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return true
}

//Call to add a new project
export const updateAgencyProject = (projectDetails, getAfterAdd = true) => {
	return async (dispatch) => {
		const updateResults = await updateAgencyProjectPromise(projectDetails)
		if (getAfterAdd) {
			const projectResults = await getAgenciesPromise(GET_AGENCY_PROJECTS)
		
			dispatch({
				type: 'SET_AGENCY_PROJECTS',
				payload: projectResults

			})

			const staffResults = await getAgenciesPromise(GET_AGENCY_STAFF)
			dispatch({
				type: 'SET_AGENCY_STAFF',
				payload: staffResults
			})
		}

		return updateResults
	}
};

//Ajax call to the backend to add a project.
export const updateAgencyStaffPromise = async (staffDetails) => {
	const apiResults  = await axios({ 
		method: 'post', 
		url: UPDATE_AGENCY_STAFF,
		data: {...staffDetails}
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return true
}

//Call to add a new project
export const updateAgencyStaff = (staffDetails) => {
	return async (dispatch) => {
		const updateResults = await updateAgencyStaffPromise(staffDetails)
		// todo: get staff
		const staffResults = await getAgenciesPromise(GET_AGENCY_STAFF)
		dispatch({
			type: 'SET_AGENCY_STAFF',
			payload: staffResults
		})

		return updateResults
	}
};

//Ajax call to the backend to get the different info for agency.
export const addAgencyStaffPromise = async (staffDetails) => {
	const apiResults  = await axios({ 
		method: 'post', 
		url: ADD_AGENCY_STAFF,
		data: {...staffDetails}
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return apiResults.data
}

export const deleteAgencyStaffPromise = async (staffID) => {
	const apiResults  = await axios({ 
		method: 'post', 
		url: DELETE_AGENCY_STAFF,
		data: {
			staffID: staffID
		}
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return true
}

//Call that comes in to request adding a new staff member to a project.
export const addAgencyStaff = (staffDetails, getAfterAdd = true) => {
	return async (dispatch) => {
		const addResults = await addAgencyStaffPromise(staffDetails)
		if (getAfterAdd) {
			const projectResults = await getAgenciesPromise(GET_AGENCY_PROJECTS)
			dispatch({
				type: 'SET_AGENCY_PROJECTS',
				payload: projectResults

			})
			const apiResults = await getAgenciesPromise(GET_AGENCY_STAFF)
			dispatch({
				type: 'SET_AGENCY_STAFF',
				payload: apiResults
			})
		}
		return addResults
	}
};

//Call that comes in to request adding a new staff member to a project.
export const deleteAgencyStaff = (staffID, getAfterAdd = true) => {
	return async (dispatch) => {
		try {
			const deleteResults = await deleteAgencyStaffPromise(staffID)
			if (getAfterAdd) {
				const projectResults = await getAgenciesPromise(GET_AGENCY_PROJECTS)
				dispatch({
					type: 'SET_AGENCY_PROJECTS',
					payload: projectResults

				})
				const apiResults = await getAgenciesPromise(GET_AGENCY_STAFF)
				dispatch({
					type: 'SET_AGENCY_STAFF',
					payload: apiResults
				})
			}
			return deleteResults
		} catch (err) {
			return false
		}
	}
};


//Ajax call to the backend to add a project.
export const updateBaselineHoursPromise = async (updateDetails) => {
	const apiResults  = await axios({ 
		method: 'post', 
		url: UPDATE_BASELINE_HOURS,
		data: {
			...updateDetails,
			hoursNew: parseFloat(updateDetails.hoursNew)
		}
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return true
}

//Call to add a new project
export const updateBaselineHours = (updateDetails) => {
	return async (dispatch) => {
		
		const updateResults = await updateBaselineHoursPromise(updateDetails)
		const projectResults = await getAgenciesPromise(GET_AGENCY_PROJECTS)
		dispatch({
			type: 'SET_AGENCY_PROJECTS',
			payload: projectResults

		})

		return updateResults
	}
};

