import { createSelector } from 'reselect'

import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

const agencyFunctionsEntity = (state) => state.entities.agencyFunctions
const agencyProjectsEntity = (state) => state.entities.agencyProjects
const agencyStaffEntity = (state) => state.entities.agencyStaff
const brandAgenciesEntity = (state) => state.entities.brandAgencies


const getProjectByID = (state, props) => {
	try {
		return state.entities.agencyProjects.byId[parseInt(props.match.params.projectid)]
	} catch (e) {
		return {}
	}
	
}

export const getAgencyStaffSelector = createSelector(
	[agencyStaffEntity, agencyFunctionsEntity, brandAgenciesEntity], 
	(curStaff, curFunctions, curBrandAgencyies) => {
		
		if (curBrandAgencyies.allIds.length === 0) return []
		
		return curStaff.allIds.reduce((acc,cur) => {
			let objCur = curStaff.byId[cur]
			
			if (objCur.status === 'deleted') return acc
			let objCurFunction = curFunctions.byId[objCur.functionID] || {}
			
			/* 
				Added in late, we now have to determine if the hours used are within the current fiscal year that is set for the client. 
				Client table has current year and fiscal year start month.  Current year represents the END month, not the start month. 
				Example, fiscal start month = 3 and current year = 2022, then we would look for dates from March 2021 - Feb 2022
			*/

			let totalHoursUsed = 0
			let _hours = []
			for (let curHours of objCur.hours) {
				let currentYear = (curBrandAgencyies.byId[curHours.brandAgencyID] ? curBrandAgencyies.byId[curHours.brandAgencyID].currentyear : 0)
				let fiscalStartMonth = (curBrandAgencyies.byId[curHours.brandAgencyID] ? curBrandAgencyies.byId[curHours.brandAgencyID].fiscalStartMonth : 0)
				// Make sure there is a relationship and we need the year
				if (currentYear !== 0) {
					
					//subtract 1 unless it's 0 (january), in which case, set to 11 (dec) 
					let fiscalMonthEnd = (fiscalStartMonth - 1 < 0 ? 11 : fiscalStartMonth - 1)
					let startOfYear = dayjs().year(currentYear).startOf('year')
					let endOfYear = dayjs().year(currentYear).endOf('year')
					/* 
						2 scenerios for start and end fiscal year dates. 
						1. If the start month is January, then calendar year
						2. start year will be previous year from current
						
					*/

					if (fiscalStartMonth !== 0 )  {
						// Fiscal year start date should use previous year, the end year will use this year
						startOfYear = dayjs().set('month', fiscalStartMonth).set('year', dayjs().year(currentYear).subtract(1, 'year').year()).startOf('month')
						endOfYear = dayjs().set('month', fiscalMonthEnd).set('year', currentYear).endOf('month')
					} 

					let isStartInRange = dayjs(curHours.dateEntered).isSameOrAfter(dayjs(startOfYear))
					let isEndInRange = dayjs(curHours.dateEntered).isSameOrBefore(dayjs(endOfYear))

					if (isStartInRange && isEndInRange) {
						_hours.push(curHours)
						if (curHours.state === 'add') {
							totalHoursUsed += curHours.hours
						} else if (curHours.state === 'remove') {
							totalHoursUsed -= curHours.hours
						}
					}
				}
			}
			
			let arrBrands = objCur.brandAgencies.reduce((acc,cur) => {
				let arrTemp = cur.split('|')
				
				if (curBrandAgencyies.byId[arrTemp[0]]) {
					if (objCur.baselineHoursByBrand[cur][curBrandAgencyies.byId[arrTemp[0]].currentyear]) {
						let objCurBrand = curBrandAgencyies.byId[arrTemp[0]] || []
						let strCurBrandName = objCurBrand.brandName
						if (arrTemp[2] !== '') strCurBrandName += ' (' + arrTemp[2] + ')'
						let strCurBrandID = objCurBrand.brandAgencyID
						if (arrTemp[1] !== '') strCurBrandID += '|' + arrTemp[1]
						acc.push({
							label: strCurBrandName,
							value: strCurBrandID
						})
					}
				}
				return acc
			},[])

			/* 
				This was added very late, need to work staff year in to all the formulas, trying to do it with touching least number of files. 
				baselineHoursByBrand will store all years coming in, but when it leaves, it will only store the current year per client (brand)
			*/
			let _baselineHours = 0
			let _brandAgencies = []
			let _baselineHoursByBrand = Object.keys(objCur.baselineHoursByBrand).reduce((acc,cur) => {
				//The key holds the brandAgencyID as well as the audience. First element is brandAgencyID
				let _arrKey = cur.split('|')
				let _total = 0
				if (objCur.baselineHoursByBrand[cur][curBrandAgencyies.byId[_arrKey[0]].currentyear]) {
					_total = objCur.baselineHoursByBrand[cur][curBrandAgencyies.byId[_arrKey[0]].currentyear]
					acc[cur] = _total
					_brandAgencies.push(cur)
				}
				_baselineHours += _total
				return acc
			},{})

			acc.push({
				...objCur, 
				name: objCur.name.trim(),
				brands: arrBrands,
				brandName: arrBrands.map(cur => cur.label).join(', '),
				hours: _hours.map((cur) => {
					let objCurBrand = curBrandAgencyies.byId[cur.brandAgencyID] || []
					let strCurrentBrandName = objCurBrand.brandName
					if (cur.audienceName !== '') strCurrentBrandName += ' (' + cur.audienceName + ')'
					return {
						...cur,
						brandName: strCurrentBrandName
					}
				}),
				totalHours: _baselineHours,
				totalHoursUsed: totalHoursUsed,
				baselineCost: _baselineHours * objCur.rate,
				baselineHoursByBrand:_baselineHoursByBrand,
				brandAgencies: _brandAgencies,
				totalCost: (totalHoursUsed || 0) * objCur.rate,
				department: objCurFunction.functionTitle,
				displayName: (objCur.staffType === 'named'?objCur.name.trim():objCur.category.trim())
			})

			return acc
		},[]).sort((a,b) => (a.displayName > b.displayName?1:-1)).sort((a,b) => (a.isSenior > b.isSenior?-1:1))
		
	}
)

export const getAgencyProjectsSelector = createSelector(
	[agencyProjectsEntity, agencyFunctionsEntity, brandAgenciesEntity],
	(curAgencyProjects, curFunctions, curBrands) => {
		
	let arrProjects = curAgencyProjects.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			let arrFunctionDropdown = curFunctions.allIds.map((cur) =>{
				let objCurFunction = curFunctions.byId[cur]
				
				return {
					label: objCurFunction.functionTitle,
					value: objCurFunction.functionID
				}
			})

			let objFunctions = curFunctions.allIds.reduce((acc,cur) =>{
				let objCurFunction = curFunctions.byId[cur]
				acc[objCurFunction.functionID] = objCurFunction
				return acc
			},{})

			let objCurBrand = curBrands.byId[objCur.brandAgencyID] || {}

			let totalCost = 0
			let totalHours = 0
			let totalHoursUsed = 0
			let totalCostUsed = 0
			let minRate = null
			let maxRate = 0
			let rateLookup = {}
			for (let curBaseline of objCur.projectBaselines) {
				totalHours += curBaseline.hours
				totalCost += curBaseline.rate * curBaseline.hours
				rateLookup[curBaseline.functionID] = curBaseline.rate
				if (curBaseline.rate > maxRate) maxRate = curBaseline.rate
				if (!minRate || curBaseline.rate < minRate) minRate = curBaseline.rate
			}

			let rateRange = null
			if (minRate) {
				if (minRate === maxRate) rateRange = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(minRate)
				else {
					rateRange = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(minRate) + ' - ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(maxRate)
				}
			}

			
			for (let curHours of objCur.projectHours) {
				
				let _rate = rateLookup[curHours.functionID] || (objCur.blendedRate ? objCur.blendedRate  : 0)
				if (curHours.state === 'add') {
					totalHoursUsed += curHours.hours
					totalCostUsed += _rate * curHours.hours
				} else if (curHours.state === 'remove') {
					totalHoursUsed -= curHours.hours
					totalCostUsed -= _rate * curHours.hours
				}
			}

			acc.push({
				...objCur,
				startDate: new Date(objCur.startDate),
				endDate: new Date(objCur.endDate),
				startDateFormatted: dayjs(objCur.startDate).format('MMMM YYYY'),
				endDateFormatted: dayjs(objCur.endDate).format('MMMM YYYY'),
				dateRange: dayjs(objCur.startDate).format('MMMM YYYY') + ' - ' + dayjs(objCur.endDate).format('MMMM YYYY'),
				projectName: objCur.projectName.trim(),
				totalHours: totalHours,
				totalHoursUsed: totalHoursUsed,
				totalCost: totalCost,
				totalCostUsed: totalCostUsed,
				functionDropdown: arrFunctionDropdown,
				functions: objFunctions, 
				rate: rateRange,
				yearsFromCurrent: Math.abs(new Date(objCur.startDate).getFullYear() - new Date().getFullYear()),
				therapeuticAreas: objCurBrand.therapeuticAreas || [],
				indications: objCurBrand.indications || [],
			})
			return acc
		},[])

		return arrProjects.sort((a,b) => (a.startDate < b.startDate?1:-1)).sort((a,b) => (a.yearsFromCurrent > b.yearsFromCurrent?1:-1))
	}
)

export const getAgencyProjectsDropdown = createSelector(
	[agencyProjectsEntity],
	(curAgencyProjects) => {
		let arrProjects = curAgencyProjects.allIds.reduce((acc,cur) => {
			if(!acc.find(curF => curF.value === curAgencyProjects.byId[cur].projectID)) {
				acc.push({
					label: curAgencyProjects.byId[cur].projectName,
					value: curAgencyProjects.byId[cur].projectID
				})
			}

			return acc
		},[])

		return arrProjects
	}
)

export const getAgencyFunctionsDropdown = createSelector(
	[agencyFunctionsEntity],
	(curAgencyFunctions) => {
		let arrFunctions = curAgencyFunctions.allIds.map((cur) => {
			return  {
				label: curAgencyFunctions.byId[cur].functionTitle,
				value: curAgencyFunctions.byId[cur].functionID,
				isHidden: curAgencyFunctions.byId[cur].isHidden
			}
		}).sort((a,b) => (a.isHidden > b.isHidden?-1:1))

		return arrFunctions
	}
)

export const getAgencyFunctionsSelector = createSelector(
	[agencyFunctionsEntity],
	(curAgencyFunctions) => {
		let arrFunctions = curAgencyFunctions.allIds.reduce((acc,cur) => {
			if (curAgencyFunctions.byId[cur].isHidden === 0) acc.push(curAgencyFunctions.byId[cur])
			return acc
		},[])

		return arrFunctions
	}
)

export const getProjectByIdSelector = createSelector(
	[getProjectByID, getAgencyProjectsSelector, agencyFunctionsEntity],
	(curProject, curDerivedProjects, curFunctions) => {
		if (curProject) {
			let objCurProject = curDerivedProjects.find((curF => curF.projectID === curProject.projectID))

			objCurProject.departments = curFunctions.allIds.reduce((acc,cur) => {
				let objCur = curFunctions.byId[cur]
				let baselineHours = curProject.projectBaselines.find(curF => curF.functionID === objCur.functionID)
				if (!baselineHours) {
					if (objCurProject.blendedRate) baselineHours = {hours: 0, rate: objCurProject.blendedRate}
					else baselineHours = {hours: 0, rate: 0}
					
				}

				let actualHours = curProject.projectHours.reduce((accI,curI) => {
					if (curI.functionID === objCur.functionID) {
						if (curI.state === 'add') accI += curI.hours
						else if ('subtract') accI -= curI.hours
					}
					return accI
				},0)

				if (baselineHours.hours > 0 || actualHours !== 0 ) {
					acc.push({
						...objCur,
						baseline: baselineHours.hours,
						rate: baselineHours.rate,
						hours: actualHours
					})
				}

				return acc
			},[])

			return {
				...objCurProject
			}
		}
		else return {}
	}
)
