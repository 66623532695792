import axios from 'axios';
import { GET_COMMENTS, UPDATE_COMMENTS }  from '../constants/URLS';

//Ajax call to the backend to get the Team Hooks.
export const getCommentsPromise = async () => {
	const commentsResults  = await axios({ method: 'get', url: GET_COMMENTS})

	if ( !commentsResults.data.success ) {
		console.error(commentsResults.data.error)
		return {byId: {},allIds: []}
	} else return commentsResults.data.results
}

//Call that comes in to request the Brands and update the store.
export const getComments = () => (dispatch,getState) => {
	return async (dispatch) => {
		const commentResults = await getCommentsPromise()
		dispatch({
			type: 'SET_COMMENTS',
			payload: commentResults
		})
	}
};

//Ajax call to the backend to add a project.
export const updateCommentsPromise = async (updateDetails) => {
	const apiResults  = await axios({ 
		method: 'post', 
		url: UPDATE_COMMENTS,
		data: {
			...updateDetails
		}
	})
	if ( !apiResults.data.success ) {
		console.error(apiResults.data.error)
		return false
	} else return true
}

//Call to add a new project
export const updateComments = (updateDetails) => {
	return async (dispatch) => {
		
		const updateResults = await updateCommentsPromise(updateDetails)
		const commentResults = await getCommentsPromise()
		dispatch({
			type: 'SET_COMMENTS',
			payload: commentResults
		})

		return updateResults
	}
};