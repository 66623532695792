import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import withAuth from '../auth/withAuth';

// action files (get data from server and set up redux store)
import { getAgencyFunctions, getAgencyProjects, getBrandsByAgency, addAgencyProjectHours, 
	updateAgencyProject, updateBaselineHours, addOOPCosts} from '../../actions/agencies'

// selector files (transform data from redux store in to what we need for presentation)
import { getProjectByIdSelector, getAgencyFunctionsSelector, getAgencyProjectsSelector } from '../../selectors/agenciesSelector'
import { getBrandAgencyDropdown } from '../../selectors/brandsSelector'

// presentation component
import AgencyProjectList from './agency-project-detail'

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		getAgencyFunctions: () => dispatch(getAgencyFunctions()),
		getAgencyProjects: () => dispatch(getAgencyProjects()),
		getBrandsByAgency: (agencyID) => dispatch(getBrandsByAgency(agencyID)),
		updateAgencyProject: (projectDetails) => updateAgencyProject(projectDetails),
		addAgencyProjectHours: (addHoursDetails) => addAgencyProjectHours(addHoursDetails),
		addOOPCosts: (objDetails) => addOOPCosts(objDetails),
		updateBaselineHours: (updateDetails) => updateBaselineHours(updateDetails)
	}, dispatch);
};

const mapStateToProps = (state, ownProps) => {
	
	return {
		projectDetails: getProjectByIdSelector(state,ownProps),
		brandsDropdown: getBrandAgencyDropdown(state),
		projects: getAgencyProjectsSelector(state), 
		agencyFunctions: getAgencyFunctionsSelector(state),
	}
}

const AgencyProjectListContainer = connect(mapStateToProps, mapDispatchToProps)(AgencyProjectList);
export default AgencyProjectListContainer;