// greens
export const LIGHT_GREEN = "#7BCC77" // 123,204,119
export const DARK_GREEN = "#56A648" // 86,166,72
export const GREEN_ARRAY = ['#56A648','#7BCC77','#457C41','#609927','#6FBE1F','#8D9980','#9BFF36','#A0C77A']

// reds
export const LIGHT_RED = "#D983CB"
export const DARK_RED = "#A93977"
export const RED_ARRAY = ['#D983CB', '#A93977', '#9D010B', '#67090F','#B67070','#8E2F2F','#583235','#947275']

// blues
export const LIGHT_BLUE = "#ACDAE1"
export const DARK_BLUE = "#79C1CC"

// grays
export const GRAY_ARRAY = ['#808080', '#C6C6C6']

// New BUDGET COLORS (Blues)
export const BUDGET_LIGHT_COLOR = "#ACDAE1"
export const BUDGET_DARK_COLOR = "#79C1CC"
export const BUDGET_OUTSIDE_COLOR = "#c6d6e2"
export const BUDGET_COLOR_ARRAY = ['#1a5861','#79C1CC','#379cae','#ACDAE1','#8fdaea','#bfd9f2','#dff4f9']
export const BUDGET_COLOR_FADED_ARRAY = ['rgba(9,22,35,.3)','rgba(31,66,104,.3)','rgba(55,111,174,.3)','rgba(74,143,218,.3)','rgba(143,189,234,.3)','rgba(191,217,242,.3)','rgba(223,236,249,.3)']

// new Staff Colors (Purples)
export const STAFF_LIGHT_COLOR = "#D983CB"
export const STAFF_DARK_COLOR = "#A93977"
//export const STAFF_COLOR_ARRAY = ['#1d1527','#A93977','#382b50','#DA9B9B','#72549d','#9179b6','#a794c4']
export const STAFF_COLOR_ARRAY = ['#1d1527','#A93977','#1d1527','#DA9B9B','#A93977','#9179b6','#a794c4']
export const STAFF_COLOR_FADED_ARRAY = ['rgba(29,21,39,.3)','rgba(37,27,53,.3)','rgba(56,43,80,.3)','rgba(80,59,109,.3)','rgba(114,84,157,.3)','rgba(145,121,182,.3)','rgba(167,148,196,.3)']

// new OOP Colors (greens)
export const OOP_LIGHT_COLOR = "#7BCC77"
export const OOP_DARK_COLOR = "#1FB437"
export const OOP_OUTSIDE_COLOR = "#82c491"
export const OOP_COLOR_ARRAY = ['#101d12','#1FB437','#396642','#7BCC77','#62ad70','#82c491','#a4d4b2']
