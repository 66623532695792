import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs'

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { dayTemplate, monthTemplate } from '../utilities/CommonSnippets'

const ProjectChangesModal = (props) => {
	
	const { fncHideModal, displayChanges, projectChanges, modalType, header} = props

	const projectNameTemplate = ( rowData ) => {
		return (
			<div className="flex flex-column">
				<div className="mb-2 name ">
					{rowData.projectName}
				</div>
				<div className="mb-2 dateRange">
					{monthTemplate(rowData.startDate)} - {monthTemplate(rowData.endDate)} 
				</div>
				<div className="mb-2 comments">
					{rowData.comments} 
				</div>
			</div>
		)
	}

	const typeTemplate = ( rowData ) => {
		let strType = ''
		if (rowData.type === 'add') strType = 'New Project'
		if (rowData.type === 'edit') strType = 'Project Edit'
		
		return (
			<div>{strType}</div>
		)
	}

	const changesTemplate_old = ( rowData ) => {
		let objFields = (rowData.changes || []).reduce((acc,cur) => {
			if (modalType === 'project' && (cur === 'status' || cur === 'priority')) acc[cur] = true
			if (modalType === 'budget' && (cur === 'passthrough' || cur === 'totalProjectBudget')) acc[cur] = true
			return acc
		},{})

		let arrFields = Object.keys(objFields).map((cur) => {
			return (
				<div style={{textTransform: 'capitalize'}}>{cur}</div>
			)
		})

		return (
			<div className="flex flex-column " >
				{arrFields}
			</div>
		)
	}

	const changesTemplate = (rowData) => {
		
		
		let strType = rowData.logType

		if (strType === 'add') {
			return (
				<div className="flex flex-column " >
					<div key={"change_add"} style={{textTransform: 'capitalize'}}>Project Added</div>
				</div>
			)
		} else {

			let arrFields = (rowData.changes || []).map((cur, index) => {

				let strOldValue = cur.oldValue
				let strNewValue = cur.newValue
				let strField = cur.fieldChange

				strType = <div className="flex flex-column mb-3" >
					<div>
						{strField}
					</div>
					{strNewValue && strNewValue !== '' && 
						<div className="ml-3 mb-1" style={{fontWeight: '600', fontSize:'13px'}}>
							New: {strNewValue}
						</div>
					}
					{strOldValue && strOldValue !== '' && 
						<div className="ml-3" style={{fontWeight: '600', fontSize:'13px'}}>
							Previous: {strOldValue}
						</div>
					}
					{strField !== 'Budget Edit' && strField !== 'Project Edit' &&
						<div className="ml-3" style={{fontWeight: '600', fontSize:'11px'}}>
							{dayjs(cur.timeStamp).format('h:mm A')}
						</div>
					}
				</div>
				
				
				
				return (
					<div key={"change_" + index} style={{textTransform: 'capitalize'}}>{strType}</div>
				)
			})

			return (
				<div className="flex flex-column " >
					{arrFields}
				</div>
			)
		}
	}

	

	return (
		<Dialog header={header} visible={displayChanges} className="changesModal"  onHide={fncHideModal}>
			<DataTable className="p-datatable-products" value={projectChanges} paginator
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5,10,25]}
					paginatorPosition="bottom"  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
				<Column field="date" header="Date" sortable body={dayTemplate} style={{verticalAlign:'top',width:'15%'}}></Column>
				<Column field="projectNumber" header="Project Number" sortable  style={{verticalAlign:'top',textAlign:'left',width:'20%'}}></Column>
				<Column field="projectName" sortable header="Project" body={projectNameTemplate} style={{verticalAlign:'top',width:'25%'}}></Column>
				<Column field="changes" header="Changes" body={changesTemplate} style={{verticalAlign:'top',width:'40%'}}></Column>
				
			</DataTable>
		</Dialog>
	)
}

export default ProjectChangesModal;