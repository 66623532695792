import React, { useEffect } from 'react';
import { connect } from 'react-redux';


// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Chart as ChartJS, PieController, ArcElement, Tooltip, Legend} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { BUDGET_COLOR_ARRAY} from '../../constants/colors'

import { getSpendByAudience } from '../../selectors/dashboardWidgetsSelector'

import { fncFormatCurrency, dollarTemplate, dateStringsMap } from '../../utilities/CommonSnippets'

ChartJS.register(ChartDataLabels);
ChartJS.register(PieController)
ChartJS.register(ArcElement)
ChartJS.register(Tooltip)
ChartJS.register(Legend)
ChartJS.defaults.font.size = 14;

const SpendByAudience = (props) => {
	const { audienceBreakdown, loading } = props

	const colorOrder = {
		0: BUDGET_COLOR_ARRAY[3],
		1: BUDGET_COLOR_ARRAY[4],
		2: BUDGET_COLOR_ARRAY[5],
		3: BUDGET_COLOR_ARRAY[6],
		4: BUDGET_COLOR_ARRAY[2],
		5: BUDGET_COLOR_ARRAY[1],
		6: BUDGET_COLOR_ARRAY[0]
	}


	let dateMap = dateStringsMap()
	useEffect(() => {
		if (!loading) {
			
			const chartData = {
				labels: audienceBreakdown.map(cur => cur.audienceName),
				datasets: [
					{
						label: 'Dataset 1',
						data: audienceBreakdown.map(cur => cur.percentSpend),
						backgroundColor:  audienceBreakdown.map((cur,index) => {
							let curMod = index % 7
							return colorOrder[curMod]
							
						}),
						hoverBackgroundColor: audienceBreakdown.map((cur,index) => {
							let curMod = index % 7
							return colorOrder[curMod]
							
						}),
					}]
			};
		
			const chartOptions = {
			   responsive: true,
				plugins: {
					// Change options for ALL labels of THIS CHART
					datalabels: {
						anchor: 'middle',
						align: 'end',
						formatter: function(value, context) {
							return value + '%'
						}
					},
					legend: {
						position: 'bottom',
						labels: {
							filter: (legendItem, data) => {
								const qtd = data.datasets[0].data[legendItem.index]
								legendItem.text = `${legendItem.text} : ${qtd}%` 
								return true
							}
						}
					  },
				  }
			};
			
			var myChart = new ChartJS(document.getElementById('spendByAudience'), {
				type: "pie",
				data: chartData ,
				options: chartOptions
			});

			// when component unmounts
			return () => {
				myChart.destroy()
			}
		}
	}, [audienceBreakdown, loading])

	const getTotals = (field) => {
        let total = 0;
        if (audienceBreakdown) {
			for(let curAudience of audienceBreakdown) {
				total += curAudience[field];
			}
		}

        return fncFormatCurrency(total);
    }

	let footerGroup = <ColumnGroup>
		<Row >
			<Column  footer="Total" footerStyle={{color: 'white',textAlign: 'right'}}/>
			<Column footer={getTotals('annualBudget')} footerStyle={{color: 'white',textAlign: 'right'}}/>
			<Column footer={getTotals('spend')} footerStyle={{color: 'white',textAlign: 'right'}}/>
			<Column footer={getTotals('ytdBudget')} footerStyle={{color: 'white',textAlign: 'right'}}/>
			<Column style={{color:'red', color: 'white',textAlign: 'right'}} footer={getTotals('differenceBudget')} />
		</Row>
		</ColumnGroup>;
	
	const differenceTemplate = (rowData) => {
		if (rowData.differenceBudget < 0) {
			return <span style={{color: 'red'}}>{dollarTemplate(rowData.differenceBudget)}</span>
		} else {
			return <span>{dollarTemplate(rowData.differenceBudget)}</span>
		}
	}
	
	return (
		<div className="flex mb-3 fullWidget ">
			<div className="pr-2 align-self-stretch thirdWidget">
				
				{!loading && 
					<div className="p-chart artCharts-doughnutHalf">
						<canvas id="spendByAudience"></canvas>
					</div>
				}
			</div>
			<div className="align-self-stretch twoThirdWidget spend">
				<DataTable className="tables" value={audienceBreakdown} footerColumnGroup={footerGroup}>
					<Column field="audienceName"  header="Audience" style={{width: '20%'}}/>
					<Column field="annualBudget" body={dollarTemplate} header="Annual Budget" style={{textAlign:'right',width: '20%'}} />
					<Column field="ytdBudget" body={dollarTemplate} header={dateMap['ytd'] + ' Budget'} style={{textAlign:'right',width: '20%'}} />
					<Column field="spend" body={dollarTemplate} header={dateMap['ytd'] + ' Spend'} style={{textAlign:'right',width: '20%'}} />
					<Column field="differenceBudget" body={differenceTemplate} header="Difference" style={{textAlign:'right',width: '20%'}} />
				</DataTable>
			</div>
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state) => {
	
	return {
		audienceBreakdown: getSpendByAudience(state,{dates:'fullYear'})
	}
}

const SpendByAudienceContainer = connect(mapStateToProps, null)(SpendByAudience);
export default SpendByAudienceContainer;