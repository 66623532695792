import React, { useState, useEffect, useRef  } from 'react';
import { Link } from 'react-router-dom';

// PrimeReact Components
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Chips } from 'primereact/chips';

// BreadCrumbs component
import BreadCrumbs from '../../components/breadCrumbs/breadCrumbs-container'
import ProjectAddHours from '../../modals/projectAddHours'
import ProjectAddOOPCosts from '../../modals/projectAddOOPCosts'
import ProjectDetailsModal from '../../modals/projectDetails';


import AuthService from '../../services/AuthService'
import { dollarTemplate, dateTemplate, monthTemplate, booleanTemplate } from '../../utilities/CommonSnippets'

const AgencyProjectList = (props) => {
	const { projectDetails, projects } = props

	const [displayHours, setDisplayHours] = useState(false);
	const [displayOOP, setDisplayOOP] = useState(false);
	const [displayEntry, setDisplayEntry] = useState(false)
	const [currentDescription, setCurrentDescription] = useState(false);
	const [editDescription, setEditDescription] = useState(false);
	
	const [currentStartDate, setCurrentStartDate] = useState(false);
	const [currentEndDate, setCurrentEndDate] = useState(false);
	const [editDuration, setEditDuration] = useState(false);
	
	const [currentPoNumber, setCurrentPoNumber] = useState(false);
	const [editPoNumber, setEditPoNumber] = useState(false);
	
	const [currentProjectNumber, setCurrentProjectNumber] = useState(false);
	const [editProjectNumber, setEditProjectNumber] = useState(false);

	const [currentProjectTypes, setCurrentProjectTypes] = useState([]);
	const [editProjectTypes, setEditProjectTypes] = useState(false);

	const [currentIssues, setCurrentIssues] = useState(false);
	const [editIssues, setEditIssues] = useState(false);
	
	const [currentStatus, setCurrentStatus] = useState(false);
	const [editStatus, setEditStatus] = useState(false);
	
	const [currentPriority, setCurrentPriority] = useState(false);
	const [editPriority, setEditPriority] = useState(false);

	const [currentTotalProjectBudget, setCurrentTotalProjectBudget] = useState(false);
	const [editTotalProjectBudget, setEditTotalProjectBudget] = useState(false);
	const [totalHours, setTotalHours] = useState(null);

	const [currentPassThrough, setCurrentPassThrough] = useState(false);
	const [editPassThrough, setEditPassThrough] = useState(false);
	const [currentPassThroughDesc, setCurrentPassThroughDesc] = useState(false);
	const [editPassThroughDesc, setEditPassThroughDesc] = useState(false);
	const [projectNumbers, setProjectNumbers] = useState([])

	const [functionDetails, setFunctionDetails] = useState([]);

	const dt = useRef(null);
	const toast = useRef(null);
	const objUserData = JSON.parse(localStorage.getItem('userData'))
	const Auth = new AuthService();
	let isAdmin = false
	if (Auth.loggedIn()) {
		const profile = Auth.getProfile()
		isAdmin = profile.isAdmin
	}
	
	useEffect(() => {
		props.getAgencyProjects()
	},[]);

	useEffect(() => {
		props.getBrandsByAgency()
	},[]);

	useEffect(() => {
		props.getAgencyFunctions()
	},[]);

	useEffect(() => {
		setCurrentPoNumber(projectDetails.poNumber)
		setCurrentProjectNumber(projectDetails.projectNumber)
		setCurrentProjectTypes(projectDetails.projectTypes)
		setCurrentDescription(projectDetails.description)
		setCurrentIssues(projectDetails.issues)
		setCurrentStatus(projectDetails.status)
		setCurrentStartDate(projectDetails.startDate)
		setCurrentEndDate(projectDetails.endDate)
		setCurrentPriority(projectDetails.priority)
		setCurrentTotalProjectBudget(projectDetails.totalProjectBudget)
		setCurrentPassThrough(projectDetails.passThrough)
		setCurrentPassThroughDesc(projectDetails.passThroughDesc)
		let _totalHours = (projectDetails.departments || []).reduce((acc,cur) => {
			if (cur.baseline > 0 && cur.rate > 0) acc += cur.baseline
			return acc
		},0)
		
		setTotalHours(_totalHours)

	},[projectDetails]);

	useEffect(() => {
		if (currentProjectNumber) {
			let _projectNumbers = projects.reduce((acc,cur) => {
				if (cur.projectNumber !== currentProjectNumber) acc.push(cur.projectNumber)
				return acc
			},[])
			
			setProjectNumbers(_projectNumbers)
		}
		
	},[projects, currentProjectNumber]);
	
	const breadCrumbItems = [
		{label: objUserData.agencyName + ' Projects', command: (event) => {window.location.hash = "/";}},
		{label: props.projectDetails.projectName},
	];

	const fncHideModal = () => {
		setDisplayHours(false)
		setDisplayEntry(false)
		setDisplayOOP(false)
	}

	const showToast = (severity, summary, detail) => {
		toast.current.show({severity: severity, summary: summary, detail: detail, life: 3000});
	}

	const fncUpdateField = async (updateField) => {
		let fieldValue = null
		if (updateField === 'description') {
			fieldValue = currentDescription
		} else if (updateField === 'issues') {
			fieldValue = currentIssues
		} else if (updateField === 'status') {
			fieldValue = currentStatus
		} else if (updateField === 'priority') {
			fieldValue = currentPriority
		} else if (updateField === 'poNumber') {
			fieldValue = currentPoNumber
		} else if (updateField === 'projectNumber') {
			fieldValue = currentProjectNumber
		} else if (updateField === 'duration') {
			fieldValue = [currentStartDate,currentEndDate]
		} else if (updateField === 'totalProjectBudget') {
			fieldValue = currentTotalProjectBudget
		} else if (updateField === 'passThrough') {
			fieldValue = currentPassThrough
		} else if (updateField === 'passThroughDesc') {
			fieldValue = currentPassThroughDesc
		} else if (updateField === 'projectTypes') {
			fieldValue = currentProjectTypes
		}

		
		
		let updateResults = await props.updateAgencyProject({
			updateField: updateField,
			value: fieldValue,
			projectID: props.projectDetails.projectID
		})

		if (updateResults) {
			if (updateField === 'description') {
				setEditDescription(false)
				showToast('success', 'Success', 'Description was updated successfully.')
			} else if (updateField === 'issues') {
				setEditIssues(false)
				showToast('success', 'Success', 'Notes was updated successfully.')
			} else if (updateField === 'status') {
				setEditStatus(false)
				if (fieldValue === 'deleted') {
					showToast('success', 'Success','Project was Deleted.')
					document.location.href='/'
				} else {
					showToast('success', 'Success', 'Status was updated successfully.')
				}
			} else if (updateField === 'priority') {
				setEditPriority(false)
				showToast('success', 'Success', 'Priority was updated successfully.')
			} else if (updateField === 'poNumber') {
				setEditPoNumber(false)
				showToast('success', 'Success', 'P.O. Number was updated successfully.')
			} else if (updateField === 'projectNumber') {
				setEditProjectNumber(false)
				showToast('success', 'Success', 'Project Number was updated successfully.')
			} else if (updateField === 'duration') {
				setEditDuration(false)
				showToast('success', 'Success', 'Duration was updated successfully.')
			} else if (updateField === 'totalProjectBudget') {
				setEditTotalProjectBudget(false)
				showToast('success', 'Success', 'Total Budget was updated successfully.')
			} else if (updateField === 'passThrough') {
				setEditPassThrough(false)
				showToast('success', 'Success', 'Pass Through was updated successfully.')
			} else if (updateField === 'passThroughDesc') {
				setEditPassThroughDesc(false)
				showToast('success', 'Success', 'Pass Through Description was updated successfully.')
			} else if (updateField === 'projectTypes') {
				setEditProjectTypes(false)
				showToast('success', 'Success', 'Project Types were updated successfully.')
			}
			
			
		} else showToast('error', 'Error','There was an issue updating the project.')
	}

	const statusSelections = [
		{label: 'Pending', value: 'pending'},
		{label: 'Active', value: 'active'},
		{label: 'Not Started', value: 'not_started'},
		{label: 'On Hold', value: 'hold'},
		{label: 'Canceled', value: 'canceled'},
		{label: 'Completed', value: 'completed'}
	]

	if (isAdmin) statusSelections.push(
		{label: 'Delete', value: 'deleted'}
	)

	const prioritySelections = [
		{label: 'High', value: 'high'},
		{label: 'Normal', value: 'normal'},
		{label: 'Low', value: 'low'}
	]

	const departmentTemplate = (rowData) => {
		return  (
			<div className="flex flex-column">
				<div className="title">
					{rowData.functionTitle}
				</div>
				<div className="description">
					{rowData.functionDescription}
				</div>
			</div>
		)
	}

	const hoursEditTemplate = (rowData) => {
		return (
			<div className="flex justify-content-end">
				<div>{rowData.baseline}</div>
			</div>
		)
	}

	const addProject = async (currentNewProject) => {
		
		let blnAddResults = await props.updateAgencyProject({
			...currentNewProject,
			fullUpdate: true
		})
		fncHideModal()
		if (blnAddResults) {
			
			if (currentNewProject.status === 'deleted') {
				showToast('success', 'Success','Project was Deleted.')
				document.location.href='/'
			} else {
				showToast('success', 'Success','Project was Updated.')
			}
		} else {
			showToast('error', 'Error','There was an issue updating the project.')
		}
	}

	return (
		<div className="projectDetails">
			<Toast ref={toast} />
			{!props.projectDetails.projectName && 
				<div>
					<Link to="/">
						Project does not exist, click to return
					</Link>
				</div>
			}
			{props.projectDetails.projectName && 
				<>
					<BreadCrumbs items={breadCrumbItems} />
					<div style={{float:'right'}}>
					
						<Button label="OOP Costs" icon="pi pi-plus" className="p-button p-component p-button-outlined p-button-secondary mr-2" onClick={() => setDisplayOOP(true)} />
						<Button label="Hours" icon="pi pi-plus" className="p-button p-component p-button-outlined p-button-secondary" onClick={() => setDisplayHours(true)} />
					</div>
					<div className="flex">
						<h2>
							{props.projectDetails.projectName}
						</h2>
						{isAdmin && 
							<div className="ml-2 editIcon"  onClick={(e) => setDisplayEntry(true)}>
								<i className="fa fa-pencil-square-o"></i>
							</div>
						}
						
					</div>
					<div className="flex justify-content-between">
						<div className="card mr-4  align-self-stretch w-50">
							<div className="flex flex-column">
								<div className="flex justify-content-between detailsSection horizontalDividerBottom">
									<div className="flex flex-column">
										<div className="flex">
											<div className="mr-2">Brand: </div>
											<div className="mb-2">
												{projectDetails.brandName}
											</div>
										</div>
										<div className="flex">
											<div className="mr-2">Therapeutic Areas: </div>
											<div className="mb-2">
												{projectDetails.therapeuticAreas.join(', ')}
											</div>
										</div>
										<div className="flex">
											<div className="mr-2">Indications: </div>
											<div className="mb-2">
												{projectDetails.indications.join(', ')}
											</div>
										</div>
										
									</div>
									{projectDetails.audienceID && 
										<div className="flex flex-column">
											<div className="flex justify-content-end">
												<div className="mr-2">Audience: </div>
												<div className="mb-2">
													{projectDetails.audienceName}
												</div>
											</div>
											{projectDetails.patientPopulation.length > 0 && 
												<div className="flex justify-content-end">
													<div className="mr-2">Patient Population: </div>
													<div className="mb-2">
														{projectDetails.patientPopulation.join(', ')}
													</div>
												</div>
											}
										</div>
									}
									
								</div>
								<div className="flex flex-column detailsSection horizontalDividerBottom">
									<div className="flex justify-content-between">
										<div>
											Description
										</div>
										{!editDescription && 
											<div className="editIcon" onClick={(e) => setEditDescription(true)}>
												<i className="fa fa-pencil-square-o"></i>
											</div>
										}
										{editDescription && 
											<div className="saveIcon"  onClick={(e) => fncUpdateField('description')}>
												<i className="fa fa-save"></i>
											</div>
										}
									</div>
									{!editDescription && 
										<div>
											{currentDescription}
										</div>
									}
									{editDescription && 
										<div>
											<InputTextarea rows={5}  className="w-90" id="description" value={currentDescription} onChange={(e) => setCurrentDescription(e.target.value)} />
										</div>
									}
								</div>


								{currentProjectTypes && 
									<div className="flex flex-column detailsSection horizontalDividerBottom">
										<div className="flex justify-content-between">
											<div>
												Project Types
											</div>
											{!editProjectTypes && 
												<div className="editIcon" onClick={(e) => setEditProjectTypes(true)}>
													<i className="fa fa-pencil-square-o"></i>
												</div>
											}
											{editProjectTypes && 
												<div className="saveIcon" onClick={(e) => fncUpdateField('projectTypes')}>
													<i className="fa fa-save"></i>
												</div>
											}
										</div>
										{!editProjectTypes && 
											<div>
												{currentProjectTypes.join(', ')}
											</div>
										}
										{editProjectTypes && 
											<div>
												<Chips 
													className="w-90"
													id="projectTypes"
													value={currentProjectTypes} 
													onChange={(e) => setCurrentProjectTypes(e.value)} 
												/>
											</div>
										}
									</div>
								}



								<div className="flex flex-column detailsSection horizontalDividerBottom">
									<div className="flex justify-content-between">
										<div>
											P.O. Number
										</div>
										{!editPoNumber && 
											<div className="editIcon" onClick={(e) => setEditPoNumber(true)}>
												<i className="fa fa-pencil-square-o"></i>
											</div>
										}
										{editPoNumber && 
											<div className="saveIcon" onClick={(e) => fncUpdateField('poNumber')}>
												<i className="fa fa-save"></i>
											</div>
										}
									</div>
									{!editPoNumber && 
										<div>
											{currentPoNumber}
										</div>
									}
									{editPoNumber && 
										<div>
											<InputText className="w-90" id="poNumber" value={currentPoNumber} onChange={(e) => setCurrentPoNumber(e.target.value)} />
										</div>
									}
								</div>
								<div className="flex flex-column detailsSection horizontalDividerBottom">
									<div className="flex justify-content-between">
										<div>
											Project Number
										</div>
										{!editProjectNumber && 
											<div className="editIcon" onClick={(e) => setEditProjectNumber(true)}>
												<i className="fa fa-pencil-square-o"></i>
											</div>
										}
										{editProjectNumber && 
											<div className="saveIcon" onClick={(e) => fncUpdateField('projectNumber')}>
												<i className="fa fa-save"></i>
											</div>
										}
									</div>
									{!editProjectNumber && 
										<div>
											{currentProjectNumber}
										</div>
									}
									{editProjectNumber && 
										<div>
											<InputText className="w-90" id="projectNumber" value={currentProjectNumber} onChange={(e) => setCurrentProjectNumber(e.target.value)} />
										</div>
									}
								</div>
								<div className="flex flex-column detailsSection horizontalDividerBottom">
									<div>
										Duration
									</div>
									<div>
										{monthTemplate(currentStartDate)} - {monthTemplate(currentEndDate)}
									</div>
								</div>
								<div className="flex justify-content-between detailsSection horizontalDividerBottom">
									<div className="flex">
										<div className="flex">
											<div className="mr-2">Status: </div>
											{!editStatus &&
												<>
													<div className={"mb-2 product-badge status-" + currentStatus}>
														{currentStatus}
													</div>
													<div className="ml-2 editIcon"  onClick={(e) => setEditStatus(true)}>
														<i className="fa fa-pencil-square-o"></i>
													</div>
												</>
											}
											{editStatus && 
												<>
													<Dropdown className="w-90" value={currentStatus} options={statusSelections} onChange={(e) => setCurrentStatus(e.value)}  placeholder="Select Status" />
													<div className="ml-2 saveIcon" onClick={(e) => fncUpdateField('status')}>
														<i className="fa fa-save"></i>
													</div>
												</>
											}
										</div>
									</div>
									<div className="flex">
										<div className="mr-2">Priority: </div>
										{!editPriority &&
											<>
												<div className="mb-2" style={{textTransform:'capitalize'}}>
													{currentPriority}
												</div>
												<div className="ml-2 editIcon"  onClick={(e) => setEditPriority(true)}>
													<i className="fa fa-pencil-square-o"></i>
												</div>
											</>
										}
										{editPriority && 
											<>
												<Dropdown className="w-90" value={currentPriority} options={prioritySelections} onChange={(e) => setCurrentPriority(e.value)}  placeholder="Select Priority" />
												<div className="ml-2 saveIcon" onClick={(e) => fncUpdateField('priority')}>
													<i className="fa fa-save"></i>
												</div>
											</>
										}
									</div>
									
								</div>
								<div className="flex flex-column detailsSection">
									
									<div className="flex justify-content-between">
										<div>
											Notes
										</div>
										{!editIssues && 
											<div className="editIcon"  onClick={(e) => setEditIssues(true)}>
												<i className="fa fa-pencil-square-o"></i>
											</div>
										}
										{editIssues && 
											<div className="saveIcon" onClick={(e) => fncUpdateField('issues')}>
												<i className="fa fa-save"></i>
											</div>
										}
									</div>
									{!editIssues && 
										<div>
											{currentIssues}
										</div>
									}
									{editIssues && 
										<div>
											<InputTextarea rows={5} className="w-90" id="issues" value={currentIssues} onChange={(e) => setCurrentIssues(e.target.value)} />
										</div>
									}
								</div>
							</div>
						</div>
						<div className="card ml-4 align-self-stretch w-50">
							<div className="flex justify-content-between horizontalDividerBottom">
								<h4>Budgeted Hours and Costs</h4>
								
							</div>
							<div className="flex flex-column">
								<div className="flex horizontalDividerBottom detailsSection">
									<div style={{width:'100%'}}>
										<div className="grid grid-nogutter">
											<div className="col-5">
												Total Hours:
											</div>
											<div className="col-7" style={{textAlign:'right'}}>
												{totalHours}
											</div>
										</div>
									</div>
								</div>
								<div className="flex horizontalDividerBottom detailsSection">
									<div style={{width:'100%'}}>
										<div className="grid grid-nogutter">
											<div className="col-5">
												Total Project Budget:
											</div>
											<div className="col-7" style={{textAlign:'right'}}>
												<div className="flex justify-content-end">
													{dollarTemplate(parseFloat(currentTotalProjectBudget))}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="flex horizontalDividerBottom detailsSection">
									<div style={{width:'100%'}}>
										<div className="grid grid-nogutter">
											<div className="col-5">
												Pass-through (OOPs): 
											</div>
											<div className="col-7" style={{textAlign:'right'}}>
												<div className="flex justify-content-end">
													{dollarTemplate(parseFloat(currentPassThrough))}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="flex horizontalDividerBottom detailsSection">
									<div style={{width:'100%'}}>
										<div className="grid grid-nogutter">
											<div className="col-5">
												Description of Pass-through costs:
											</div>
											<div className="col-7" style={{textAlign:'right'}}>
												<div className="flex justify-content-end">
													{currentPassThroughDesc}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="flex horizontalDividerBottom detailsSection">
									<div style={{width:'100%'}}>
										<div className="grid grid-nogutter">
											<div className="col-5">
												Total-Fees plus Pass-through:
											</div>
											<div className="col-7" style={{textAlign:'right'}}>
												{dollarTemplate(currentTotalProjectBudget + props.projectDetails.passThrough)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card w-100">
						<div className="flex justify-content-between">
							<div className="flex">
								<h4 className="mr-4">Departments</h4>
							</div>
						</div>
						<DataTable ref={dt} value={props.projectDetails.departments}>
							<Column field="functionTitle" header="Department" body={departmentTemplate} style={{width: '55%'}}></Column>
							<Column field="baseline" header="Budgeted Hours" body={hoursEditTemplate} style={{textAlign:'right',width: '15%'}}></Column>
							<Column field="rate" header="Rate" body={dollarTemplate} style={{textAlign:'right',width: '15%'}}></Column>
							<Column field="hours" header="Billed Hours" style={{textAlign:'right',width: '15%'}} ></Column>
						</DataTable>
					</div>

					<ProjectAddHours
						displayHours={displayHours}
						fncHideModal={fncHideModal}
						currentProject={props.projectDetails}
						addAgencyProjectHours={props.addAgencyProjectHours}
						showToast={showToast}
					/>

					<ProjectAddOOPCosts
						displayOOP={displayOOP}
						fncHideModal={fncHideModal}
						currentProject={props.projectDetails}
						passThrough={props.projectDetails.passThrough}
						oopCosts={props.projectDetails.oopCosts}
						addOOPCosts={props.addOOPCosts}
						showToast={showToast}
					/>

					{displayEntry && isAdmin && 
						<ProjectDetailsModal
							displayEntry={displayEntry}
							brandsDropdown={props.brandsDropdown}
							agencyFunctions={props.agencyFunctions}
							fncHideModal={fncHideModal}
							addProject={addProject}
							showToast={showToast}
							projectNumbers={projectNumbers}
							projectDetails={projectDetails}
						/>
					}
				</>
			}
		</div>
	);
}

export default AgencyProjectList;