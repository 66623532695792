import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';

// Form validation library
import { useForm, Controller } from 'react-hook-form';

import AuthService from '../services/AuthService'

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from 'primereact/inputtextarea';
import { Steps} from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { Messages } from 'primereact/messages';
import { classNames } from 'primereact/utils';
import { confirmDialog } from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Chips } from 'primereact/chips';

import { dollarTemplate } from '../utilities/CommonSnippets'


const ProjectDetailsModal = (props) => {
	const toast = useRef(null);
	const Auth = new AuthService();
	let isAdmin = false
	if (Auth.loggedIn()) {
		const profile = Auth.getProfile()
		isAdmin = profile.isAdmin
	}

	let defaultYear = dayjs().year()
	if (dayjs().month() >= 8 ) defaultYear = dayjs().add(1, 'year').year()
	
	let defaultDates = [
		new Date('1/1/' + defaultYear),
		new Date('12/31/' + defaultYear)
	]

	// initially the defaults were here, but with the form validation library, this sets up the object schema but the defauts are down below
	const objProjectShell = {
		brandAgencyID: 0,
		audienceID: 0,
		patientPopulation: [],
		name: '',
		ponumber: '',
		description: '',
		status: 'active',
		priority: 'normal',
		totalProjectBudget: '',
		number: '',
		issues: '',
		projectBrandID: 0,
		brandAgencyID: 0,
		startDate: defaultDates[0],
		endDate: defaultDates[1],
		totalCost: 0,
		totalHours: 0,
		passthrough: 0,
		passthroughDesc: '',
		projectTypes: []
	}
	
	const steps = [
		{label: 'Details'},
		{label: 'Departments'},
		{label: 'Hours'}
	];

	const { projectDetails, displayEntry, brandsDropdown } = props

	const [activeIndex, setActiveIndex] = useState(0);
	const [errorShowing, setErrorShowing] = useState(false);
	const [currentProject, setCurrentProject] = useState(objProjectShell);
	const [departmentHours, setDepartmentHours] = useState({});
	const [totalHours, setTotalHours] = useState(0);
	const [totalBudget, setTotalBudget] = useState(0);
	const [audienceLookup, setAudienceLookup] = useState({});
	const [patientPopulationLookup, setPatientPopulationLookup] = useState({});

	const msgs = useRef(null);

	useEffect(() => {
		detailReset()
		staffReset()
		passthroughDefaultValuesReset()
		setCurrentProject(objProjectShell)
		setActiveIndex(0)
		setTotalHours(0)
		setTotalBudget(0)

	},[displayEntry]);

	useEffect(() => {
		let _patientPopulationLookup = {}
		let _audienceLookup = brandsDropdown.reduce((acc,cur) =>{
			acc[cur.value] = cur.audiences.reduce((accA, curA) => {
				accA.push({
					label: curA.name,
					value: curA.audienceID
				})

				_patientPopulationLookup[curA.audienceID] = curA.patientPopulation

				return accA
			},[{
				label: 'No Audience',
				value: null
			}])
			return acc
		},{})

		setPatientPopulationLookup(_patientPopulationLookup)
		setAudienceLookup(_audienceLookup)
	},[brandsDropdown]);

	useEffect(() => {
		let objCurLookup = {}
		if (projectDetails && projectDetails.departments) {
			objCurLookup = projectDetails.departments.reduce((acc,cur) => {
				acc[cur.functionID] = {
					hours: cur.baseline,
					rate: cur.rate
				}
				return acc
			},{})
		}

		let _departmentHours = props.agencyFunctions.reduce((acc,cur) => {
			if (!acc[cur.functionID]) {
				acc[cur.functionID] = {
					hours: 0,
					rate: 0
				}
			}
			return acc
		},objCurLookup)
		setDepartmentHours(_departmentHours)
		
	},[props.agencyFunctions, props.displayEntry, projectDetails]);

	

	useEffect(() => {
		if (projectDetails) {
			setValue("projectID", projectDetails.projectID)
			setValue("name", projectDetails.projectName)
			setValue("description", projectDetails.description)
			setValue("ponumber", projectDetails.poNumber)
			setValue("number", projectDetails.projectNumber)
			setValue("startDate", projectDetails.startDate)
			setValue("endDate", projectDetails.endDate)
			setValue("status", projectDetails.status)
			setValue("priority", projectDetails.priority)
			setValue("issues", projectDetails.issues)
			setValue("totalHours", projectDetails.totalHours)
			setValue("totalProjectBudget", projectDetails.totalProjectBudget)
			setValue("projectTypes", projectDetails.projectTypes)
			setPassthroughValue("passthrough", projectDetails.passThrough)
			setPassthroughValue("passthroughDesc", projectDetails.passThroughDesc)
			setValue("brandAgencyID", projectDetails.brandAgencyID)
			//setValue("audienceID", projectDetails.audienceID)
		}

	},[projectDetails]);

	useEffect(() => {
		if (activeIndex === 1) {
			let _totalHours = Object.keys(departmentHours).reduce((acc,cur) => {
				if (departmentHours[cur].hours) acc += parseFloat(departmentHours[cur].hours)
				return acc
			},0)

			setTotalHours(_totalHours)

			let _totalAccountedBaseline = Object.keys(departmentHours).reduce((acc,cur) => {
				if (departmentHours[cur].hours > 0 && departmentHours[cur].rate > 0) acc += parseFloat(departmentHours[cur].hours) * parseFloat(departmentHours[cur].rate)
				return acc
			},0)

			setTotalBudget(_totalAccountedBaseline)
		}
		if (document.getElementsByClassName('p-dialog-content').length > 0) document.getElementsByClassName('p-dialog-content')[0].scrollTo(0,0)

	},[activeIndex]);

	// These are the needed items for the form validation component, there are multiple since we are in a wizard and each act independently 
	const detailsDefaultValues = {
		brandAgencyID: '',
		projectID: 0,
		name: '',
		description: '',
		status: 'active',
		priority: 'normal',
		number: '',
		startDate: defaultDates[0],
		endDate: defaultDates[1],
		issues: ''
	}

	const staffDefaultValues = {
		name: '',
		title: '',
		functionID: null,
		rate: '',
		hours: '',
		isSenior: false,
		status: 'active',
	}

	const passthroughDefaultValues = {
		passthrough: 0,
		passthroughDesc: ''
	}

	const { 
		control: detailControl, 
		formState: { errors: detailErrors }, 
		handleSubmit: detailHandleSubmit, 
		setValue, getValues, watch,
		reset: detailReset} = useForm({defaultValues: detailsDefaultValues});

	const watchedFormVars = watch("brandAgencyID")
	useEffect(() => {
		if (projectDetails && projectDetails.brandAgencyID === watchedFormVars && projectDetails.audienceID) {
			fncUpdateCurrentProject('audienceID',projectDetails.audienceID)
		} else {
			fncUpdateCurrentProject('audienceID',null)
		}	
	},[projectDetails, watchedFormVars]);

	useEffect(() => {
		if (projectDetails && projectDetails.audienceID === currentProject.audienceID && projectDetails.patientPopulation) {
			fncUpdateCurrentProject('patientPopulation',projectDetails.patientPopulation)
		} else {
			fncUpdateCurrentProject('patientPopulation',[])
		}	
	},[projectDetails, currentProject.audienceID]);



	const { 
		control: staffControl, 
		formState: { errors: staffErrors, isDirty: staffIsDirty }, 
		handleSubmit: staffHandleSubmit, 
		reset: staffReset} = useForm({defaultValues: staffDefaultValues});

	const { 
		control: passthroughControl, 
		formState: { errors: passthroughErrors}, 
		handleSubmit: passthroughDefaultValuesHandleSubmit, 
		reset: passthroughDefaultValuesReset,
		setValue: setPassthroughValue, 
		
		getValues: passthroughValues} = useForm({defaultValues: passthroughDefaultValues});

	

	const statusSelections = [
		{label: 'Pending', value: 'pending'},
		{label: 'Active', value: 'active'},
		{label: 'Not Started', value: 'not_started'},
		{label: 'On Hold', value: 'hold'},
		{label: 'Canceled', value: 'canceled'},
		{label: 'Completed', value: 'completed'}
	];

	if (isAdmin && projectDetails) statusSelections.push(
		{label: 'Delete', value: 'deleted'}
	)



	const prioritySelections = [
		{label: 'High', value: 'high'},
		{label: 'Normal', value: 'normal'},
		{label: 'Low', value: 'low'}
	];
	
	// function moving from Details to the Staff section
	const fncGoToDepartment = (data) => {
		// Make sure projectNumber is unique
		if (props.projectNumbers.includes(parseInt(data.number))) {
			if (!errorShowing) {
				setErrorShowing(true)
				msgs.current.show(
					{ severity: 'error', detail: 'Project Number must be unique.', life: 3000}
				);
			}
		} else if (parseFloat(data.totalProjectBudget) === 0) {
			if (!errorShowing) {
				setErrorShowing(true)
				msgs.current.show(
					{ severity: 'error', detail: 'Total Project Budget must be greater than 0.', life: 3000}
				);
			}
		} else {
			let _currentProject = {...currentProject, ...data}

			setCurrentProject(_currentProject)
			setActiveIndex(1)
		}
	}

	// function moving from Department to the Hours section, need to make sure you have at least some hours
	const fncCheckDepartment = () => {
		
		let percentUsed = Math.abs(totalBudget / currentProject.totalProjectBudget) * 100
		
		if (totalHours <= 0) {
			if (!errorShowing) {
				setErrorShowing(true)
				msgs.current.show(
					{ severity: 'warn', detail: 'At least 1 department must have hours.', life: 3000}
				);
			}
		} else if (percentUsed < 90 || percentUsed > 110 ) {
			if (!errorShowing) {
				setErrorShowing(true)
				msgs.current.show(
					{ severity: 'warn', detail: 'The department budget does not match the overall budget and must be within 10% ', life: 3000}
				);
			}
		}
		else {
			setActiveIndex(2)
		}
	}

	//Adding the actual project to database. 
	const fncAddProject = (data) => {
		
		let arrDepartmentHours = Object.keys(departmentHours).map((cur) => {
			return {
				functionID: cur,
				hours: parseFloat(departmentHours[cur].hours),
				rate: parseFloat(departmentHours[cur].rate)
			}
		})

		// todo: make the end date be the last day of the month chosen

		props.addProject({...currentProject, ...data, departmentHours: arrDepartmentHours})
	}

	const footerDetails = () => {
		return (
			<div>
				<Button label="Next" onClick={detailHandleSubmit(fncGoToDepartment)} />
			</div>
		);
	}

	const footerStaff = () => {
		return (
			<div>
				<Button label="Prev" onClick={() => setActiveIndex(0)} />
				<Button label="Next" onClick={() => fncCheckDepartment()} />
			</div>
		);
	}

	const footerHours = (name) => {
		return (
			<div>
				<Button label="Prev" onClick={() => setActiveIndex(1)} />
				<Button label={(projectDetails?"Update":"Create")} onClick={passthroughDefaultValuesHandleSubmit(fncAddProject)} />
			</div>
		);
	}

	const fncSetDepartmentHour = (cur, value) => {
		let _departmentHours = {...departmentHours}
		_departmentHours[cur].hours = value.replace(/[^0-9.]/g, '')

		let _totalHours = Object.keys(_departmentHours).reduce((acc,cur) => {
			if (_departmentHours[cur].hours) acc += parseFloat(_departmentHours[cur].hours)
			return acc
		},0)

		let _totalBudget = Object.keys(departmentHours).reduce((acc,cur) => {
			if (departmentHours[cur].hours) acc += parseFloat(departmentHours[cur].hours) * parseFloat(departmentHours[cur].rate)
			return acc
		},0)

		let _currentProject = {...currentProject}
		// Calculate the new totals based on the staff
		_currentProject.totalHours = _totalHours
		_currentProject.totalCost = _totalBudget

		setTotalHours(_totalHours)
		setTotalBudget(_totalBudget)
		setDepartmentHours(_departmentHours)
		setCurrentProject(_currentProject)

	}

	const fncSetDepartmentRate = (cur, value) => {
		
		let _departmentHours = {...departmentHours}
		_departmentHours[cur].rate = value.replace(/[^0-9.]/g, '')

		let _totalHours = Object.keys(_departmentHours).reduce((acc,cur) => {
			if (_departmentHours[cur].hours) acc += parseFloat(_departmentHours[cur].hours)
			return acc
		},0)
		
		let _totalBudget = Object.keys(departmentHours).reduce((acc,cur) => {
			if (departmentHours[cur].hours) acc += parseFloat(departmentHours[cur].hours) * parseFloat(departmentHours[cur].rate)
			return acc
		},0)

		let _currentProject = {...currentProject}
		// Calculate the new totals based on the staff
		_currentProject.totalHours = _totalHours
		_currentProject.totalCost = _totalBudget

		setTotalHours(_totalHours)
		setTotalBudget(_totalBudget)
		setDepartmentHours(_departmentHours)
		setCurrentProject(_currentProject)

	}

	

	const fncUpdateCurrentProject = (key, value) => {
		let _currentProject = {...currentProject}
		_currentProject[key] = value
		setCurrentProject(_currentProject)
	}

	let arrDepartmentsDiv = props.agencyFunctions.map((cur) => {
		if (!departmentHours[cur.functionID]) return
		return (
			<div className="flex justify-content-between mb-2">
				<div className="flex flex-column">
					<div className="title">
						{cur.functionTitle}
					</div>
					<div className="description">
						{cur.functionDescription}
					</div>
				</div>
				<div className="flex">
					<div className="flex flex-column mr-3">
						<div className="flex justify-content-center" style={{fontSize:'10px'}}>
							Rate
						</div>
						<div >
							<InputText 
								value={departmentHours[cur.functionID].rate} 
								onChange={(e) => fncSetDepartmentRate(cur.functionID, e.target.value)} 
								className="p-inputtext-sm"
								style={{width:'70px',align:'right'}}
								placeholder={0}
							/>
							
						</div>
						
						
					</div>
					<div className="flex flex-column">
						<div className="flex justify-content-center" style={{fontSize:'10px'}}>
							Hours
						</div>
						<div>
							<InputText 
								value={departmentHours[cur.functionID].hours} 
								onChange={(e) => fncSetDepartmentHour(cur.functionID, e.target.value)} 
								className="p-inputtext-sm"
								style={{width:'70px'}}
								placeholder={0}
							/>
						</div>
						
					</div>
				</div>
			</div>
		)
	})

	const fncConfirmClose = () => {
		confirmDialog({
			message: 'Are you sure you want to close? You will lose any data for this project.',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => props.fncHideModal(),
			reject: () => {}
		});


	}

	const allowOnlyNumber=(value)=>{
		return value.replace(/[^0-9]/g, '')
	 }

	 const fncPatientPopulationChange = (e) => {
        let _patientPopulation = [...currentProject.patientPopulation];

        if (e.checked) {
			_patientPopulation.push(e.value);
		} else {
			_patientPopulation.splice(_patientPopulation.indexOf(e.value), 1);
		}
		
		
		fncUpdateCurrentProject('patientPopulation',_patientPopulation)
    }

	return (
		<Dialog header={(projectDetails?"Edit Project":"New Project")} visible={props.displayEntry} className="projectModal" 
			footer={activeIndex === 0?footerDetails:activeIndex === 1?footerStaff:footerHours} onHide={fncConfirmClose}>
			<Toast ref={toast} baseZIndex={1000} />
			<ConfirmDialog />
			<Steps className="steps" model={steps} activeIndex={activeIndex}/>
			{activeIndex === 0 && 
				<form className="p-fluid">
					
					<div className="field grid">
						<label htmlFor="name" className="col-12 mb-2 md:col-2 mb-md-0">Project Name*</label>
						<div className="col-12 md:col-10">
							<Controller name="name"  control={detailControl} rules={{ required: true }} render={({ field, fieldState }) => (
								
								 <InputText 
									id={field.name} 
									value={field.value} 
									{...field}
									autoFocus 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{detailErrors.name && 
								<small className="p-error">Name is required.</small>
							}
						</div>
					</div>
					
					<div className="field grid">
						<label htmlFor="brand" className="col-12 mb-2 md:col-2 mb-md-0">Brand*</label>
						<div className="col-12 md:col-3">
							<Controller name="brandAgencyID" control={detailControl} rules={{ required: true }} render={({ field, fieldState }) => (
								<Dropdown 
									id={field.name} 
									value={field.value} 
									options={brandsDropdown} 
									onChange={(e) => field.onChange(e.value)} 
									placeholder="Select Brand" 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{detailErrors.brandAgencyID && 
								<small className="p-error">Brand is required.</small>
							}
						</div>

						{audienceLookup[getValues('brandAgencyID')] && audienceLookup[getValues('brandAgencyID')].length > 0 && 
							<>
								<label htmlFor="audience" className="col-12 mb-2 md:col-offset-2 md:col-2 mb-md-0">Audience</label>
								<div className="col-12 md:col-3">
									<Dropdown 
										id="audienceID" 
										value={currentProject.audienceID} 
										options={audienceLookup[getValues('brandAgencyID')]} 
										onChange={(e) => fncUpdateCurrentProject('audienceID',e.value)} 
										placeholder="Select Audience" 
									/>
								</div>
							</>
						}
					</div>

					{currentProject.audienceID > 0 && patientPopulationLookup[currentProject.audienceID].length > 0 &&
						<div className="field grid">
							<label htmlFor="brand" className="col-12 col-offset-7 md:col-2 mb-2 mb-md-0">Patient Population</label>
							<div className="col-12 md:col-3">
								<div className="flex flex-wrap justify-content-start gap-3">
									{patientPopulationLookup[currentProject.audienceID].map((cur,index) => {
										return (
											<div className="flex align-items-center">
												<Checkbox inputId={"patientPopulation" + index} name="patientPopulation" value={cur} onChange={fncPatientPopulationChange} checked={currentProject.patientPopulation.includes(cur)} />
												<label htmlFor={"patientPopulation" + index} className="ml-2">{cur}</label>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					}

					<div className="field grid">
						<label htmlFor="status" className="col-12 mb-2 md:col-2 mb-md-0">Start Date*</label>
						<div className="col-12 md:col-3">
							<Controller name="startDate" control={detailControl} rules={{ required: true }} render={({ field, fieldState }) => (
								 <Calendar 
									id={field.name} 
									value={field.value} 
									onChange={(e) => field.onChange(e.value)} 
									view="month" dateFormat="MM yy"
									showIcon 
									numberOfMonths={1}
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{detailErrors.startDate && 
								<small className="p-error">Start Date is required.</small>
							}
							
						</div>
						<label htmlFor="number" className="col-12 mb-2 md:col-offset-2 md:col-2 mb-md-0">End Date*</label>
						<div className="col-12 md:col-3">
							<Controller name="endDate" control={detailControl} rules={{ required: true }} render={({ field, fieldState }) => (
								<Calendar 
									id={field.name} 
									value={field.value} 
									onChange={(e) => field.onChange(e.value)} 
									view="month" dateFormat="MM yy"
									showIcon 
									numberOfMonths={1}
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{detailErrors.endDate && 
								<small className="p-error">End Date is required.</small>
							}
						</div>
						
					</div>

					<div className="field grid">
						
						<label htmlFor="ponumber" className="col-12 mb-2 md:col-2 mb-md-0">P.O. Number*</label>
						<div className="col-12 md:col-3">
							<Controller name="ponumber"  control={detailControl} rules={{ required: true }} render={({ field, fieldState }) => (
								
								 <InputText 
									id={field.name} 
									value={field.value} 
									{...field}
									autoFocus 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
									style={{width: '100%'}}
								/>
							)} />
							{detailErrors.ponumber && 
								<small className="p-error">P.O. Number is required.</small>
							}
						</div>
						
						
						<label htmlFor="number" className="col-12 mb-2 md:col-offset-2 md:col-2 mb-md-0">Project Number*</label>
						<div className="col-12 md:col-3">
							<Controller name="number" control={detailControl} rules={{ required: true }} render={({ field, fieldState }) => (
								 <InputText 
									id={field.name} 
									value={field.value} 
									{...field}
									autoFocus
									className={classNames({ 'p-invalid': fieldState.invalid })} 
									style={{width: '100%'}}
								/>
							)} />
							{detailErrors.number && 
								<small className="p-error">Number is required.</small>
							}
						</div>
						
					</div>
					<div className="field grid">
						<label htmlFor="status" className="col-12 mb-2 md:col-2 mb-md-0">Status*</label>
						<div className="col-12 md:col-3">
							<Controller name="status" control={detailControl} rules={{ required: true }} render={({ field, fieldState }) => (
								<Dropdown 
									id={field.name} 
									value={field.value} 
									options={statusSelections} 
									onChange={(e) => field.onChange(e.value)} 
									placeholder="Select Status" 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{detailErrors.status && 
								<small className="p-error">Status is required.</small>
							}
							
						</div>
						
					
						<label htmlFor="priority" className="col-12 mb-2 md:col-offset-2 md:col-2 mb-md-0">Priority*</label>
						<div className="col-12 md:col-3">
							<Controller name="priority" control={detailControl} rules={{ required: true }} render={({ field, fieldState }) => (
								 

								<Dropdown 
									id={field.name} 
									value={field.value} 
									options={prioritySelections} 
									onChange={(e) => field.onChange(e.value)} 
									placeholder="Select Priority" 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{detailErrors.priority && 
								<small className="p-error">Priority is required.</small>
							}
						</div>
						
					</div>
					<div className="field grid">
						<label htmlFor="totalProjectBudget" className="col-12 mb-2 md:col-2 mb-md-0">Total Project Budget*</label>
						<div className="col-12 md:col-4">
							<Controller name="totalProjectBudget" control={detailControl} rules={{ required: true, number: true }} render={({ field, fieldState }) => (
								<InputText 
									id={field.name} 
									value={field.value} 
									type="number"
									{...field}
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{detailErrors.totalProjectBudget && 
								<small className="p-error">Total Project Budget is required.</small>
							}
						</div>
					</div>

					<div className="field grid">
						<label htmlFor="description" className="col-12 mb-2 md:col-2 mb-md-0">Description</label>
						<div className="col-12 md:col-10">
							<Controller name="description" control={detailControl} render={({ field, fieldState }) => (
								
								<InputTextarea 
									rows={5}
									id={field.name} 
									value={field.value} 
									{...field}
									className={classNames('w-100',{'p-invalid': fieldState.invalid })} 
								/>
							)} />
						</div>
					</div>

					<div className="field grid">
						<label htmlFor="issues" className="col-12 mb-2 md:col-2 mb-md-0">Notes</label>
						<div className="col-12 md:col-10">
							<Controller name="issues" control={detailControl} rules={{ required: false }} render={({ field, fieldState }) => (
								 <InputText 
									id={field.name} 
									value={field.value} 
									{...field}
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
						</div>
					</div>

					<div className="field grid">
						<label htmlFor="projectTypes" className="col-12 mb-2 md:col-2 mb-md-0">Project Types</label>
						<div className="col-12 md:col-10">
							<Controller name="projectTypes" control={detailControl} rules={{ required: false }} render={({ field }) => (
								 <Chips 
								 	id={field.name} 
									value={field.value} 
									onChange={(e) => field.onChange(e.value)} 
								/>
							)} />
						</div>
					</div>
				</form>
			}

			{activeIndex === 1 && 
				<div className="grid grid-nogutter" >
					<div className="col-12">
						<div className="flex mb-2 title justify-content-end">
							<div className="mr-2">Total Budget: </div>
							<div className="mr-1">
								{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalBudget)}
							</div>
							<div className="mr-1">
								/
							</div>
							<div className="mr-2">
								{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currentProject.totalProjectBudget)}
							</div>
							<div>
								({(Math.abs(totalBudget / currentProject.totalProjectBudget) * 100).toFixed(1)}%)
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="flex flex-column highlightrows">
							{arrDepartmentsDiv}
						</div>
					</div>
				</div>
			}

			{activeIndex === 2 && 
				<>
					<div className="field grid">
						<label htmlFor="totalHours" className="col-12 mb-3 md:col-3 mb-md-0">Total Hours</label>
						<div className="col-12 md:col-3" style={{padding: '0.5rem 0.5rem'}}>
							{currentProject.totalHours}
						</div>
						<label htmlFor="rate" className="col-12 mb-3 md:col-3 mb-md-0">Pass-through (OOPs), $</label>
						<div className="col-12 md:col-3">
							<Controller name="passthrough" control={passthroughControl} rules={{ required: false }} render={({ field, fieldState }) => (
								<InputText 
									id={field.name} 
									value={field.value} 
									{...field}
									autoFocus
									style={{width:'75px'}}
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
						</div>
					</div>

					<div className="field grid">
						<label htmlFor="totalCost" className="col-12 mb-3 md:col-3 mb-md-0">Total Cost</label>
						<div className="col-12 md:col-3" style={{padding: '0.5rem 0.5rem'}}>
							{dollarTemplate(currentProject.totalCost)}
						</div>
						<label htmlFor="rate" className="col-12 mb-3 md:col-3 mb-md-0">Total-Fees plus Pass-through</label>
						<div className="col-12 md:col-3">
							{dollarTemplate(parseFloat(currentProject.totalProjectBudget) + parseFloat((passthroughValues('passthrough')?passthroughValues('passthrough'):0)))}
						</div>
					</div>

					<div className="field grid">
						<label htmlFor="rate" className="col-12 mb-3 mb-md-0">Description of Pass-through costs</label>
						<div className="col-12">
							<Controller name="passthroughDesc" control={passthroughControl} rules={{ required: false }} render={({ field, fieldState }) => (
								<InputTextarea 
									rows={5}
									id={field.name} 
									value={field.value} 
									{...field}
									autoFocus
									className={classNames('w-100',{'p-invalid': fieldState.invalid })} 
								/>
							)} />
						</div>
					</div>
				</>
			}
			<Messages ref={msgs} className="modalMessage" onRemove={(e) => setErrorShowing(false)}></Messages>
		</Dialog>
	)
}

export default ProjectDetailsModal;