import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import ExcelJS  from 'exceljs'
import saveAs from "file-saver";

import { STAFF_LIGHT_COLOR, STAFF_COLOR_FADED_ARRAY } from '../../constants/colors'
import { fncDrawHeaderCell, fncDrawFooterCell, fncDrawFooterCellWhite, fncDrawTitleCell } from '../../utilities/commonXslxFunctions'
import { getFullStaffByBrand } from '../../selectors/dashboardWidgetsSelector'
import { fncFormatPercentage, percentTemplateNoDecimal, isTrue, numberTemplate, dateStringsMap } from '../../utilities/CommonSnippets'

const SpendByAudienceStaff = (props) => {
	const { fullStaffByBrand, brandList, loading, dates, downloadClicked, fileName } = props

	const colorOrder = {
		0: STAFF_COLOR_FADED_ARRAY[3],
		1: STAFF_COLOR_FADED_ARRAY[4],
		2: STAFF_COLOR_FADED_ARRAY[5],
		3: STAFF_COLOR_FADED_ARRAY[6],
		4: STAFF_COLOR_FADED_ARRAY[2],
		5: STAFF_COLOR_FADED_ARRAY[1],
		6: STAFF_COLOR_FADED_ARRAY[0]
	}

	const [seniorOnly, setSeniorOnly] = useState('');

	useEffect(() => {
		if (downloadClicked > 0) exportExcel()
	},[downloadClicked])

	useEffect(() => {
		setSeniorOnly(
			fullStaffByBrand.filter(cur => isTrue(cur.isSenior)).sort((a,b) => (a.name > b.name?-1:1)).sort((a,b) => (a.functionTitle > b.functionTitle?1:-1))
		)
	},[fullStaffByBrand])


	

	const getTotals = (field, type, noFormat) => {
        let total = 0;
		
        if (fullStaffByBrand) {
			for(let curAudience of fullStaffByBrand) {
				
				
				if (curAudience[field]) {
					if (type === 'all') total += curAudience[field];
					if (type === 'senior' && curAudience.isSenior) total += curAudience[field];
					if (type === 'others' && !curAudience.isSenior) total += curAudience[field];
				}
			}
		}
		total = Math.round(total)

		if (noFormat) return total

        return total.toLocaleString('en-US');
    }



	const getTotalAverage = (field1, field2, type, noFormat) => {
        let totalScoped = 0;
		let totalUsed = 0;
        if (fullStaffByBrand) {
			for(let curTotal of fullStaffByBrand) {
				if (type === 'all') {
					if (curTotal[field1]) totalScoped += curTotal[field1];
					if (curTotal[field2]) totalUsed += curTotal[field2];
				}
				if (type === 'senior' && curTotal.isSenior) {
					if (curTotal[field1]) totalScoped += curTotal[field1];
					if (curTotal[field2]) totalUsed += curTotal[field2];
				}
				if (type === 'others' && !curTotal.isSenior) {
					if (curTotal[field1]) totalScoped += curTotal[field1];
					if (curTotal[field2]) totalUsed += curTotal[field2];
				}
			}
		}
		
		if (noFormat) return totalUsed / totalScoped
        return Math.round(fncFormatPercentage(totalUsed / totalScoped,1)) + '%';
    }
	

	let footerGroup = <ColumnGroup>
		<Row >
			<Column className="whiteTableFooter talentWidth" footer="Senior Staff Sub-Total" footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			
			{brandList.map((cur) => {
					return [
						<Column footer={getTotals(cur + '_scoped','senior')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
						<Column footer={getTotals(cur + '_utilized','senior')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
						<Column footer={getTotalAverage(cur + '_scoped', cur + '_utilized', 'senior')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
					]
				})}
			<Column footer={getTotals('total_scoped','senior')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			<Column footer={getTotals('total_utilized','senior')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			<Column footer={getTotalAverage('total_scoped', 'total_utilized','senior')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
		</Row>

		<Row >
			<Column className="whiteTableFooter talentWidth" footer="All Other Sub-Total" footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />
			
			{brandList.map((cur, index) => {
					let curMod = index % 7
					return [
						<Column footer={getTotals(cur + '_scoped','others')} footerStyle={{background: colorOrder[curMod],textAlign: 'right', fontWeight: '700'}} />,
						<Column footer={getTotals(cur + '_utilized','others')} footerStyle={{background: colorOrder[curMod],textAlign: 'right', fontWeight: '700'}} />,
						<Column footer={getTotalAverage(cur + '_scoped', cur + '_utilized', 'others')}  footerStyle={{background: colorOrder[curMod],textAlign: 'right', borderRight:'1px solid ' + STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
					]
				})}
			<Column footer={getTotals('total_scoped','others')} footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />
			<Column footer={getTotals('total_utilized','others')} footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />
			<Column footer={getTotalAverage('total_scoped', 'total_utilized','others')} footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />
		</Row>
	
		<Row >
			<Column  className="whiteTableFooter talentWidth" footer="Total" footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			
			{brandList.map((cur) => {
					return [
						<Column footer={getTotals(cur + '_scoped','all')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
						<Column footer={getTotals(cur + '_utilized','all')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
						<Column footer={getTotalAverage(cur + '_scoped', cur + '_utilized', 'all')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
					]
				})}
			<Column footer={getTotals('total_scoped','all')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			<Column footer={getTotals('total_utilized','all')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			<Column footer={getTotalAverage('total_scoped', 'total_utilized', 'all')} footerStyle={{color: 'white', textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
		</Row>
		</ColumnGroup>;

	
	
	let headerGroup = <ColumnGroup>
			
			<Row>
				<Column header="" className="talentWidth" style={{textAlign:'center'}}/>
				{brandList.map((cur) => {
					return <Column key={cur} header={cur} colSpan={3} style={{textAlign:'center'}} />
				})}
				<Column header="Totals" colSpan={3} style={{textAlign:'center'}} />
			</Row>
			<Row>
				<Column  header="Talent" className="talentWidth" style={{textAlign:'center'}}/>
				{brandList.map((cur) => {
					return [
						<Column field={cur}  header="Scoped" style={{textAlign:'center'}}/>,
						<Column field={cur}  header="Utilized" style={{textAlign:'center'}}/>,
						<Column field={cur}  header="Percent" style={{textAlign:'center'}}/>,
					]
				})}
				<Column field="total_scoped"  header="Scoped" style={{textAlign:'center'}}/>
				<Column field="total_utilized"  header="Utilized" style={{textAlign:'center'}}/>
				<Column field="total_percent"  header="Percent" style={{textAlign:'center'}}/>
			</Row>
			
		</ColumnGroup>;

	let talentTemplate = (rowData) => {
		return (
			<div className="flex flex-column">
				<div className="mb-2 flex justify-content-between" >
					<div>
						{rowData.name}
					</div>
					
				</div>
				<div style={{fontWeight: '600'}}>
					{rowData.title}
				</div>
				<div style={{fontWeight: '600'}}>
					{rowData.functionTitle}
				</div>
				
			</div>
		)
	}

	let intTotalColumns = (brandList.length * 3) + 6
	const exportExcel = () => {
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");
		let objTempCell
		worksheet.properties.defaultColWidth = 10;
		worksheet.properties.defaultRowHeight = 16;
		
		worksheet.mergeCells(1,1,1,intTotalColumns);
		fncDrawTitleCell(worksheet, 'A1', "Staff Hours by Brand (" + dateStringsMap()[dates] + ")")

		// TOP BAR
		let topBar = worksheet.addRow();
		topBar.height  = 20;
		worksheet.mergeCells(3,1,3,intTotalColumns);
		fncDrawHeaderCell(worksheet, 'C3', 'Total Across All Brands')

		let brandNameBar = worksheet.addRow();
		brandNameBar.height  = 20;
		worksheet.mergeCells(4,1,4,3);
		fncDrawHeaderCell(brandNameBar,1,'')
		let curCounter_1 = 4
		for (let curBrand of brandList) {
			let tempEnd = curCounter_1 + 2
			worksheet.mergeCells(4,curCounter_1,4,tempEnd);
			fncDrawHeaderCell(brandNameBar,curCounter_1,curBrand)
			curCounter_1 = curCounter_1 + 3
		}
		worksheet.mergeCells(4,curCounter_1,4,curCounter_1+2);
		fncDrawHeaderCell(brandNameBar,curCounter_1,'Totals')
		
		let submenuBar = worksheet.addRow();
		submenuBar.height  = 20;
		fncDrawHeaderCell(submenuBar,1,'Talent')
		fncDrawHeaderCell(submenuBar,2,'Title')
		fncDrawHeaderCell(submenuBar,3,'Department')

		let curCounter_2 = 4
		for (let curBrand of brandList) {
			fncDrawHeaderCell(submenuBar,curCounter_2++,'Scoped')
			fncDrawHeaderCell(submenuBar,curCounter_2++,'Utilized')
			fncDrawHeaderCell(submenuBar,curCounter_2++,'Percent')
		}

		// totals
		fncDrawHeaderCell(submenuBar,curCounter_2++,'Scoped')
		fncDrawHeaderCell(submenuBar,curCounter_2++,'Utilized')
		fncDrawHeaderCell(submenuBar,curCounter_2++,'Percent')

		worksheet.getColumn(1).width = 25
		worksheet.getColumn(2).width = 30
		worksheet.getColumn(3).width = 30

		let curFillDetails = {
			type: 'pattern',
			pattern:'solid',
			fgColor:{argb:'dad2e6'}
		}

		let curFillDetails_odd = {
			type: 'pattern',
			pattern:'solid',
			fgColor:{argb:'b4a4cc'}
		}

		let curBorder = {
			top: {style:'thin', color: {argb:'FFFFFFFF'}},
			left: {style:'thin', color: {argb:'FFFFFFFF'}},
			bottom: {style:'thin', color: {argb:'FFFFFFFF'}},
			right: {style:'thin', color: {argb:'FFFFFFFF'}}
		  };

		for (let curRow of seniorOnly) {
			let dataRow = worksheet.addRow();
			let talentCell = dataRow.getCell(1);
			talentCell.value = curRow.name

			let titleCell = dataRow.getCell(2);
			titleCell.value = curRow.title

			let departmentCell = dataRow.getCell(3);
			departmentCell.value = curRow.functionTitle

			let curCounter_3 = 4
			for (let curBrandIndex in brandList) {
				let curBrand = brandList[curBrandIndex]
				
				let curMod = curBrandIndex % 2


				let dataCell_scoped = dataRow.getCell(curCounter_3++);
				dataCell_scoped.value = curRow[curBrand + '_scoped']
				dataCell_scoped.numFmt = '#,###'
				if (!curMod) {
					dataCell_scoped.fill = curFillDetails
				} else {
					dataCell_scoped.fill = curFillDetails_odd
				}
				dataCell_scoped.border = curBorder

				let dataCell_utilized = dataRow.getCell(curCounter_3++);
				dataCell_utilized.value = curRow[curBrand + '_utilized']
				dataCell_utilized.numFmt = '#,###'
				if (!curMod) {
					dataCell_utilized.fill = curFillDetails
				} else {
					dataCell_utilized.fill = curFillDetails_odd
				}
				dataCell_utilized.border = curBorder

				let dataCell_percent = dataRow.getCell(curCounter_3++);
				if (curRow[curBrand + '_percent'] > 0 ) {
					dataCell_percent.value = curRow[curBrand + '_percent'] / 100
				} else {
					dataCell_percent.value = 0
				}
				dataCell_percent.numFmt = '0%'
				if (!curMod) {
					dataCell_percent.fill = curFillDetails
				} else {
					dataCell_percent.fill = curFillDetails_odd
				}
				dataCell_percent.border = curBorder
			}

			let dataCell_scoped_total = dataRow.getCell(curCounter_3++);
			dataCell_scoped_total.value = curRow.total_scoped
			dataCell_scoped_total.numFmt = '#,###'

			let dataCell_utilized_total = dataRow.getCell(curCounter_3++);
			dataCell_utilized_total.value = curRow.total_utilized
			dataCell_utilized_total.numFmt = '#,###'

			let dataCell_percent_total = dataRow.getCell(curCounter_3++);
			dataCell_percent_total.value = curRow.total_percent / 100
			dataCell_percent_total.numFmt = '0%'

		}

		let footerSeniorBar = worksheet.addRow();
		footerSeniorBar.height  = 20;
		worksheet.mergeCells(footerSeniorBar['_number'],1,footerSeniorBar['_number'],3);
		fncDrawFooterCell(footerSeniorBar,1,'Senior Staff Sub-Total')

		
		let curCounter_4 = 4
		for (let curBrandIndex in brandList) {
			let curBrand = brandList[curBrandIndex]

			objTempCell = fncDrawFooterCell(footerSeniorBar,curCounter_4++,getTotals(curBrand + '_scoped','senior',1))
			objTempCell.numFmt = '#,###'
			objTempCell = fncDrawFooterCell(footerSeniorBar,curCounter_4++,getTotals(curBrand + '_utilized','senior',1))
			objTempCell.numFmt = '#,###'
			objTempCell = fncDrawFooterCell(footerSeniorBar,curCounter_4++,getTotalAverage(curBrand + '_scoped', curBrand + '_utilized', 'senior',1))
			objTempCell.numFmt = '0%'
		}

		objTempCell = fncDrawFooterCell(footerSeniorBar,curCounter_4++,getTotals('total_scoped','senior',1))
		objTempCell.numFmt = '#,###'
		objTempCell = fncDrawFooterCell(footerSeniorBar,curCounter_4++,getTotals('total_utilized','senior',1))
		objTempCell.numFmt = '#,###'
		objTempCell = fncDrawFooterCell(footerSeniorBar,curCounter_4++,getTotalAverage('total_scoped', 'total_utilized', 'senior',1))
		objTempCell.numFmt = '0%'

		let footerNonSeniorBar = worksheet.addRow();
		footerNonSeniorBar.height  = 20;
		worksheet.mergeCells(footerNonSeniorBar['_number'],1,footerNonSeniorBar['_number'],3);
		fncDrawFooterCellWhite(footerNonSeniorBar,1,'All Other Sub-Total')

		
		let curCounter_5 = 4
		for (let curBrandIndex in brandList) {
			let curBrand = brandList[curBrandIndex]
			
			objTempCell = fncDrawFooterCellWhite(footerNonSeniorBar,curCounter_5++,getTotals(curBrand + '_scoped','others',1))
			objTempCell.numFmt = '#,###'
			objTempCell = fncDrawFooterCellWhite(footerNonSeniorBar,curCounter_5++,getTotals(curBrand + '_utilized','others',1))
			objTempCell.numFmt = '#,###'
			objTempCell = fncDrawFooterCellWhite(footerNonSeniorBar,curCounter_5++,getTotalAverage(curBrand + '_scoped', curBrand + '_utilized', 'others',1))
			objTempCell.numFmt = '0%'
				
		}

		objTempCell = fncDrawFooterCellWhite(footerNonSeniorBar,curCounter_5++,getTotals('total_scoped','others',1))
		objTempCell.numFmt = '#,###'
		objTempCell = fncDrawFooterCellWhite(footerNonSeniorBar,curCounter_5++,getTotals('total_utilized','others',1))
		objTempCell.numFmt = '#,###'
		objTempCell = fncDrawFooterCellWhite(footerNonSeniorBar,curCounter_5++,getTotalAverage('total_scoped', 'total_utilized', 'others',1))
		objTempCell.numFmt = '0%'

		let footerTotalBar = worksheet.addRow();
		footerTotalBar.height  = 20;
		worksheet.mergeCells(footerTotalBar['_number'],1,footerTotalBar['_number'],3);
		fncDrawFooterCell(footerTotalBar,1,'Total')

		
		let curCounter_6 = 4
		
		for (let curBrandIndex in brandList) {
			let curBrand = brandList[curBrandIndex]
			
			objTempCell = fncDrawFooterCell(footerTotalBar,curCounter_6++,getTotals(curBrand + '_scoped','all',1))
			objTempCell.numFmt = '#,###'
			objTempCell = fncDrawFooterCell(footerTotalBar,curCounter_6++,getTotals(curBrand + '_utilized','all',1))
			objTempCell.numFmt = '#,###'
			objTempCell = fncDrawFooterCell(footerTotalBar,curCounter_6++,getTotalAverage(curBrand + '_scoped', curBrand + '_utilized', 'all',1))
			objTempCell.numFmt = '0%'
				
		}

		objTempCell = fncDrawFooterCell(footerTotalBar,curCounter_6++,getTotals('total_scoped','all',1))
		objTempCell.numFmt = '#,###'
		objTempCell = fncDrawFooterCell(footerTotalBar,curCounter_6++,getTotals('total_utilized','all',1))
		objTempCell.numFmt = '#,###'
		objTempCell = fncDrawFooterCell(footerTotalBar,curCounter_6++,getTotalAverage('total_scoped', 'total_utilized', 'all',1))
		objTempCell.numFmt = '0%'

		
		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  fileName + `.xlsx`
			);
		  });

	}

	return (
		<div className="flex mb-3 fullWidget ">
			<div className="align-self-stretch fullWidget staff">
				<div style={{color: 'white',paddingTop: '15px', textAlign: 'center', height:'50px',background: STAFF_LIGHT_COLOR, fontWeight: '700'}}>
					Total Across All Brands
				</div>
				<DataTable className="tables" value={seniorOnly} headerColumnGroup={headerGroup} footerColumnGroup={footerGroup}>
					<Column field="name" className="talentWidth" header="Name"  body={talentTemplate} />
					{brandList.map((cur, index) =>{
						
						let curMod = index % 7
						
						return [
							<Column field={cur + '_scoped'} body={numberTemplate}  header="Scoped" style={{ background: colorOrder[curMod],textAlign:'right'}}/>,
							<Column field={cur + '_utilized'} body={numberTemplate} header="Utilized" style={{background: colorOrder[curMod],textAlign:'right'}}/>,
							<Column field={cur+ '_percent'} body={percentTemplateNoDecimal} header="Percent" style={{background: colorOrder[curMod],textAlign:'right', borderRight:'1px solid ' + STAFF_LIGHT_COLOR}}/>,
						]
					})}
					<Column field='total_scoped' body={numberTemplate}  header="Scoped" style={{textAlign:'right'}}/>
					<Column field='total_utilized' body={numberTemplate}  header="Utilized" style={{textAlign:'right'}}/>
					<Column field='total_percent' body={percentTemplateNoDecimal} header="Percent" style={{textAlign:'right'}}/>
				</DataTable>
			</div>
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	const objDetails = getFullStaffByBrand(state, ownProps)
	return {
		fullStaffByBrand: objDetails.fullDetails,
		brandList: objDetails.brandList || []
	}
}

const SpendByAudienceStaffContainer = connect(mapStateToProps, null)(SpendByAudienceStaff);
export default SpendByAudienceStaffContainer;