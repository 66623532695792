import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText  } from 'primereact/inputtext';

import ExcelJS  from 'exceljs'
import saveAs from "file-saver";
import dayjs from 'dayjs'

import ImportProjects from '../modals/importProjects'

import {dollarTemplate, statusTemplate, numberTemplate } from '../utilities/CommonSnippets'

const AgencyProjectList = (props) => {
	const dt = useRef(null);
	const { projects, addAgencyProject, updateAgencyProject, showToast, agencyFunctions, addAgencyProjectHours, deleteAgencyProject, getAgencyProjects, addOOPCosts } = props
	
	// Filters
	const [availableBrands, setAvailableBrands] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState(null);
	const [availableStatus, setAvailableStatus] = useState([]);
	const [availableTherapeutic, setAvailableTherapeutic] = useState([]);
	const [availableIndications, setAvailableIndications] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [availableDates, setAvailableDates] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [availablePriorities, setAvailablePriorities] = useState([]);
	const [selectedPriority, setSelectedPriority] = useState(null);
	const [selectedName, setSelectedName] = useState('');
	const [selectedType, setSelectedType] = useState('');
	const [selectedTherapeutic, setSelectedTherapeutic] = useState(null);
	const [selectedIndication, setSelectedIndication] = useState([]);
	const [displayImportProject, setDisplayImportProject] = useState(false);

	const fncHideImportModal = () => {
		setDisplayImportProject(false)
		getAgencyProjects()
	}

	const fncFiltersMatch = (curFilter, curRow) => {
		if (curFilter !== 'status') {
			if (selectedStatus && curRow.status !== selectedStatus) return false
		}

		if (curFilter !== 'priority') {
			if (selectedPriority && curRow.priority !== selectedPriority) return false
		}

		if (curFilter !== 'startDate') {
			if (selectedDate && curRow.startDate != selectedDate) return false
		}

		if (curFilter !== 'brandName') {
			if (selectedBrand && curRow.brandName !== selectedBrand) return false
		}

		if (curFilter !== 'therapeutic') {
			if (selectedTherapeutic && !curRow.therapeuticAreas.includes(selectedTherapeutic)) return false
		}

		if (curFilter !== 'indication') {
			if (selectedIndication && !curRow.indications.includes(selectedIndication)) return false
		}
		
		return true
	}

	useEffect(() => {
		let _brands = {}
		let _status = {}
		let _therapeutic = {}
		let _indications = {}
		let _priorities = {}
		let _dates = {}
		for (let cur of projects) {
			if (cur.brandName) {
				if (fncFiltersMatch('brandName',cur)) {
					if (!_brands[cur.brandName]) _brands[cur.brandName] = 0
					_brands[cur.brandName]++
				}
			}

			if (cur.status) {
				
				if (fncFiltersMatch('status',cur)) {
					if (!_status[cur.status]) _status[cur.status] = 0
					_status[cur.status]++
				}
			}

			if (cur.priority) {
				if (fncFiltersMatch('priority',cur)) {
					if (!_priorities[cur.priority]) _priorities[cur.priority] = 0
					_priorities[cur.priority]++
				}
			}

			if (cur.startDate) {
				if (fncFiltersMatch('startDate',cur)) {
					
					let dateKey = dayjs(cur.startDate).format('MMMM YYYY') + ' - ' + dayjs(cur.endDate).format('MMMM YYYY')
					
					if (!_dates[dateKey]) _dates[dateKey] = 0
					_dates[dateKey]++
				}
			}

			if (cur.therapeuticAreas) {
				if (fncFiltersMatch('therapeutic',cur)) {
					for (let curTherapeutic of cur.therapeuticAreas) {
						if (!_therapeutic[curTherapeutic]) _therapeutic[curTherapeutic] = 0
						_therapeutic[curTherapeutic]++
					}
				}
			}

			if (cur.indications) {
				if (fncFiltersMatch('indications',cur)) {
					for (let curIndications of cur.indications) {
						if (!_indications[curIndications]) _indications[curIndications] = 0
						_indications[curIndications]++
					}
				}
			}
		}
		
		setAvailableBrands(Object.keys(_brands).map((cur) => {
			return {
				label: cur + ' (' + _brands[cur] + ')', 
				value: cur
			}
		}))
		
		setAvailableStatus(Object.keys(_status).map((cur) => {
			return {
				label: cur + ' (' + _status[cur] + ')', 
				value: cur
			}
		}))

		setAvailablePriorities(Object.keys(_priorities).map((cur) => {
			return {
				label: cur + ' (' + _priorities[cur] + ')', 
				value: cur
			}
		}))

		setAvailableDates(Object.keys(_dates).map((cur) => {
			return {
				label: cur + ' (' + _dates[cur] + ')', 
				value: cur
			}
		}).sort((a,b) => (new Date(a.value) < new Date(b.value)?-1:1)))

		setAvailableTherapeutic(Object.keys(_therapeutic).map((cur) => {
			return {
				label: cur + ' (' + _therapeutic[cur] + ')', 
				value: cur
			}
		}))

		setAvailableIndications(Object.keys(_indications).map((cur) => {
			return {
				label: cur + ' (' + _indications[cur] + ')', 
				value: cur
			}
		}))

		
	}, [projects, selectedBrand, selectedStatus, selectedPriority, selectedDate, selectedTherapeutic, selectedIndication]);

	const fncAddHours = (rowData) => {
		props.setCurrentProject(rowData)
		props.setDisplayHours(true)
	}

	const projectNameTemplate = (rowData) => {
		return (
			<div className="flex flex-column" >
				<div className="mb-2 name">
					<Link to={"/projectDetails/" + rowData.projectID}>{rowData.projectName}</Link>
				</div>
				<div className="mb-2 description">{rowData.description}</div>
				<div className="mb-2">
					<Button label="Hours" icon="pi pi-plus" className="p-button p-component p-button-outlined p-button-secondary" onClick={() => fncAddHours(rowData)} />
				</div>
				
			</div>
		)
	}

	const brandTemplate = (rowData) => {
		return (
			<div className="flex flex-column" >
				<div className="mb-2 description">{rowData.brandName}</div>
				{rowData.audienceID && 
					<div className="flex ml-2">
						<div className="flex flex-column mb-2 description">
							<div>
								{rowData.audienceName}
							</div>
							{rowData.patientPopulation.length > 0 && 
								<div>
									Population: {rowData.patientPopulation.join(',')}
								</div>
							}
							
						</div>
					</div>
				}
			</div>
		)
	}

	const arrayTemplate = (rowData, column) => {
		
		return (
			<div className="flex flex-column" >
				{rowData[column.field].map((cur, index) => {
					return (
						<div key={column.field + '|array|' + index}>
							{cur}
						</div>
					)
				})}
			</div>
		)
	}

	

	const datesTemplate = (rowData) => {
		return (
			<div className="flex">
				<div>
					{dayjs(rowData.startDate).format('MMMM YYYY')} - {dayjs(rowData.endDate).format('MMMM YYYY')}
				</div>
			</div>
		)
	}

	const totalCostTemplate = (rowData) => {
		return (
			<div className="flex flex-column">
				<div>
					{dollarTemplate(rowData.totalProjectBudget)}
				</div>
			</div>
		)
	}

	const totalSpendTemplate = (rowData) => {
		return (
			<div className="flex flex-column">
				<div>
					({dollarTemplate(rowData.totalCostUsed)})
				</div>
			</div>
		)
	}

	

	const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

	const exportExcel = () => {
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Actual Vs Budget Staff");
		worksheet.properties.defaultColWidth = 20;
		worksheet.properties.defaultRowHeight = 16;
		
		let cell = null
		
		//let dataRow = worksheet.addRow();
		//worksheet.getCell('A1').value('Test')

		worksheet.getCell('A1').value = 'Project'
		worksheet.getCell('B1').value = 'Brand'
		worksheet.getCell('C1').value = 'Number'
		worksheet.getCell('D1').value = 'Status'
		worksheet.getCell('E1').value = 'Priority'
		worksheet.getCell('F1').value = 'Start Date'
		worksheet.getCell('G1').value = 'End Date'
		worksheet.getCell('H1').value = 'Notes'
		worksheet.getCell('I1').value = 'Rate'
		worksheet.getCell('J1').value = 'Hours'
		worksheet.getCell('K1').value = 'Cost'
		worksheet.getCell('L1').value = 'Pass-through (OOPS)'
		worksheet.getCell('M1').value = 'Pass-through Desc'
		worksheet.getCell('N1').value = 'Therapeutic Areas'
		worksheet.getCell('O1').value = 'Indications'
		worksheet.getCell('P1').value = 'Project Types'

		

		
		for (let curRow of props.projects) {
			let dataRow = worksheet.addRow();
			cell = dataRow.getCell(1).value = curRow.projectName
			cell = dataRow.getCell(2).value = curRow.brandName
			cell = dataRow.getCell(3)
			cell.numFmt = '@'
			cell.value = curRow.projectNumber
			cell = dataRow.getCell(4).value = curRow.status
			cell = dataRow.getCell(5).value = curRow.priority
			cell = dataRow.getCell(6).value = dayjs(curRow.startDate).format('MMMM YYYY') 
			cell = dataRow.getCell(7).value = dayjs(curRow.endDate).format('MMMM YYYY')
			cell = dataRow.getCell(8).value = curRow.issues
			cell = dataRow.getCell(9).value = curRow.rate
			cell = dataRow.getCell(10).value = curRow.totalHours
			cell = dataRow.getCell(11).value = curRow.totalProjectBudget
			cell = dataRow.getCell(12).value = curRow.passThrough
			cell = dataRow.getCell(13).value = curRow.passThroughDesc
			cell = dataRow.getCell(14).value = curRow.therapeuticAreas.join(', ')
			cell = dataRow.getCell(15).value = curRow.indications.join(', ')
			cell = dataRow.getCell(16).value = curRow.projectTypes.join(', ')
		}
		

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  props.agencyName + "_projects.xlsx"
			);
		  });
    }

	// Filter On Change and Dropdown configure
	const onBrandChange = (e) => {
        dt.current.filter(e.value, 'brandName', 'equals');
        setSelectedBrand(e.value);
    }

	const onStatusChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatus(e.value);
    }

	const onDateChange = (e) => {
        dt.current.filter(e.value, 'dateRange', 'equals');
        setSelectedDate(e.value);
    }

	const onPriorityChange = (e) => {
        dt.current.filter(e.value, 'priority', 'equals');
        setSelectedPriority(e.value);
    }

	const onNameChange = (e) => {
        dt.current.filter(e.target.value, 'projectName', 'contains');
        setSelectedName(e.target.value);
    }

	const onTypeChange = (e) => {
        dt.current.filter(e.target.value, 'projectTypes', 'contains');
        setSelectedType(e.target.value);
    }

	const onTherapeuticChange = (e) => {
        dt.current.filter(e.value, 'therapeuticAreas', 'contains');
        setSelectedTherapeutic(e.value);
    }

	const onIndicationChange = (e) => {
        dt.current.filter(e.value, 'indications', 'contains');
        setSelectedIndication(e.value);
    }


	const brandFilter = <Dropdown value={selectedBrand} options={availableBrands} filter onChange={onBrandChange} placeholder="Select a Brand" className="p-column-filter" showClear />;
	const statusFilter = <Dropdown value={selectedStatus} options={availableStatus} filter onChange={onStatusChange} placeholder="Select a Status" className="p-column-filter" showClear />;
	const dateFilter = <Dropdown value={selectedDate} options={availableDates} filter onChange={onDateChange} placeholder="Select a Project Date" className="p-column-filter" showClear />;
	const priorityFilter = <Dropdown value={selectedPriority} options={availablePriorities} filter onChange={onPriorityChange} placeholder="Select a Priority" className="p-column-filter" showClear />;
	const nameFilter = <InputText value={selectedName} onChange={onNameChange} placeholder="Select a Name" className="p-column-filter" />;
	const therapeuticFilter = <Dropdown value={selectedTherapeutic} options={availableTherapeutic} filter onChange={onTherapeuticChange} placeholder="Select a Therapeutic Area" className="p-column-filter" showClear />;
	const indicationFilter = <Dropdown value={selectedIndication} options={availableIndications} filter onChange={onIndicationChange} placeholder="Select an Indication" className="p-column-filter" showClear />;
	const typeFilter = <InputText value={selectedType} onChange={onTypeChange} placeholder="Select a Type" className="p-column-filter" />;
	
	

	const paginatorRight = <></>;
	const paginatorLeft = <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={(e) =>fncClearFilters() } />;

	const fncClearFilters = () => {
		setSelectedBrand(null)
		setSelectedStatus(null)
		setSelectedPriority(null);
		setSelectedDate(null);
		setSelectedName('');
		setSelectedType('');
		setSelectedTherapeutic(null);
		setSelectedIndication(null);
		dt.current.reset();

	//	dt.current.restoreTableState()
	}

	const fncSetFilters = (objFilter) => {
		if (objFilter.filters) {
			if (objFilter.filters.brandName) setSelectedBrand(objFilter.filters.brandName.value)
			if (objFilter.filters.status) setSelectedStatus(objFilter.filters.status.value)
			if (objFilter.filters.priority) setSelectedPriority(objFilter.filters.priority.value)
			if (objFilter.filters.projectName) setSelectedName(objFilter.filters.projectName.value)
			if (objFilter.filters.startDate) setSelectedDate(objFilter.filters.startDate.value)
			if (objFilter.filters.therapeuticAreas) setSelectedTherapeutic(objFilter.filters.therapeuticAreas.value)
			if (objFilter.filters.indications) setSelectedIndication(objFilter.filters.indications.value)
		}
	}
	return (
			<div className="card">
				<div style={{textAlign: 'right', paddingBottom:'15px'}}>
					<Button label="Import" icon="pi pi-upload" style={{marginRight: '15px'}} className="p-button p-component" onClick={() => setDisplayImportProject(true)} />
					<Button label="Create New Project" icon="pi pi-plus" className="p-button p-component p-button-outlined" onClick={() => props.fncDisplayModal()} />
				</div>
				<DataTable ref={dt} value={props.projects} className="p-datatable-projects" scrollable scrollHeight="auto" style={{ width: '100%' }} 
					paginator stateKey="projectList-session"
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}
					paginatorPosition = "both" exportFilename={props.agencyName + "_projects"}
					paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} onStateRestore={fncSetFilters}
					removableSort={true} filterDisplay="row" stripedRows 
				>
					<Column field="projectName" columnKey="projectName" header="Project" filter filterMatchMode="contains" showFilterMenu={false} showClearButton={false} filterElement={nameFilter}  body={projectNameTemplate} sortable style={{verticalAlign:'top',width:'250px'}}></Column>
					<Column field="brandName" columnKey="brandName" header="Brand" filter filterElement={brandFilter} showFilterMenu={false} showClearButton={false} body={brandTemplate} sortable style={{verticalAlign:'top',width:'250px'}}></Column>
					<Column field="therapeuticAreas" columnKey="therapeuticAreas" header="Therapeutic Areas" filter filterElement={therapeuticFilter} showFilterMenu={false} showClearButton={false} body={arrayTemplate} style={{verticalAlign:'top',width:'250px'}}></Column>
					<Column field="indications" columnKey="indications" header="Indications" filter filterElement={indicationFilter} showFilterMenu={false} showClearButton={false} body={arrayTemplate} style={{verticalAlign:'top',width:'250px'}}></Column>
					<Column field="projectTypes" columnKey="projectTypes" header="Project Types" filter filterMatchMode="contains" showFilterMenu={false} showClearButton={false} filterElement={typeFilter} body={arrayTemplate} style={{verticalAlign:'top',width:'250px'}}></Column>
					{/*}
					<Column field="projectNumber" columnKey="projectNumber" header="Number" style={{textAlign:'center',verticalAlign:'top',width:'150px'}} sortable></Column>
					<Column field="priority" columnKey="priority" filter filterElement={priorityFilter} sortable header="Priority" style={{textAlign:'center',verticalAlign:'top',width:'200px'}}></Column>
					<Column field="issues" columnKey="issues" header="Notes" style={{verticalAlign:'top',width:'200px'}}></Column>
					<Column field="rate" columnKey="rate" header="Rate"  sortable style={{textAlign:'right',verticalAlign:'top',width:'120px'}}></Column>
					<Column field="totalHours" columnKey="totalHours" header="Hours" body={totalHoursTemplate} sortable style={{textAlign:'right',verticalAlign:'top',width:'120px'}}></Column>
					<Column field="passThrough" columnKey="passThrough" header="Pass-through (OOPs)" body={dollarTemplate} sortable style={{textAlign:'right',verticalAlign:'top',width:'180px'}}></Column>
					<Column field="passThroughDesc" columnKey="passThroughDesc" header="Pass-through Desc" style={{verticalAlign:'top',width:'180px'}}></Column>
					<Column field="endDate" header="End Date" body={monthTemplate} sortable style={{verticalAlign:'top',width:'200px'}}></Column>
					
					{*/}
					<Column field="status" columnKey="status" filter filterElement={statusFilter} showFilterMenu={false} showClearButton={false} sortable header="Status" body={statusTemplate} style={{textAlign:'center',verticalAlign:'top',width:'200px'}}></Column>
					
					<Column field="startDate" header="Project Dates" filter filterElement={dateFilter} showFilterMenu={false} showClearButton={false} body={datesTemplate} sortable style={{verticalAlign:'top',width:'200px'}}></Column>
					
					
					
					<Column field="totalProjectBudget" columnKey="totalProjectBudget" header="Budget" sortable body={totalCostTemplate}  style={{textAlign:'right',verticalAlign:'top',width:'120px'}}></Column>
					<Column field="totalCostUsed" columnKey="totalCostUsed" header="Spend" sortable body={totalSpendTemplate}  style={{textAlign:'right',verticalAlign:'top',width:'120px'}}></Column>
					
				</DataTable>
				{displayImportProject && 
					<ImportProjects 
						displayImportProject={displayImportProject}
						fncHideImportModal={fncHideImportModal}
						addAgencyProject={addAgencyProject}
						updateAgencyProject={updateAgencyProject}
						agencyName={props.agencyName}
						brandsDropdown={props.brandsDropdown}
						projects={props.projects}
						showToast={showToast}
						agencyFunctions={agencyFunctions}
						addAgencyProjectHours={addAgencyProjectHours}
						addOOPCosts={addOOPCosts}
						deleteAgencyProject={deleteAgencyProject}
					/>
				}
			</div>	
	);
}

export default AgencyProjectList;