import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import readXlsxFile from 'read-excel-file'
import ExcelJS  from 'exceljs'
import saveAs from "file-saver";
import classNames from 'classnames'
import dayjs from 'dayjs'

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { getAgenciesPromise } from '../actions/agencies'
import { fncDrawTitleCellImport } from '../utilities/commonXslxFunctions'
import { COLUMN_MAPPINGS_STAFF } from '../constants/excelMappings'
import {dollarTemplate, statusTemplate, fncCompareStaff } from '../utilities/CommonSnippets'
import { GET_AGENCY_STAFF, UPLOAD_EXCEL_FILE } from '../constants/URLS'

const ImportStaff = (props) => {
	const toast = useRef(null);
	const fileUploadRef = useRef(null);
	const dt = useRef(null);
	const { displayImportStaff, fncHideImportModal, brandsDropdown, agencyName, staff, functionsDropdown, showToast, addAgencyStaff, updateAgencyStaff,
		addAgencyStaffHours, deleteAgencyStaff } = props

	const [ importedStaff, setImportedStaff ] = useState([]);
	const [ hasImportError, setHasImportError] = useState(false);
	const [ functionTitleByID, setFunctionTitleByID] = useState({});
	const [ functionIDByTitle, setFunctionIDByTitle] = useState({});
	const [ displayProgress, setDisplayProgress] = useState(false);
	const [ importCounts, setImportCounts] = useState({});
	const [ importCountsProgress, setImportCountsProgress] = useState({});
	
	useEffect(() => {
		let _functionTitleByID = {}
		let _functionIDByTitle = {}

		for (let curFunction of functionsDropdown) {
			_functionTitleByID[curFunction.value] = curFunction.label
			_functionIDByTitle[curFunction.label] = curFunction.value
		}
		setFunctionTitleByID(_functionTitleByID)
		setFunctionIDByTitle(_functionIDByTitle)
	},[functionsDropdown]);
	

	//const possibleBrands = brandsDropdown.map(cur => cur.label)
	const possibleDepartments = functionsDropdown.map(cur => cur.label)

	const fncReset = () => {
		let objCurUploader = fileUploadRef.current
		objCurUploader.clear()
		setImportedStaff([])
	}

	/*
		ALL THE EXPORT LOGIC IS HERE - PROVIDES THE NEEDED TEMPLATE
	*/
	const exportExcel = () => {
		// This is where the data starts, after the headers
		let startingRow = 4 

		
		let arrDepartments = ['"' + possibleDepartments.join(',') + '"']
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Staff Template", {
			views: [{ state: "frozen", ySplit: startingRow, xSplit: 2 }]
		});
		worksheet.protect()
		worksheet.properties.defaultColWidth = 20;
		worksheet.properties.defaultRowHeight = 16;
		//Meta data for the agency
		fncDrawTitleCellImport(worksheet, 'A1', "Agency: ")
		fncDrawTitleCellImport(worksheet, 'B1', agencyName)
		fncDrawTitleCellImport(worksheet, 'A2', "Month: ")
		fncDrawTitleCellImport(worksheet, 'B2', dayjs().format('MM/YYYY'))

		worksheet.getCell('B2').protection = {
			locked: false,
		};

		worksheet.getCell('B2').numFmt = '@'

		// Need to add the dynamic columns for each function (dept base hours, dept rate, dept used hours for the month)
		let _COLUMN_MAPPINGS = {...COLUMN_MAPPINGS_STAFF}
		for (let curBrand of Object.keys(brandsDropdown)) {
			_COLUMN_MAPPINGS[curBrand + ' base hours'] = {
				display: 'Base Hours',
				locked: false,
				width: 25,
				isBrand: true,
				brand: curBrand,
				numFmt: '#,##0'
			}

			_COLUMN_MAPPINGS[curBrand + ' monthly used'] = {
				display: 'Monthly Used',
				locked: false,
				width: 25,
				isBrand: true,
				brand: curBrand,
				numFmt: '#,##0'
			}
		}
		
	
		let alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
		let arrKeys = Object.keys(_COLUMN_MAPPINGS)
		
		for (let curColumnIndex in arrKeys) {
			let objCurObj = _COLUMN_MAPPINGS[arrKeys[curColumnIndex]]
			let strLetter = alphabet[curColumnIndex % 26]

			// This covers 104 columns, I don't think we will go over that? 
			if (curColumnIndex > 25 && curColumnIndex <= 51) strLetter = 'A' + strLetter
			if (curColumnIndex > 51 && curColumnIndex <= 77) strLetter = 'B' + strLetter
			if (curColumnIndex > 77) strLetter = 'C' + strLetter

			if (objCurObj.isBrand) {
				fncDrawTitleCellImport(worksheet, strLetter + parseInt(startingRow -1), objCurObj.brand)
			}

			fncDrawTitleCellImport(worksheet, strLetter + startingRow, objCurObj.display)
			if (objCurObj.width) worksheet.getColumn(strLetter).width = objCurObj.width
			if (objCurObj.numFmt) worksheet.getColumn(strLetter).numFmt =  objCurObj.numFmt;
			
			// Items with dropdown list in the excel
			if (objCurObj.list) {

				if (objCurObj.list === 'department') {
					worksheet.dataValidations.add(strLetter + parseInt(startingRow +1) + ':' + strLetter + '1000', {
						type: 'list',
						allowBlank: false,
						formulae: arrDepartments,
						showErrorMessage: true,
						errorStyle: 'error',
						error: 'Choose a valid Department',
					});
				}

				if (objCurObj.list === 'status') {
					worksheet.dataValidations.add(strLetter + parseInt(startingRow +1) + ':' + strLetter + '1000', {
						type: 'list',
						allowBlank: false,
						formulae: ['"active,leave,inactive"'],
						showErrorMessage: true,
						errorStyle: 'error',
						error: 'Choose a valid Status',
					});
				}

				//todo: senior staff, yes / no
			}
		}

		let curCell

		for (let curStaff of staff.sort((a,b) => (a.name < b.name?-1:1))) {
			let dataRow = worksheet.addRow();
			for (let curColumnIndex in arrKeys) {
				curCell = dataRow.getCell(parseInt(curColumnIndex) + 1);
				let curValue = ''
				let objCurObj = _COLUMN_MAPPINGS[arrKeys[curColumnIndex]]
				if (!objCurObj.isBrand) curValue = curStaff[arrKeys[curColumnIndex]]
				else {
					if (curStaff.baselineHoursByBrand[brandsDropdown[objCurObj.brand]]) {
						if (objCurObj.display === 'Base Hours') {
							curValue = curStaff.baselineHoursByBrand[brandsDropdown[objCurObj.brand]]
						}
					}
				}

				if (Array.isArray(curValue)) curValue = curValue.join(',')
				curCell.value = curValue
			}
			
		}
		

		for (let curRow = startingRow + 1; curRow < 1000; curRow++) {
			for (let curColumnIndex in arrKeys) {
				let objCurObj = _COLUMN_MAPPINGS[arrKeys[curColumnIndex]]
				let strLetter = alphabet[curColumnIndex % 26]

				if (curColumnIndex > 25 && curColumnIndex < 51) strLetter = 'A' + strLetter
				if (curColumnIndex > 51) strLetter = 'B' + strLetter

				worksheet.getCell(strLetter + curRow).protection = {locked: objCurObj.locked}
			}
		}

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  agencyName + "_staff_template.xlsx"
			);
		  });
    }

	/*
		ALL THE IMPORT LOGIC IS HERE - READS IN THE TEMPLATE
	*/

	const fncLoadExcel = async () => {
		let objCurUploader = fileUploadRef.current
		let schema = {
			'Art Internal ID': {
				prop: 'staffID'
			},
			'Staff Name': {
				prop: 'name',
				type: (value) => {
					return value.toString()
				}
			},
			'Title': {
				prop: 'title'
			},
			'Category (unnamed)': {
				prop: 'category'
			},
			'Status': {
				prop: 'status'
			},
			'Senior Staff': {
				prop: 'isSenior'
			},
			'Department': {
				prop: 'department'
			},
			'Rate': {
				prop: 'rate'
			},
			'Month': {
				prop: 'currentDate',
				type: (value) => {
					let arrParts = value.split('/')
					return new Date(arrParts[1], arrParts[0] -1 )
				}
			}
		}

		for (let curBrand of Object.keys(brandsDropdown)) {	
			schema[curBrand] = {
				prop: 'brand|' + curBrand,
				type: {
					[curBrand + '|Base Hours']: {
						prop: "baseline"
					},
					[curBrand + '|Monthly Used']: {
						prop: "used"
					}
				}
			}
		}

		let rows = await readXlsxFile(objCurUploader.getFiles()[0], {
			schema,
			transformData(data) {
				let agencyName = null
				let curMonth = null
				let brandColumns = {}
				let _finalData = data.reduce((acc,cur, index) => {
					if (index === 0) {
						agencyName = cur[1]
						return acc
					} else if (index === 1) {
						curMonth = cur[1]
						return acc
					} else if (index === 2) {
						
						brandColumns = cur.reduce((accD,curD, indexD) => {
							if (curD) accD[indexD] = curD
							return accD
						},{})
						
						return acc
					} else if (index === 3) {
						let _cur = cur.map((curInner, indexInner) => {
							if (brandColumns[indexInner]) {
								return brandColumns[indexInner] + '|' + curInner
							} else {
								return curInner
							}
						})
						
						acc.push([
							'Agency Name',
							'Month',
							..._cur
						])
					}else {
						acc.push([
							agencyName,
							curMonth,
							...cur
						])
					}

					return acc
				},[])
				return _finalData
			}
		})

	

		let transFormedRows = rows.rows.map(cur => {
			let objReturned = {}
			for (let curColumn in cur) {
				if (curColumn.indexOf('brand|') === 0) {
					if (!objReturned['brandAgencies']) objReturned['brandAgencies'] = []
					if (!objReturned['staffHours']) objReturned['staffHours'] = []
					let brandName = curColumn.replace('brand|', '')
					objReturned['brandAgencies'].push({
						brandAgencyID: brandsDropdown[brandName],
						isChecked: true,
						baseline: cur[curColumn].baseline || 0, 
						brandName: brandName
					})

					
					if (cur[curColumn].used && parseInt(cur[curColumn].used) > 0) {
						objReturned['staffHours'].push({
							comments: 'Imported from Upload tool.',
							brandAgencyID: brandsDropdown[brandName],
							staffID: cur.staffID,
							state: 'add',
							date: cur.currentDate,
							hours: cur[curColumn].used,
							brandName: brandName
						})
					} else if (cur[curColumn].used && parseInt(cur[curColumn].used) < 0) {
						objReturned['staffHours'].push({
							comments: 'Imported from Upload tool.',
							brandAgencyID: brandsDropdown[brandName],
							staffID: cur.staffID,
							state: 'remove',
							date: cur.currentDate,
							hours: Math.abs(cur[curColumn].used),
							brandName: brandName
						})
					}
					
				} else {
					objReturned[curColumn] = cur[curColumn]
				}
			}

			return objReturned
		})

		//let existingStaff = await getAgenciesPromise(GET_AGENCY_STAFF)
		let existingStaff = staff.reduce((acc,cur) => {
			acc[cur.staffID] = cur
			return acc
		},{})
		let deletedMap = staff.reduce((acc,cur) => {
			acc[cur.staffID] = 0
			return acc
		},{})

		let importedStaff = transFormedRows.reduce((acc,cur) => {
			let rowType = 'Add'
			// Some default data to cover NULL coming in from excel
			let existingData = {}
			let changedFields = []

			deletedMap[cur.staffID] = 1
			if (cur.staffID && parseInt(cur.staffID) > 0 && existingStaff[cur.staffID]) {
				existingData = {

				}
				rowType = ''
				changedFields = fncCompareStaff(existingStaff[cur.staffID], cur, functionTitleByID)
				if (changedFields.length > 0) {
					rowType = 'Edit'
				}
			}

			if (rowType !== "") {
				acc.push({
					...existingData,
					...cur, 
					rowType: rowType,
					changedFields: changedFields
				})
			}
			
			return acc
		},[])

		for (let curDeleteCheckStaffID in deletedMap) {
			if (!deletedMap[curDeleteCheckStaffID]) {
				importedStaff.push({
					...existingStaff[curDeleteCheckStaffID],
					rowType: 'Delete',
					changedFields: []
				})
			}
		}

		// Now check the rows for Errors
		let _importCounts = {}
		let _warningCounts = 0
		let _importCountsProgress = {}
		let _hasImportError = false
		importedStaff = importedStaff.map(cur => {
			let curRowType = cur.rowType
			let _warningFields = []
			let _errorFields = []
			let _rowTypeSorted

			//if deleting, just ignore any errors, they don't matter
			if (curRowType !== 'Delete') {
				
				if (curRowType === 'Add') _rowTypeSorted = 10
				if (curRowType === 'Edit') _rowTypeSorted = 11

				if (!cur.status) {
					curRowType = 'error'
					_errorFields.push('Status')
				}

				if (!cur.department) {
					curRowType = 'error'
					_errorFields.push('Department')
				}

				// If we don't have category, then assume we are named, so title and staff name are needed
				if (!cur.category) {
					if (!cur.name) {
						curRowType = 'error'
						_errorFields.push('Name')
					}
	
					if (!cur.title) {
						curRowType = 'error'
						_errorFields.push('Title')
					}
				}

				if (cur.isSenior !== 0 && cur.isSenior !== 1) {
					curRowType = 'error'
					_errorFields.push('Senior Staff')
				}

				if (!cur.brandAgencies || cur.brandAgencies.length === 0) {
					curRowType = 'error'
					_errorFields.push('Must have one Brand')
				}

				if (!cur.rate) {
					curRowType = 'error'
					_errorFields.push('Rate')
				}

				if (curRowType === 'error') {
					_hasImportError = true
					_rowTypeSorted = 1
				}
			} else {
				_rowTypeSorted = 12
			}


			if (!_importCounts[curRowType]) {
				_importCounts[curRowType] = 0
				_importCountsProgress[curRowType] = 0
			}
			_importCounts[curRowType]++

			if (_warningFields && _warningFields.length > 0) {
				if (!_importCounts['warning']) _importCounts['warning'] = 0
				_importCounts['warning']++
			}
	
			return {
				...cur,
				rowType: curRowType,
				rowTypeSorted: _rowTypeSorted,
				errorFields: _errorFields,
				warningFields: _warningFields
			}
		})

		setImportCounts(_importCounts)
		setImportCountsProgress(_importCountsProgress)

		if (importedStaff.length === 0) {
			toast.current.show({severity:'error', summary: 'Import Issue', detail: 'The file you are uploading does not contain any changes. Please make changes locally and try again.', life: 3000});
			objCurUploader.clear()
		}

		setHasImportError(_hasImportError)
		setImportedStaff(importedStaff)
	}

	// todo: handle the proper reloading
	const fncSaveStaff = async (rowData) => {
		return await addAgencyStaff(rowData, false)
	}

	
	const fncUpdateStaff = async (rowData) => {
		return await addAgencyStaff(rowData, false)
		
	}

	const fncDeleteStaff = async (staffID) => {
		await deleteAgencyStaff(staffID, false)
	}

	const fncSaveAll = async () => {
		setDisplayProgress(true)
		let blnAllSuccessful = true

		for (let curRow of importedStaff) {
			
			if (curRow.rowType !== "") {
				
				let strStaffType = 'named'
				if (curRow.category) strStaffType = 'unNamed'
				
				let _curRow = {
					...curRow,
					staffType: strStaffType,
					functionID: functionIDByTitle[curRow.department],
					comments: 'Imported from Upload tool.'
				}
				
				if (curRow.rowType === 'Edit' && curRow.staffID && parseInt(curRow.staffID) > 0) {
					let editResults = await fncUpdateStaff(_curRow)
					if (!editResults) blnAllSuccessful = false
					if (editResults.success && curRow.staffHours && curRow.staffHours.length > 0) {
						for (let curHours of curRow.staffHours) {
							await addAgencyStaffHours(curHours, false)
						}
					}

				} else if(curRow.rowType === 'Add') {
					let addResults = await fncSaveStaff({
						..._curRow,
						staffID: 0
					})
					if (addResults.success && curRow.staffHours && curRow.staffHours.length > 0) {
						
						for (let curHours of curRow.staffHours) {
							await addAgencyStaffHours({
								...curHours,
								staffID: addResults.staffID
							}, false)
						}
					}

					if (!addResults) blnAllSuccessful = false

				} else if(curRow.rowType === 'Delete') {
					await fncDeleteStaff(_curRow.staffID)
				}
				
				setImportCountsProgress(importCountsProgress => {
					return {
						...importCountsProgress,
						[curRow.rowType]: importCountsProgress[curRow.rowType] + 1
					}
				})
			}
		}

		const formData = new FormData();
		let objCurUploader = fileUploadRef.current
		let arrFiles = objCurUploader.getFiles() || []
		if (arrFiles[0]) {
			formData.append("uploadExcel", arrFiles[0]);
		}

		await axios({
			method: 'post', 
			url: UPLOAD_EXCEL_FILE,
			data: formData,
			headers: { "Content-Type": "multipart/form-data" }
		})

		setDisplayProgress(false)
		fncReset()

		if (blnAllSuccessful) showToast('success', 'Success','Staff Upload was successful.')
		else showToast('warn', 'Warning','Not all Staff were able to save.')
		fncHideImportModal()

	}

	const renderFooter = () => {
		return (
			<div className="flex justify-content-between">
				<div className="flex">
					{importedStaff.length > 0 && 
						<Button label="Reset" icon="pi pi-replay" onClick={() => fncReset()} className="p-button-text" />
					}
					{importedStaff.length === 0 && 
						<div className="mr-2">
							<Button label="Download Template" icon="pi pi-download" onClick={() => exportExcel()} className="p-button-text" />
						</div>
					}
				</div>
				{displayProgress === true && 
					<div className="flex">
						{Object.keys(importCounts).map(cur => {
							
							let strLabel = 'Edits'
							if (cur === 'Delete') strLabel = 'Deletions'
							if (cur === 'Add') strLabel = 'Additions'

							if (!['Edit','Delete','Add'].includes(cur)) return null

							return (
								<div className="ml-4 mr-4 smallerDetails">{strLabel}: {importCountsProgress[cur]} / {importCounts[cur]}</div>
							)
						})}
					</div>
				}
				<div className="flex">
					<div className="ml-2">
						{importedStaff.length > 0 && !hasImportError && !displayProgress && 
							<Button label="Save All" icon="pi pi-save" onClick={fncSaveAll} className="p-button" />
						}
						{importedStaff.length > 0 && !hasImportError && displayProgress && 
							<Button label="Saving" disabled={true} icon="pi pi-spin pi-spinner"  className="p-button" />
						}

						{importedStaff.length > 0 && hasImportError && 
							<div className="mr-4 font-bold red">
								All Errors must be corrected
							</div>
						}
						{importedStaff.length === 0 && 
							<Button label="Close" icon="pi pi-times" onClick={() => fncHideImportModal()} className="p-button" />
						}
					</div>
					
				</div>
			</div>
		);
	}

	const brandTemplate = (rowData) => {
		return (
			<div className="flex flex-column" >
				<div className="mb-2 description">{rowData.brandName}</div>
				{rowData.audienceID && 
					<div className="flex ml-2">
						<div className="flex flex-column mb-2 description">
							<div>
								{rowData.audienceName}
							</div>
							{rowData.patientPopulation.length > 0 && 
								<div>
									Population: {rowData.patientPopulation.join(',')}
								</div>
							}
							
						</div>
					</div>
				}
			</div>
		)
	}

	const rowTypeTemplate = (rowData, column) => {
		if (rowData.rowType === '') return null
		if (rowData.rowType === 'error') {
			return (
				<div className="flex flex-column red">
					<div className="font-bold ">
						Error
					</div>
					
					{rowData.errorFields && rowData.errorFields.length > 0 && rowData.errorFields.map(cur => {
						return (
							<div className="ml-2">{cur}</div>
						)
					})}
				</div>
			)
		}
		return (
			<div className="flex flex-column">
				<div className="font-bold">
					{rowData.rowType}
				</div>
				{rowData.changedFields && rowData.changedFields.length > 0 && rowData.changedFields.map(cur => {
					return (
						<div className="flex flex-column ml-2">
							<div>
								{cur.type.displayName}
							</div>
							{cur.type.hideComparison !== true && 
								<div className="flex flex-column ml-4">
									<div>
										{cur.databaseValue} (prev)
									</div>
									<div>
										{cur.importValue} (new)
									</div>
								</div>
							}
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<Dialog header="Import Staff" visible={displayImportStaff} className="importModal"  footer={renderFooter()} onHide={() => fncHideImportModal()}>
			<Toast ref={toast} />
			<div className="flex flex-column">
				<div className={classNames({ 'hidden': importedStaff.length > 0 })}>
					<div className="strong pb-2">Step 1 - Choose & Process File</div>
					<div className="pb-4">
						<FileUpload 
							name="excelFile" 
							ref={fileUploadRef} 
							accept="application/*" 
							maxFileSize={3000000} 
							customUpload 
							uploadHandler={fncLoadExcel}
							chooseLabel="Choose File to Load"
							uploadLabel="Process Data"
							emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} 
						/>
					</div>
				</div>
				{importedStaff.length > 0 && 
					<div className="projectImport">
						<div className="strong pb-2">Step 2 - Update Staff</div>

						<div className="flex">
							{Object.keys(importCounts).map(cur => {
								
								let strLabel = ''
								if (cur === 'Edit') strLabel = 'Edits'
								if (cur === 'Delete') strLabel = 'Deletions'
								if (cur === 'Add') strLabel = 'Additions'
								if (cur === 'error') strLabel = 'Errors'
								if (cur === 'warning') strLabel = 'Warnings'

								if (strLabel ==='') return null

								return (
									<div className="ml-4 mr-4 smallerDetails">{strLabel}: {importCounts[cur]} row(s)</div>
								)
							})}
						</div>

						<DataTable ref={dt} value={importedStaff} className="p-datatable-projects" scrollable scrollHeight="auto" style={{ width: '100%' }} 
							paginator removableSort={true} sortField="rowTypeSorted" sortOrder={1} dataKey="staffID"
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={25} rowsPerPageOptions={[25,50,100]}
							paginatorPosition = "both"
							stripedRows  
						>
							<Column field="rowType" sortable columnKey="rowType" body={rowTypeTemplate} header="Change Type" style={{verticalAlign:'top',width:'400px'}}></Column>
							<Column field="name" sortable columnKey="name" header="Staff Name"  style={{verticalAlign:'top',width:'200px'}}></Column>
							<Column field="title" sortable columnKey="title" header="Title"  style={{verticalAlign:'top',width:'200px'}}></Column>
							<Column field="category" sortable columnKey="category" header="Category"  style={{verticalAlign:'top',width:'200px'}}></Column>
							<Column field="department" sortable columnKey="department" header="Department"  style={{verticalAlign:'top',width:'200px'}}></Column>
							<Column field="status" columnKey="status" header="Status" body={statusTemplate} style={{textAlign:'center',verticalAlign:'top',width:'200px'}}></Column>
						</DataTable>
						
					</div>

		
				}
			</div>
			
		</Dialog>
	)
}

export default ImportStaff;