import React  from 'react';

// PrimeReact components
import { BreadCrumb } from 'primereact/breadcrumb';

// Modals

const BreadCrumbs = (props) => {
	const { items } = props
	const home = { icon: 'pi pi-home', command: (event) => {window.location.hash = "/";}}

	return (
		<div className="flex justify-content-between" style={{minHeight:'50px', zIndex:'500'}}>
			<div className="mr-2 align-self-center">
				<BreadCrumb model={items} home={home} />
			</div>
		</div>
	)
}



export default BreadCrumbs;