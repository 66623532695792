import React, { useEffect } from 'react';
import { Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import { ResetPassword } from './pages/ResetPassword';

// JWT pieces
import AuthService from './services/AuthService'

const AppWrapper =  () => {

    let location = useLocation();
	
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

	const Auth = new AuthService();
	const loggedIn = Auth.loggedIn();

	let currentPath = location.pathname
	if (currentPath.indexOf('/resetPassword') === 0) currentPath ='/resetPassword'
	if (currentPath.indexOf('/loginSSO') === 0) {
		Auth.loginSSO(document.location.hash.replace('#/loginSSO?uuid=',''));
	}

	if(currentPath !== '/resetPassword' && !loggedIn){
		if (window.location.pathname !== '/') window.localStorage.setItem('url',window.location.href)
		currentPath = '/login'
	};

    switch (currentPath) {
        case '/resetPassword':
			return <Route path="/resetPassword/:uuid" render={ (props) => <ResetPassword {...props} uuid={props.match.params.uuid} /> } />
		case '/login':
            return <Route path="*" component={Login} />
        case '/error':
            return <Route path="/error" component={Error} />
        case '/notfound':
            return <Route path="/notfound" component={NotFound} />
        case '/access':
            return <Route path="/access" component={Access} />
        default:
            return <App />;
    }
}

export default withRouter(AppWrapper);
