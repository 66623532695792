import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import withAuth from '../auth/withAuth';

// action files (get data from server and set up redux store)
import { getBrands } from '../../actions/brands'

import { setCurrentClientID } from '../../actions/ui'

// selector files (transform data from redux store in to what we need for presentation)
import { getClientsSelector } from '../../selectors/brandsSelector'

// presentation component
import Overview from './client-overview'

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		getBrands: () => dispatch(getBrands()),
		setCurrentClientID: (payload) => setCurrentClientID(payload)
	}, dispatch);
};

const mapStateToProps = (state) => {
	
	return {
		clients: getClientsSelector(state)
	}
}

const OverviewContainer = connect(mapStateToProps, mapDispatchToProps)(Overview);
//export default withAuth(OverviewContainer);
export default OverviewContainer;