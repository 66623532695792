export const setFromDate = (data) => dispatch => {
	dispatch({
		type: 'SET_FROM_DATE',
		payload: data
	})
}

export const setToDate = (data) => dispatch => {
	dispatch({
		type: 'SET_TO_DATE',
		payload: data
	})
}

export const setDateType = (data) => dispatch => {
	dispatch({
		type: 'SET_DATE_TYPE',
		payload: data
	})
}

export const setCurrentClientID = (data) => dispatch => {
	dispatch({
		type: 'SET_CURRENT_CLIENTID',
		payload: data
	})
}

