import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import withAuth from '../auth/withAuth';

// action files (get data from server and set up redux store)
import { getAgencyFunctions, getAgencyProjects,getAgencyStaff, getBrandsByAgency, addAgencyProject, updateAgencyProject, deleteAgencyProject, addAgencyProjectHours, 
	addAgencyStaff, updateAgencyStaff, addAgencyStaffHours, deleteAgencyStaff, addOOPCosts} from '../../actions/agencies'

// selector files (transform data from redux store in to what we need for presentation)
import { getAgencyProjectsSelector, getAgencyFunctionsSelector, getAgencyFunctionsDropdown, getAgencyStaffSelector } from '../../selectors/agenciesSelector'
import { getBrandAgencyDropdown, getBrandByAudienceAgencyDropdown, getBrandByAudienceExport } from '../../selectors/brandsSelector'

// presentation component
import AgencyProjectList from './agency-project-list'

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		getAgencyFunctions: () => dispatch(getAgencyFunctions()),
		getAgencyProjects: () => dispatch(getAgencyProjects()),
		getBrandsByAgency: (agencyID) => dispatch(getBrandsByAgency(agencyID)),
		getAgencyStaff: () => dispatch(getAgencyStaff()),
		addAgencyProject: (projectDetails, getAfterAdd) => addAgencyProject(projectDetails, getAfterAdd),
		addAgencyProjectHours: (addHoursDetails) => addAgencyProjectHours(addHoursDetails),
		addOOPCosts: (addHoursDetails) => addOOPCosts(addHoursDetails), 
		addAgencyStaffHours: (addHoursDetails, getAfterAdd) => addAgencyStaffHours(addHoursDetails, getAfterAdd),
		addAgencyStaff: (staffDetails, getAfterAdd) => addAgencyStaff(staffDetails, getAfterAdd),
		deleteAgencyStaff: (staffID, getAfterAdd) => deleteAgencyStaff(staffID, getAfterAdd),
		updateAgencyStaff: (staffDetails) => updateAgencyStaff(staffDetails),
		updateAgencyProject: (projectDetails, getAfterAdd) => updateAgencyProject(projectDetails, getAfterAdd),
		deleteAgencyProject: (projectID, getAfterAdd) => deleteAgencyProject(projectID, getAfterAdd)
	}, dispatch);
};

const mapStateToProps = (state) => {
	
	return {
		projects: getAgencyProjectsSelector(state), 
		staff: getAgencyStaffSelector(state), 
		brandsDropdown: getBrandAgencyDropdown(state),
		brandsAudienceDropdown: getBrandByAudienceAgencyDropdown(state),
		brandsAudienceExport: getBrandByAudienceExport(state),
		functionsDropdown: getAgencyFunctionsDropdown(state),
		agencyFunctions: getAgencyFunctionsSelector(state),
	}
}

const AgencyProjectListContainer = connect(mapStateToProps, mapDispatchToProps)(AgencyProjectList);
export default AgencyProjectListContainer;