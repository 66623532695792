// user
export const GET_USER_AUTH = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/login"
export const RESET_PASSWORD = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/resetPassword"
export const CHECK_UUID = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/checkUUID"
export const UPDATE_PASSWORD = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/updatePassword"
export const GET_USER_AUTH_SSO = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/loginSSO"
export const LOGIN_HYBRID = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/loginHybrid"
export const LOGOUT_HYBRID = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/logoutHybrid"

// brands
export const GET_BRANDS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getBrands"

// insight Text
export const GET_BRAND_INSIGHT_TEXT = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getInsightByBrand"
export const SAVE_BRAND_INSIGHT_TEXT = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/saveInsightByBrand"

// Comments
export const GET_COMMENTS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getComments"
export const UPDATE_COMMENTS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/updateComments"

// agencies
export const GET_AGENCY_FUNCTIONS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getAgencyFunctions"
export const GET_AGENCY_PROJECTS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getAgencyProjects"
export const GET_AGENCIES_BY_BRAND = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getAgenciesByBrand"
export const GET_AGENCY_FUNCTIONS_BY_BRAND = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getAgencyFunctionsByBrand"
export const GET_AGENCY_PROJECTS_BY_BRAND = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getAgencyProjectsByBrand"
export const GET_AGENCY_PROJECT_STAFF_BY_BRAND = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getAgencyStaffByBrand"
export const ADD_AGENCY_PROJECTS_HOURS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/addAgencyProjectHours"
export const ADD_AGENCY_OOP_COSTs = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/addAgencyOOPCosts"
export const GET_BRANDS_BY_AGENCY = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getBrandsByAgency"
export const ADD_AGENCY_PROJECT = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/addAgencyProject"
export const UPDATE_AGENCY_PROJECT = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/updateAgencyProject"
export const DELETE_AGENCY_PROJECT = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/deleteAgencyProject"
export const GET_AGENCY_STATUS_LOGS_BY_BRAND = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getStaffLogsByBrand"
export const UPDATE_BASELINE_HOURS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/updateBaselineHours"
export const GET_AGENCIES = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getAgencies"
export const UPLOAD_EXCEL_FILE = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/uploadExcelFile"


// Agency Staff
export const GET_AGENCY_STAFF = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getAgencyStaff"
export const UPDATE_AGENCY_STAFF = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/updateAgencyStaff"
export const ADD_AGENCY_STAFF = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/addAgencyStaff"
export const ADD_AGENCY_STAFF_HOURS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/addAgencyStaffHours"
export const DELETE_AGENCY_STAFF = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/deleteAgencyStaff"

