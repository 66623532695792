import { getAgenciesByBrandPromise } from './agencies'
import { GET_AGENCIES_BY_BRAND, GET_AGENCY_PROJECTS_BY_BRAND, GET_AGENCY_FUNCTIONS_BY_BRAND, GET_AGENCY_PROJECT_STAFF_BY_BRAND, GET_AGENCY_STATUS_LOGS_BY_BRAND }  from '../constants/URLS';


//Call that comes in to request the Agencies and update the store.
export const getAgenciesByFranchise = (brands) => () => {
	return async (dispatch) => {
		let objAll = {byId: {}, allIds:[]}
		for (let curBrand of brands) {
			let curProjectDetails = await getAgenciesByBrandPromise(GET_AGENCIES_BY_BRAND, curBrand)
			
			objAll.byId = {
				...objAll.byId, 
				...curProjectDetails.byId
			}

			objAll.allIds = [
				...objAll.allIds, 
				...curProjectDetails.allIds
			]
		}
		
		dispatch({
			type: 'SET_AGENCIES',
			payload: {
				...objAll,
				brandID: 0
			}
		})
	}
};

//Call that comes in to request the Agency Functions and update the store.
export const getAgencyFunctionsByFranchise = (brands) => () => {
	return async (dispatch) => {
		let objAll = {byId: {}, allIds:[]}
		for (let curBrand of brands) {
			let curProjectDetails = await getAgenciesByBrandPromise(GET_AGENCY_FUNCTIONS_BY_BRAND, curBrand)
			objAll.byId = {
				...objAll.byId, 
				...curProjectDetails.byId
			}

			objAll.allIds = [
				...objAll.allIds, 
				...curProjectDetails.allIds
			]
		}
		
		dispatch({
			type: 'SET_AGENCY_FUNCTIONS',
			payload: {
				...objAll,
				brandID: 0
			}
		})
	}
};

//Call that comes in to request the Agency Status Logs and update the store.
export const getAgencyStatusLogsByFranchise = (brands) => () => {
	return async (dispatch) => {
		
		let objAll = {byId: {}, allIds:[]}
		for (let curBrand of brands) {
			let curProjectDetails = await getAgenciesByBrandPromise(GET_AGENCY_STATUS_LOGS_BY_BRAND, curBrand)
			
			let objNewOnes = curProjectDetails.allIds.reduce((acc,cur) => {
				if (!objAll.allIds.includes(cur)) {
					acc.byId[cur] = curProjectDetails.byId[cur]
					acc.allIds.push(cur)
				}
				return acc
			},{byId: {}, allIds:[]})
			
			objAll.byId = {
				...objAll.byId, 
				...objNewOnes.byId
			}

			objAll.allIds = [
				...objAll.allIds, 
				...objNewOnes.allIds
			]
		}

		dispatch({
			type: 'SET_AGENCY_STATUS_LOGS',
			payload: {
				...objAll,
				brandID: 0
			}
		})
	}
};

//Call that comes in to request the Agency Projects and update the store.
export const getAgencyProjectsByFranchise = (brands) => () => {
	return async (dispatch) => {
		let objAll = {byId: {}, allIds:[]}
		for (let curBrand of brands) {
			let curProjectDetails = await getAgenciesByBrandPromise(GET_AGENCY_PROJECTS_BY_BRAND, curBrand)
			objAll.byId = {
				...objAll.byId, 
				...curProjectDetails.byId
			}

			objAll.allIds = [
				...objAll.allIds, 
				...curProjectDetails.allIds
			]
		}
		
		dispatch({
			type: 'SET_AGENCY_PROJECTS',
			payload: {
				...objAll,
				brandID: 0
			}
		})
	}
};

//Call that comes in to request the Agency Project Staff and update the store.
export const getAgencyProjectStaffByFranchise = (brands) => () => {
	return async (dispatch) => {
		
		let objAll = {byId: {}, allIds:[]}
		for (let curBrand of brands) {
			
			let curProjectDetails = await getAgenciesByBrandPromise(GET_AGENCY_PROJECT_STAFF_BY_BRAND, curBrand)
			
			for ( let staffID of curProjectDetails.allIds) {
				if (objAll.byId[staffID]) {
					objAll.byId[staffID].baselineHours += curProjectDetails.byId[staffID].baselineHours
					objAll.byId[staffID].hours = [
						...objAll.byId[staffID].hours,
						...curProjectDetails.byId[staffID].hours
					]
					
					objAll.byId[staffID].brandAgencies = [
						...objAll.byId[staffID].brandAgencies,
						...curProjectDetails.byId[staffID].brandAgencies
					]
					
					objAll.byId[staffID].baselineHoursByBrand = {
						...objAll.byId[staffID].baselineHoursByBrand,
						...curProjectDetails.byId[staffID].baselineHoursByBrand
					}

					objAll.byId[staffID].audiences = {
						...objAll.byId[staffID].audiences,
						...curProjectDetails.byId[staffID].audiences
					}

					objAll.byId[staffID].brandAgencyLookup[curProjectDetails.byId[staffID].brandAgencyID] = curProjectDetails.byId[staffID].brandID
				} else {
					objAll.byId[staffID] = {
						...curProjectDetails.byId[staffID],
						brandAgencyLookup: {
							[curProjectDetails.byId[staffID].brandAgencyID]: curProjectDetails.byId[staffID].brandID
						}
					}
					objAll.allIds.push(staffID)
				}

			}
		}

		dispatch({
			type: 'SET_AGENCY_STAFF',
			payload: {
				...objAll,
				brandID: 0
			}
		})
	}
};
