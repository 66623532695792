import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import withAuth from '../auth/withAuth';

// action files (get data from server and set up redux store)
import { getBrands } from '../../actions/brands'
import { getComments, updateComments } from '../../actions/comments'

// selector files (transform data from redux store in to what we need for presentation)
import { getCommentsSelector } from '../../selectors/brandsSelector'

// presentation component
import ManageComments from './manage-comments'

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		getBrands: () => dispatch(getBrands()),
		getComments: () => dispatch(getComments()),
		updateComments: (updateDetails) => updateComments(updateDetails)
	}, dispatch);
};

const mapStateToProps = (state, ownProps) => {
	
	return {
		comments: getCommentsSelector(state)
	}
}

const ManageCommentsContainer = connect(mapStateToProps, mapDispatchToProps)(ManageComments);
//export default withAuth(OverviewContainer);
export default ManageCommentsContainer;