import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames'

// action files (get data from server and set up redux store)
import { getBrands } from '../actions/brands'
import { getAgenciesByFranchise, getAgencyFunctionsByFranchise, getAgencyProjectsByFranchise, getAgencyProjectStaffByFranchise, getAgencyStatusLogsByFranchise} from '../actions/franchises'

// selector files (transform data from redux store in to what we need for presentation)
import { getBrandsByFranchiseSelector, getFranchiseDetailsSelector } from '../selectors/brandsSelector'
import { getAudiences, getAudiencesProject, getWidgetAnnualStaffingBaseline, getWidgetAnnualBaselineBudget, getWidgetOutOfPocketBaseline } from '../selectors/dashboardWidgetsSelector'

import { setCurrentClientID } from '../actions/ui'

// PrimeReact Components

// BreadCrumbs component
import BreadCrumbs from '../components/breadCrumbs/breadCrumbs-container'

// Widget components
import DashboardWidget from '../components/dashboardWidget'
import AnnualBaselineBudget from '../components/dashboardWidgets/annualBaselineBudget'
import AnnualStaffingBaseline from '../components/dashboardWidgets/annualStaffingBaseline'
import OutOfPocketCosts from '../components/dashboardWidgets/outOfPocketCosts'
import KeyChanges from '../components/dashboardWidgets/keyChanges'
import ProjectSpendSummary from '../components/dashboardWidgets/projectSpendSummary'
import MonthlyBurnRate from '../components/dashboardWidgets/monthlyBurnRate'
import SpendByAudience from '../components/dashboardWidgets/spendByAudience'
import ResourceMix from '../components/dashboardWidgets/resourceMix'
import DepartmentalHours from '../components/dashboardWidgets/departmentalHours'
import ActualVsBudgetStaff from '../components/dashboardWidgets/actualVsBudgetStaff'
import StaffHours from '../components/dashboardWidgets/staffHours'
import StaffChanges from '../components/dashboardWidgets/staffChanges'
import StaffHoursByAudience from '../components/dashboardWidgets/staffHoursByAudience'
import OutOfPocketcosts from '../components/dashboardWidgets/outOfPocketCostsTable'
import OutOfPocketBudget from '../components/dashboardWidgets/outOfPocketBudgetTable'
import StaffHoursByBrand from '../components/dashboardWidgets/staffHoursByBrand'
import SpendByBrand from '../components/dashboardWidgets/spendByBrand'
import OutOfPocketBudgetVsSpend from '../components/dashboardWidgets/outOfPocketBudgetVsSpend'

const NewOverview = (props) => {
	
	const { getBrands, setCurrentClientID, getAgenciesByFranchise, getAgencyFunctionsByFranchise, 
		getAgencyProjectsByFranchise, getAgencyProjectStaffByFranchise, getAgencyStatusLogsByFranchise, 
		franchiseID, franchiseName, clientID, audienceList, audienceListProject,
		staffYearlyBudget, yearlyBudget, oopYearlyBudget, brands, clientFiscalStartMonth  } = props

	
	const defaultWidgetKeys = ['annualBaselineBudget','annualStaffingBaseline','oopCosts','spendSummary', 'departmentalHours', 'actualVersusBudgetStaff', 
		'staffHours', 'staffChanges', 'staffHoursByAudience', 'staffHoursByBrand', 'outOfPocketCosts', 'outOfPocketBudget', 'keyChanges', 'spendByBrand'] // Used to set the date dropdown defaults when page loads
	
	const defaultWidgetKeysYearOnly = ['resourceMix','monthlyBurnRate','outOfPocketBudgetVsSpend','actualVersusBudgetStaff']
	
	
	const dateDependencies = {
		'annualBaselineBudget': ['spendSummary', 'departmentalHours','resourceMix','monthlyBurnRate', 'spendByBrand'],
		'annualStaffingBaseline': ['staffHours','staffChanges','staffHoursByAudience','staffHoursByBrand', 'actualVersusBudgetStaff'],
		'oopCosts': ['outOfPocketCosts','outOfPocketBudget','outOfPocketBudgetVsSpend'],
	}

	const downloadWidgets = {
		actualVersusBudgetStaff: 0,
		staffHoursByBrand: 0,
		monthlyBurnRate: 0,
		spendByBrand: 0,
		outOfPocketBudget: 0,
		outOfPocketCosts: 0,
		staffHours: 0
	}

	const [ selectedTab, setSelectedTab] = useState('budget'); // This controls which 'tab' is being looked at. The tab is the widget
	const [ widgetDates, setWidgetDates] = useState({}); // This holds all the dates for the widgets. when a date changes, it is stored here
	const [ loading, setLoading] = useState(false);
	const [ isDesktop, setIsDesktop] = useState(true);
	const [ download, setDownload] = useState(downloadWidgets);
	
	
	useEffect(() => {
		getBrands()
	},[getBrands]);

	useEffect(() => {
		let defaultDateType = 'ytd' //This is the default date type for all date pickers
		let defaultStaticDateType = 'fullYear' //This is the default for year only date pickers (previously you coulnd't change)
	
		if (new Date().getMonth() === clientFiscalStartMonth) {
			defaultDateType = 'lastYear'
			defaultStaticDateType = 'lastYear'
		}
		
		let _widgetDates = {...widgetDates}
		for (let curKey of defaultWidgetKeys) {
			_widgetDates[curKey] = defaultDateType
		}

		for (let curKey of defaultWidgetKeysYearOnly) {
			_widgetDates[curKey] = defaultStaticDateType
		}

		setWidgetDates(_widgetDates)
	},[clientFiscalStartMonth]);

	useEffect(() => {
		if (franchiseID) {
			setLoading(true)
			fncLoadNewBrands()
		}
	},[franchiseID]);

	useEffect(() => {
		setCurrentClientID(clientID)
	},[clientID]);

	useEffect(() => {
		if (!loading) handleResize()
	},[loading]);

	useEffect(() => {
		window.addEventListener("resize", handleResize, false);
	},[]);

	const fncDownloadClicked = (downloadKey) => {
		let _download = {...download}
		_download[downloadKey]++
		setDownload(_download)

	}

	const handleResize = () => {
		setIsDesktop(window.innerWidth > 1200)
	}
	
	const breadCrumbItems = [
        {label: 'Overview'},
		{label: <div className="strong">{franchiseName} Dashboard</div> }
    ];

	let fncSetTab = (tab) => {
		setSelectedTab(tab)
	}

	let fncDates = (key,value) => {
		
		let _widgetDates = {...widgetDates}
		_widgetDates[key] = value

		// set any dependency widgets
		if (dateDependencies[key]) {
			for (let curDependent of dateDependencies[key]) {
				if (defaultWidgetKeysYearOnly.includes(curDependent)) {
					if (value === 'lastYear') _widgetDates[curDependent] = 'lastYear'
					else _widgetDates[curDependent] = 'fullYear'
				} else {
					_widgetDates[curDependent] = value
				}
			}
		}

		setWidgetDates(_widgetDates)
	}

	let fncLoadNewBrands = async () => {
		await getAgenciesByFranchise(brands)
		await getAgencyProjectsByFranchise(brands)
		await getAgencyProjectStaffByFranchise(brands)
		await getAgencyStatusLogsByFranchise(brands)
		await getAgencyFunctionsByFranchise(brands)
		
		setLoading(false)
	}

	return (
		<div className="flex flex-column newOverview" >
			
			<div className="mb-2">
				<BreadCrumbs items={breadCrumbItems} />
			</div>
			<div className="flex mb-3 fullWidget">
				<DashboardWidget 
					widgetKey="keyChanges"
					currentDateValue={widgetDates.keyChanges}
					title={franchiseName +  " Key Changes"}
					onDateChange={fncDates}
				>
					<KeyChanges dates={widgetDates.keyChanges} />
				</DashboardWidget>
			</div>
			<div className="flex mb-3 fullWidget">
				<div className="pr-2 align-self-stretch thirdWidget">
					<DashboardWidget 
						widgetKey="annualBaselineBudget"
						currentDateValue={widgetDates.annualBaselineBudget}
						title="Baseline Budget" 
						viewDetails={() => fncSetTab('budget')} 
						isSelected={selectedTab === 'budget'?true:false}
						totalYearly={new Intl.NumberFormat('en-US',{style: 'currency',currency: 'USD',minimumFractionDigits: 2}).format(yearlyBudget)}
						infoKey="NEW_BASELINE_BUDGET" 
						activeClass={selectedTab === 'budget'?'budgetActive':null}
						onDateChange={fncDates}
						zIndex={100}
						compareLink={"/franchiseCompare/" + franchiseID}
					>
						<AnnualBaselineBudget dates={widgetDates.annualBaselineBudget} loading={loading} instance={1}/>
					</DashboardWidget>
				</div>
				<div className="pr-2 align-self-stretch thirdWidget">
					<DashboardWidget 
						widgetKey="annualStaffingBaseline"
						currentDateValue={widgetDates.annualStaffingBaseline}
						title="Staffing Baseline" 
						viewDetails={() => fncSetTab('staff')} 
						isSelected={selectedTab === 'staff'?true:false}
						totalYearly={new Intl.NumberFormat('en-US').format(staffYearlyBudget) + " Hours"}
						infoKey="NEW_STAFF_BASELINE" 
						activeClass={selectedTab === 'staff'?'staffActive':null}
						onDateChange={fncDates}
						zIndex={100}
					>
						<AnnualStaffingBaseline dates={widgetDates.annualStaffingBaseline} loading={loading}/>
					</DashboardWidget>
					
				</div>
				<div className="align-self-stretch thirdWidget">
					<DashboardWidget 
						widgetKey="oopCosts"
						currentDateValue={widgetDates.oopCosts}
						title="Out-of-Pocket Costs" 
						viewDetails={() => fncSetTab('oop')} 
						isSelected={selectedTab === 'oop'?true:false}
						totalYearly={new Intl.NumberFormat('en-US',{style: 'currency',currency: 'USD',minimumFractionDigits: 2}).format(oopYearlyBudget)}
						activeClass={selectedTab === 'oop'?'oopActive':null}
						onDateChange={fncDates}
						zIndex={100}
						infoKey="NEW_OOP_COSTS" 
					>
						<OutOfPocketCosts dates={widgetDates.oopCosts} loading={loading}/>
					</DashboardWidget>
				</div>
			</div>
			{selectedTab === 'budget' && 
				<>
					<div className="flex flex-column xl:flex-row fullWidget mb-3 ">
						<div className={classNames({'pr-2 align-self-stretch thirdWidget': isDesktop,'pb-2 fullWidget': !isDesktop})}>
							<DashboardWidget 
								widgetKey="spendSummary"
								title="Project Spend Summary"
								onDateChange={fncDates}
								currentDateValue={widgetDates.spendSummary}
								infoKey="NEW_PROJECT_SPEND_SUMMARY" 
								compareLink={"/franchiseCompare/" + franchiseID}
							>
								<ProjectSpendSummary dates={widgetDates.spendSummary} entityName={franchiseName} />
							</DashboardWidget>
						</div>
						<div className={classNames({'align-self-stretch twoThirdWidget': isDesktop,'pb-2 fullWidget': !isDesktop})}>
							<DashboardWidget 
								widgetKey="monthlyBurnRate" 
								title="Monthly Burn Rate vs. Forecasted Spend" 
								lockDates={true} 
								currentDateValue={widgetDates.monthlyBurnRate}
								onDateChange={fncDates}
								infoKey="NEW_MONTHLY_BURN_RATE" 
								download={fncDownloadClicked}
							>
								<MonthlyBurnRate 
									loading={loading}
									downloadClicked = {download.monthlyBurnRate}
									fileName = {franchiseName + '_Monthly_Burn_Rate_vs_Forcasted_Spend'}
									dates={widgetDates.monthlyBurnRate}
								/>
							</DashboardWidget>
						</div>
					</div>
					{audienceListProject.length > 0 && 
						<div className="flex fullWidget mb-3 ">
							<DashboardWidget 
								widgetKey="spendByAudience"
								title="Spend by Audience"
								onDateChange={fncDates}
								currentDateValue={widgetDates.spendByAudience}
								hideDatePicker={true} 
								infoKey="NEW_SPEND_BY_AUDIENCE" 
							>
								<SpendByAudience loading={loading} />

							</DashboardWidget>
						</div>
					}
					<div className="flex flex-column xl:flex-row fullWidget mb-3 ">
						<div className={classNames({'pr-2 align-self-stretch twoThirdWidget': isDesktop,'pb-2 fullWidget': !isDesktop})}>
							<DashboardWidget 
								widgetKey="resourceMix"
								title="Resource Mix"
								lockDates={true} 
								onDateChange={fncDates}
								currentDateValue={widgetDates.resourceMix}
								infoKey="NEW_RESOURCE_MIX" 
							>
								<ResourceMix dates={widgetDates.resourceMix}/>
							</DashboardWidget>
						</div>
						<div className={classNames({'align-self-stretch thirdWidget': isDesktop,'pb-2 fullWidget': !isDesktop})}>
							<DashboardWidget 
								widgetKey="departmentalHours"
								title="Departmental Hours"
								onDateChange={fncDates}
								currentDateValue={widgetDates.departmentalHours}
								infoKey="NEW_DEPARTMENTAL_HOURS" 
							>
									<DepartmentalHours dates={widgetDates.departmentalHours} loading={loading}/>
							</DashboardWidget>
						</div>
					</div>
					<div className="flex fullWidget mb-3 ">
						<DashboardWidget 
							widgetKey="spendByBrand"
							title="Spend by Brand"
							onDateChange={fncDates}
							currentDateValue={widgetDates.spendByBrand}
							infoKey="NEW_SPEND_BY_BRAND" 
							download={fncDownloadClicked}
						>
							<SpendByBrand 
								dates={widgetDates.spendByBrand} 
								loading={loading}
								downloadClicked = {download.spendByBrand}
								fileName = {franchiseName + '_Spend_by_Brand'}
								franchiseName={franchiseName}
							/>

						</DashboardWidget>
					</div>
				</>
			}
			{selectedTab === 'staff' && 
				<>
					<div className="flex fullWidget mb-3 ">
						<DashboardWidget 
							widgetKey="actualVersusBudgetStaff"
							title="Actual Versus Budgeted Staff Hours"
							lockDates={true} 
							onDateChange={fncDates}
							currentDateValue={widgetDates.actualVersusBudgetStaff}
							infoKey="NEW_ACTUAL_VS_BUDGET_STAFF" 
							download={fncDownloadClicked}
						>
							<ActualVsBudgetStaff 
								dates={widgetDates.actualVersusBudgetStaff} 
								loading={loading}
								downloadClicked = {download.actualVersusBudgetStaff}
								fileName = {franchiseName + '_Actual_vs_Budgeted_Staff_Hours'}
							/>
						</DashboardWidget>
					</div>
					<div className="flex flex-column xl:flex-row fullWidget mb-3 ">
						<div className={classNames({'pr-2 align-self-stretch halfWidget': isDesktop,'pb-2 fullWidget': !isDesktop})}>
							<DashboardWidget 
								widgetKey="staffHours"
								title="Staff Hours"
								onDateChange={fncDates}
								currentDateValue={widgetDates.staffHours}
								infoKey="NEW_STAFF_HOURS" 
								download={fncDownloadClicked}
							>
								<StaffHours 
									dates={widgetDates.staffHours} 
									downloadClicked = {download.staffHours}
									fileName = {franchiseName + '_Staff_Hours'}
								/>
							</DashboardWidget>
						</div>
						<div className={classNames({'halfWidget': isDesktop,'pb-2 fullWidget': !isDesktop})}>
							<DashboardWidget 
								widgetKey="staffChanges"
								title={"Staff Changes"}
								onDateChange={fncDates}
								currentDateValue={widgetDates.staffChanges}
								infoKey="NEW_STAFF_CHANGES" 
							>
								<StaffChanges dates={widgetDates.staffChanges} />
							</DashboardWidget>
						</div>
					</div>

					<div className="flex fullWidget mb-3 ">
							<DashboardWidget 
								widgetKey="staffHoursByBrand"
								title="Staff Hours by Brand"
								onDateChange={fncDates}
								currentDateValue={widgetDates.staffHoursByBrand}
								infoKey="NEW_STAFF_BY_BRAND" 
								download={fncDownloadClicked}
							>
								<StaffHoursByBrand 
									dates={widgetDates.staffHoursByBrand} 
									loading={loading}
									downloadClicked = {download.staffHoursByBrand}
									fileName = {franchiseName + '_Staff_Hours_by_Brand'}
								/>

							</DashboardWidget>
						</div>

					
					{audienceList.length > 1 && 
						<div className="flex fullWidget mb-3 ">
							<DashboardWidget 
								widgetKey="staffHoursByAudience"
								title="Staff Hours by Audience"
								onDateChange={fncDates}
								currentDateValue={widgetDates.staffHoursByAudience}
								infoKey="NEW_STAFF_BY_AUDIENCE" 
							>
								<StaffHoursByAudience dates={widgetDates.staffHoursByAudience} loading={loading}/>

							</DashboardWidget>
						</div>
					}
					
				</>
			}
			{selectedTab === 'oop' && 
				<>
					<div className="flex fullWidget mb-3 ">
								<DashboardWidget 
									widgetKey="outOfPocketBudgetVsSpend"
									title="Out-of-Pocket Budget vs. Spend"
									lockDates={true} 
									onDateChange={fncDates}
									currentDateValue={widgetDates.outOfPocketBudgetVsSpend}
									infoKey="NEW_OOP_BUDGET_VS_SPEND"
								>
									<OutOfPocketBudgetVsSpend loading={loading} dates={widgetDates.outOfPocketBudgetVsSpend} />
								</DashboardWidget>
							</div>
					
					<div className="flex fullWidget mb-3 ">
						<DashboardWidget 
							widgetKey="outOfPocketCosts"
							title="Out-of-Pocket Spend"
							onDateChange={fncDates}
							currentDateValue={widgetDates.outOfPocketCosts}
							infoKey="NEW_OOP_COSTS_TABLE" 
							download={fncDownloadClicked}
						>
							<OutOfPocketcosts 
								dates={widgetDates.outOfPocketCosts} 
								downloadClicked = {download.outOfPocketCosts}
								fileName = {franchiseName + '_Out_of_Pocket_Spend'}
							/>
						</DashboardWidget>
					</div>

					<div className="flex fullWidget mb-3 ">
						<DashboardWidget 
							widgetKey="outOfPocketBudget"
							title="Out-of-Pocket Budget"
							onDateChange={fncDates}
							currentDateValue={widgetDates.outOfPocketBudget}
							infoKey="NEW_OOP_BUDGET_TABLE" 
							download={fncDownloadClicked}
						>
							<OutOfPocketBudget 
								dates={widgetDates.outOfPocketBudget} 
								downloadClicked = {download.outOfPocketBudget}
								fileName = {franchiseName + '_Out_of_Pocket_Budget'}
							/>
						</DashboardWidget>
					</div>
				</>



			}
				
		</div>
    );
}

/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		getBrands: () => dispatch(getBrands()),
		setCurrentClientID: (payload) => setCurrentClientID(payload),
		getAgenciesByFranchise: (brands) => dispatch(getAgenciesByFranchise(brands)),
		getAgencyFunctionsByFranchise: (brands) => dispatch(getAgencyFunctionsByFranchise(brands)),
		getAgencyProjectsByFranchise: (brands) => dispatch(getAgencyProjectsByFranchise(brands)),
		getAgencyStatusLogsByFranchise: (brands) => dispatch(getAgencyStatusLogsByFranchise(brands)),
		getAgencyProjectStaffByFranchise: (brands) => dispatch(getAgencyProjectStaffByFranchise(brands))
	}, dispatch);
};

const mapStateToProps = (state, ownProps) => {
	
	let objFranchiseDetails = getFranchiseDetailsSelector(state,ownProps)

	return {
		franchiseID: objFranchiseDetails.franchiseID,
		franchiseName: objFranchiseDetails.franchiseName,
		clientID: objFranchiseDetails.clientID,
		clientFiscalStartMonth: objFranchiseDetails.clientFiscalStartMonth,
		brands: getBrandsByFranchiseSelector(state,ownProps),
		audienceList: getAudiences(state, {dates:'fullYear'}),
		audienceListProject: getAudiencesProject(state, {dates:'fullYear'}),
		staffYearlyBudget: getWidgetAnnualStaffingBaseline(state, {dates:'fullYear'}),
		yearlyBudget: getWidgetAnnualBaselineBudget(state, {dates:'fullYear'}),
		oopYearlyBudget: getWidgetOutOfPocketBaseline(state,{dates:'fullYear'}),
	}
}

const NewOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(NewOverview);
//export default withAuth(OverviewContainer);
export default NewOverviewContainer;