import React, { useState, useEffect } from 'react';

// Form validation library
import { useForm, Controller  } from 'react-hook-form';
import { classNames } from 'primereact/utils';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox} from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';

const ProjectAddStaffModal = (props) => {
	const { currentStaff, brandsDropdown, functionsDropdown } = props
	const defaultValues = {
		name: '',
		title: '',
		functionID: null,
		rate: '',
		hours: '',
		isSenior: false,
		status: 'active',
		comments: '',
		brandAgency: null
	}

	const defaultUnNamedValues = {
		category: '',
		functionID: null,
		rate: '',
		hours: '',
		comments: '',
		brandAgency: null
	}

	const [brandAgencies, setBrandAgencies] = useState([]);
	const [brandsSelected, setBrandsSelected] = useState({});
	const [brandsUnNamedSelected, setBrandsUnNamedSelected] = useState({});
	const [staffFunctions, setStaffFunctions] = useState({});
	const [brandError, setBrandError] = useState(false);
	const [staffType, setStaffType] = useState('named');
	const [isEdit, setIsEdit] = useState(false);
	
	useEffect(() => {
		
		if (currentStaff.staffType) {
			setStaffType(currentStaff.staffType)
			setIsEdit(true)
			if (currentStaff.staffType === 'named') {
				if (currentStaff.name) setValue("name", currentStaff.name)
				if (currentStaff.title) setValue("title", currentStaff.title)
				if (currentStaff.rate) setValue("rate", currentStaff.rate)
				if (currentStaff.functionID) setValue("functionID", currentStaff.functionID)
				if (currentStaff.status) setValue("status", currentStaff.status)
				if (currentStaff.isSenior) setValue("isSenior", true)
				if (currentStaff.comments) setValue("comments", currentStaff.comments)
				if (currentStaff.brands) setValue("comments", currentStaff.comments)
				let _brandsSelected = {}
				for (let i in brandAgencies) {
					
					if (currentStaff.brandAgencies.includes(brandAgencies[i].brandAgencyID)) {
						_brandsSelected[brandAgencies[i].brandName] = true
						setValue("brandAgency." + i + ".isChecked", true)
						setValue("brandAgency." + i + ".baseline", currentStaff.baselineHoursByBrand[brandAgencies[i].brandAgencyID])
						setValue("brandAgency." + i + ".brandAgencyID", brandAgencies[i].brandAgencyID)
					} else {
						setValue("brandAgency." + i + ".isChecked", false)
						setValue("brandAgency." + i + ".baseline", null)
						setValue("brandAgency." + i + ".brandAgencyID", brandAgencies[i].brandAgencyID)
					}
					
				}
				setBrandsSelected(_brandsSelected)

			} else if (currentStaff.staffType === 'unNamed') {
				if (currentStaff.category) unNamedSetValue("category", currentStaff.category)
				if (currentStaff.rate) unNamedSetValue("rate", currentStaff.rate)
				if (currentStaff.functionID) unNamedSetValue("functionID", currentStaff.functionID)
				if (currentStaff.comments) unNamedSetValue("comments", currentStaff.comments)
				let _brandsSelected = {}
				for (let i in brandAgencies) {
					
					if (currentStaff.brandAgencies.includes(brandAgencies[i].brandAgencyID)) {
						_brandsSelected[brandAgencies[i].brandName] = true
						unNamedSetValue("brandAgency." + i + ".isChecked", true)
						unNamedSetValue("brandAgency." + i + ".baseline", currentStaff.baselineHoursByBrand[brandAgencies[i].brandAgencyID])
						unNamedSetValue("brandAgency." + i + ".brandAgencyID", brandAgencies[i].brandAgencyID)
					} else {
						unNamedSetValue("brandAgency." + i + ".isChecked", false)
						unNamedSetValue("brandAgency." + i + ".baseline", null)
						unNamedSetValue("brandAgency." + i + ".brandAgencyID", brandAgencies[i].brandAgencyID)
					}
					
				}
				setBrandsUnNamedSelected(_brandsSelected)
			}
		} else {
			for (let i in brandAgencies) {
				setValue("brandAgency." + i + ".brandAgencyID", brandAgencies[i].brandAgencyID)
				unNamedSetValue("brandAgency." + i + ".brandAgencyID", brandAgencies[i].brandAgencyID)
			}
		}
	},[currentStaff, brandAgencies])


	// These are the needed items for the form validation component
	const { control, formState: { errors }, handleSubmit, reset, clearErrors, setValue} = useForm({ defaultValues });
	
	const { 
		control: unNamedControl, 
		formState: { errors: unNamedErrors}, 
		handleSubmit: unNamedHandleSubmit, 
		reset: unNamedReset,
		setValue: unNamedSetValue} = useForm({defaultValues: defaultUnNamedValues});

	const staffStatusSelections = [
		{label: 'Active', value: 'active'},
		{label: 'On Leave', value: 'leave'},
		{label: 'Inactive', value: 'inactive'}
	];

	// This will get called everytime the boolean displayStaff changes, it determines if the modal is showing or not.
	useEffect(() => {
		// When we close the modal, reset all the form field values
		if (!props.displayStaff) {
			reset()
			unNamedReset()
			setBrandError(false)
			setIsEdit(false)
			setBrandsSelected({})
			clearErrors()
		} else {
			for (let i in brandAgencies) {
				setValue("brandAgency." + i + ".brandAgencyID", brandAgencies[i].brandAgencyID)
				unNamedSetValue("brandAgency." + i + ".brandAgencyID", brandAgencies[i].brandAgencyID)
			}
		}
	},[props.displayStaff])

	useEffect(() => {
		const _brandAgencies = brandsDropdown.map((cur) => {
			return {
				isChecked: false,
				brandName: cur.label,
				brandAgencyID: cur.value,
				baseline: null, 
				client: cur.client
			}
		})
		setBrandAgencies(_brandAgencies)
	},[brandsDropdown])

	useEffect(() => {
		const _staffFunctions = functionsDropdown.filter(cur => cur.isHidden === 0)
		setStaffFunctions(_staffFunctions)
	},[functionsDropdown])

	const renderFooter = () => {
		return (
			<div className="flex justify-content-between">
				<div>
					{isEdit === true &&
						<Button label="Delete" icon="pi pi-times" className="p-button p-button-danger p-component p-button-outlined p-button-text" onClick={() => props.deleteAgencyStaff()}  />
					}
				</div>
				<div>
					<Button label="Cancel" icon="pi pi-times" onClick={() => props.fncHideModal()} className="p-button-text" />
					{staffType === 'named' && 
						<Button label={isEdit?"Update":"Add"} icon="pi pi-plus" onClick={handleSubmit(addStaff)} />
					}
					{staffType === 'unNamed' && 
						<Button label={isEdit?"Update":"Add"} icon="pi pi-plus" onClick={unNamedHandleSubmit(addStaffUnnamed)} />
					}
				</div>
			</div>
		);
	}

	const addStaff = async (data) => {
		
		let haveBrand = Object.keys(brandsSelected).reduce((acc,cur) => {
			if (brandsSelected[cur]) acc = true
			return acc
		},false)

		if (!haveBrand) setBrandError(true)
		else {
			setBrandError(false)
			
			let blnAddResults = await props.addAgencyStaff({
				...data,
				projectID: props.projectID,
				staffType: staffType,
				staffID: currentStaff.staffID?currentStaff.staffID:0
			})

			props.fncHideModal()
			if (props.showToast) {
				if (currentStaff.staffID > 0) {
					if (blnAddResults) props.showToast('success', 'Success','Staff was added successfully.')
					else props.showToast('error', 'Error','There was an issue adding the staff.')
				} else {
					if (blnAddResults) props.showToast('success', 'Success','Staff was updated successfully.')
					else props.showToast('error', 'Error','There was an issue updating the staff.')
				}
				
			}
			reset()
			unNamedReset()
		}
	}

	const addStaffUnnamed = async (data) => {
		
		let haveBrand = Object.keys(brandsUnNamedSelected).reduce((acc,cur) => {
			if (brandsUnNamedSelected[cur]) acc = true
			return acc
		},false)

		if (!haveBrand) setBrandError(true)
		else {
			setBrandError(false)
			let blnAddResults = await props.addAgencyStaff({
				...data,
				projectID: props.projectID,
				staffType: staffType,
				staffID: currentStaff.staffID?currentStaff.staffID:0
			})

			props.fncHideModal()
			if (props.showToast) {
				if (currentStaff.staffID > 0) {
					if (blnAddResults) props.showToast('success', 'Success','Staff was added successfully.')
					else props.showToast('error', 'Error','There was an issue adding the staff.')
				} else {
					if (blnAddResults) props.showToast('success', 'Success','Staff was updated successfully.')
					else props.showToast('error', 'Error','There was an issue updating the staff.')
				}
			}
			reset()
			unNamedReset()
		}
	}

	const checkboxChange = (e, field, brandName) => {
		field.onChange(e.checked)

		let _brandsSelected = {...brandsSelected}
		_brandsSelected[brandName] = e.checked
		setBrandsSelected(_brandsSelected)
		clearErrors()
		setBrandError(false)
	}

	const checkboxUnnamedChange = (e, field, brandName) => {
		field.onChange(e.checked)

		let _brandsSelected = {...brandsUnNamedSelected}
		_brandsSelected[brandName] = e.checked
		setBrandsUnNamedSelected(_brandsSelected)
		clearErrors()
		setBrandError(false)
	}

	return (
		<Dialog header={isEdit?"Edit Staff":"Add New Staff"} visible={props.displayStaff} footer={renderFooter()}  className="addStaffModal" onHide={() => props.fncHideModal()}>
			<form className="p-fluid">
				<div className="col-12 p-fluid addStaffDiv" >

					<div className="field grid">
						<div className="col-12 md:col-11">
							<div className="flex">
								<div className="mr-4">
									<RadioButton disabled={isEdit} className="mr-1" inputId="named" name="staffType" value="named" onChange={(e) => setStaffType(e.value)} checked={staffType === 'named'} />
									<label htmlFor="named">Named Staff</label>
								</div>
								<div>
									<RadioButton disabled={isEdit} className="mr-1" inputId="unNamed" name="staffType" value="unNamed" onChange={(e) => setStaffType(e.value)} checked={staffType === 'unNamed'} />
									<label htmlFor="unNamed">Unnamed Staff</label>
								</div>
							</div>
							
						</div>
					</div>
					{staffType === 'named' && 
						<>
							<div className="field grid">
								<label for="name" className="col-12 mb-3 md:col-3 mb-md-0">Name*</label>
								<div className="col-12 md:col-9">
								
									<Controller name="name" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
										<InputText 
											id={field.name} 
											value={field.value} 
											{...field}
											className={classNames({ 'p-invalid': fieldState.invalid })} 
										/>
									)} />
									{errors.name && 
										<small className="p-error">Staff Name is required.</small>
									}
								</div>
							</div>

							<div className="field grid">
								<label for="title" className="col-12 mb-3 md:col-3 mb-md-0">Title*</label>
								<div className="col-12 md:col-9">
									<Controller name="title" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
										<InputText 
											id={field.name} 
											value={field.value} 
											{...field}
											className={classNames({ 'p-invalid': fieldState.invalid })} 
										/>
									)} />
									{errors.title && 
										<small className="p-error">Title is required.</small>
									}
								</div>
							</div>

							<div className="field grid">
								<label for="status" className="col-12 mb-3 md:col-3 mb-md-0">Status</label>
								<div className="col-12 md:col-4">
									<Controller name="status" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
										<Dropdown 
											id={field.name} 
											value={field.value} 
											options={staffStatusSelections} 
											onChange={(e) => field.onChange(e.value)} 
											placeholder="Select Status" 
											className={classNames({ 'p-invalid': fieldState.invalid })} 
										/>
									)} />
									{errors.status && 
										<small className="p-error">Status is required.</small>
									}
								</div>
								<label for="title" className="col-12 mb-3 md:col-3 mb-md-0">Senior Staff</label>
								<div className="col-12 md:col-2">
									<Controller name="isSenior" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
											<InputSwitch 
												checked={field.value} 
												onChange={(e) => field.onChange(e.value)} />
										)} />
								</div>
							</div>

							<div className="field grid">
								<label for="brand" className="col-12 mb-3 md:col-3 mb-md-0">Department*</label>
								<div className="col-12 md:col-9">
									<Controller name="functionID" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
										<Dropdown 
											id={field.name} 
											value={field.value} 
											options={staffFunctions} 
											onChange={(e) => field.onChange(e.value)} 
											placeholder="Select Department" 
											className={classNames({ 'p-invalid': fieldState.invalid })} 
										/>
									)} />
									{errors.functionID && 
										<small className="p-error">Department is required.</small>
									}
								</div>
							</div>

							<div className="field grid">
								<div className="col-12 mb-md-0">
									<label for="brand">Brands*</label>
								</div>
								{brandAgencies.reduce((acc,cur, index) => {
									let prevClient
									if (index !== 0)  prevClient = brandAgencies[index-1].client

									acc.push(
										<React.Fragment key={"brandAgencies2|" + index}>
											{(index === 0 || prevClient !== cur.client) && 
												<div className="p-offset-1 col-11 mb-md-0">
													<label for="brand">{cur.client}</label>
												</div>
											}
											<div className="col-12 md:col-offset-3 md:col-9">
											
												<div className="mb-2 flex justify-content-between">
													
													<div className="flex">
														<div className="mr-2">
															<Controller defaultValue={false} name={"brandAgency." + index + ".isChecked"} control={control}  render={({ field }) => (
																<Checkbox inputId={field.name} 
																	onChange={(e) => checkboxChange(e,field, cur.brandName)} 
																	checked={field.value}
																/>
															)} />
														</div>
														<div>
															<Controller defaultValue={cur.brandAgencyID} name={"brandAgency." + index + ".brandAgencyID"} control={control}  render={({ field, fieldState }) => (
																<span>{cur.brandName}</span>
															)} />
														</div>
													</div>
													<div>
														<div className="flex justify-content-end">
															<div className="mr-2">Hours*: </div>
															<div>
																<Controller name={"brandAgency." + index + ".baseline"} control={control} rules={{ required: (brandsSelected[cur.brandName]?true:false) }} render={({ field, fieldState }) => (
																	<InputText 
																		id={field.name} 
																		value={field.value} 
																		{...field}
																		style={{width:'75px'}} 
																		className={classNames({ 'p-invalid': fieldState.invalid })} 
																	/>
																)} />
															</div>
															
														</div>
														{errors.brandAgency && errors.brandAgency[index] && errors.brandAgency[index].baseline && 
															<div>
																<small className="p-error">Hours are required for selected brands.</small>
															</div>
														}
													</div>
													
												</div>
											

												{brandError && 
													<small className="p-error">You must choose at least one brand.</small>
												}
										
											</div>
										</React.Fragment>
									)

									return acc
								},[])}
							</div>

							

							<div className="field grid">
								<div className="col-12 mb-3 md:col-3 mb-md-0">
									<label for="rate" >Hourly Rate, $*</label>
								</div>
								<div className="col-12 md:col-3" >
									<Controller name="rate" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
										<InputText 
											id={field.name} 
											value={field.value} 
											{...field}
											className={classNames({ 'p-invalid': fieldState.invalid })} 
										/>
									)} />
									{errors.rate && 
										<small className="p-error">Rate is required.</small>
									}
								</div>
							</div>

							<div className="field grid">
								<label for="rate" className="col-12 mb-3 mb-md-0">Comments</label>
								<div className="col-12">
									<Controller name="comments" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
										<InputTextarea 
											rows={5}
											id={field.name} 
											value={field.value} 
											{...field}
										/>
									)} />
								</div>
							</div>
						</>
					}
					{staffType === 'unNamed' && 
						<>
							<div className="field grid">
								<label for="name" className="col-12 mb-3 md:col-3 mb-md-0">Category*</label>
								<div className="col-12 md:col-9">
								
									<Controller name="category" control={unNamedControl} rules={{ required: true }} render={({ field, fieldState }) => (
										<InputText 
											id={field.name} 
											value={field.value} 
											{...field}
											className={classNames({ 'p-invalid': fieldState.invalid })} 
										/>
									)} />
									{unNamedErrors.category && 
										<small className="p-error">Category is required.</small>
									}
								</div>
							</div>

							<div className="field grid">
								<label for="brand" className="col-12 mb-3 md:col-3 mb-md-0">Department*</label>
								<div className="col-12 md:col-9">
									<Controller name="functionID" control={unNamedControl} rules={{ required: true }} render={({ field, fieldState }) => (
										<Dropdown 
											id={field.name} 
											value={field.value} 
											options={functionsDropdown} 
											onChange={(e) => field.onChange(e.value)} 
											placeholder="Select Department" 
											className={classNames({ 'p-invalid': fieldState.invalid })} 
										/>
									)} />
									{errors.functionID && 
										<small className="p-error">Department is required.</small>
									}
								</div>
							</div>

							<div className="field grid">
								<div className="col-12 mb-2 md:col-3 mb-md-0">
									<label for="brand">Brands*</label>
								</div>
								<div className="col-12 md:col-9">
									{brandAgencies.map((cur, index) => (
										<div className="mb-2 flex justify-content-between" key={"brandAgencies|" + index} >
											
											<div className="flex">
												<div className="mr-2">
													<Controller defaultValue={false} name={"brandAgency." + index + ".isChecked"} control={unNamedControl}  render={({ field }) => (
														<Checkbox inputId={field.name} 
															onChange={(e) => checkboxUnnamedChange(e,field, cur.brandName)} 
															checked={field.value}
														/>
													)} />
												</div>
												<div>
													<Controller defaultValue={cur.brandAgencyID} name={"brandAgency." + index + ".brandAgencyID"} control={unNamedControl}  render={({ field, fieldState }) => (
														<span>{cur.brandName}</span>
													)} />
												</div>
											</div>
											<div>
												<div className="flex justify-content-end">
													<div className="mr-2">Hours*: </div>
													<div>
														<Controller name={"brandAgency." + index + ".baseline"} control={unNamedControl} rules={{ required: (brandsUnNamedSelected[cur.brandName]?true:false) }} render={({ field, fieldState }) => (
															<InputText 
																id={field.name} 
																value={field.value} 
																{...field}
																style={{width:'75px'}} 
																className={classNames({ 'p-invalid': fieldState.invalid })} 
															/>
														)} />
													</div>
													
												</div>
												{unNamedErrors.brandAgency && unNamedErrors.brandAgency[index] && unNamedErrors.brandAgency[index].baseline && 
													<div>
														<small className="p-error">Hours are required for selected brands.</small>
													</div>
												}
											</div>
											
										</div>
									))}

									{brandError && 
										<small className="p-error">You must choose at least one brand.</small>
									}
									
								</div>
							</div>

							<div className="field grid">
								<div className="col-12 mb-3 md:col-3 mb-md-0">
									<label for="rate" >Hourly Rate, $*</label>
								</div>
								<div className="col-12 md:col-3" >
									<Controller name="rate" control={unNamedControl} rules={{ required: true }} render={({ field, fieldState }) => (
										<InputText 
											id={field.name} 
											value={field.value} 
											{...field}
											className={classNames({ 'p-invalid': fieldState.invalid })} 
										/>
									)} />
									{unNamedErrors.rate && 
										<small className="p-error">Rate is required.</small>
									}
								</div>
							</div>

							<div className="field grid">
								<label for="rate" className="col-12 mb-3 mb-md-0">Comments</label>
								<div className="col-12">
									<Controller name="comments" control={unNamedControl} rules={{ required: false }} render={({ field, fieldState }) => (
										<InputTextarea 
											rows={5}
											id={field.name} 
											value={field.value} 
											{...field}
										/>
									)} />
								</div>
							</div>
						</>
					}
				</div>
			</form>
		</Dialog>
	)
}

export default ProjectAddStaffModal;