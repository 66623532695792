import { connect } from 'react-redux';

//presentation component
import AppTopbar from './AppTopbar'

// selector files (transform data from redux store in to what we need for presentation)
import { getBrandsDropdown, getCurrentClientName } from '../selectors/brandsSelector'
import { getAgencyProjectsDropdown } from '../selectors/agenciesSelector'

const mapStateToProps = (state, ownProps) => {
	return {
		brands: getBrandsDropdown(state),
		clientName: getCurrentClientName(state, ownProps),
		projects: getAgencyProjectsDropdown(state),
		pageName: window.location.hash
	}
}

const AppTopbarContainer = connect(mapStateToProps, null)(AppTopbar);
export default AppTopbarContainer;