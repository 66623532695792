import React, { useState } from 'react';
import * as ColumnInfo from '../constants/columnHeaderInfoText'

// PrimeReact Components
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const ColumnHeaderInfo = (props) => {
	const [displayInfo, setDisplayInfo] = useState(false);

	const renderFooter = () => {
		return (
			
			<div>
				<Button label="OK" icon="pi pi-checkmark" onClick={(e) => setDisplayInfo(false)} />
			</div>
		);
	}

	let curBody = ColumnInfo[props.columnKey].body
	let curHeading = ColumnInfo[props.columnKey].heading
	if (typeof ColumnInfo[props.columnKey] === 'function') {
		let objTemp = ColumnInfo[props.columnKey](props)
		curBody = objTemp.body
		curHeading = objTemp.heading
	}

	let widthClass = 'infoModal'
	if (props.widthClass) widthClass = props.widthClass


	return (
		<>
			<i className="infoCircle pi pi-question-circle" onClick={(e) => setDisplayInfo(true)}></i>

			<Dialog header={curHeading} visible={displayInfo} className={widthClass} footer={renderFooter()} onHide={() => setDisplayInfo(false)}>
				{curBody}	
			</Dialog>

		</>
		
	)
}

export default ColumnHeaderInfo;
