import React, { useState, useEffect, useRef } from 'react';

// Form validation library
import { useForm, Controller } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Messages } from 'primereact/messages';
import { ScrollPanel } from 'primereact/scrollpanel';
import { classNames } from 'primereact/utils';

import { dollarTemplate, dateTemplate, monthTemplate } from '../utilities/CommonSnippets'

const ProjectAddHoursModal = (props) => {

	const defaultValues = {
		currentFunction: null,
		currentDate: new Date(),
		currentState: 'add',
		hours: '', 
		comments: ''
	}
	
	const [currentFunctionID, setCurrentFunctionID] = useState(null);
	const [currentFunctionProfile, setCurrentFunctionProfile] = useState(null);
	const [addAnother, setAddAnother] = useState(true);

	// These are the needed items for the form validation component
	const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

	const toast = useRef(null);
	const msgs = useRef(null);

	// This will get called everytime the boolean displayHours changes, it determines if the modal is showing or not.
	useEffect(() => {
		// When we close the modal, reset all the form field values
		if (!props.displayHours) reset()
		else {
			// We we first open the modal, set the Function drop down to the first department in the list
			if (props.currentProject.functionDropdown && props.currentProject.functionDropdown[0]) {
				setValue("currentFunction", props.currentProject.functionDropdown[0].value);
				setCurrentFunctionID(props.currentProject.functionDropdown[0].value)
			}
		}
	},[props.displayHours])

	// This controls who the current function is and can set the needed object to show the function summary on the right of the modal
	useEffect(() => {
		
		if (currentFunctionID) {
			let _temp = {...props.currentProject.functions[currentFunctionID]}
			_temp.rate = 0
			_temp.hoursLog = []
			_temp.baseline = props.currentProject.projectBaselines.reduce((acc,cur) => {
				if (cur.functionID === currentFunctionID) {
					acc += cur.hours
					_temp.rate = cur.rate
				}
				return acc
			},0)

			_temp.hours = props.currentProject.projectHours.reduce((acc,cur) => {
				if (cur.functionID === currentFunctionID) {
					_temp.hoursLog.push(cur)
					if (cur.state === 'add') acc += cur.hours
					else if (cur.state === 'remove') acc -= cur.hours
				}
				return acc
			},0)
			_temp.cost = _temp.hours * _temp.rate

			setCurrentFunctionProfile(_temp)
		} else {
			setCurrentFunctionProfile(null)
		}
	},[currentFunctionID]);

	const fncOnFunctionChange = (field,e) => {
		field.onChange(e.value)
		setCurrentFunctionID(e.value)
	}

	const fncOnHoursChange = (field,e) => {
		if (e.target.value !== '' && !isNaN(e.target.value)) {
			
			let arrSplitDecimal = e.target.value.split('.')
			if (arrSplitDecimal.length === 1) field.onChange(e.target.value)
			else {
				if (arrSplitDecimal[1].length > 2) {
					field.onChange(arrSplitDecimal[0] + '.' + arrSplitDecimal[1].substring(0,2))
				} else {
					field.onChange(e.target.value)
				}
			}
		} else field.onChange('')
	}

	const renderFooter = () => {
		return (
			<div className="flex justify-content-between">
				<div>
					<Checkbox onChange={e => setAddAnother(e.checked)} checked={addAnother}></Checkbox> Add More hours
				</div>
				<div className="flex">
					<div className="mr-2">
						<Button label="Cancel" icon="pi pi-times" onClick={() => props.fncHideModal()} className="p-button-text" />
					</div>
					<div>
						<Button label="Add" icon="pi pi-plus" onClick={handleSubmit(onSubmit)} />
					</div>
				</div>
			</div>
		);
	}
	
	let arrStates = [
		{label: 'Add', value: 'add'},
		{label: 'Remove', value: 'remove'}
	]

	const hoursTemplate = (rowData) => {
		if (rowData.state === 'add') {
			return <div className="addHours">+ {rowData.hours}</div>
		} else if (rowData.state === 'remove') {
			return <div className="removeHours">- {rowData.hours}</div>
		} else {
			return ''
		}
	}

	const onSubmit = async (data) => {
       let blnAddResults = await props.addAgencyProjectHours({
			projectID: props.currentProject.projectID, 
			projectFunctionID: data.currentFunction,
			state: data.currentState,
			hours: data.hours,
			date: data.currentDate,
			comments: data.comments
		})

		setCurrentFunctionID(null)
		if (blnAddResults) {
			if (addAnother) {
				msgs.current.show([
					{ severity: 'success', detail: 'Hours were updated successfully.', life: 3000}
				]);
			} else {
				props.fncHideModal()
				props.showToast('success', 'Success','Hours were updated successfully.')
			}
			reset();
		}
		else {
			msgs.current.show([
				{ severity: 'error', detail: 'There was an issue adding hours.', life: 3000}
			]);
		}
    };


	return (
		<Dialog header={"Add hours for: " + props.currentProject.projectName} visible={props.displayHours} className="addHoursModal"  footer={renderFooter()} onHide={() => props.fncHideModal()}>
			<Toast ref={toast} />
			
			<div className="grid">
				<div className="col-6">
					<form className="p-fluid">
						<div>
							Department*: 
							<Controller name="currentFunction" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<Dropdown 
									id={field.name} 
									value={field.value} 
									options={props.currentProject.functionDropdown} 
									onChange={(e) => fncOnFunctionChange(field,e)} 
									placeholder="Select Function" 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{errors.currentFunction && 
								<small className="p-error">Function is required.</small>
							}
						</div>
						<br/>
						<div>
							Date for When Hours Were Worked*: 
							<Controller name="currentDate" control={control} rules={{ required: true }}  render={({ field, fieldState }) => (
                                <Calendar 
									id={field.name} 
									value={field.value} 
									onChange={(e) => field.onChange(e.value)} 
									view="month" dateFormat="MM yy"
									mask="99/99/9999" 
									showIcon 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
                            )} />
							{errors.currentDate && 
								<small className="p-error">Date is required.</small>
							}
							
						</div>
						<br/>
						<div>
							State*: 
							<Controller name="currentState" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<Dropdown 
									value={field.value} 
									options={arrStates} 
									onChange={(e) => field.onChange(e.value)}  
									placeholder="Select a State" 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{errors.currentState && 
								<small className="p-error">State is required.</small>
							}
						</div>
						<br/>
						<div>
							Hours*: 
							<Controller name="hours" control={control} rules={{validate:{positiveNumber: (value) => parseFloat(value) > 0,}}} render={({ field, fieldState }) => (
                                <InputText 
									id={field.name} 
									value={field.value} 
									{...field}
									onChange={(e) => fncOnHoursChange(field,e)} 
									autoFocus 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
                            )} />
							{errors.hours && 
								<small className="p-error">Hours are required.</small>
							}
						</div>
						<br/>
						<div>
							Comments: 
							<Controller name="comments" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
								<InputTextarea 
									rows={5}
									id={field.name} 
									value={field.value} 
									{...field}
									autoFocus
								/>
							)} />
						</div>
					</form>
				</div>
				<div className="col-6 verticalDividerLeft">
					{currentFunctionProfile && 
						<div className="flex flex-column currentStaff">
							<div className="flex justify-content-between">
								<div className="name">
									{currentFunctionProfile.functionTitle}
								</div>
								<div className="rate">{dollarTemplate(currentFunctionProfile.rate)}</div>
							</div>
							<div className="mb-2 title">
								{currentFunctionProfile.functionDescription}
							</div>
							
							<div className="flex justify-content-between">
								<div className="rate">{currentFunctionProfile.hours} / {currentFunctionProfile.baseline} hours</div>
								<div className="flex justify-content-end rate">
									{dollarTemplate(currentFunctionProfile.cost)}
								</div>
							</div>
							
							<div className="mt-4">
								<ScrollPanel style={{width: '100%', height: '200px'}}  className="hoursScroller">
									<DataTable value={currentFunctionProfile.hoursLog} className="hoursTable">
										<Column field="dateEntered" body={monthTemplate} header="Date" style={{width: '70%'}}></Column>
										<Column field="hours" header="Hours" body={hoursTemplate} style={{width: '30%',textAlign:'right'}}></Column>
									</DataTable>
								</ScrollPanel>
							</div>
							
						</div>
					}
				</div>
				<Messages ref={msgs} className="modalMessage"></Messages>
			</div>
		</Dialog>
	)
}

export default ProjectAddHoursModal;