import React, { useEffect } from 'react';
import { connect } from 'react-redux';


import { Chart as ChartJS, DoughnutController, ArcElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BUDGET_LIGHT_COLOR, BUDGET_DARK_COLOR} from '../../constants/colors'

import { getWidgetAnnualBaselineBudget, getWidgetSpent } from '../../selectors/dashboardWidgetsSelector'

ChartJS.register(ChartDataLabels);
ChartJS.register(DoughnutController)
ChartJS.register(ArcElement)
ChartJS.register(Tooltip)
ChartJS.defaults.font.size = 14;

const AnnualBaselineBudget = (props) => {
	
	const { budget, spent, loading, dates, instance } = props

	//if (!instance) instance = 0
	

	useEffect(() => {
		if (!loading) {
			
			let borderArray = [BUDGET_LIGHT_COLOR, BUDGET_DARK_COLOR]
			if (spent > budget) borderArray = ['red', 'red']
			
			
			const chartData = {
				datasets: [
					{
						data: [(budget - spent > 0?budget - spent:0), spent],
						backgroundColor: [
							BUDGET_LIGHT_COLOR,
							BUDGET_DARK_COLOR
						],
						borderColor: borderArray,
						hoverBackgroundColor: [
							BUDGET_LIGHT_COLOR,
							BUDGET_DARK_COLOR
						]
					}]
			};
		
			const chartOptions = {
				layout: {
					padding: {
						right: 20,
						left: 20,
						bottom: 0
					}
				},
				animation: {
					onComplete: function(animation) {
						
					}
				},
				rotation: -90,
				circumference: 180,
				plugins: {
					// Change options for ALL labels of THIS CHART
					datalabels: {
						anchor: 'middle',
						align: 'middle',
						color: '#ffffff',
						font: {
							size: 16
						},
						formatter: function(value, context) {
							let percent = Math.round((spent / budget) * 100)
							if (context.dataIndex === 0) {
								percent = (100 - percent > 0?100 - percent:0)
							}
							
							return percent + '%'
						},
						display: function(context) {
							return context.dataset.data[context.dataIndex] > 0;
						}
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								if (context.dataIndex === 0) {
									return '$' + context.formattedValue + ' Remaining'
								} else if (context.dataIndex === 1) {
									return '$' + context.formattedValue + ' Used'
								}
							}
						}
					},
				  }
			};
			
			
			var myChart = new ChartJS(document.getElementById('budgetChart_' + instance), {
				type: "doughnut",
				data: chartData ,
				options: chartOptions
			});

			// when component unmounts
			return () => {
				myChart.destroy()
			}
		}
	}, [budget, spent, loading, dates])

	return (
		<div className="flex flex-column" >
			{loading === true && 
				
				<div className="flex flex-column doughnutHalfLoading">
					<div className="align-self-center mb-4">
						<i className="pi pi-spin pi-spinner pageLoadingBudget"></i>
					</div>
					<div className="align-self-center">Loading...</div>
				</div>
				
			}

			{!loading && 
				<div className="chart-container doughnutHalf" >
					<div id="annualBaselineBudgetGraph" className="p-chart artCharts-doughnutHalf">
						<canvas id={"budgetChart_" + instance}></canvas>
					</div>
				</div>
			}
			
			<div className="flex justify-content-between subtitle mb-4" >
				<div className="ml-1 flex">
					<div className="legendSquare" style={{background: BUDGET_LIGHT_COLOR}}></div>
					<div className="ml-2 ">
						Budget: ${new Intl.NumberFormat('en-US').format(Math.round(props.budget))}
					</div>
				</div>
				<div className="mr-1 flex">
					<div className="legendSquare" style={{background: BUDGET_DARK_COLOR}}></div>
					<div className="ml-2" style={props.spent > props.budget ? {color:'red'}:{}}>
						Budget Used: ${new Intl.NumberFormat('en-US').format(Math.round(props.spent))}
					</div>
				</div>
			</div>
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		budget: getWidgetAnnualBaselineBudget(state,ownProps),
		spent: getWidgetSpent(state,ownProps),
	}
}

const AnnualBaselineBudgetContainer = connect(mapStateToProps, null)(AnnualBaselineBudget);
export default AnnualBaselineBudgetContainer;