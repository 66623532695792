import React, { useState, useEffect, useMemo, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ZenLogo  from '../images/zenLogo.png'
import ArtLogo  from '../images/artLogo.png'

import AuthService from '../services/AuthService';

export const ResetPassword = (props) => {
	const { uuid } = props
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [isValid, setIsValid] = useState(false);
	


	const Auth = useMemo(() => new AuthService(), []);
	const toast = useRef(null);

	useEffect(() => {
		Auth.checkUUID(uuid).then(res =>{
			setIsValid(true)
			setUsername(res.data.contactEmail)
		}).catch( err => {
			setIsValid(false)
			document.location.href='/'
		})
    }, [uuid]);

	

	let handleFormSubmit = (e) => {
		e.preventDefault();

		// todo: validateion on regex
		if (password !== '' ) {
			Auth.updatePassword(uuid,password).then(res =>{
				document.location.href='/'
			}).catch( err => {
				toast.current.show({ severity: 'error', summary: 'Error Message', detail: err, life: 3000 });
			})
			
		} else {
			return false
		}
	}

	const header = <h6>Pick a password</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

	 return (
        <div className="login-body">
			<Toast ref={toast} />
			<div className="card">

				<div className="grid logos grid-nogutter">
					<div className="col-6 zenLogo">
						<img src={ZenLogo} />
					</div>
					<div className="col-6 artLogo">
						<img src={ArtLogo} />
					</div>
				</div>
				<div className="grid login-form">
					<div className="col-12">
						{isValid === false && 
							<div></div>
						}

						{isValid === true && 
							<form onSubmit={handleFormSubmit}>
								<div className="field">
									<label htmlFor="username" className="block">Email</label>
									<InputText disabled={true} id="username" className="block" value={username} />
								</div>
								<div className="field">
									<label htmlFor="password" className="block">New Password</label>
									<Password 
										id="password" 
										className="block" 
										onChange={(e) => setPassword(e.target.value)} 
										header={header} footer={footer}
										toggleMask 
									/>
								</div>

								

								<div className="field">
									<Button label="Reset Password"></Button>
								</div>
							</form>
						}
					</div>
				</div>
			</div>
		</div>
	);
}
