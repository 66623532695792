import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import readXlsxFile from 'read-excel-file'
import ExcelJS  from 'exceljs'
import saveAs from "file-saver";
import classNames from 'classnames'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';

import { fncDrawTitleCellImport } from '../utilities/commonXslxFunctions'
import {dollarTemplate, statusTemplate, fncCompareProjects } from '../utilities/CommonSnippets'
import { COLUMN_MAPPINGS } from '../constants/excelMappings'

import { getAgenciesPromise } from '../actions/agencies'
import { GET_AGENCY_PROJECTS, UPLOAD_EXCEL_FILE } from '../constants/URLS'

dayjs.extend(isBetween)

const ImportProjects = (props) => {
	const toast = useRef(null);
	const fileUploadRef = useRef(null);
	const dt = useRef(null);

	const { displayImportProject, fncHideImportModal, addAgencyProject, agencyName, brandsDropdown, projects, updateAgencyProject, 
			showToast, agencyFunctions, addAgencyProjectHours, deleteAgencyProject, addOOPCosts } = props

	const [ importedProjects, setImportedProjects ] = useState([]);
	const [ functionTitleByID, setFunctionTitleByID] = useState({});
	const [ brandLookup, setBrandLookup] = useState({});
	const [ importCounts, setImportCounts] = useState({});
	const [ importCountsProgress, setImportCountsProgress] = useState({});
	const [ functionIDByTitle, setFunctionIDByTitle] = useState({});
	const [ hasImportError, setHasImportError] = useState(false);
	const [ displayProgress, setDisplayProgress] = useState(false);

	useEffect(() => {
		let _functionTitleByID = {}
		let _functionIDByTitle = {}

		for (let curFunction of agencyFunctions) {
			_functionTitleByID[curFunction.functionID] = curFunction.functionTitle
			_functionIDByTitle[curFunction.functionTitle] = curFunction.functionID
		}
		setFunctionTitleByID(_functionTitleByID)
		setFunctionIDByTitle(_functionIDByTitle)
	},[agencyFunctions]);


	
	const possibleBrands = brandsDropdown.map(cur => cur.label)

	useEffect(() => {
		let _brandLookup = brandsDropdown.reduce((acc,cur) => {
			acc[cur.label] = cur.value
			return acc
		},{})
		setBrandLookup(_brandLookup)
	},[brandsDropdown]);

	const renderFooter = () => {
		return (
			<div className="flex justify-content-between">
				<div className="flex">
					{importedProjects.length > 0 && 
						<Button label="Reset" icon="pi pi-replay" onClick={() => fncReset()} className="p-button-text" />
					}
					{importedProjects.length === 0 && 
						<div className="mr-2">
							<Button label="Download Template" icon="pi pi-download" onClick={() => exportExcel()} className="p-button-text" />
						</div>
					}
				</div>
				{displayProgress === true && 
					<div className="flex">
						{Object.keys(importCounts).map(cur => {
							
							let strLabel = 'Edits'
							if (cur === 'Delete') strLabel = 'Deletions'
							if (cur === 'Add') strLabel = 'Additions'

							if (!['Edit','Delete','Add'].includes(cur)) return null

							return (
								<div className="ml-4 mr-4 smallerDetails">{strLabel}: {importCountsProgress[cur]} / {importCounts[cur]}</div>
							)
						})}
					</div>
				}
				<div className="flex">
					<div className="ml-2">
						{importedProjects.length > 0 && !hasImportError && !displayProgress && 
							<Button label="Save All" icon="pi pi-save" onClick={fncSaveAll} className="p-button" />
						}
						{importedProjects.length > 0 && !hasImportError && displayProgress && 
							<Button label="Saving" disabled={true} icon="pi pi-spin pi-spinner"  className="p-button" />
						}

						{importedProjects.length > 0 && hasImportError && 
							<div className="mr-4 mt-2 font-bold red">
								All Errors must be corrected
							</div>
						}
						{importedProjects.length === 0 && 
							<Button label="Close" icon="pi pi-times" onClick={() => fncHideImportModal()} className="p-button" />
						}
					</div>
					
				</div>
			</div>
		);
	}

	/* 
		FILTERED FUNCTION - BOTH IMPORT AND EXPORT USE THIS AND CAN LIMIT BY DATE
	*/
	const fncFilterProjects = (projects, startDate, endDate) => {
		
		return projects.filter(cur => {
			return (new Date(endDate) >= new Date(cur.startDate) && new Date(startDate) <= new Date(cur.endDate))
		})
	}

	/*
		ALL THE EXPORT LOGIC IS HERE - PROVIDES THE NEEDED TEMPLATE
	*/
	const exportExcel = () => {
		// This is where the data starts, after the headers
		let startingRow = 4 

		let arrBrands = ['"' + possibleBrands.join(',') + '"']
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Project Template", {
			views: [{ state: "frozen", ySplit: startingRow, xSplit: 2 }]
		});
		worksheet.protect()
		worksheet.properties.defaultColWidth = 20;
		worksheet.properties.defaultRowHeight = 16;
		//Meta data for the agency
		fncDrawTitleCellImport(worksheet, 'A1', "Agency: ")
		fncDrawTitleCellImport(worksheet, 'B1', agencyName)
		fncDrawTitleCellImport(worksheet, 'A2', "Month: ")
		fncDrawTitleCellImport(worksheet, 'B2', dayjs().format('MM/YYYY'))

		worksheet.getCell('B2').protection = {
			locked: false,
		};

		worksheet.getCell('B2').numFmt = '@'

		// Need to add the dynamic columns for each function (dept base hours, dept rate, dept used hours for the month)
		let _COLUMN_MAPPINGS = {...COLUMN_MAPPINGS}
		
		for (let curFunction of agencyFunctions) {
			let curTitle = curFunction.functionTitle
			
			_COLUMN_MAPPINGS[curTitle + ' base hours'] = {
				display: 'Base Hours',
				locked: false,
				width: 25,
				isDepartment: true,
				department: curTitle,
				numFmt: '#,##0'
			}

			_COLUMN_MAPPINGS[curTitle + ' rate'] = {
				display: 'Rate',
				locked: false,
				width: 25,
				isDepartment: true,
				department: curTitle,
				numFmt: '"$"#,##0.00'
			}

			_COLUMN_MAPPINGS[curTitle + ' monthly used'] = {
				display: 'Monthly Used',
				locked: false,
				width: 25,
				isDepartment: true,
				department: curTitle,
				numFmt: '#,##0'
			}
		}


		let alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
		let arrKeys = Object.keys(_COLUMN_MAPPINGS)
		
		for (let curColumnIndex in arrKeys) {
			let objCurObj = _COLUMN_MAPPINGS[arrKeys[curColumnIndex]]
			let strLetter = alphabet[curColumnIndex % 26]

			// This covers 78 columns, I don't think we will go over that? 
			// 11 default departments (this could change) X 3 per each is only 33 plus the default columns
			if (curColumnIndex > 25 && curColumnIndex <= 51) strLetter = 'A' + strLetter
			if (curColumnIndex > 51) strLetter = 'B' + strLetter

			if (objCurObj.isDepartment) {
				fncDrawTitleCellImport(worksheet, strLetter + parseInt(startingRow -1), objCurObj.department)
			}

			fncDrawTitleCellImport(worksheet, strLetter + startingRow, objCurObj.display)
			if (objCurObj.width) worksheet.getColumn(strLetter).width = objCurObj.width
			if (objCurObj.numFmt) worksheet.getColumn(strLetter).numFmt =  objCurObj.numFmt;
			
			// Items with dropdown list in the excel
			if (objCurObj.list) {

				if (objCurObj.list === 'brand') {
					worksheet.dataValidations.add(strLetter + parseInt(startingRow +1) + ':' + strLetter + '1000', {
						type: 'list',
						allowBlank: false,
						formulae: arrBrands,
						showErrorMessage: true,
						errorStyle: 'error',
						error: 'Choose a valid Brand',
					});
				}

				if (objCurObj.list === 'status') {
					worksheet.dataValidations.add(strLetter + parseInt(startingRow +1) + ':' + strLetter + '1000', {
						type: 'list',
						allowBlank: false,
						formulae: ['"pending,active,not_started,hold,canceled,completed"'],
						showErrorMessage: true,
						errorStyle: 'error',
						error: 'Choose a valid Status',
					});
				}

				if (objCurObj.list === 'priority') {
					worksheet.dataValidations.add(strLetter + parseInt(startingRow +1) + ':' + strLetter + '1000', {
						type: 'list',
						allowBlank: false,
						formulae: ['"high,normal,low"'],
						showErrorMessage: true,
						errorStyle: 'error',
						error: 'Choose a valid Status',
					});
				}
			}
		}

		let curCell
		
		// we only want to get projects that are for the current year
		let filteredProjects = fncFilterProjects(projects, '1/1/' + dayjs().format('YYYY'), '12/31/' + dayjs().format('YYYY'))
		for (let curProject of filteredProjects.sort((a,b) => (a.brandName < b.brandName?-1:1))) {
			let dataRow = worksheet.addRow();

			let objDepartmentLookups = curProject.projectBaselines.reduce((acc,cur) => {
				acc[functionTitleByID[cur.functionID]] ={
					rate: cur.rate,
					hours: cur.hours
				}
				return acc
			},{})
			
			for (let curColumnIndex in arrKeys) {
				curCell = dataRow.getCell(parseInt(curColumnIndex) + 1);
				let curValue = ''
				let objCurObj = _COLUMN_MAPPINGS[arrKeys[curColumnIndex]]
				if (!objCurObj.isDepartment && !objCurObj.clear) curValue = curProject[arrKeys[curColumnIndex]]
				else {
					if (objDepartmentLookups[objCurObj.department]) {
						if (objCurObj.display === 'Rate') {
							curValue = objDepartmentLookups[objCurObj.department].rate
						} else if (objCurObj.display === 'Base Hours') {
							curValue = objDepartmentLookups[objCurObj.department].hours
						}
					}
				}
				if (Array.isArray(curValue)) curValue = curValue.join(',')
				if (arrKeys[curColumnIndex] === 'startDate' || arrKeys[curColumnIndex] === 'endDate') curValue = dayjs(curValue).format('MM/YYYY')

				curCell.value = curValue
			}
			
		}

		for (let curRow = startingRow + 1; curRow < 1000; curRow++) {
			for (let curColumnIndex in arrKeys) {
				let objCurObj = _COLUMN_MAPPINGS[arrKeys[curColumnIndex]]
				let strLetter = alphabet[curColumnIndex % 26]

				if (curColumnIndex > 25 && curColumnIndex < 51) strLetter = 'A' + strLetter
				if (curColumnIndex > 51) strLetter = 'B' + strLetter

				worksheet.getCell(strLetter + curRow).protection = {locked: objCurObj.locked}
			}
		}

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  agencyName + "_project_template.xlsx"
			);
		  });
    }

	/*
		ALL THE IMPORT LOGIC IS HERE - READS IN THE TEMPLATE
	*/

	const fncLoadExcel = async () => {
		let objCurUploader = fileUploadRef.current
		let schema = {
			'Art Internal ID': {
				prop: 'projectID'
			},
			'Start Date (mm/yyyy)': {
				prop: 'startDate',
				type: (value) => {
					let arrParts = value.split('/')
					return new Date(arrParts[1], arrParts[0] -1 )
				}
			},
			'End Date (mm/yyyy)': {
				prop: 'endDate',
				type: (value) => {
					
					let arrParts = value.split('/')
					if (arrParts.length === 2) {
						return new Date(arrParts[1], arrParts[0] -1 )
					} else {
						return null
					}
				}
			},
			'Project Types': {
				prop: 'projectTypes',
				type: (value) => {
					return value.split(',')
				}
			},
			'Project Name': {
				prop: 'projectName',
				type: (value) => {
					return value.toString()
				}
			},
			'Project Number': {
				prop: 'projectNumber',
				type: (value) => {
					return value.toString()
				}
			},
			'PO Number': {
				prop: 'poNumber',
				type: (value) => {
					return value.toString()
				}
			},
			'Brand': {
				prop: 'brandName'
			},
			'Agency Name': {
				prop: 'agencyName'
			},
			'Row Type': {
				prop: 'rowType'
			},
			'Priority': {
				prop: 'priority'
				},
			'Status': {
				prop: 'status'
			},
			'Cost': {
				prop: 'totalProjectBudget'
			},
			'Pass-through (OOPS)': {
				prop: 'passThrough'
			},
			'Pass-through Desc': {
				prop: 'passThroughDesc'
			},
			'Pass-through Costs': {
				prop: 'oopCosts'
			},
			'Month': {
				prop: 'currentDate',
				type: (value) => {
					let arrParts = value.split('/')
					return new Date(arrParts[1], arrParts[0] -1 )
				}
			}
		}

		for (let curFunction of agencyFunctions) {
			schema[curFunction.functionTitle] = {
				prop: 'department|' + curFunction.functionTitle,
				type: {
					[curFunction.functionTitle + '|Rate']: {
						prop: "rate"
					},
					[curFunction.functionTitle + '|Base Hours']: {
						prop: "baseline"
					},
					[curFunction.functionTitle + '|Monthly Used']: {
						prop: "used"
					}
				}
			}
		}

		let rows = await readXlsxFile(objCurUploader.getFiles()[0], {
			schema,
			transformData(data) {
				let agencyName = null
				let curMonth = null
				let departmentColumns = {}
				let _finalData = data.reduce((acc,cur, index) => {
					if (index === 0) {
						agencyName = cur[1]
						return acc
					} else if (index === 1) {
						curMonth = cur[1]
						return acc
					} else if (index === 2) {
						departmentColumns = cur.reduce((accD,curD, indexD) => {
							if (curD) accD[indexD] = curD
							return accD
						},{})
						return acc
					} else if (index === 3) {
						
						let _cur = cur.map((curInner, indexInner) => {
							if (departmentColumns[indexInner]) {
								return departmentColumns[indexInner] + '|' + curInner
							} else {
								return curInner
							}
						})
						
						acc.push([
							'Agency Name',
							'Month',
							
							..._cur
						])
					}else {
						acc.push([
							agencyName,
							curMonth,
							...cur
						])
					}

					return acc
				},[])

				return _finalData
			}
		})

		let transFormedRows = rows.rows.map(cur => {
			let objReturned = {}
			let totalDepartmentBudget = 0
			for (let curColumn in cur) {
				if (curColumn.indexOf('department|') === 0) {
					if (!objReturned['departmentHours']) objReturned['departmentHours'] = []
					if (!objReturned['projectHours']) objReturned['projectHours'] = []

					totalDepartmentBudget += cur[curColumn].baseline * (cur[curColumn].rate || 0)

					let departmentTitle = curColumn.replace('department|', '')
					objReturned['departmentHours'].push({
						hours: cur[curColumn].baseline,
						projectID: cur.projectID,
						rate: cur[curColumn].rate || 0,
						functionID: functionIDByTitle[departmentTitle]
					})
					if (cur[curColumn].used && parseInt(cur[curColumn].used) > 0) {
						objReturned['projectHours'].push({
							comments: 'Imported from Upload tool.',
							projectFunctionID: functionIDByTitle[departmentTitle],
							projectID: cur.projectID,
							state: 'add',
							date: cur.currentDate,
							hours: cur[curColumn].used
						})
					} else if (cur[curColumn].used && parseInt(cur[curColumn].used) < 0) {
						objReturned['projectHours'].push({
							comments: 'Imported from Upload tool.',
							projectFunctionID: functionIDByTitle[departmentTitle],
							projectID: cur.projectID,
							state: 'remove',
							date: cur.currentDate,
							hours: Math.abs(cur[curColumn].used)
						})
					}
				} else {
					objReturned[curColumn] = cur[curColumn]
				}

			}

			return {
				...objReturned,
				totalDepartmentBudget: totalDepartmentBudget
			}
		})

		let existingProjects = await getAgenciesPromise(GET_AGENCY_PROJECTS)
		let  projects = existingProjects.allIds.map(cur => existingProjects.byId[cur])

		let filteredProjects = fncFilterProjects(projects, '1/1/' + dayjs().format('YYYY'), '12/31/' + dayjs().format('YYYY'))
		
		let deletedMap = filteredProjects.reduce((acc,cur) => {
			acc[cur.projectID] = 0
			return acc
		},{})

		let importedProjects = transFormedRows.reduce((acc,cur) => {
			let rowType = 'Add'
			// Some default data to cover NULL coming in from excel
			let existingData = {
				passThroughDesc: '',
				passThrough: 0
			}
			let changedFields = []

			deletedMap[cur.projectID] = 1
			if (cur.projectID && parseInt(cur.projectID) > 0 && existingProjects.byId[cur.projectID]) {
				existingData = {
					brandAgencyID: existingProjects.byId[cur.projectID].brandAgencyID,
					totalHours: existingProjects.byId[cur.projectID].totalHours,
					totalCost: existingProjects.byId[cur.projectID].totalCost,
					passThroughDesc: existingProjects.byId[cur.projectID].passThroughDesc
				}
				rowType = ''
				changedFields = fncCompareProjects(existingProjects.byId[cur.projectID], cur, functionTitleByID)
				if (changedFields.length > 0) {
					rowType = 'Edit'
				}
			}

			if (rowType !== "") {
				acc.push({
					...existingData,
					...cur, 
					rowType: rowType,
					changedFields: changedFields
				})
			}
			
			return acc
		},[])
		
		for (let curDeleteCheckProjID in deletedMap) {
			if (!deletedMap[curDeleteCheckProjID]) {
				importedProjects.push({
					...existingProjects.byId[curDeleteCheckProjID],
					rowType: 'Delete',
					changedFields: []
				})
			}
		}

		
		// Now check the rows for Errors
		let _importCounts = {}
		let _warningCounts = 0
		let _importCountsProgress = {}
		let _hasImportError = false
		importedProjects = importedProjects.map(cur => {
			let curRowType = cur.rowType
			let _warningFields = []
			let _errorFields = []
			let _rowTypeSorted
			
			//if deleting, just ignore any errors, they don't matter
			if (curRowType !== 'Delete') {
				
				if (curRowType === 'Add') _rowTypeSorted = 10
				if (curRowType === 'Edit') _rowTypeSorted = 11
				
				if (!cur.startDate || !dayjs(cur.startDate).isValid()) {
					curRowType = 'error'
					_errorFields.push('Start Date')
				}
				if (!cur.endDate || !dayjs(cur.endDate).isValid()) {
					curRowType = 'error'
					_errorFields.push('End Date')
				}

				if (!brandLookup[cur.brandName]) {
					curRowType = 'error'
					_errorFields.push('Brand')
				}

				if (!cur.status) {
					curRowType = 'error'
					_errorFields.push('Status')
				}

				if (!cur.priority) {
					curRowType = 'error'
					_errorFields.push('Priority')
				}

				if (!cur.totalProjectBudget) {
					curRowType = 'error'
					_errorFields.push('Total Project Budget')
				}

				if (!cur.departmentHours) {
					curRowType = 'error'
					_errorFields.push('No Department Hours')
				}

				if (!cur.poNumber) {
					curRowType = 'error'
					_errorFields.push('P.O. Number')
				}

				if (!cur.projectNumber) {
					curRowType = 'error'
					_errorFields.push('Project Number')
				}

				if (curRowType === 'error') {
					_hasImportError = true
					_rowTypeSorted = 1
				}

				// WARNINGS WILL NOT STOP PROCESSING
				let percentUsed = Math.abs(cur.totalDepartmentBudget / cur.totalProjectBudget) * 100
				if (percentUsed < 90 || percentUsed > 110 ) {
					_warningFields.push('Total hours entered are over the budgeted hours for this project.')
					_warningCounts++
					_rowTypeSorted = _rowTypeSorted - 5
				}

				// WARNING IF HOURS ADDED ARE OUTSIDE PROJECT RANGE
				let hoursIsBetween = dayjs(cur.currentDate).isBetween(cur.startDate, cur.endDate, 'month', '[]')
				if (!hoursIsBetween && cur.projectHours && cur.projectHours.length > 0) {
					_warningFields.push('Hours entered are not within the Start and End date of the project.')
					_warningCounts++
					_rowTypeSorted = _rowTypeSorted - 5
				}

				// WARNING IF HOURS ADDED ARE OUTSIDE PROJECT RANGE
				
				if (!hoursIsBetween && cur.oopCosts && cur.oopCosts !== 0) {
					_warningFields.push('OOP Hours entered are not within the Start and End date of the project.')
					_warningCounts++
					_rowTypeSorted = _rowTypeSorted - 5
				}
			} else {
				_rowTypeSorted = 12
			}

			if (!_importCounts[curRowType]) {
				_importCounts[curRowType] = 0
				_importCountsProgress[curRowType] = 0
			}
			_importCounts[curRowType]++

			if (_warningFields && _warningFields.length > 0) {
				if (!_importCounts['warning']) _importCounts['warning'] = 0
				_importCounts['warning']++
			}


			return {
				...cur,
				rowType: curRowType,
				rowTypeSorted: _rowTypeSorted,
				errorFields: _errorFields,
				warningFields: _warningFields
			}
		})

		setImportCounts(_importCounts)
		setImportCountsProgress(_importCountsProgress)

		if (importedProjects.length === 0) {
			toast.current.show({severity:'error', summary: 'Import Issue', detail: 'The file you are uploading does not contain any changes. Please make changes locally and try again.', life: 3000});
			objCurUploader.clear()
		}

		

		setHasImportError(_hasImportError)
		setImportedProjects(importedProjects)
		
	}

	const fncReset = () => {
		let objCurUploader = fileUploadRef.current
		objCurUploader.clear()
		setImportedProjects([])
	}

	const fncSaveProject = async (rowData) => {
		return await addAgencyProject(rowData, false)
	}

	const fncUpdateProject = async (rowData) => {
		await updateAgencyProject({
			...rowData,
			fullUpdate: true
		}, false)
		
	}

	const fncDeleteProject = async (projectID) => {
		await deleteAgencyProject(projectID, false)
	}

	const fncSaveAll = async () => {
		setDisplayProgress(true)
		let blnAllSuccessful = true
		
		for (let curRow of importedProjects) {
			
			if (curRow.rowType !== "") {
				let _curRow = {
					...curRow,
					name: curRow.projectName, 
					patientPopulation: [],
					number: curRow.projectNumber, 
					description: curRow.notes || '', 
					issues:'',  
					startDate: new Date(curRow.startDate).toISOString(), 
					endDate: new Date(curRow.endDate).toISOString(),  
					totalCost: curRow.totalProjectBudget
				}
				
				if (curRow.rowType === 'Edit' && curRow.projectID && parseInt(curRow.projectID) > 0) {
					await fncUpdateProject(_curRow)
					if (curRow.projectHours && curRow.projectHours.length > 0) {
						for (let curHours of curRow.projectHours) {
							await addAgencyProjectHours(curHours)
						}
					}
					if (curRow.oopCosts && curRow.oopCosts !== 0) {
						await addOOPCosts({
							projectID: curRow.projectID, 
							costs: curRow.oopCosts,
							date: curRow.currentDate,
							comments: 'Imported from Upload tool.',
						})
					}
				} else if(curRow.rowType === 'Add') {
					let addResults = await fncSaveProject({
						..._curRow,
						brandAgencyID: brandLookup[curRow.brandName]
					})
					
					if (addResults.success && curRow.projectHours && curRow.projectHours.length > 0) {
						for (let curHours of curRow.projectHours) {
							await addAgencyProjectHours({
								...curHours,
								projectID: addResults.newProjectID
							})
						}
					}

					if (curRow.oopCosts && curRow.oopCosts !== 0) {
						await addOOPCosts({
							projectID: curRow.projectID, 
							costs: curRow.oopCosts,
							date: curRow.currentDate,
							comments: 'Imported from Upload tool.',
						})
					}

					if (!addResults) blnAllSuccessful = false

				} else if(curRow.rowType === 'Delete') {
					await fncDeleteProject(_curRow.projectID)
				}
				
				setImportCountsProgress(importCountsProgress => {
					return {
						...importCountsProgress,
						[curRow.rowType]: importCountsProgress[curRow.rowType] + 1
					}
				})
			}
		}

		const formData = new FormData();
		let objCurUploader = fileUploadRef.current
		let arrFiles = objCurUploader.getFiles() || []
		if (arrFiles[0]) {
			formData.append("uploadExcel", arrFiles[0]);
		}

		await axios({
			method: 'post', 
			url: UPLOAD_EXCEL_FILE,
			data: formData,
			headers: { "Content-Type": "multipart/form-data" }
		})

		setDisplayProgress(false)
		fncReset()

		if (blnAllSuccessful) showToast('success', 'Success','Project Upload was successful.')
		else showToast('warn', 'Warning','Not all Projects were able to save.')
		fncHideImportModal()

	}

	const projectNameTemplate = (rowData) => {
		try {
			return (
				<div className="flex flex-column" >
					<div className="mb-2 name">
						{rowData.projectName}
					</div>
					<div className="mb-2 description">{rowData.description}</div>
				</div>
			)
		} catch (err) {
			console.error('Error in arrayTemplate')
			return null
		}
	}

	const brandTemplate = (rowData) => {
		try {
			return (
				<div className="flex flex-column" >
					<div className="mb-2 description">{rowData.brandName}</div>
					{rowData.audienceID && 
						<div className="flex ml-2">
							<div className="flex flex-column mb-2 description">
								<div>
									{rowData.audienceName}
								</div>
								{rowData.patientPopulation.length > 0 && 
									<div>
										Population: {rowData.patientPopulation.join(',')}
									</div>
								}
								
							</div>
						</div>
					}
				</div>
			)
		} catch (err) {
			console.error('Error in arrayTemplate')
			return (
				<div>Process Error</div>
			)
		}
	}

	const arrayTemplate = (rowData, column) => {
		try {
			if (!rowData[column.field]) return null
			let fields = []

			if (!Array.isArray(rowData[column.field])) {
				if (rowData[column.field]) fields = rowData[column.field].split(',')
			} else {
				fields = rowData[column.field]
			}
			
			return (
				<div className="flex flex-column" >
					{(fields || []).map((cur, index) => {
						return (
							<div key={column.field + '|array|' + index}>
								{cur}
							</div>
						)
					})}
				</div>
			)
		} catch (err) {
			console.error('Error in arrayTemplate')
			return (
				<div>Process Error</div>
			)
		}
	}

	const datesTemplate = (rowData, column) => {
		try {
			if (rowData.startDate && dayjs(rowData.startDate).isValid() && rowData.endDate && dayjs(rowData.endDate).isValid()) {
				return (
					<div className="flex">
						<div>
							{dayjs(rowData.startDate).format('MMMM YYYY')} - {dayjs(rowData.endDate).format('MMMM YYYY')}
						</div>
					</div>
				)
			} else {
				return (
					<div>Invalid Dates</div>
				)
			}
		} catch (err) {
			console.error('Error in datesTemplate')
			return null
		}
	}

	const totalCostTemplate = (rowData, column) => {
		try {
			return (
				<div className="flex flex-column">
					<div>
						{dollarTemplate(rowData.totalProjectBudget)}
					</div>
				</div>
			)
		} catch (err) {
			console.error('Error in totalCostTemplate')
			return null
		}
	}

	const rowTypeTemplate = (rowData, column) => {
		try {
			if (rowData.rowType === '') return null
			if (rowData.rowType === 'error') {
				return (
					<div className="flex flex-column red">
						<div className="font-bold ">
							Error
						</div>
						
						{rowData.errorFields && rowData.errorFields.length > 0 && rowData.errorFields.map(cur => {
							return (
								<div className="ml-2">{cur}</div>
							)
						})}
					</div>
				)
			}
			return (
				<div className="flex flex-column">
					<div className="font-bold">
						{rowData.rowType}
					</div>
					{rowData.changedFields && rowData.changedFields.length > 0 && rowData.changedFields.map(cur => {
						return (
							<div className="flex flex-column ml-2">
								<div>
									{cur.type.displayName}
								</div>
								{cur.type.hideComparison !== true && 
									<div className="flex flex-column ml-4">
										<div>
											{cur.databaseValue} (prev)
										</div>
										<div>
											{cur.importValue} (new)
										</div>
									</div>
								}
							</div>
						)
					})}

					{rowData.warningFields && rowData.warningFields.length > 0 && rowData.warningFields.map(cur => {
						return (
							<div className="orange font-bold">{cur}</div>
						)
					})}
				</div>
			)
		} catch (err) {
			console.error('Error in rowTypeTemplate')
			return null
		}
	}


	return (
		<Dialog header="Import Projects" visible={displayImportProject} className="importModal"  footer={renderFooter()} onHide={() => fncHideImportModal()}>
			<Toast ref={toast} />
			<div className="flex flex-column">
				
					<div className={classNames({ 'hidden': importedProjects.length > 0 })}>
						<div className="strong pb-2">Step 1 - Choose & Process File</div>
						<div className="pb-4">
							<FileUpload 
								name="excelFile" 
								ref={fileUploadRef} 
								accept="application/*" 
								maxFileSize={3000000} 
								customUpload 
								uploadHandler={fncLoadExcel}
								chooseLabel="Choose File to Load"
								
								uploadOptions= {{
									label: 'Process Data'
								}}
								emptyTemplate={<p className="m-0">Drag and drop files to here to upload. Download a new template to start. (lower left corrner)</p>} 
							/>
						</div>
					</div>
				
			
				{importedProjects.length > 0 && 
					<div className="projectImport">
						<div className="strong pb-2">Step 2 - Update Projects</div>
						
						<div className="flex">
							{Object.keys(importCounts).map(cur => {
								
								let strLabel = ''
								if (cur === 'Edit') strLabel = 'Edits'
								if (cur === 'Delete') strLabel = 'Deletions'
								if (cur === 'Add') strLabel = 'Additions'
								if (cur === 'error') strLabel = 'Errors'
								if (cur === 'warning') strLabel = 'Warnings'

								if (strLabel ==='') return null

								return (
									<div className="ml-4 mr-4 smallerDetails">{strLabel}: {importCounts[cur]} row(s)</div>
								)
							})}
						</div>

						<DataTable ref={dt} value={importedProjects} className="p-datatable-projects" scrollable scrollHeight="auto" style={{ width: '100%' }} 
							paginator removableSort={true} sortField="rowTypeSorted" sortOrder={1} dataKey="projectID"
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={25} rowsPerPageOptions={[25,50,100]}
							paginatorPosition = "both"
							stripedRows  
						>
							<Column field="rowType" sortable columnKey="rowType" body={rowTypeTemplate} header="Change Type" style={{verticalAlign:'top',width:'350px'}}></Column>
							<Column field="projectName" sortable columnKey="projectName" header="Project Name" body={projectNameTemplate}  style={{verticalAlign:'top',width:'250px'}}></Column>
							<Column field="brandName" sortable columnKey="brandName" header="Brand" body={brandTemplate} style={{verticalAlign:'top',width:'250px'}}></Column>
							<Column field="projectTypes" columnKey="projectTypes" header="Project Types" body={arrayTemplate} style={{verticalAlign:'top',width:'250px'}}></Column>
							<Column field="status" columnKey="status" header="Status" body={statusTemplate} style={{textAlign:'center',verticalAlign:'top',width:'200px'}}></Column>
							<Column field="startDate" header="Project Dates" body={datesTemplate} style={{verticalAlign:'top',width:'200px'}}></Column>
							<Column field="totalProjectBudget" columnKey="totalProjectBudget" body={totalCostTemplate} header="Budget" style={{textAlign:'right',verticalAlign:'top',width:'120px'}}></Column>
							
						</DataTable>
						
					</div>

		
				}
			</div>
		</Dialog>
	)
}

export default ImportProjects;