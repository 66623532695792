import React, { useState,useEffect } from 'react';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// BreadCrumbs component
import BreadCrumbs from '../../components/breadCrumbs/breadCrumbs-container'

import { percentageLineTemplateWithPercentage } from '../../utilities/CommonSnippets'
import ColumnHeaderInfo from '../../components/columnHeaderInfo'


const Overview = (props) => {
	const { getBrands, clientID, setCurrentClientID } = props
	const [expandedRows, setExpandedRows] = useState(null);
	
	useEffect(() => {
		getBrands()
	},[getBrands]);

	useEffect(() => {
		setCurrentClientID(clientID)
	},[clientID, setCurrentClientID]);


	const breadCrumbItems = [
        {label: 'Overview'}
    ];

	const nameTemplate = (rowData) => {
		if (rowData.isFranchise) { 
			if (expandedRows && expandedRows.includes(rowData)) {
				return (
					<div>
						<div className="strong customChevron" >
							<button onClick={(e) => {setExpandedRows([])}} type="button" aria-expanded="true" aria-controls="content_0_expanded" className="p-row-toggler p-link">
								<span className="p-row-toggler-icon pi pi-fw p-clickable pi-chevron-down"></span>
							</button>
						</div>
						
						<div className="strong" style={{display:'inline-block',paddingLeft:'10px'}}>
							<span className="tinyTitle">Franchise</span><br/>
							<div className="strong link" onClick={(e) => fncViewFranchiseDetails(rowData.id)}>{rowData.name}</div>
						</div>
						
					</div>
				)
			} else {
				return (
					<div>
						<div className="strong customChevron">
							<button onClick={(e) => {setExpandedRows([rowData])}} type="button" className="p-row-toggler p-link" aria-expanded="false" aria-controls="content_1_expanded">
								<span className="p-row-toggler-icon pi pi-fw p-clickable pi-chevron-right"></span>
							</button>
						</div>
						<div className="strong" style={{display:'inline-block',paddingLeft:'10px'}}>
							<span className="tinyTitle">Franchise</span><br/>
							<div className="strong link" onClick={(e) => fncViewFranchiseDetails(rowData.id)}>{rowData.name}</div>
						</div>
					</div>
				)
			}
		} else {
			return (
				<div className="strong link" onClick={(e) => fncViewBrandDetails(rowData.id)}>{rowData.name}</div>
			)
		}
	}

	const currencyTemplate = (rowData,column) => {
		return (
			<div className="grid">
				<div className="col-12 strong">
					{rowData[column.field].toLocaleString('en-US', {style: 'currency',currency: 'USD',})}
				</div>
			</div>
		)

	}

	const tableHeader = <div className="tableHeader">List of Franchises/Brands</div>
	
	const rowExpansionTemplate = (data) => {
		return (
			<div className="subTable">
				<DataTable  value={data.brands} className="p-datatable-products" >
					
					<Column field="name" header="Name" body={nameTemplate} sortable style={{width:'20%'}}></Column>
					<Column field="spendPercentage" body={percentageLineTemplateWithPercentage} header="Spend (YTD vs. Monthly Budget)" style={{width:'40%'}}></Column>
					
					<Column field="spend" header="Spend YTD" body={currencyTemplate} sortable style={{width:'20%'}}></Column>
					<Column field="spendAllocation" body={currencyTemplate} header="Total Budget" sortable style={{width:'20%'}}></Column>
				</DataTable>
			</div>
		)
	}

	const fncViewBrandDetails = (brandID) => {
		props.history.push('/newOverview/' + brandID)
	}

	const fncViewFranchiseDetails = (franchiseID) => {
		

		
		props.history.push('/franchiseOverview/' + franchiseID.replace('franchise_',''))
	}

	//if (props.brands.length === 1) window.location.hash = "/brandSummary/" + props.brands[0].id

	const colHeader_currentYear = <div className="flex">
		<ColumnHeaderInfo columnKey="CURRENT_YEAR"/>
		<div>Current Fiscal Year</div>
	</div>

	const colHeader_spendYTD = <div className="flex">
		<ColumnHeaderInfo columnKey="SPEND_YTD"/>
		<div>Spend Fiscal YTD</div>
	</div>

    return (
		<div className="grid">
			<div className="col-12">
				<BreadCrumbs items={breadCrumbItems} />
				<div className="card">
					
					<DataTable value={props.brands} header={tableHeader} paginator rows={20} className="p-datatable-products" 
						expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    	rowExpansionTemplate={rowExpansionTemplate} >
						
						<Column field="name" header="Name" body={nameTemplate} sortable style={{width:'20%'}}></Column>
						<Column field="spendPercentage" body={percentageLineTemplateWithPercentage} header="Spend (Fiscal YTD vs. Monthly Budget)" style={{width:'40%'}}></Column>
						<Column field="spend" header={colHeader_spendYTD} body={currencyTemplate} sortable style={{width:'20%'}}></Column>
						<Column field="spendAllocation" header={colHeader_currentYear} body={currencyTemplate}  sortable style={{width:'20%'}}></Column>
					</DataTable>
				</div>
			</div>
		</div>
    );
}

export default Overview;