import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';

// BreadCrumbs component
import BreadCrumbs from '../../components/breadCrumbs/breadCrumbs-container'

const ManageComments = (props) => {
	const { getBrands, getComments } = props
	const [comments, setComments] = useState([]);
	const [commentsFilter, setCommentsFilter] = useState('pending');
	const toast = useRef(null);
	useEffect(() => {
		getBrands()
		getComments()
	},[getBrands, getComments]);	

	useEffect(() => {
		if (props.comments) {
			let filteredComments = props.comments.filter((cur) => {
				return cur.commentsStatus === commentsFilter
			})
			setComments(filteredComments)
		}
	},[props.comments, commentsFilter]);

	const breadCrumbItems = [
        {label: 'Manage Comments'}
    ];

	const tableHeader = <div className="tableHeader">Manage Comments</div>

	const commentsStatusSelections = [
		{label: 'Pending', value: 'pending'},
		{label: 'Active', value: 'active'},
		{label: 'Inactive', value: 'inactive'}
	];

	const commentsEditTemplate = (rowData) => {
		return (
			<InputTextarea rows={5} style={{width: '100%'}} value={rowData['comments']} onChange={(e) => onEditorValueChange(rowData, 'comments', e.target.value)}  />
		)
	}

	const commentsStatusEditTemplate = (rowData) => {
		return (
			<Dropdown value={rowData['commentsStatus']} options={commentsStatusSelections} 
						onChange={(e) => onEditorValueChange(rowData, 'commentsStatus', e.value)}  
						placeholder="Select Status" 
					/>
		)
	}

	const saveTemplate = (rowData) => {
		return (
			<Button icon="pi pi-save" className="p-button p-component p-button-outlined p-button-secondary" onClick={() => fncSaveStaffStatus(rowData)} />
		)
	}

	const typeTemplate = (rowData) => {
		if (rowData.type === 'project') {
			return (
				<div className="flex flex-column">
					<div className="mb-2 strong">Project Notes</div>
				</div>
			)
		} else if (rowData.type === 'hours') {
			return (
				<div className="flex flex-column">
					<div className="mb-2 strong">Hours Comments</div>
					<div className="smallerDetails">{dayjs(rowData.dateEntered).format('MMM d, yy')}</div>
					{rowData.state === 'add' && 
						<div className="smallerDetails">+ {rowData.hours} hours</div>
					}
					{rowData.state === 'remove' && 
						<div className="smallerDetails">- {rowData.hours} hours</div>
					}
				</div>
				
			)
		} else if (rowData.type === 'staff') {
			return (
				<div className="flex flex-column">
					<div className="mb-2 strong">Staff Comments</div>
					<div className="smallerDetails">{rowData.staffName}</div>
					<div className="smallerDetails">{dayjs(rowData.dateEntered).format('MMM d, yy')}</div>
				</div>
			)
		} else if (rowData.type === 'staffHours') {
			return (
				<div className="flex flex-column">
					<div className="mb-2 strong">Staff Hours Comments</div>
					<div className="smallerDetails">{rowData.staffName}</div>
					<div className="smallerDetails">{dayjs(rowData.dateEntered).format('MMM d, yy')}</div>
					{rowData.state === 'add' && 
						<div className="smallerDetails">+ {rowData.hours} hours</div>
					}
					{rowData.state === 'remove' && 
						<div className="smallerDetails">- {rowData.hours} hours</div>
					}
				</div>
				
			)
		}
	}

	const onEditorValueChange = (rowData, field, value) => {
        let updatedComments = [...comments];
		let index = updatedComments.findIndex(curF => curF.id === rowData.id)
		updatedComments[index][field] = value;
		setComments(updatedComments)
    }

	const fncSaveStaffStatus = (rowData) => {
		
		props.updateComments({
			id: rowData.id,
			comments:rowData.comments,
			commentsStatus: rowData.commentsStatus
		}).then((results) => {
			toast.current.show({severity:'success', summary: 'Success', detail: 'Agency Message was updated.', life: 3000});
		})
	}
	

	 return (
		<div className="grid">
			<div className="col-12">
				<Toast ref={toast} />
				<BreadCrumbs items={breadCrumbItems} />
				<div className="card">
					
					<div className="flex justify-content-end">
						<div className="mb-2">
							<SelectButton value={commentsFilter} options={commentsStatusSelections} onChange={(e) => setCommentsFilter(e.value)}></SelectButton>
						</div>
					</div>
					
					<DataTable value={comments} header={tableHeader} className="p-datatable-products"
						paginator rows={10} rowsPerPageOptions={[10,20,50]} paginatorPosition="both"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
						<Column field="clientName" filter={true} header="Client" style={{verticalAlign:'top',width:'10%'}} sortable ></Column>
						<Column field="brandName" filter filterMatchMode="contains"  header="Brands" style={{verticalAlign:'top',width:'10%'}} sortable></Column>
						<Column field="agencyName"  filter filterMatchMode="contains" header="Agency" style={{verticalAlign:'top',width:'10%'}} sortable></Column>
						<Column field="projectName"  header="Project" style={{verticalAlign:'top',width:'10%'}} sortable></Column>
						<Column field="type" header="Type" filter filterMatchMode="contains" body={typeTemplate} style={{verticalAlign:'top',width:'10%'}} sortable></Column>
						<Column field="comments" filter filterMatchMode="contains" body={commentsEditTemplate} style={{verticalAlign:'top',width:'20%'}} header="Comments"></Column>
						<Column field="commentStatus" body={commentsStatusEditTemplate} style={{verticalAlign:'top',width:'15%'}} header="Status"></Column>
						<Column field="" body={saveTemplate} style={{verticalAlign:'top',width:'5%'}} header=""></Column>
					</DataTable>
				</div>
			</div>
		</div>
    );
}

export default ManageComments;