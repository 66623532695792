 const fn = (state = {}, action) => {
	switch (action.type) {
		case 'SET_FROM_DATE':
			return {
				...state,
				fromDate: action.payload
			};
		case 'SET_TO_DATE':
			return {
				...state,
				toDate: action.payload
			};
		case 'SET_DATE_TYPE':
			return {
				...state,
				dateType: action.payload
			};
		case 'SET_CURRENT_CLIENTID':
			return {
				...state,
				currentClientID: action.payload
			};
		default:
			return state
	}
}

export default fn