import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Chart as ChartJS, PieController, ArcElement, Tooltip, Legend} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { STAFF_COLOR_ARRAY, STAFF_LIGHT_COLOR } from '../../constants/colors'

import { getStaffByAudience, getFullStaffByAudience, getAudiences } from '../../selectors/dashboardWidgetsSelector'

import { fncFormatPercentage, percentTemplate, isTrue } from '../../utilities/CommonSnippets'

ChartJS.register(ChartDataLabels);
ChartJS.register(PieController)
ChartJS.register(ArcElement)
ChartJS.register(Legend)
ChartJS.register(Tooltip)
ChartJS.defaults.font.size = 14;

const SpendByAudienceStaff = (props) => {
	const { staffAudienceBreakdown, fullStaffByAudience, audienceList, loading, dates } = props

	const colorOrder = {
		0: STAFF_COLOR_ARRAY[3],
		1: STAFF_COLOR_ARRAY[4],
		2: STAFF_COLOR_ARRAY[5],
		3: STAFF_COLOR_ARRAY[6],
		4: STAFF_COLOR_ARRAY[2],
		5: STAFF_COLOR_ARRAY[1],
		6: STAFF_COLOR_ARRAY[0]
	}
	

	useEffect(() => {
		if (!loading) {
			
			const chartData = {
				labels: staffAudienceBreakdown.map(cur => cur.audienceName),
				datasets: [
					{
						label: 'Dataset 1',
						data: staffAudienceBreakdown.map(cur => cur.percentSpend),
						backgroundColor:  staffAudienceBreakdown.map((cur,index) => {
							let curMod = index % 7
							return colorOrder[curMod]
							
						}),
						hoverBackgroundColor: staffAudienceBreakdown.map((cur,index) => {
							let curMod = index % 7
							return colorOrder[curMod]
							
						}),
					}]
			};
		
			const chartOptions = {
			   responsive: true,
				plugins: {
					// Change options for ALL labels of THIS CHART
					datalabels: {
						anchor: 'middle',
						align: 'end',
						formatter: function(value, context) {
							if (value > 0) return value + '%'
							else return null
						}
					},
					legend: {
						position: 'bottom',
						labels: {
							filter: (legendItem, data) => {
								const qtd = data.datasets[0].data[legendItem.index]
								legendItem.text = `${legendItem.text} : ${qtd}%` 
								return true
							}
						}
					  },
				  }
			};
			
			var myChart = new ChartJS(document.getElementById('spendByAudienceStaff'), {
				type: "pie",
				data: chartData ,
				options: chartOptions
			});

			// when component unmounts
			return () => {
				myChart.destroy()
			}
		}
	}, [loading, dates, staffAudienceBreakdown])

	const getTotals = (field, type) => {
        let total = 0;
		
        if (fullStaffByAudience) {
			for(let curAudience of fullStaffByAudience) {
				
				
				if (curAudience[field]) {
					if (type === 'all') total += curAudience[field];
					if (type === 'senior' && curAudience.isSenior) total += curAudience[field];
					if (type === 'others' && !curAudience.isSenior) total += curAudience[field];
				}
			}
		}

        return total.toLocaleString('en-US');
    }

	const getTotalAverage = (field1, field2, type) => {
        let totalScoped = 0;
		let totalUsed = 0;
        if (fullStaffByAudience) {
			for(let curTotal of fullStaffByAudience) {
				if (type === 'all') {
					if (curTotal[field1]) totalScoped += curTotal[field1];
					if (curTotal[field2]) totalUsed += curTotal[field2];
				}
				if (type === 'senior' && curTotal.isSenior) {
					if (curTotal[field1]) totalScoped += curTotal[field1];
					if (curTotal[field2]) totalUsed += curTotal[field2];
				}
				if (type === 'others' && !curTotal.isSenior) {
					if (curTotal[field1]) totalScoped += curTotal[field1];
					if (curTotal[field2]) totalUsed += curTotal[field2];
				}
			}
		}

        return fncFormatPercentage(totalUsed / totalScoped,1) + '%';
    }
	
	let intTotalColumns = (audienceList.length * 3) + 6
	let footerGroup = <ColumnGroup>
		<Row >
			<Column className="whiteTableFooter talentWidth" footer="Senior Staff Sub-Total" footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			
			{audienceList.map((cur) => {
					return [
						<Column footer={getTotals(cur + '_scoped','senior')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
						<Column footer={getTotals(cur + '_utilized','senior')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
						<Column footer={getTotalAverage(cur + '_scoped', cur + '_utilized', 'senior')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
					]
				})}
			<Column footer={getTotals('total_scoped','senior')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			<Column footer={getTotals('total_utilized','senior')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			<Column footer={getTotalAverage('total_scoped', 'total_utilized','senior')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
		</Row>

		<Row >
			<Column className="whiteTableFooter talentWidth" footer="All Other Sub-Total" footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />
			
			{audienceList.map((cur) => {
					return [
						<Column footer={getTotals(cur + '_scoped','others')} footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />,
						<Column footer={getTotals(cur + '_utilized','others')} footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />,
						<Column footer={getTotalAverage(cur + '_scoped', cur + '_utilized', 'others')}  footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />,
					]
				})}
			<Column footer={getTotals('total_scoped','others')} footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />
			<Column footer={getTotals('total_utilized','others')} footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />
			<Column footer={getTotalAverage('total_scoped', 'total_utilized','others')} footerStyle={{textAlign: 'right', background: 'white', fontWeight: '700'}} />
		</Row>
	
		<Row >
			<Column className="whiteTableFooter talentWidth" footer="Total" footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			
			{audienceList.map((cur) => {
					return [
						<Column footer={getTotals(cur + '_scoped','all')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
						<Column footer={getTotals(cur + '_utilized','all')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
						<Column footer={getTotalAverage(cur + '_scoped', cur + '_utilized', 'all')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />,
					]
				})}
			<Column footer={getTotals('total_scoped','all')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			<Column footer={getTotals('total_utilized','all')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
			<Column footer={getTotalAverage('total_scoped', 'total_utilized', 'all')} footerStyle={{color: 'white',textAlign: 'right', background: STAFF_LIGHT_COLOR, fontWeight: '700'}} />
		</Row>
		</ColumnGroup>;

	
	
	let headerGroup = <ColumnGroup>
			<Row>
				<Column header="" className="talentWidth" />
				{audienceList.map((cur) => {
					return <Column key={cur} header={cur} colSpan={3} style={{textAlign:'center'}} />
				})}
				<Column header="Totals" colSpan={3} style={{textAlign:'center'}} />
			</Row>
			<Row>
				<Column  header="Talent" className="talentWidth" style={{textAlign:'center'}}/>
				{audienceList.map((cur) => {
					return [
						<Column field={cur}  header="Scoped" style={{textAlign:'right'}}/>,
						<Column field={cur}  header="Utilized" style={{textAlign:'right'}}/>,
						<Column field={cur}  header="Percent" style={{textAlign:'right'}}/>,
					]
				})}
				<Column field="total_scoped"  header="Scoped" style={{textAlign:'right'}}/>
				<Column field="total_utilized"  header="Utilized" style={{textAlign:'right'}}/>
				<Column field="total_percent"  header="Percent" style={{textAlign:'right'}}/>
			</Row>
			
		</ColumnGroup>;

	let talentTemplate = (rowData) => {
		return (
			<div className="flex flex-column">
				<div className="mb-2 flex justify-content-between" >
					<div>
						{rowData.name}
					</div>
					{isTrue(rowData.isSenior) && 
						<div style={{fontWeight: '600', fontSize: '12px'}}>
							Senior
						</div>
					}
					
				</div>
				<div style={{fontWeight: '600'}}>
					{rowData.title}
				</div>
				<div style={{fontWeight: '600'}}>
					{rowData.functionTitle}
				</div>
				
			</div>
		)
	}
	
	return (
		<div className="flex mb-3 fullWidget ">
			<div className="pr-2 align-self-stretch thirdWidget">
				{!loading && 
					<div className="p-chart artCharts-doughnutHalf">
						<canvas id="spendByAudienceStaff"></canvas>
					</div>
				}
			</div>
			<div className="align-self-stretch twoThirdWidget staff">
				<div style={{color: 'white',paddingTop: '15px', textAlign: 'center', height:'50px',background: STAFF_LIGHT_COLOR, fontWeight: '700'}}>
					Total Across All Audiences
				</div>
				<DataTable className="tables" value={fullStaffByAudience} headerColumnGroup={headerGroup} footerColumnGroup={footerGroup}>
					<Column field="name" className="talentWidth" header="Name"  body={talentTemplate} />
					{audienceList.map((cur) =>{
						return [
							<Column field={cur + '_scoped'}  header="Scoped" style={{textAlign:'right'}}/>,
							<Column field={cur + '_utilized'}  header="Utilized" style={{textAlign:'right'}}/>,
							<Column field={cur+ '_percent'} body={percentTemplate} header="Percent" style={{textAlign:'right'}}/>,
						]
					})}
					<Column field='total_scoped'  header="Scoped" style={{textAlign:'right'}}/>
					<Column field='total_utilized'  header="Utilized" style={{textAlign:'right'}}/>
					<Column field='total_percent' body={percentTemplate} header="Percent" style={{textAlign:'right'}}/>
				</DataTable>
			</div>
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		staffAudienceBreakdown: getStaffByAudience(state, ownProps),
		fullStaffByAudience: getFullStaffByAudience(state, ownProps),
		audienceList: getAudiences(state, ownProps),
	}
}

const SpendByAudienceStaffContainer = connect(mapStateToProps, null)(SpendByAudienceStaff);
export default SpendByAudienceStaffContainer;