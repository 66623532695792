import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { booleanTemplate } from '../utilities/CommonSnippets'

const defaultTemplate = (rowData,column) => {
	return (
		<div className="flex flex-column infoBody">
			<div className="strong">
				{rowData[column.field]}
			</div>
		</div>
	)
}

const nameTemplate = (rowData) => {
	return (
		<div className="flex flex-column infoBody">
			<div className="strong">
				{rowData.staffName}
			</div>
		</div>
	)
}

const dateTemplate = (rowData) => {
	return (
		<div className="flex flex-column infoBody">
			<div className="strong">
				{rowData.timeStamp}
			</div>
		</div>
	)
}

const statusTemplate = (rowData) => {
	return (
		<div className="flex flex-column infoBody">
			<div style={{textTransform:'capitalize'}}>
				{rowData.status}
			</div>
		</div>
	)
}

const logTypeTemplate = (rowData) => {
	
	let strType = null
	switch (rowData.logType) {
		case 'addStaff': 
			strType = 'Add'
			break
		case 'editStaff': 
			strType = 'Edit'
			break
		case 'updateStatus': 
			strType = 'Status Update'
			break
		case 'deleteStaff': 
			strType = 'Delete'
			break
		default:
			strType = ''
	}



	return (
		<div className="flex flex-column infoBody">
			<div>
				{strType}
			</div>
		</div>
	)
}



export const CURRENT_YEAR = {
	heading: 'More Info - Current Year',
	body: <div className="flex flex-column">
		<div className="infoTitle">
			Current Fiscal Year Budget
		</div>
		<div className="infoBody">
			This is the budget that was set for all <strong>active</strong> projects for the current fiscal year. If a project extends in to multiple fiscal years, the budget is pro-rated to account for the current fiscal year. 
			For example, if a $100,000 project has a start date of July 1st and and an end date of June 30th the following year, $50,000 would be applied for the current year. 
		</div>
	</div>
}

export const SPEND_YTD = {
	heading: 'More Info - Spend Fiscal YTD',
	body: <div className="flex flex-column">
		<div className="infoTitle">
			Amount Spent Fiscal YTD
		</div>
		<div className="infoBody">
			This is the amount of money spent on <strong>active</strong> projects for the current fiscal year.
		</div>
	</div>
}

export const TOTAL_HOURS_USED = {
	heading: 'More Info - Total Hours Used',
	body: <div className="flex flex-column">
		<div className="infoTitle">
			Total Hours Used
		</div>
		<div className="infoBody">
			Based on the date picker, how many hours have been used in that range. 
		</div>
		
	</div>
}

export const ACTUAL_VS_ESTIMATED_HOURS = {
	heading: 'More Info - Actual v. Estimated Hours',
	body: <div className="flex flex-column">
		<div className="infoBody">
			This takes the actual number of hours, based on the date picker, then the estimated hours based on the data picker, using the ratio.  
		</div>
		<div className="infoBody">
			So for example, if the total hours for the projects is 100 hours, each day should use .273 hours.  So January would use 8.4 hours, but I round, so it 
			would show 8 hours.  
		</div>
	</div>
}

/*
export const PROGRESS_DEPARTMENT_HOURS = {
	heading: 'More Info - Department Progress Meter',
	body: <div className="flex flex-column">
		<div className="infoBody">
			This is all about the ratio of the hours used, based on the date picker, compared to the hours that should have been used.  
			The ratio are compared and if the percentage is within 10%, will show green, 10%-20% and will show orange, above 20%, it will show red.
		</div>
	</div>
}
*/

export const PROGRESS_DEPARTMENT_HOURS = {
	heading: 'More Info - Department Progress Meter',
	body: <div className="flex flex-column">
		<div className="infoBody">
			This is all about the ratio of the hours used and the time used.  For example, if you have used 50% of the hours and in only 10% of the time, this will 
			show as Off Track
		</div>
	</div>
}


export const ACTUAL_VS_ESTIMATED_HOURS_SPECIFIC = (props) => {
	return {
		heading: 'More Info - Department Progress Meter',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				{props.functionTitle}
			</div>
			<div className="infoTitle mt-4">
				Calculations
			</div>
			<div className="grid grid-nogutter infoBodyTable">
				<div className="col-5">
					Total Days for All Projects: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInProjects}
				</div>
				<div className="col-5">
					Total Days in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInPicker}
				</div>
				<div className="col-5">
					Picker Ratio: 
				</div>
				<div className="col-5">
					{props.budgetDetails.pickerRatio.toFixed(4)}
				</div>
				<div className="col-5">
					Hours Budgeted: 
				</div>
				<div className="col-5">
					{props.curDepartmentTotals.budgetHours}
				</div>
				<div className="col-5">
					Estimated Hours Budgeted: 
				</div>
				<div className="col-5 strong">
					{props.curDepartmentDetails.budgetHours.toFixed(4)}
				</div>
				<div className="col-5">
					Hours Used: 
				</div>
				<div className="col-5 strong">
					{props.curDepartmentDetails.hours}
				</div>
			
				<div className="col-5">
					Variance Level: 
				</div>
				<div className="col-5 strong">
					{props.curDepartmentDetails.varianceLevel} <span className="infoBodyQuestion">Using Variance level from Progress Column</span>
				</div>

				
			</div>
		</div>
	}
}


export const PROGRESS_DEPARTMENT_HOURS_SPECIFIC = (props) => {
	return {
		heading: 'More Info - Department Progress Meter',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				{props.functionTitle}
			</div>
			<div className="infoTitle mt-4">
				Calculations
			</div>
			<div className="grid grid-nogutter infoBodyTable">
				<div className="col-5">
					Total Days for All Projects: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInProjects}
				</div>
				<div className="col-5">
					Total Days in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInPicker}
				</div>
				<div className="col-5">
					Time Used Percentage: 
				</div>
				<div className="col-5">
					{props.curDepartmentDetails.timePercentage.toFixed(4)} <span className="infoBodyQuestion">({props.budgetDetails.totalDaysInPicker} / {props.budgetDetails.totalDaysInProjects}) * 100</span>
				</div>
				<div className="col-5">
					Hours Budgeted: 
				</div>
				<div className="col-5">
					{props.curDepartmentTotals.budgetHours}
				</div>
				<div className="col-5">
					Hours Used: 
				</div>
				<div className="col-5">
					{props.curDepartmentDetails.hours}
				</div>
				<div className="col-5">
					Hours Used Percentage: 
				</div>
				<div className="col-5">
					{props.curDepartmentDetails.percentHoursUsed.toFixed(4)} <span className="infoBodyQuestion">({props.curDepartmentDetails.hours} / {props.curDepartmentTotals.budgetHours}) * 100</span>
				</div>
				<div className="col-5">
					Variance: 
				</div>
				<div className="col-5">
					{props.curDepartmentDetails.variance.toFixed(4)} <span className="infoBodyQuestion"> Absolute Value of ({props.curDepartmentDetails.timePercentage.toFixed(4)} - {props.curDepartmentDetails.percentHoursUsed.toFixed(4)}).</span>
				</div>
				<div className="col-5">
					Variance Level: 
				</div>
				<div className="col-5 strong">
					{props.curDepartmentDetails.varianceLevel}
				</div>

				
			</div>
		</div>
	}
}

export const AVERAGE_MONTHLY_HOURS = (props) => {
	return {
		heading: 'More Info - Average Monthly Hours',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				Average Monthly Hours
			</div>
			<div className="infoBody">
				This takes the total number of hours used, based on the date picker, divides by the number of days in the date picker, then multiplies by 30.  So for example, if I use 150 hours for Jan/Feb, I would 
				take the (150 / 59) * 30 and round to get 76 average monthly hours.  This looks a bit off when looking at January, since total hours Used might be slight different since there are 31 days in January.  
			</div>
			<div className="infoTitle mt-4">
				Calculations
			</div>
			<div className="grid grid-nogutter infoBodyTable">
				<div className="col-5">
					Billed Hours Per Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.datePicker.billedHours}
				</div>
				<div className="col-5">
					Total Days in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInPicker}
				</div>
				<div className="col-5">
					Average Hour Per Day: 
				</div>
				<div className="col-5">
					{(props.budgetDetails.datePicker.billedHours / props.budgetDetails.totalDaysInPicker).toFixed(4)}
				</div>
				<div className="col-5">
					Average Hours For 30 Days (month): 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.averageHoursPerMonth}
				</div>
			</div>
		</div>
	}
}

export const TOTAL_BILLED_USED = (props) => {
	return {
		heading: 'More Info - Total Allocated',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				Description
			</div>
			<div className="infoBody">
				This pie chart will combine the Total Hours Used with the total Allocated.  It is showing the percent of hours used based on what you should have used for the given time period. 
			</div>
			<div className="infoBodyQuestion">
				This one is odd, the View Details button goes to a different page completely and deals with Staff Hours and not Project Hours.
			</div>
			<div className="infoTitle mt-4">
				Calculations
			</div>
			<div className="grid grid-nogutter infoBodyTable">
				<div className="col-5">
					Total Days for All Projects: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInProjects}
				</div>
				<div className="col-5">
					Total Days in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInPicker}
				</div>
				<div className="col-5">
					Picker Ratio: 
				</div>
				<div className="col-5">
					{props.budgetDetails.pickerRatio.toFixed(4)}
				</div>
				<div className="col-5">
					Total Budget Hours: 
				</div>
				<div className="col-5">
					{props.budgetDetails.budgetHours} <span className="infoBodyQuestion"> - Sum of all department hours.</span>
				</div>
				<div className="col-5">
					Total Allocated: 
				</div>
				<div className="col-5">
					{props.budgetDetails.datePicker.budgetHours.toFixed(4)}
				</div>
				<div className="col-5">
					Billed Hours: 
				</div>
				<div className="col-5">
					{props.budgetDetails.datePicker.billedHours}
				</div>
				<div className="col-5">
					Percent of hours used: 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.totalHoursBilledPercentage}
				</div>
			</div>
		</div>
	}
}

export const TOTAL_HOURS_ALLOCATED = (props) => {
	return {
		heading: 'More Info - Total Allocated',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				Description
			</div>
			<div className="infoBody">
				This takes the total number of hours assigned to departments/functions and then multiplies it by the ratio of the date picker.  For example, if you have 1900 total hours and are looking at January, 
				it would be 31 (days in January)  divided by 365 (project length) to get the ratio (0.0849) times 1900 and then rouded to get 161.  161 hours should have been used in January.
			</div>
			<div className="infoTitle mt-4">
				Calculations
			</div>
			<div className="grid grid-nogutter infoBodyTable">
				<div className="col-5">
					Total Days for All Projects: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInProjects}
				</div>
				<div className="col-5">
					Total Days in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInPicker}
				</div>
				<div className="col-5">
					Picker Ratio: 
				</div>
				<div className="col-5">
					{props.budgetDetails.pickerRatio.toFixed(4)}
				</div>
				<div className="col-5">
					Total Budget Hours: 
				</div>
				<div className="col-5">
					{props.budgetDetails.budgetHours} <span className="infoBodyQuestion"> - Sum of all department hours.</span>
				</div>
				<div className="col-5">
					Total Allocated: 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.budgetHours.toFixed(4)}
				</div>
			</div>
		</div>
	}
}

export const MONTHLY_BURNRATE = (props) => {
	return {
		heading: 'More Info - Monthly Burn Rate',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				Current
			</div>
			<div className="infoBody">
				Get the total cost that was spent so far, divide by the number of days in the datepicker, multiple by 30.  So for example, if I have spent $7500 in 
				January / Feb, I would take (7500 / 59) * 30

			</div>
			<div className="infoTitle mt-4">
				Estimated
			</div>
			<div className="infoBody">
				Total Budget / total number of days in the range for all active projects, multiple by 30.  This would could change if new projects come in as you change the date
				picker. Looks slightly off when looking at January, since 31 days vs 30 days
			</div>
			<div className="infoTitle mt-4">
				Calculations
			</div>
			<div className="grid grid-nogutter infoBodyTable">
				<div className="col-5">
					Total Spent in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.datePicker.used}
				</div>
				<div className="col-5">
					Total Days in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInPicker}
				</div>
				<div className="col-5">
					Average Spent Per Day: 
				</div>
				<div className="col-5">
					{props.budgetDetails.datePicker.datePickerAvgCostPerDay.toFixed(4)}
				</div>
				<div className="col-5">
					Average Spent For 30 Days (month): 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.monthlyBurnRate}
				</div>
				<div className="col-5">
					Total Project Budget: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalBaselineBudget}
				</div>
				<div className="col-5">
					Total days for all projects: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInProjects}
				</div>
				<div className="col-5">
					Average Budget Per Day: 
				</div>
				<div className="col-5">
					{props.budgetDetails.avgBaselineCostPerDay.toFixed(4)}
				</div>
				<div className="col-5">
					Average Budget For 30 Days (month): 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.monthlyBurnRateEstimated}
				</div>
				<div className="col-5">
					Spent / Budget For 30 Days: 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.monthlyBurnRatePercent}%
				</div>
			</div>
			
		</div>
	}
}

export const PROJECT_BREAKDOWN = (props) => {
	
	return {
		heading: 'More Info - Project Breakdown',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				Description
			</div>
			<div className="infoBody">
				This is a breakdown of which projects are included in the totals based on status and the date picker values. 
			</div>
			<div className="infoBodyQuestion">
				Only Active projects will be used for calculations, no other status will get added in to any budgets.
			</div>
			
			<div className="infoTitle mt-4">
				Projects
			</div>
			<div className="grid grid-nogutter infoBodyTable">
				<DataTable value={props.budgetDetails.projects} className="p-datatable-products" paginator
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}
					paginatorPosition="both"  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				>
					<Column field="projectName" body={defaultTemplate} sortable header="Name" style={{verticalAlign:'top', width:'20%'}}></Column>
					<Column field="projectDateRange" header="Dates" body={defaultTemplate} sortable style={{verticalAlign:'top', width:'25%'}}></Column>
					<Column field="status" header="Status" body={statusTemplate} sortable style={{verticalAlign:'top', width:'12%'}}></Column>
					<Column field="totalProjectBudget" body={defaultTemplate} sortable header="Budget" style={{textAlign:'right',verticalAlign:'top', width:'15%'}}></Column>
					<Column field="isInDatepicker" body={booleanTemplate} sortable header="Picker" style={{textAlign:'right',verticalAlign:'top', width:'14%'}}></Column>
					<Column field="isInCurrentYear" body={booleanTemplate} sortable header="Current" style={{textAlign:'right',verticalAlign:'top', width:'14%'}}></Column>
				</DataTable>
				
			</div>
		</div>
	}
}

export const TOTAL_BASELINE_BUDGET = (props) => {
	
	return {
		heading: 'More Info - Total Baseline Budget',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				Description
			</div>
			<div className="infoBody">
				This is the estimated budget for all <strong>active</strong> projects for the brand using the Date Picker.
			</div>
			<div className="infoBody mt-2">
				For Example, if all the active projects have a start date of Jan 1st and an end date of Dec 31st and total $100,000 each day would have a budget of $273.97. 
				If you are looking at 31 days, you would have an estimated budget of $8,493. 
			</div>
			<div className="infoTitle mt-4">
				Calculations
			</div>
			<div className="grid grid-nogutter infoBodyTable">
				<div className="col-5">
					Total Days for All Projects: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInProjects}
				</div>
				<div className="col-5">
					Total Days in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalDaysInPicker}
				</div>
				<div className="col-5">
					Picker Ratio: 
				</div>
				<div className="col-5">
					{props.budgetDetails.pickerRatio.toFixed(4)}
				</div>
				<div className="col-5">
					Total Project Budget: 
				</div>
				<div className="col-5">
					{props.budgetDetails.totalBaselineBudget}
				</div>
				<div className="col-5">
					Total Baseline Budget: 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.budget}
				</div>
			</div>
		</div>
	}
}

export const REMAINING = (props) => {
	return {
		heading: 'More Info - Monthly Burn Rate',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				Current
			</div>
			<div className="infoBodyQuestion">
				This added to the Total Baseline Utilized, should equal the total baseline budget.  It's just the compete opposite of Total Baselie Utilized, maybe they can be show together?

			</div>
			<div className="infoTitle mt-4">
				Estimated
			</div>
			<div className="infoBodyQuestion">
				This would always be 0? If I am doing exactly what I am supposed to do per day, then I would never have any remaining? I don't think we should show this.
			</div>
			<div className="infoTitle mt-4">
				Calculations
			</div>
			<div className="grid grid-nogutter infoBodyTable">
			
				<div className="col-5">
					Total Budget in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.datePicker.budget}
				</div>
				<div className="col-5">
					Total Spent in Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.datePicker.used}
				</div>
				<div className="col-5">
					Total remainingBudget in Date Picker: 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.totalBaselineRemaining}
				</div>
				<div className="col-5">
					Percentage Used per Date Picker: 
				</div>
				<div className="col-5">
					{props.budgetDetails.datePicker.totalBaselineUtilizedPercent}%
				</div>
				<div className="col-5">
					Percentage Remaining per Date Picker: 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.totalBaselineRemainingPercent}%
				</div>
			</div>
			
		</div>
	}
}

export const TOTAL_BASELINE_UTILIZED = (props) => {
	return {
		heading: 'More Info - Total Baseline Utilized',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				Current Description
			</div>
			<div className="infoBody">
				This is the total used, based on the date picker.  The percentage is the percentage that you have used based on what you should have used. 
			</div>
			<div className="infoTitle mt-4">
				Estimated Description
			</div>
			<div className="infoBodyQuestion">
				Wouldn't this value be the same as what we have in Total Baseline Budget? If I am using the dates from the date picker, the estimated would ALWAYS be 100%? Not sure what this is showing.
			</div>
			<div className="infoTitle mt-4">
				Calculations
			</div>
			<div className="grid grid-nogutter infoBodyTable">
			
				<div className="col-5">
					Total Budget in Date Picker: 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.budget}
				</div>
				<div className="col-5">
					Total Used in Date Picker: 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.used}
				</div>
				<div className="col-5">
					Percentage Used per Date Picker: 
				</div>
				<div className="col-5 strong">
					{props.budgetDetails.datePicker.totalBaselineUtilizedPercent}%
				</div>
			</div>
		</div>
	}
}

export const STAFFING_CHANGES = (props) => {
	return {
		heading: 'More Info - Staffing Changes',
		body: <div className="flex flex-column">
			<div className="infoTitle">
				Description
			</div>
			<div className="infoBody">
				These are all the added, deleted staff as well as any changes to the staff status during the time period in the date picker.
			</div>
			<div className="infoBodyQuestion mt-4">
				I think this widget needs reworked some, kind of odd nad not even sure what it is supposed to show. The button isn't even clickable or doesn't do anything.
			</div>
			<div className="infoBodyQuestion mt-2">	
				There were some bugs with the data before, but I made some changes in the code. Old changes will not be recorded since I was doing a hard delete on staff, which means I removed it completely, so even though I log that a delete happened, I donÕt know who was deleted. This is now fixed.
			</div>
			<div className="infoBodyQuestion mt-2">	
				Also, every time you made a change to the staff, it would save a new record, but you wouldnÕt know the reason. This, too, has been addressed.
			</div>
		</div>
	}
}

export const STAFFING_BREAKDOWN = (props) => {
	return {
		heading: 'More Info - Staffing Breakdown Table',
		body: <div className="flex flex-column">
			
			<div className="infoTitle mt-4">
				Staff Changes
			</div>
			<div className="grid grid-nogutter infoBodyTable">
				<DataTable value={props.fullStatusLogs} className="p-datatable-products" paginator
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}
					paginatorPosition="both"  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				>
					<Column field="timeStamp" header="Date" body={dateTemplate} sortable style={{verticalAlign:'top', width:'25%'}}></Column>
					<Column field="staffName" body={nameTemplate} sortable header="Name" style={{verticalAlign:'top', width:'30%'}}></Column>
					<Column field="functionTitle" body={defaultTemplate} sortable header="Department" style={{verticalAlign:'top', width:'30%'}}></Column>
					<Column field="status" header="Status" body={statusTemplate} style={{verticalAlign:'top', width:'15%'}}></Column>
					<Column field="logType" header="Type" body={logTypeTemplate} style={{verticalAlign:'top', width:'15%'}}></Column>
				</DataTable>
				
			</div>
		</div>
	}
}

export const NEW_BASELINE_BUDGET = {
	heading: 'Baseline Budget',
	body: <div className="flex flex-column budgetHelp">
		<div className="infoBody">
            Use the date selector to choose the date range for the Baseline Budget Data. This range is used to calculate the values:
               <ul>
                    <li><span className="emphasisColor">Budget</span> - The pro-rated budget for all projects except <span className="strong">canceled</span> for the date range, calculated assuming the total project budget is evenly divided over the entire length of the project (for example, if the total budget is <span className="strong">120,000</span> for a project lasting <span className="strong">12</span> months, each month is assumed to contribute <span className="strong">10,000</span> toward the overall budget; thus, a date range spanning <span className="strong">4</span> months will show <span className="strong">40,000</span> as the pro-rated budget)</li>
                    <li><span className="emphasisColor">Budget Used</span> - The total budget used for all projects except <span className="strong">canceled</span> for the selected date range</li>
					<li><span className="emphasisColor">Annual Budget</span> - The total budget used for all projects except <span className="strong">canceled</span> for the current calendar year</li>
               </ul>
		</div>
	</div>
}

export const NEW_STAFF_BASELINE = {
	heading: 'Staffing Baseline',
	body: <div className="flex flex-column staffHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Staffing Baseline Hours. This range is used to calculate the values:
               <ul>
                    <li><span className="emphasisColor">Budget</span> - The total budgeted staff hours for the entire project</li>
                    <li><span className="emphasisColor">Budget Used</span> - The total staff hours used in the selected date range</li>
					<li><span className="emphasisColor">Annual Budget</span> - The total staff hours used for all projects except <span className="strong">canceled</span> for the current calendar year</li>
               </ul>
		</div>
	</div>
}

export const NEW_OOP_COSTS = {
	heading: 'Out-of-Pocket Costs',
	body: <div className="flex flex-column oopHelp">
		<div className="infoBody">
            Use the date selector to choose the date range for the Out-of-Pocket Costs. This range is used to calculate the values:
               <ul>
                    <li><span className="emphasisColor">Budget</span> - The pro-rated Out-of-Pocket budget for all projects except <span className="strong">canceled</span> for the date range, calculated assuming the total Out-of-Pocket budget is evenly divided over the entire length of the project (for example, if the total Out-of-Pocket budget is <span className="strong">120,000</span> for a project lasting <span className="strong">12</span> months, each month is assumed to contribute <span className="strong">10,000</span> toward the overall budget; thus, a date range spanning <span className="strong">4</span> months will show <span className="strong">40,000</span> as the pro-rated Out-of-Pocket budget)</li>
                    <li><span className="emphasisColor">Budget Used</span> - The total Out-of-Pocket budget used for all projects except <span className="strong">canceled</span> for the selected date range</li>
					<li><span className="emphasisColor">Annual Budget</span> - The total Out-of-Pocket budget used for all projects except <span className="strong">canceled</span> for the current calendar year</li>
			   </ul>
		</div>
	</div>
}

export const NEW_PROJECT_SPEND_SUMMARY = {
	heading: 'Project Spend Summary',
	body: <div className="flex flex-column budgetHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Project Spend Summary. This range is used to calculate the values:
			<ul>
				<li><span className="emphasisColor">Project Status</span> - Projects are grouped by status: Pending, Active, Not Started, On Hold, Canceled, and Completed</li>
				<li><span className="emphasisColor">Budget</span> - The prorated sum for all budgets with the given status</li>
				<li><span className="emphasisColor">Used</span> - The sum for all budgets used with the given status</li>
			</ul>
			Clicking on a status will bring up a modal with the breakdown information for that status.
		</div>
	</div>
}

export const NEW_MONTHLY_BURN_RATE= {
	heading: 'Monthly Burn Rate vs. Forecasted Spend',
	body: <div className="flex flex-column budgetHelp">
		<div className="infoBody">
			This widget is displaying the breakdown budget per month for all projects except <span className="strong">canceled</span>. 
			<ul>
				<li><span className="emphasisColor">Budget</span> - The pro-rated budget for all projects except <span className="strong">canceled</span> for the given month calculated assuming the total project budget is evenly divided over the entire length of the project (for example, if the total budget is <span className="strong">120,000</span> for a project lasting <span className="strong">6</span> months, each month is assumed to contribute <span className="strong">20,000</span> toward the overall budget and only those 6 months would include this budget)</li>
				<li><span className="emphasisColor">Actual</span> - The total budget used for all projects except <span className="strong">canceled</span> for the given month</li>
			</ul>
		</div>
	</div>
}

export const NEW_SPEND_BY_AUDIENCE = {
	heading: 'Spend By Audience',
	body: <div className="flex flex-column budgetHelp">
		<div className="infoBody">
			This widget is displaying the breakdown budget per audience for all projects except <span className="strong">canceled</span>. This widget will only display on the dashboard when there are more than one audience.
			<ul>
				<li><span className="emphasisColor">Audience</span> - Projects can be broken down by audience within certain brands. This will group by audience regardless of the brand.</li>
				<li><span className="emphasisColor">Budget</span> - The total budget for all projects except <span className="strong">canceled</span> for the current year</li>
				<li><span className="emphasisColor">YTD Budget</span> - The pro-rated budget for all projects except <span className="strong">canceled</span> up to the end of the previous month</li>
				<li><span className="emphasisColor">YTD Spend</span> - The used budget for all projects except <span className="strong">canceled</span> up to the end of the previous month</li>
				<li><span className="emphasisColor">Difference (YTD Budget - YTD Spend)</span> - Displaying how much of the pro-rated budget has been used for the given audience</li>
			</ul>
		</div>
	</div>
}

export const NEW_RESOURCE_MIX = {
	heading: 'Resource Mix',
	body: <div className="flex flex-column budgetHelp">
		<div className="infoBody">
			This widget is displaying the breakdown budget per department role for all projects except <span className="strong">canceled</span>. This widget is only using data from the <span className="strong">start of the year</span> to the <span className="strong">end of the previous month</span>. 
			<ul>
				<li><span className="emphasisColor">Role</span> - Project baselines are broken down by departments and these are the combined values for all projects</li>
				<li>
					<span className="emphasisColor">Status</span> - This uses the <span className="strong">used hours</span> and the <span className="strong">pro-rated budgeted hours</span> for projects up to the previous month
					<ul>
						<li><span className="green strong">As Planned</span> - Used hours are within 10% of the expected budget hours, above or below expected</li>
						<li><span className="orange strong">As Planned</span> - Used hours are between 11% - 20% of the expected budget hours, above or below expected</li>
						<li><span className="red strong">Needs Review</span> - Used hours are more than 20% of the expected budget hours, above or below expected</li>
					</ul>
				</li>
				<li>
					<span className="emphasisColor">Progress</span> - Using the same data as the status, this shows a bar to indicate the actual percentage used
				</li>
			</ul>
		</div>
	</div>
}

export const NEW_DEPARTMENTAL_HOURS = {
	heading: 'Department Hours',
	body: <div className="flex flex-column budgetHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Department Hours. This widget is displaying the breakdown of hours per department for all projects except <span className="strong">canceled</span>. This range is used to calculate the values:
			<ul>
				<li><span className="emphasisColor">Budget</span> - The pro-rated sum for all hours with the given department</li>
				<li><span className="emphasisColor">Actual</span> - The sum for all hours used with the given department</li>
				<li><span className="emphasisColor">Percentage</span> - Percentage of hours used versus the pro-rated sum of hours</li>
			</ul>
			
		</div>
	</div>
}

export const NEW_ACTUAL_VS_BUDGET_STAFF = {
	heading: 'Actual Versus Budgeted Staff Hours',
	body: <div className="flex flex-column staffHelp">
		<div className="infoBody">
			This widget is displaying the month-to-month breakdown of staff hours budgeted versus staff hours used. The radio button will toggle between All Staff and 
			Senior Staff only.
		</div>
	</div>
}

export const NEW_STAFF_HOURS = {
	heading: 'Staff Hours',
	body: <div className="flex flex-column staffHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Staff Hours. 
			<ul>
				<li><span className="emphasisColor">Scoped Hours</span> - The total amount of baseline hours for the staff member (the date picker will not change this value)</li>
				<li><span className="emphasisColor">Billed Hours</span> - The total amount of used hours for the staff member for the given time frame</li>
				<li><span className="emphasisColor">% Utilized</span> - The percent of hours used for the given time frame</li>
			</ul>
			The radio button will allow you to toggle between All Staff and Senior Staff only.
		</div>
	</div>
}

export const NEW_STAFF_CHANGES = {
	heading: 'Staff Changes',
	body: <div className="flex flex-column staffHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Staff Changes. Multiple changes within the same day will be grouped together with each change listed in the Changes column.
			<ul>
				<li><span className="emphasisColor">Date</span> - The day that the change was made</li>
				<li><span className="emphasisColor">Staff Name</span> - The staff member and department for the given change</li>
				<li><span className="emphasisColor">Brands/Audience</span> - List of Brands/Audiences that the staff member is associted to</li>
				<li><span className="emphasisColor">Changes</span> - A list of changes that were made witha. time stamp, along with old and new values</li>
			</ul>
		</div>
	</div>
}

export const NEW_STAFF_BY_BRAND = {
	heading: 'Staff By Brand',
	body: <div className="flex flex-column staffHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Staff Hours by Brand. This widget is displaying the breakdown of hours per brand for all staff. 
			<ul>
				<li><span className="emphasisColor">Talent</span> - Staff Member along with the department and title.</li> 
				<li><span className="emphasisColor">Totals on Right</span> - Adding up all the brands for a given talent.</li> 
				<li><span className="emphasisColor">Totals on Bottom</span> - Adding up all the talents for a given brand.</li> 
			</ul>
		</div>
	</div>
}

export const NEW_SPEND_BY_BRAND = {
	heading: 'Spend By Brand',
	body: <div className="flex flex-column budgetHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Spend by Brand. This widget is displaying the breakdown of spendings per brand for all projects. 
			
		</div>
	</div>
}

export const NEW_STAFF_BY_AUDIENCE = {
	heading: 'Staff Hours by Audience',
	body: <div className="flex flex-column staffHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Staff Hours by Audience. This widget is displaying the breakdown of hours per audience for all staff. 
			This widget will only display on the dashboard when there are more than one audience.
			<ul>
				<li><span className="emphasisColor">Talent</span> - Staff name along with the department and title</li> 
				<li><span className="emphasisColor">Totals on Right</span> - Adding up all the brands for a given talent</li> 
				<li><span className="emphasisColor">Totals on Bottom</span> - Adding up all the talents for a given brand</li> 
			</ul>
		</div>
	</div>
}



export const NEW_OOP_COSTS_TABLE = {
	heading: 'Out-of-Pocket Costs',
	body: <div className="flex flex-column oopHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Out-of-Pocket Costs. This widget is displaying all of the used costs that were entered for Out-of-Pocket Spending. 
			<ul>
				<li><span className="emphasisColor">Date</span> - Date when the OOP Cost was spent.</li>
				<li><span className="emphasisColor">Project</span> - Project that is associated with the cost.</li>
				<li><span className="emphasisColor">Description of OOPs</span> - Project level description for the OOPs. This will be the same for any costs within a project.</li>
				<li><span className="emphasisColor">Notes</span> - Note that was entered when the cost was entered in to the system.</li>
				<li><span className="emphasisColor">Notes</span> - Total Amount of the OOP Cost.</li>
				<li><span className="emphasisColor">Remaining</span> - Estimated Prorated remaining budget. </li>
			</ul>
		</div>
	</div>
}

export const NEW_OOP_BUDGET_TABLE = {
	heading: 'Out-of-Pocket Budget',
	body: <div className="flex flex-column oopHelp">
		<div className="infoBody">
			Use the date selector to choose the date range for the Out-of-Pocket Budget. This widget is displaying all of the pro-rated budgets for all projects for out-of-pocket spending. 
			<ul>
				<li><span className="emphasisColor">Brand</span> - Associated brand</li>
				<li><span className="emphasisColor">Project</span> - Associated project including start and end dates</li>
				<li><span className="emphasisColor">Description of OOPs</span> - Project-level description for the OOPs (this will be the same for any costs within a project)</li>
				<li><span className="emphasisColor">Budget</span> - Pro-rated out-of-pocket budget for the project</li>
				<li><span className="emphasisColor">Amount Spent</span> - Total amount spent for out of the out-of-pocket budget</li>
			</ul>
		</div>
	</div>
}

export const NEW_OOP_BUDGET_VS_SPEND = {
	heading: 'Out-of-Pocket Budget vs. Spend',
	body: <div className="flex flex-column oopHelp">
		<div className="infoBody">
			This widget is displaying all of the pro-rated budgets vs. all of the used costs that were entered for Out-of-Pocket Spending. 
			
		</div>
	</div>
}


