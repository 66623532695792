import React, { useState, useEffect, useRef } from 'react';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import { dollarTemplate, booleanTemplate, isTrue, numberTemplate } from '../utilities/CommonSnippets'

import ImportStaff from '../modals/importStaff'

const AgencyStaffList = (props) => {
	// Filters
	const [availableBrands, setAvailableBrands] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState(null);
	const [availableDepartments, setAvailableDepartments] = useState([]);
	const [selectedDepartment, setSelectedDepartment] = useState(null);
	const [availableStatus, setAvailableStatus] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [availableSenior, setAvailableSenior] = useState([]);
	const [selectedSenior, setSelectedSenior] = useState(null);
	const [displayImportStaff, setDisplayImportStaff] = useState(false);
	
	const [staffDetails, setStaffDetails] = useState([]);
	const dt = useRef(null);
	const { staff, functionsDropdown, brandsDropdown, addAgencyStaff, updateAgencyStaff, addAgencyStaffHours, deleteAgencyStaff, getAgencyStaff } = props

	const fncHideImportModal = () => {
		setDisplayImportStaff(false)
		getAgencyStaff()
	}

	useEffect(() => {
		let _brands = {}
		let _department = {}
		let _status = {}
		let _senior = {}

		for (let cur of staff) {
			if (cur.brands) {
				if ((!selectedDepartment || cur.department === selectedDepartment) &&
					(!selectedStatus || cur.status === selectedStatus) && 
					(!selectedSenior || isTrue(cur.isSenior) === isTrue(selectedSenior))
				) {
					for (let curBrand of cur.brands) {
						if (!_brands[curBrand.label]) _brands[curBrand.label] = 0
						_brands[curBrand.label]++
					}
				}
			}

			if (cur.department) {
				if ((!selectedBrand || cur.brands.some((cur) => cur.label === selectedBrand)) &&
					(!selectedStatus || cur.status === selectedStatus) && 
					(!selectedSenior || isTrue(cur.isSenior) === isTrue(selectedSenior))
				) {
					if (!_department[cur.department]) _department[cur.department] = 0
					_department[cur.department]++
				}
			}

			if (cur.status) {
				if ((!selectedBrand || cur.brands.some((cur) => cur.label === selectedBrand)) && 
					(!selectedDepartment || cur.department === selectedDepartment) &&
					(!selectedSenior || isTrue(cur.isSenior) === isTrue(selectedSenior))
				) {
					if (!_status[cur.status]) _status[cur.status] = 0
					_status[cur.status]++
				}
			}

			
				if ((!selectedBrand || cur.brands.some((cur) => cur.label === selectedBrand)) && 
					(!selectedDepartment || cur.department === selectedDepartment) && 
					(!selectedStatus || cur.status === selectedStatus)
				) {
					if (!_senior[cur.isSenior]) _senior[cur.isSenior] = 0
					_senior[cur.isSenior]++
				}
			
		}
		
		
		setAvailableBrands(Object.keys(_brands).map((cur) => {
			return {
				label: cur + ' (' + _brands[cur] + ')', 
				value: cur
			}
		}))

		setAvailableDepartments(Object.keys(_department).map((cur) => {
			return {
				label: cur + ' (' + _department[cur] + ')', 
				value: cur
			}
		}))

		setAvailableStatus(Object.keys(_status).map((cur) => {
			return {
				label: cur + ' (' + _status[cur] + ')', 
				value: cur
			}
		}))

		setAvailableSenior(Object.keys(_senior).map((cur) => {
			return {
				label: (cur === '1'?'Yes':'No') + ' (' + _senior[cur] + ')', 
				value: cur
			}
		}))

		
	}, [staff, selectedBrand, selectedDepartment, selectedStatus, selectedSenior]);

	// Filter On Change and Dropdown configure
	const onBrandChange = (e) => {
        dt.current.filter(e.value, 'brandName', 'contains');
        setSelectedBrand(e.value);
    }

	const onDepartmentChange = (e) => {
        dt.current.filter(e.value, 'department', 'equals');
        setSelectedDepartment(e.value);
    }

	const onStatusChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatus(e.value);
    }

	const onSeniorChange = (e) => {
        dt.current.filter(e.value, 'isSenior', 'equals');
		setSelectedSenior(e.value);
    }

	const brandFilter = <Dropdown value={selectedBrand} options={availableBrands} filter onChange={onBrandChange} placeholder="Select a Brand" className="p-column-filter" showClear />;
	const departmentFilter = <Dropdown value={selectedDepartment} options={availableDepartments} filter onChange={onDepartmentChange} placeholder="Select a Department" className="p-column-filter" showClear />;
	const statusFilter = <Dropdown value={selectedStatus} options={availableStatus} filter onChange={onStatusChange} placeholder="Select a Status" className="p-column-filter" showClear />;
	const seniorFilter = <Dropdown value={selectedSenior} options={availableSenior} filter onChange={onSeniorChange} placeholder="Select Is Senior" className="p-column-filter" showClear />;

	const brandTemplate = (rowData) => {
		let arrBrandNames = rowData.brands.map(cur => cur.label) || []

		return arrBrandNames.map((cur) => {
			return (
				<div>
					{cur}
				</div>
			)
		})
		
	}

	const staffNameTemplate = (rowData) => {
		return (
			<div className="flex flex-column" >
				{rowData.staffType === 'named' && 
					<>
						<div className="flex mb-2">
							<div className="mr-2 name">
								{rowData.name}
							</div>
							{props.isAdmin && 
								<div className="editIcon" onClick={(e) => props.fncEditStaff(rowData)}>
									<i className="fa fa-pencil-square-o"></i>
								</div>
							}
						</div>
						<div className="mb-2 brand">{rowData.title}</div>
					</>
				}
				{rowData.staffType === 'unNamed' && 
					<>
						<div className="flex mb-2">
							<div className="mr-2 name">
								{rowData.category}
							</div>
							{props.isAdmin && 
								<div className="editIcon" onClick={(e) => props.fncEditStaff(rowData)}>
									<i className="fa fa-pencil-square-o"></i>
								</div>
							}
						</div>
						<div className="mb-2 brand">Unnamed</div>
					</>
				}
				
				
				<div className="mb-2">
					<Button label="Hours" icon="pi pi-plus" className="p-button p-component p-button-outlined p-button-secondary" onClick={() => props.fncAddStaffHours(rowData)} />
				</div>
			</div>
		)
	}


	const staffStatusSelections = [
		{label: 'Active', value: 'active'},
		{label: 'On Leave', value: 'leave'},
		{label: 'Inactive', value: 'inactive'}
	];

	const onEditorValueChange = (props, value) => {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
		setStaffDetails(updatedProducts)
    }

	const statusEditTemplate = (rowData) => {
		if (!rowData.status) return ''
		return (
			<div className="flex justify-content-center">
				<div className={"mb-2 product-badge status-" + rowData.status.replace(/ /g,'')}>{rowData.status}</div>
				<div className="ml-2 editIcon"><i className="fa fa-pencil-square-o"></i></div>
			</div>
		)
	}

	const statusEditor = (props) => {
        return (
			<div className="flex">
				<div className="mb-2">
					<Dropdown value={props.rowData['status']} options={staffStatusSelections} 
						onChange={(e) => onEditorValueChange(props, e.value)}  
						placeholder="Select Status" 
					/>
				</div>
				<div className="ml-2 saveIcon" onClick={(e) => fncSaveStaffStatus(props.rowData['staffID'],props.rowData['status'])}>
					<i className="fa fa-save"></i>
				</div>
			</div>
        );
    }

	const fncSaveStaffStatus = (staffID, status) => {
		props.updateAgencyStaff({
			staffID: staffID,
			value:status,
			updateField: 'status'
		}).then(() => {
			
			dt.current.closeEditingCell()
			props.showToast('success', 'Success', 'Staff status was updated successfully.')
		})
	}

	const paginatorRight = <></>;
	const paginatorLeft = <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={(e) =>fncClearFilters() } />;

	const fncClearFilters = () => {
		setSelectedBrand(null)
		setSelectedDepartment(null)
		setSelectedStatus(null)
		setSelectedSenior(null)
		dt.current.reset();
	}

	return (
		<div className="card">
			<div className="flex justify-content-end">
				<div className="mb-2">
					<Button label="Import" icon="pi pi-upload" style={{marginRight: '15px'}} className="p-button p-component" onClick={() => setDisplayImportStaff(true)} />
					<Button label="Hours" icon="pi pi-plus" className="mr-2 p-button p-component p-button-outlined p-button-secondary" onClick={() => props.fncAddStaffHours()} />
					<Button label="Staff" icon="pi pi-plus" className="p-button p-component p-button-outlined" onClick={() => props.fncDisplayStaff()} />
				</div>
			</div>

			<DataTable ref={dt} value={props.staff} className="p-datatable-staff"  scrollable scrollHeight="auto" style={{ width: '100%' }} editMode="cell"
				paginator
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}
				paginatorPosition = "both" exportFilename={props.agencyName + "_staff"}
				paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
				removableSort={true} filterDisplay="row" stripedRows
			>
				
				<Column field="displayName" columnKey="displayName" header="Staff Name" filter filterMatchMode="contains" showFilterMenu={false} showClearButton={false} body={staffNameTemplate} sortable  style={{verticalAlign:'top',width:'250px'}}></Column>
				<Column field="isSenior" columnKey="isSenior" header="Senior Staff" filter filterElement={seniorFilter} showFilterMenu={false} showClearButton={false} body={booleanTemplate} sortable  style={{verticalAlign:'top',width:'200px'}}></Column>
				<Column field="brandName" columnKey="brandName" sortable body={brandTemplate} header="Brands" filter filterElement={brandFilter} showFilterMenu={false} showClearButton={false}  style={{verticalAlign:'top',width:'220px'}}></Column>
				<Column field="staffType" columnKey="staffType" header="Staff Type" style={{display:'none',width:'0px'}}></Column>
				<Column field="department" columnKey="department" header="Department" filter filterElement={departmentFilter} showFilterMenu={false} showClearButton={false}  sortable  style={{verticalAlign:'top',width:'250px'}}></Column>
				<Column field="status" body={statusEditTemplate} sortable filter filterElement={statusFilter} showFilterMenu={false} showClearButton={false} header="Status" style={{textAlign:'center',verticalAlign:'top',width:'200px'}} editor={(props) => statusEditor(props)}></Column>
				<Column field="rate" columnKey="rate" header="Rate" body={dollarTemplate} sortable  style={{textAlign:'right',verticalAlign:'top',width:'100px'}}></Column>
				<Column field="totalHours" columnKey="totalHours" body={numberTemplate} header="Baseline Hours" sortable  style={{textAlign:'right',verticalAlign:'top',width:'140px'}}></Column>
				<Column field="totalHoursUsed" columnKey="totalHoursUsed" header="Billed Hours" sortable  style={{textAlign:'right',verticalAlign:'top',width:'140px'}}></Column>
				<Column field="baselineCost" columnKey="baselineCost" header="Baseline Cost" sortable body={dollarTemplate}  style={{textAlign:'right',verticalAlign:'top',width:'140px'}}></Column>
				<Column field="totalCost" columnKey="totalCost" header="Billed Cost" sortable body={dollarTemplate}  style={{textAlign:'right',verticalAlign:'top',width:'140px'}}></Column>
			</DataTable>
			{displayImportStaff && 
					<ImportStaff 
						displayImportStaff={displayImportStaff}
						fncHideImportModal={fncHideImportModal}
						brandsDropdown={brandsDropdown}
						agencyName={props.agencyName}
						staff={props.staff}
						functionsDropdown={functionsDropdown}
						showToast={props.showToast}
						addAgencyStaff={addAgencyStaff}
						updateAgencyStaff={updateAgencyStaff}
						addAgencyStaffHours={addAgencyStaffHours}
						deleteAgencyStaff={deleteAgencyStaff}
					/>
				}
		</div>
	);
}

export default AgencyStaffList;