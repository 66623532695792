import React, { useState, useEffect, useRef } from 'react';

// Form validation library
import { useForm, Controller } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Messages } from 'primereact/messages';
import { classNames } from 'primereact/utils';
import { ScrollPanel } from 'primereact/scrollpanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { dollarTemplate, monthTemplate } from '../utilities/CommonSnippets'

const ProjectAddHoursModal = (props) => {
	const { staff, displayStaffHours, staffDropdown} = props
	const defaultValues = {
		currentStaff: null,
		currentDate: new Date(),
		currentState: 'add',
		hours: '', 
		comments: '', 
		currentAgencyBrand: null
	}
	
	const [currentStaffID, setCurrentStaffID] = useState(null);
	const [currentStaffProfile, setCurrentStaffProfile] = useState(null);
	const [addAnother, setAddAnother] = useState(true);
	const [brandDropdown, setBrandDropdown] = useState([]);
	const [brandStick, setBrandStick] = useState('');

	// These are the needed items for the form validation component
	const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

	const toast = useRef(null);
	const msgs = useRef(null);

	// This will get called everytime the boolean displayHours changes, it determines if the modal is showing or not.
	useEffect(() => {
		// When we close the modal, reset all the form field values
		if (!props.displayStaffHours) {
			reset()
			setCurrentStaffID(null)
		} else {
			// We we first open the modal, set the User drop down to the first user in the list
			if (props.currentStaffID) {
				setValue("currentStaff", props.currentStaffID);
				setCurrentStaffID(props.currentStaffID)
			} else if (staffDropdown && staffDropdown[0]) {
				setValue("currentStaff", staffDropdown[0].value);
				setCurrentStaffID(staffDropdown[0].value)
			}
		}
		
	},[displayStaffHours])

	// This controls who the current staff member is and can set the needed object to show the staff summary on the right of the modal
	useEffect(() => {
		if (currentStaffID) {
			let _temp = staff.find(cur => cur.staffID === currentStaffID)
			setCurrentStaffProfile(_temp)
			setBrandDropdown(_temp.brands)


			let _tempBrand = _temp.brands.find(cur => cur.value === brandStick)
			if (_tempBrand) setValue("currentAgencyBrand", brandStick)
			else {
				setValue("currentAgencyBrand", _temp.brands[0]?.value);
				setBrandStick(_temp.brands[0]?.value)
			}
		} else {
			setCurrentStaffProfile(null)
			//setBrandDropdown([])
		}
	},[currentStaffID]);
	
	const fncOnStaffChange = (field,e) => {
		field.onChange(e.value)
		setCurrentStaffID(e.value)
	}

	const renderFooter = () => {
		return (
			<div className="flex justify-content-between">
				<div>
					<Checkbox onChange={e => setAddAnother(e.checked)} checked={addAnother}></Checkbox> Add More hours
				</div>
				<div className="flex">
					<div className="mr-2">
						<Button label="Cancel" icon="pi pi-times" onClick={() => props.fncHideModal()} className="p-button-text" />
					</div>
					<div>
						<Button label="Add" icon="pi pi-plus" onClick={handleSubmit(onSubmit)} />
					</div>
				</div>
			</div>
		);
	}
	
	let arrStates = [
		{label: 'Add', value: 'add'},
		{label: 'Remove', value: 'remove'}
	]

	const hoursTemplate = (rowData) => {
		if (rowData.state === 'add') {
			return <div className="addHours">+ {rowData.hours}</div>
		} else if (rowData.state === 'remove') {
			return <div className="removeHours">- {rowData.hours}</div>
		} else {
			return ''
		}
	}

	const onSubmit = async (data) => {
		let blnAddResults = await props.addAgencyStaffHours({
			staffID: currentStaffID,
			state: data.currentState,
			brandAgencyID: data.currentAgencyBrand,
			hours: data.hours,
			date: data.currentDate,
			comments: data.comments
		})

		let currentDate = data.currentDate
		let currentBrand = data.currentAgencyBrand

		setCurrentStaffID(null)
		if (blnAddResults) {
			reset();
			if (addAnother) {
				setValue("currentDate", currentDate);
				setValue("currentAgencyBrand", currentBrand);
				setBrandStick(currentBrand)
				msgs.current.show([
					{ severity: 'success', detail: 'Hours were updated successfully.', life: 3000}
				]);
			} else {
				props.fncHideModal()
				props.showToast('success', 'Success','Hours were updated successfully.')
			}
			
		}
		else {
			msgs.current.show([
				{ severity: 'error', detail: 'There was an issue adding hours.', life: 3000}
			]);
		}
    };

	return (
		<Dialog header="Add Staff Hours" visible={props.displayStaffHours} className="addHoursModal"  footer={renderFooter()} onHide={() => props.fncHideModal()}>
			<Toast ref={toast} />
			
			
			<div className="grid">
				<div className="col-6">
					<form className="p-fluid">
					<div>
							Staff*: 
							<Controller name="currentStaff" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<Dropdown 
									id={field.name} 
									value={field.value} 
									options={staffDropdown} 
									onChange={(e) => fncOnStaffChange(field,e)} 
									placeholder="Select Staff" 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{errors.currentStaff && 
								<small className="p-error">Staff is required.</small>
							}
						</div>
						<br/>
						<div>
							Brand*: 
							<Controller name="currentAgencyBrand" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<Dropdown 
									id={field.name} 
									value={field.value} 
									options={brandDropdown} 
									onChange={(e) => {setBrandStick(e.value); return field.onChange(e.value)}}  
									placeholder="Select Brand" 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{errors.currentAgencyBrand && 
								<small className="p-error">Brand is required.</small>
							}
						</div>
						<br/>
						<div>
							Date for When Hours Were Worked*: 
							<Controller name="currentDate" control={control} rules={{ required: true }}  render={({ field, fieldState }) => (
                                <Calendar 
									id={field.name} 
									value={field.value} 
									onChange={(e) => field.onChange(e.value)} 
									view="month" dateFormat="MM yy"
									mask="99/99/9999" 
									showIcon 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
                            )} />
							{errors.currentDate && 
								<small className="p-error">Date is required.</small>
							}
							
						</div>
						<br/>
						<div>
							State*: 
							<Controller name="currentState" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<Dropdown 
									value={field.value} 
									options={arrStates} 
									onChange={(e) => field.onChange(e.value)}  
									placeholder="Select a State" 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
							)} />
							{errors.currentState && 
								<small className="p-error">State is required.</small>
							}
						</div>
						<br/>
						<div>
							Hours*: 
							<Controller name="hours" control={control} rules={{validate:{positiveNumber: (value) => parseFloat(value) > 0,}}} render={({ field, fieldState }) => (
                                <InputText 
									id={field.name} 
									value={field.value} 
									{...field}
									autoFocus 
									className={classNames({ 'p-invalid': fieldState.invalid })} 
								/>
                            )} />
							{errors.hours && 
								<small className="p-error">Hours are required.</small>
							}
						</div>
						<br/>
						<div>
							Comments: 
							<Controller name="comments" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
								<InputTextarea 
									rows={5}
									id={field.name} 
									value={field.value} 
									{...field}
									autoFocus
								/>
							)} />
					</div>
					</form>
				</div>
				<div className="col-6 verticalDividerLeft">
					{currentStaffProfile && 
						<div className="flex flex-column currentStaff">
							<div className="flex justify-content-between">
								{currentStaffProfile.staffType === 'named' && 
									<div className="name">
										{currentStaffProfile.name}
										{currentStaffProfile.isSenior === 1 && 
											<i className="fa fa-user-plus briefcasemarg" title="Senior Staff"></i>
										}
									</div>
								}
								{currentStaffProfile.staffType === 'unNamed' && 
									<div className="name">
										{currentStaffProfile.category}
									</div>
								}
								<div className="rate">{dollarTemplate(currentStaffProfile.rate)}</div>
							</div>
							<div className="flex justify-content-between">
							{currentStaffProfile.staffType === 'named' && 
									<div className="title">
										{currentStaffProfile.title}
									</div>
								}	
								{currentStaffProfile.staffType === 'unNamed' && 
									<div className="title">
										Unnamed
									</div>
								}	
								<div className="rate">{currentStaffProfile.totalHoursUsed} / {currentStaffProfile.totalHours} hours</div>
							</div>
							<div className="flex justify-content-end rate">
								{dollarTemplate(currentStaffProfile.totalCost)}
							</div>
							<div className="mt-4">
								<ScrollPanel style={{width: '100%', height: '200px'}}  className="hoursScroller">
									<DataTable value={currentStaffProfile.hours} className="hoursTable">
										<Column field="dateEntered" body={monthTemplate} header="Date" style={{width: '50%'}}></Column>
										<Column field="brandName" header="Brand" style={{width: '30%'}}></Column>
										<Column field="hours" header="Hours" body={hoursTemplate} style={{width: '20%',textAlign:'right'}}></Column>
									</DataTable>
								</ScrollPanel>
							</div>
							
						</div>
					}
				</div>
				<Messages ref={msgs} className="modalMessage"></Messages>
				
			</div>
		</Dialog>
	)
}

export default ProjectAddHoursModal;