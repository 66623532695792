import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Chart as ChartJS, BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

// PrimeReact Components

// Selectors
import { getDepartmentalHours} from '../../selectors/dashboardWidgetsSelector'

// helpers
import { fncFormatPercentage } from '../../utilities/CommonSnippets'
import { BUDGET_LIGHT_COLOR, BUDGET_DARK_COLOR, BUDGET_OUTSIDE_COLOR} from '../../constants/colors'

ChartJS.register(BarController)
ChartJS.register(CategoryScale)
ChartJS.register(LinearScale)
ChartJS.register(BarElement)
ChartJS.register(Tooltip)
ChartJS.register(Legend)
ChartJS.defaults.font.size = 14;

const DepartmentalHours = (props) => {
	
	const { resourceBreakdown, loading, dates } = props
	useEffect(() => {
		if (!loading) {
			
			const chartData = {
				labels: resourceBreakdown.map(cur => [cur.functionTitle, fncFormatPercentage(cur.percentUsed,1) + '%']),
				datasets: [
					{
					  label: 'Budget',
					  data: resourceBreakdown.map(cur => cur.budgetHours),
					  borderColor: BUDGET_DARK_COLOR,
					  backgroundColor: BUDGET_LIGHT_COLOR,
					},
					{
					  label: 'Actual',
					  data: resourceBreakdown.map(cur => cur.usedHours),
					  borderColor: BUDGET_LIGHT_COLOR,
					  backgroundColor: BUDGET_DARK_COLOR,
					},
					{
					  label: 'Outside',
					  data: resourceBreakdown.map(cur => cur.outsideHours),
					  borderColor: BUDGET_OUTSIDE_COLOR,
					  backgroundColor: BUDGET_OUTSIDE_COLOR,
					}
				  ]
			};
		
			const chartOptions = {
				layout: {
					padding: {
						right: 20,
						left: 20
					}
				},
				indexAxis: 'y',
				maintainAspectRatio: false,
				plugins: {
					// Change options for ALL labels of THIS CHART
					datalabels: {
						anchor: 'end',
						align: 'end',
						formatter: function(value, context) {
							return null
						}
					},
					tooltip: {
						callbacks: {
							title: (context) =>{
								let arrLabelElements = context[0].label.split(',')
								return [arrLabelElements[0],arrLabelElements[1]]	
							}
						},
						callbacks: {
							label: function(context) {
								return context.dataset.label + ': ' + context.formattedValue + ' hours'
							}
						}
					}
				  }
			};
			
			var myChart = new ChartJS(document.getElementById('departmentHoursChart'), {
				type: "bar",
				data: chartData ,
				options: chartOptions
			});

			// when component unmounts
			return () => {
				myChart.destroy()
			}
		}
	}, [resourceBreakdown, loading, dates])

	return (
		<div className="flex spend" style={{height:'60vh'}}>
			{!loading && 
				<div className="p-chart artCharts-bar">
					<canvas id="departmentHoursChart"></canvas>
				</div>
			}
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		resourceBreakdown: getDepartmentalHours(state,ownProps),
	}
}

const DepartmentalHoursContainer = connect(mapStateToProps, null)(DepartmentalHours);
export default DepartmentalHoursContainer;