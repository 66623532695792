import { combineReducers } from 'redux';
import brandsReducer from './entities/brandsReducer'
import clientsReducer from './entities/clientsReducer'
import agenciesReducer from './entities/agenciesReducer'
import brandAgenciesReducer from './entities/brandAgenciesReducer'
import agencyFunctionsReducer from './entities/agencyFunctionsReducer'
import agencyProjectsReducer from './entities/agencyProjectsReducer'
import agencyStaffReducer from './entities/agencyStaffReducer'
import insightTextReducer from './entities/insightTextReducer'
import commentsReducer from './entities/commentsReducer'
import agencyStatusLogsReducer from './entities/agencyStatusLogsReducer'

export default combineReducers({
	brands: brandsReducer, 
	clients: clientsReducer, 
	agencies: agenciesReducer,
	agencyFunctions: agencyFunctionsReducer,
	agencyProjects: agencyProjectsReducer,
	agencyStaff: agencyStaffReducer,
	brandAgencies: brandAgenciesReducer,
	insightText: insightTextReducer,
	comments: commentsReducer,
	agencyStatusLogs: agencyStatusLogsReducer
});