import React, {useState, useEffect } from 'react';
import AuthService from '../../services/AuthService';

export default function withAuth(AuthComponent) {
    const Auth = new AuthService();
    let AuthWrapped = (props) => {
		const [user, setUser] = useState(null);
		const [isAdmin, setIsAdmin] = useState(null);
        useEffect(() => {
			
			if(!Auth.loggedIn()) {
                window.location.reload()
            } else {
                try {
					const profile = Auth.getProfile()
					setUser(profile)
					setIsAdmin(profile.isAdmin)
                }
                catch(err) {
                    Auth.logout()
                    window.location = '/'
                }
            }
        },[props.history]);
    
		//This will stop the page from loading, even though it's not shown in the menu.
		const arrAdminPages = ['/config-labels','/config-annotations','/config-sites']
		
		if (user) {
			if (props.location && arrAdminPages.includes(props.location.pathname) && !isAdmin) {
				return null
			} else {
				return (
					<AuthComponent history={props.history} {...props} user={user} />
				)
			}
        } else {
    		return null
        }
    }

    return AuthWrapped
}