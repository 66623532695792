import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {InputSwitch} from 'primereact/inputswitch';

import ExcelJS  from 'exceljs'
import saveAs from "file-saver";

// Selectors
import { getStaffHours} from '../../selectors/dashboardWidgetsSelector'

// helpers
import { percentTemplate, fncFormatPercentage, numberTemplate, dateStringsMap } from '../../utilities/CommonSnippets'
import { fncDrawHeaderCell, fncDrawFooterCell, fncDrawTitleCell} from '../../utilities/commonXslxFunctions'

const StaffHours = (props) => {
	const { staffHours, downloadClicked, fileName, dates } = props
	const [ seniorStaff, setSeniorStaff] = useState(true);
	const [ curStaffHours, setCurStaffHours] = useState();
	

	useEffect(() => {
		if (!seniorStaff) setCurStaffHours(staffHours)
		else {
			let _seniorOnly = staffHours.filter(cur => cur.isSenior === 1)
			setCurStaffHours(_seniorOnly)
		}
		
	},[staffHours, seniorStaff]);

	useEffect(() => {
		if (downloadClicked > 0) exportExcel()
	},[downloadClicked])

	const getTotals = (field) => {
        let total = 0;
        if (curStaffHours) {
			for(let curTotal of curStaffHours) {
				total += curTotal[field];
			}
		}

        return total.toLocaleString('en-US');
    }

	const getTotalAverage = () => {
        let totalScoped = 0;
		let totalUsed = 0;
        if (curStaffHours) {
			for(let curTotal of curStaffHours) {
				totalScoped += curTotal['baselineHours'];
				totalUsed += curTotal['spent'];
			}
		}

        return fncFormatPercentage(totalUsed / totalScoped,1) + '%';
    }

	let footerGroup = () => {
		
		return (
			<ColumnGroup>
			<Row>
				<Column footer="Totals" colSpan={3} footerStyle={{color: 'white',textAlign: 'right'}}/>
				<Column footer={getTotals('baselineHours')} footerStyle={{color: 'white',textAlign: 'right'}}/>
				<Column footer={getTotals('spent')} footerStyle={{color: 'white',textAlign: 'right'}}/>
				<Column footer={getTotalAverage()} footerStyle={{color: 'white',textAlign: 'right'}}/>
			</Row>
			</ColumnGroup>
		)
	}

	const exportExcel = () => {
       
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Staff Hours");
		worksheet.properties.defaultColWidth = 20;
		worksheet.properties.defaultRowHeight = 16;
		
		worksheet.mergeCells("A1:F1");
		fncDrawTitleCell(worksheet, 'A1', "Staff Hours (" + dateStringsMap()[dates] + ")")
		
		// TOP BAR
		fncDrawHeaderCell(worksheet, 'A3', 'Name')
		fncDrawHeaderCell(worksheet, 'B3', 'Title')
		fncDrawHeaderCell(worksheet, 'C3', 'Department')
		fncDrawHeaderCell(worksheet, 'D3', 'Scoped Hours')
		fncDrawHeaderCell(worksheet, 'E3', 'Billed Hours')
		fncDrawHeaderCell(worksheet, 'F3', '% Utilized')

		let cell = null
		for (let curRow of curStaffHours) {
			let dataRow = worksheet.addRow();
			cell = dataRow.getCell(1).value = curRow.name
			cell = dataRow.getCell(2).value = curRow.title
			cell = dataRow.getCell(3).value = curRow.functionTitle
			cell = dataRow.getCell(4).value = curRow.baselineHours
			cell = dataRow.getCell(5).value = curRow.spent
			cell = dataRow.getCell(6)
			cell.numFmt = '0%'
			cell.value = curRow.percentUsed / 100
		}

		let dataFooterRow = worksheet.addRow();
		worksheet.mergeCells(dataFooterRow['_number'],1,dataFooterRow['_number'],3);
		fncDrawFooterCell(dataFooterRow,1,'Totals')
		fncDrawFooterCell(dataFooterRow,4, getTotals('baselineHours'))
		fncDrawFooterCell(dataFooterRow,5, getTotals('spent'))
		fncDrawFooterCell(dataFooterRow,6, getTotalAverage())
		

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  fileName + `.xlsx`
			);
		  });
    }

	return (
		<div className="flex flex-column staff">
			
			<div className="flex mb-2">
				<div className="mr-2 ml-2"> 
					All Staff
				</div>
				<div className="mr-2"> 
					<InputSwitch checked={seniorStaff} onChange={(e) => setSeniorStaff(e.value)} />
				</div>
				<div>
					Senior Staff
				</div>
			</div>
			<div>
				<DataTable className="tables" value={curStaffHours} footerColumnGroup={footerGroup()}
					paginator paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,50,100]}
				>
					<Column field="name"  header="Name" />
					<Column field="title"  header="Title" style={{textAlign:'left'}} />
					<Column field="functionTitle" header="Department" style={{textAlign:'left'}} />
					<Column field="baselineHours"  body={numberTemplate} header="Scoped Hours" style={{textAlign:'right'}} />
					<Column field="spent" body={numberTemplate} header="Billed Hours" style={{textAlign:'right'}} />
					<Column field="percentUsed" body={percentTemplate} header="% Utilized" style={{textAlign:'right'}} />
				</DataTable>
			</div>
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		staffHours: getStaffHours(state,ownProps)
	}
}

const StaffHoursContainer = connect(mapStateToProps, null)(StaffHours);
export default StaffHoursContainer;