import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
//import createHistory from 'history/createBrowserHistory'
import { createBrowserHistory } from 'history';
import createRootReducer from './reducers/rootReducer'
import dayjs from 'dayjs';

const startOfYear = dayjs().startOf('year')

//export const history = createHistory()
export const history = createBrowserHistory();

let defaultDateType = 'ytd'
if (new Date().getMonth() === 0) defaultDateType= 'lastYear'

const initialState = {
	entities: {
		brands: {allIds: [],byId:{}},
		clients: {allIds: [],byId:{}},
		brandAgencies: {allIds: [],byId:{}}, // A join between brands and agency
		agencies: {allIds: [],byId:{}, brandID: 0},
		agencyFunctions: {allIds: [],byId:{}, brandID: 0},
		agencyStatusLogs: {allIds: [],byId:{}, brandID: 0},
		agencyProjects: {allIds: [],byId:{}, brandID: 0},
		agencyStaff: {allIds: [],byId:{}, brandID: 0},
		insightText: {allIds: [],byId:{}, brandID: 0},
		comments: {allIds: [],byId:{}}
	}, ui: {
		dateType: defaultDateType,
		fromDate: new Date(startOfYear),
		toDate: new Date(),
		currentClientID: null
	}
}
const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
	enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
)

export default store