import React from 'react';
import { connect } from 'react-redux';

// PrimeReact Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from 'primereact/progressbar';

import { getHoursByResource } from '../../selectors/dashboardWidgetsSelector'
import { defaultTitleTemplate, fncFormatPercentage } from "../../utilities/CommonSnippets";

const ResourceMix = (props) => {
	const { resourceBreakdown } = props

	const percentageLineTemplate = (rowData,column) => {
	
		let forecasted = (rowData[column.field] * 100).toFixed(1)
		
		return (
			<div className="grid percentageToolTip" data-pr-position="right"  >
				<div className="col-10" >
					<ProgressBar  className={(rowData.varianceLevel === 'low'?'progressBarGreen':rowData.varianceLevel === 'high'?'progressBarRed':'progressBarOrange')} value={forecasted} showValue={false} />
				</div>
				<div className="col-2 tinyPercent" >
					{fncFormatPercentage(rowData.percentUsed, 1)}%
				</div>
			</div>
		)
		
	}

	const statusTemplate = (rowData, column) => {
		if (rowData.varianceLevel === 'low') {
			return (
				<div className="flex flex-column">
					<div className={"mb-2 product-badge status-low"}>As Planned</div>
				</div>
			)
		} else if (rowData.varianceLevel === 'medium') {
			return (
				<div className="flex flex-column">
					<div className={"mb-2 product-badge status-medium"}>As Planned</div>
				</div>
			)
		} else if (rowData.varianceLevel === 'high') {
			return (
				<div className="flex flex-column">
					<div className={"mb-2 product-badge status-high"}>Needs Review</div>
				</div>
			)
		}
	}

	const colHeader_progress = <div className="flex">
		<div>Progress</div>
	</div>
	
	return (
		<div className="flex flex-column resourceMix">
			
			<div className="flex mb-3 fullWidget " >
				<DataTable value={resourceBreakdown} className="p-datatable-products"  style={{width:'100%'}}>
						<Column field="functionTitle" header="Role" sortable body={defaultTitleTemplate} style={{ width: "20%" }}></Column>
						<Column field="varianceLevel" header="Status" sortable body={statusTemplate} style={{ textAlign:"center", width: "20%" }}></Column>
						<Column field="percentUsed" body={percentageLineTemplate} header={colHeader_progress} style={{ textAlign:"center",width: "35%" }}></Column>
					</DataTable>
			</div>
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		resourceBreakdown: getHoursByResource(state,{dates:ownProps.dates})
		
	}
}

const ResourceMixContainer = connect(mapStateToProps, null)(ResourceMix);
export default ResourceMixContainer;