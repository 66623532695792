import axios from 'axios';
import dayjs from 'dayjs'
import { GET_BRANDS, GET_BRAND_INSIGHT_TEXT, SAVE_BRAND_INSIGHT_TEXT }  from '../constants/URLS';

//Ajax call to the backend to get the Team Hooks.
export const getBrandsPromise = async () => {
	const brandResults  = await axios({ method: 'get', url: GET_BRANDS})

	if ( !brandResults.data.success ) {
		console.error(brandResults.data.error)
		return {byId: {},allIds: []}
	} else return brandResults.data.results
}

//Call that comes in to request the Brands, update the clients and update the store.
// FISCAL YEAR LOGIC IS HERE AND STORED IN REDUX
export const getBrands = () => (dispatch,getState) => {
	return async (dispatch,getState) => {
		
		if (getState().entities.brands.allIds.length > 0) return
		
		const brandResults = await getBrandsPromise()
		let clientResults = brandResults.allIds.reduce((acc,cur) => {
			let objCurBrand = brandResults.byId[cur]
			if (!acc.byId[objCurBrand.clientID]) {
				acc.allIds.push(objCurBrand.clientID)
				let fiscalMonthStart = objCurBrand.clientFiscalStartMonth

				//subtract 1 unless it's 0 (january), in which case, set to 11 (dec) 
				let fiscalMonthEnd = (fiscalMonthStart - 1 < 0 ? 11 : fiscalMonthStart - 1)
				let startOfYear = dayjs().startOf('year')
				let endOfYear = dayjs().endOf('year')

				/* 
					3 scenerios for start and end fiscal year dates. 
					1. If the start month is January, then calendar year
					2. start month is coming up, which means we are in the previous fiscal year still, subtract 1 from the start year
					3. start date is behind (or equal) which means we are in the current fiscal year
				*/

				if (fiscalMonthStart !== 0 && fiscalMonthStart > dayjs().month())  {
					// Fiscal year start date should use previous year, the end year will use this year
					startOfYear = dayjs().set('month', fiscalMonthStart).set('year', dayjs().subtract(1, 'year').year()).startOf('month')
					endOfYear = dayjs().set('month', fiscalMonthEnd).set('year', dayjs().year()).endOf('month')
				} else if (fiscalMonthStart !== 0 && fiscalMonthStart <= dayjs().month()) {
					// Fiscal year start date should use current year, the end year will use next year
					startOfYear = dayjs().set('month', fiscalMonthStart).set('year', dayjs().year()).startOf('month')
					endOfYear = dayjs().set('month', fiscalMonthEnd).set('year', dayjs().add(1, 'year').year()).endOf('month')
				}

				/* 
					Previously, there was a library I could use for quarters, but that was based on calendar year and not fiscal, so now have to 
					calculate myself
				*/
				let quarter1 = objCurBrand.clientFiscalStartMonth
				let quarter2 = objCurBrand.clientFiscalStartMonth + 3
				if (quarter2 > 11) quarter2 = quarter2 - 12

				let quarter3 = objCurBrand.clientFiscalStartMonth + 6
				if (quarter3 > 11) quarter3 = quarter3 - 12

				let quarter4 = objCurBrand.clientFiscalStartMonth + 9
				if (quarter4 > 11) quarter4 = quarter4 - 12

				let objQuarters = {
					1: quarter1,
					2: quarter2,
					3: quarter3,
					4: quarter4,
				}

				let currentMonth = dayjs().month()
				let currentQuarter
				if (currentMonth >= quarter1 && currentMonth < quarter2) currentQuarter = 1
				else if (currentMonth >= quarter2 && currentMonth < quarter3) currentQuarter = 2
				else if (currentMonth >= quarter3 && currentMonth < quarter4) currentQuarter = 3
				else currentQuarter = 4

				let previousQuarter = currentQuarter - 1
				if (previousQuarter === 0) previousQuarter = 4
				
				//if previous quarter month is greater than current month, use previous year
				let lastQtrYear = dayjs().year()
				if (objQuarters[previousQuarter] > currentMonth) lastQtrYear = dayjs().subtract(1, 'year').year()
				let startOfPrevQuarter = dayjs().month(objQuarters[previousQuarter]).year(lastQtrYear).startOf('month')

				//if previous quarter end month is greater than current month, use previous year
				let lastQtrEndMonth = objQuarters[currentQuarter] - 1
				if (lastQtrEndMonth < 0) lastQtrEndMonth = 11
				let lastQtrYear_end = dayjs().year()
				
				if (lastQtrEndMonth > currentMonth) lastQtrYear_end = dayjs().subtract(1, 'year').year()
				let endOfPrevQuarter = dayjs().month(lastQtrEndMonth).year(lastQtrYear_end).endOf('month')

				let objFiscalDates = {
					startOfYear: startOfYear,
					endOfYear: endOfYear,
					startOfPrevMonth: dayjs().subtract(1, 'month').startOf('month'),
					endOfPrevMonth: dayjs().subtract(1, 'month').endOf('month'),
					startOfPrevTwoMonth: dayjs().subtract(2, 'month').startOf('month'),
					endOfPrevTwoMonth: dayjs().subtract(2, 'month').endOf('month'),
					startOfPrevYear: startOfYear.subtract(1, 'year'),
					endOfPrevYear: endOfYear.subtract(1, 'year'),
					startOfPrevQuarter: startOfPrevQuarter,
					endOfPrevQuarter: endOfPrevQuarter,
					currentQuarter: currentQuarter
				}

				acc.byId[objCurBrand.clientID] = {
					clientID:objCurBrand.clientID,
					brandCount:0,
					brands:[],
					clientName: objCurBrand.clientName,
					clientFiscalStartMonth: objCurBrand.clientFiscalStartMonth,
					fiscalDates: objFiscalDates
				}
			}

			if (objCurBrand.name.trim() !== '') {
				acc.byId[objCurBrand.clientID].brandCount++
				acc.byId[objCurBrand.clientID].brands.push(objCurBrand.name)
			}
			

			return acc
		},{byId:{}, allIds:[]})

		dispatch({
			type: 'SET_BRANDS',
			payload: brandResults
		})

		dispatch({
			type: 'SET_CLIENTS',
			payload: clientResults
		})
	}
};

//Ajax call to the backend to get the Team Hooks.
export const getBrandInsightTextPromise = async (brandID) => {
	const insightResults  = await axios({ method: 'get', url: GET_BRAND_INSIGHT_TEXT + '/' + brandID})

	if ( !insightResults.data.success ) {
		console.error(insightResults.data.error)
		return {byId: {},allIds: []}
	} else return insightResults.data.results
}

//Call that comes in to request the Brands and update the store.
export const getBrandInsightText = (brandID) => (dispatch,getState) => {
	return async (dispatch,getState) => {
		
		
		let curBrandID = getState().entities.insightText.brandID || 0
		if (curBrandID === brandID) return
		
		const insightResults = await getBrandInsightTextPromise(brandID)
		return dispatch({
			type: 'SET_INSIGHT_TEXT',
			payload: {
				...insightResults,
				brandID: brandID
			}
		})
	}
};

//Ajax call to the backend to get the Team Hooks.
export const saveBrandInsightTextPromise = async (brandID, section, insightText) => {
	const insightResults  = await axios({ 
		method: 'post', 
		url: SAVE_BRAND_INSIGHT_TEXT,
		data: {
			section: section, 
			insightText: insightText,
			brandID: brandID
		}
	})

	if ( !insightResults.data.success ) {
		console.error(insightResults.data.error)
		return false
	} else return true
}

//Call that comes in to request the Brands and update the store.
export const saveBrandInsightText = (brandID, section, insightText) => (dispatch,getState) => {
	return async (dispatch) => {
		const insightResultsAdd = await saveBrandInsightTextPromise(brandID, section, insightText)
		if (insightResultsAdd) {
			const insightResults = await getBrandInsightTextPromise(brandID)
			dispatch({
				type: 'SET_INSIGHT_TEXT',
				payload: insightResults
			})
		}
	}
};


