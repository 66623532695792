import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';

// Navigation pieces
import AppTopBar from './layout/AppTopbar-container';

// JWT pieces
import withAuth from './components/auth/withAuth'
import AuthService from './services/AuthService'


// Pages
import Overview from './pages/overview/overview-container';
import NewOverview from './pages/new-overview'
import CompareOverview from './pages/compare-overview'
import FranchiseOverview from './pages/franchise-overview'
import FranchiseCompare from './pages/compare-franchise-overview'
import { EmptyPage } from './pages/EmptyPage';
import AgencyProjects from './pages/agency-project-list/agency-project-list-container'
import AgencyProjectDetails from './pages/agency-project-detail/agency-project-detail-container'
import ClientOverview from './pages/client-overview/client-overview-container'
import ManageComments from './pages/manage-comments/manage-comments-container'

//import 'primereact/resources/primereact.min.css';
//import 'primeicons/primeicons.css';
//import 'primeflex/primeflex.css';
//import './App.scss';

const Auth = new AuthService();
const loggedIn = Auth.loggedIn();

if(!loggedIn && window.location.pathname !== '/'){
	window.localStorage.setItem('url',window.location.href);
};

const App = () => {
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);


    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let configClick = false;
	
	let routers = []

	if (Auth.loggedIn()) {
		const profile = Auth.getProfile()
		if (profile.type === 'agency' || profile.type === 'hybrid') {
			routers = [
				{ path: '/projectDetails/:projectid', component: withAuth(AgencyProjectDetails), exact: true, meta: { breadcrumb: [{ parent: 'Agency Projects', label: 'Agency Project Details' }] } },
				{ path: '/', component: withAuth(AgencyProjects), exact: true, meta: { breadcrumb: [{ parent: 'Agency Projects', label: 'Agency Projects' }] } },
			]
		} else if (profile.type === 'client' && profile.isAdmin === true) {
			routers = [
				{ path: '/empty', component: withAuth(EmptyPage), meta: { breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }] } },
				{ path: '/overview/:clientid', component: withAuth(Overview), exact: true },
				{ path: '/manageComments', component: withAuth(ManageComments), exact: true },
				{ path: '/newOverview/:brandid', component: withAuth(NewOverview), exact: true },
				{ path: '/compareOverview/:brandid', component: withAuth(CompareOverview), exact: true },
				{ path: '/franchiseOverview/:franchiseid', component: withAuth(FranchiseOverview), exact: true },
				{ path: '/franchiseCompare/:franchiseid', component: withAuth(FranchiseCompare), exact: true },
				{ path: '/', component: withAuth(ClientOverview), exact: true },
			];
		} else if (profile.type === 'client') {
			routers = [
				{ path: '/empty', component: withAuth(EmptyPage), meta: { breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }] } },
				{ path: '/', component: withAuth(Overview), exact: true, meta: { breadcrumb: [{ parent: 'Overview', label: 'Overview' }] } },
				{ path: '/newOverview/:brandid', component: withAuth(NewOverview), exact: true },
				{ path: '/compareOverview/:brandid', component: withAuth(CompareOverview), exact: true },
				{ path: '/franchiseOverview/:franchiseid', component: withAuth(FranchiseOverview), exact: true },
				{ path: '/franchiseCompare/:franchiseid', component: withAuth(FranchiseCompare), exact: true },
			];
		}
	}

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        }
        else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!menuClick) {
           if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);

       

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
        }
        else {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive(prevTopbarNotificationMenuActive => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive(prevSearchActive => !prevSearchActive);
        searchClick = true;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuButtonClick = (event) => {
        hideOverlayMenu();
        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

   

    const isDesktop = () => {
        return window.innerWidth > 1200;
    };

    const containerClassName = classNames('layout-wrapper',
        {
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive,
            'p-ripple-disabled': true,
        },
    	'layout-sidebar-blue');

    return (
        <div className={containerClassName} data-theme={'light'} onClick={onDocumentClick}>
		    <div className="layout-content-wrapper">
                <AppTopBar 
					routers={routers} 
					topbarNotificationMenuActive={topbarNotificationMenuActive} 
					topbarUserMenuActive={topbarUserMenuActive} 
					onMenuButtonClick={onMenuButtonClick} 
					onSearchClick={toggleSearch}
                    onTopbarNotification={onTopbarNotificationMenuButtonClick} 
					onTopbarUserMenu={onTopbarUserMenuButtonClick} 
					onRightMenuClick={onRightMenuButtonClick} 
					onRightMenuButtonClick={onRightMenuButtonClick}>	
				</AppTopBar>

                <div className="layout-content">
                    {
                        routers.map((router, index) => {
                            if (router.exact) {
                                return <Route key={`router${index}`} path={router.path} exact component={router.component} />
                            }

                            return <Route key={`router${index}`} path={router.path} component={router.component} />
                        })
                    }
                </div>
            </div>
            <div className="layout-mask modal-in"></div>
			
        </div>
    );
}

export default App;
