import React, { useState, useEffect, useMemo, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ZenLogo  from '../images/zenLogo.png'
import ArtLogo  from '../images/artLogo.png'

import AuthService from '../services/AuthService';

export const Login = () => {

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [rememberMe, setRememberMe] = useState(false);
	const [pageDisplay, setPageDisplay] = useState('login'); //login/resetPassword/resetPasswordConfirm

	useEffect(() => {
        let savedEmail = localStorage.getItem('email')
		if (savedEmail) {
			setUsername(savedEmail)
			setRememberMe(true)
		}
    }, []);

	const Auth = useMemo(() => new AuthService(), []);
	const toast = useRef(null);

	let handleFormSubmit = (e) => {
		e.preventDefault();

		if (rememberMe) {
			localStorage.setItem('email', username);
		} else {
			localStorage.removeItem('email');
		}

		if (username !== '' && password !== '' ) {
			Auth.login(username,password).then(res =>{
				//let url = window.localStorage.getItem('url') ? window.localStorage.getItem('url'): '/';
				//window.localStorage.removeItem('url');
				document.location.reload()
				
			}).catch( err => {
				toast.current.show({ severity: 'error', summary: 'Error Message', detail: err, life: 3000 });
			})
			
		} else {
			return false
		}
	}

	let handleFormSubmitResetPassword = (e) => {
		e.preventDefault();

		if (username !== '') {
			Auth.resetPassword(username).then(res =>{
				setPageDisplay('resetPasswordConfirm')
			}).catch( err => {
				toast.current.show({ severity: 'error', summary: 'Error Message', detail: err, life: 3000 });
			})
			
		} else {
			return false
		}
	}

	 return (
        <div className="login-body">
			<Toast ref={toast} />
			<div className="card">

				<div className="grid logos grid-nogutter">
					<div className="col-6 zenLogo">
						<img src={ZenLogo} />
					</div>
					<div className="col-6 artLogo">
						<img src={ArtLogo} />
					</div>
				</div>

				<div className="grid login-form">
					<div className="col-12">
						{pageDisplay === 'login' && 
							<form onSubmit={handleFormSubmit}>

								<div className="field">
									<label htmlFor="username" className="block">Email</label>
									<InputText id="username" className="block" value={username} onChange={(e) => setUsername(e.target.value)}/>
								</div>

								<div className="field">
									<label htmlFor="password" className="block">Password</label>
									<Password id="password" className="block" feedback={false} onChange={(e) => setPassword(e.target.value)} />
								</div>

								<div className="field-checkbox">
									<Checkbox inputId="rememberMe" name="rememberMe" value={true} onChange={(e) => setRememberMe(!rememberMe)} checked={rememberMe} />
									<label htmlFor="rememberMe">Remember Me</label>
								</div>

								<div className="field">
									<Button label="Sign In"></Button>
								</div>
								<div className="forgetPassword p-field" onClick={(e) => setPageDisplay('resetPassword')}>
									Forgot Agency Password? 
								</div>
							</form>
						}

						{pageDisplay === 'resetPassword' && 
							<form onSubmit={handleFormSubmitResetPassword}>
								<div className="field">
									<label htmlFor="username" className="block">Email</label>
									<InputText id="username" className="block" value={username} onChange={(e) => setUsername(e.target.value)}/>
								</div>
								<div className="field">
									<Button label="Reset your password"></Button>
								</div>
								<div className="forgetPassword p-field" onClick={(e) => setPageDisplay('login')}>
									Cancel 
								</div>
							</form>
						}

						{pageDisplay === 'resetPasswordConfirm' && 
							<div className="flex flex-column">
								<div className="forgetPasswordTitle mb-2">Password Reset Email Sent</div>
								<div className="mb-4">
									An email has been sent to the provied email address.  Follow the directions in the email to reset your password.
								</div>
								<div className="field">
									<Button label="Done" onClick={(e) => setPageDisplay('login')}></Button>
								</div>
							</div>
						}


					</div>
				</div>
				
				
						
					
			
			</div>
			
		</div>
	);
}
