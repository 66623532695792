import { createSelector } from 'reselect'

import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)
dayjs.extend(quarterOfYear)

const agencyProjectsEntity = (state) => state.entities.agencyProjects
const agencyStaffEntity = (state) => state.entities.agencyStaff
const agencyStaffLogsEntity = (state) => state.entities.agencyStatusLogs
const brandsEntity = (state) => state.entities.brands
const agencyFunctionsEntity = (state) => state.entities.agencyFunctions
const clientsEntity = (state) => state.entities.clients
const currentClientID = (state) => state.ui.currentClientID

const projectChangeType = (state, props) => {
	try {
		return props.changeType
	} catch (e) {
		return ''
	}	
}

const fncCheckStatus = (objCur) => {
	let blnUseThisOne = false
	if (objCur.status !== 'canceled') blnUseThisOne = true
	return blnUseThisOne
}

const fncGetRateMapping = (arrProjectBaselines, blendedRate = 0) => {
	let objRateLookup = (arrProjectBaselines || []).reduce((acc,cur) => {
		acc[cur.functionID] = cur.rate
		return acc
	},{})
	objRateLookup['blendedRate'] = blendedRate
	return objRateLookup
}

const getDates = (state, props) => {
	try {
		
		if (!state.ui.currentClientID) return null
		let objFiscalDates = state.entities.clients.byId[state.ui.currentClientID].fiscalDates

		let curDate = props.dates
		let startDate = null
		let endDate = null

		if (curDate.indexOf('custom|') === 0) {
			let arrSplit = curDate.split('|')
			curDate = 'custom'
			startDate = dayjs(arrSplit[1])
			endDate = dayjs(arrSplit[2])
		}

		switch (curDate) {
			case 'ytd':
				return {startDate: objFiscalDates.startOfYear, endDate: objFiscalDates.endOfPrevMonth}
			case 'ytd2Month':
				return {startDate: objFiscalDates.startOfYear, endDate: objFiscalDates.endOfPrevTwoMonth}
			case 'lastMonth':
				return {startDate: objFiscalDates.startOfPrevMonth, endDate: objFiscalDates.endOfPrevMonth}
			case 'twoMonthago':
				return {startDate: objFiscalDates.startOfPrevTwoMonth, endDate: objFiscalDates.endOfPrevTwoMonth}
			case 'lastQuarter':
				return {startDate: objFiscalDates.startOfPrevQuarter, endDate: objFiscalDates.endOfPrevQuarter}
			case 'lastYear':
				return {startDate: objFiscalDates.startOfPrevYear, endDate: objFiscalDates.endOfPrevYear}
			case 'fullYear':
				return {startDate: objFiscalDates.startOfYear, endDate: objFiscalDates.endOfYear}
			case 'custom':
				return {startDate: startDate, endDate: endDate}
			default:
				return null
		}
	} catch (e) {
		return null
	}	
}

const getRatios = (dateRange, projectDates) => {
	let intTotalProjectMonths = projectDates.endDate.diff(projectDates.startDate, 'month') + 1
	let rangeStart = dateRange.startDate
	if (rangeStart.isBefore(projectDates.startDate)) {
		rangeStart = projectDates.startDate
	}
	let rangeEnd = dateRange.endDate
	if (rangeEnd.isAfter(projectDates.endDate)) {
		rangeEnd = projectDates.endDate
	}
	let intTotalProjectMonthsInRange = rangeEnd.diff(rangeStart, 'month') + 1

	if (intTotalProjectMonths === 0) return 0
	let returnRatio = intTotalProjectMonthsInRange/intTotalProjectMonths
	if (returnRatio < 0) return 0
	return returnRatio
}

export const getFiscalDates = createSelector(
	[clientsEntity, currentClientID],
	(curClients, curClientID) => {

		if (!curClientID || !curClients.byId[curClientID]) {
			return {
				startOfYear: dayjs().startOf('year'),
				endOfYear: dayjs().endOf('year')
			}
		}
		let objFiscalDates = curClients.byId[curClientID].fiscalDates

		return objFiscalDates
	}
)


const projectsByDate = createSelector(
	[agencyProjectsEntity, getDates],
	(curAgencyProjects, curDates) => {
		
		if (!curDates) return []

		let curFromDate = curDates.startDate
		let curToDate = curDates.endDate

		let curAgencyProjects_byDate = curAgencyProjects.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			
			if ((dayjs(objCur.startDate).isSameOrAfter(dayjs(curFromDate)) && dayjs(objCur.startDate).isSameOrBefore(dayjs(curToDate)))
				||
				(dayjs(objCur.endDate).isSameOrBefore(dayjs(curToDate)) && dayjs(objCur.endDate).isSameOrAfter(dayjs(curFromDate)))
				||
				(dayjs(curFromDate).isSameOrAfter(dayjs(objCur.startDate)) && dayjs(curFromDate).isSameOrBefore(dayjs(objCur.endDate))) 
				||
				(dayjs(curToDate).isSameOrAfter(dayjs(objCur.startDate)) && dayjs(curToDate).isSameOrBefore(dayjs(objCur.endDate))) 
			) {
				acc.push(cur)
			}
			
			return acc
		},[])

		return curAgencyProjects_byDate
	}
  )

  const projectsByYear = createSelector(
	[agencyProjectsEntity, getFiscalDates],
	(curAgencyProjects, curFiscalDates) => {
		
		let curFromDate = curFiscalDates.startOfYear
		let curToDate = curFiscalDates.endOfYear

		let curAgencyProjects_byDate = curAgencyProjects.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			
			if ((dayjs(objCur.startDate).isSameOrAfter(dayjs(curFromDate)) && dayjs(objCur.startDate).isSameOrBefore(dayjs(curToDate)))
				||
				(dayjs(objCur.endDate).isSameOrBefore(dayjs(curToDate)) && dayjs(objCur.endDate).isSameOrAfter(dayjs(curFromDate)))
				||
				(dayjs(curFromDate).isSameOrAfter(dayjs(objCur.startDate)) && dayjs(curFromDate).isSameOrBefore(dayjs(objCur.endDate))) 
				||
				(dayjs(curToDate).isSameOrAfter(dayjs(objCur.startDate)) && dayjs(curToDate).isSameOrBefore(dayjs(objCur.endDate))) 
			) {
				acc.push(cur)
			}
			
			return acc
		},[])

		return curAgencyProjects_byDate
	}
  )

  
  const projectsByLastMonth = createSelector(
	[agencyProjectsEntity, getFiscalDates],
	(curAgencyProjects, curFiscalDates) => {
		
		let curFromDate = curFiscalDates.startOfYear
		let curToDate = curFiscalDates.endOfPrevMonth

		let curAgencyProjects_byDate = curAgencyProjects.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			
			if ((dayjs(objCur.startDate).isSameOrAfter(dayjs(curFromDate)) && dayjs(objCur.startDate).isSameOrBefore(dayjs(curToDate)))
				||
				(dayjs(objCur.endDate).isSameOrBefore(dayjs(curToDate)) && dayjs(objCur.endDate).isSameOrAfter(dayjs(curFromDate)))
				||
				(dayjs(curFromDate).isSameOrAfter(dayjs(objCur.startDate)) && dayjs(curFromDate).isSameOrBefore(dayjs(objCur.endDate))) 
				||
				(dayjs(curToDate).isSameOrAfter(dayjs(objCur.startDate)) && dayjs(curToDate).isSameOrBefore(dayjs(objCur.endDate))) 
			) {
				acc.push(cur)
			}
			
			return acc
		},[])

		return curAgencyProjects_byDate
	}
  )

  const hoursOutsideRange = createSelector(
	[agencyProjectsEntity],
	(curAgencyProjects) => {
		
		let curHoursOutsideRange = curAgencyProjects.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			let curHours = objCur.projectHours || []
			let objRateMapping = fncGetRateMapping(objCur.projectBaselines, objCur.blendedRate)

			let curstartDate = dayjs(objCur.startDate).startOf('month')
			let curendDate = dayjs(objCur.endDate).endOf('month')

			for (let curHourEntry of curHours) {
				let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
				
				if (_rate > 0) {
					
					let isFullStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curstartDate)
					let isFullEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curendDate)

					if (!isFullStartInRange || !isFullEndInRange) {
						let dateKey = dayjs(curHourEntry.dateEntered).format('YYYY-MM')
						if (!acc[dateKey]) acc[dateKey] = []
						
						acc[dateKey].push({
							...objCur,
							dateEntered: curHourEntry.dateEntered,
							hours: curHourEntry.hours,
							spend: _rate *  curHourEntry.hours,
							state: curHourEntry.state,
							functionID: curHourEntry.functionID
							
						})
					}

				}
					
			}
			return acc
		},{})

		return curHoursOutsideRange
	}
  )

  const oopCostsOutsideRange = createSelector(
	[agencyProjectsEntity],
	(curAgencyProjects) => {
		let curHoursOutsideRange = curAgencyProjects.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			let curstartDate = dayjs(objCur.startDate).startOf('month')
			let curendDate = dayjs(objCur.endDate).endOf('month')
			let curCosts = objCur.oopCosts || []
			let blnAdd = false
			let arrTempCurCosts = []
			for (let curCostEntry of curCosts) {
				
				let isFullStartInRange = dayjs(curCostEntry.dateEntered).isSameOrAfter(curstartDate)
				let isFullEndInRange = dayjs(curCostEntry.dateEntered).isSameOrBefore(curendDate)

				if (!isFullStartInRange || !isFullEndInRange) {
					arrTempCurCosts = [...arrTempCurCosts, curCostEntry]
					blnAdd = true
					
				}	

				if (blnAdd) {
					let dateKey = dayjs(curCostEntry.dateEntered).format('YYYY-MM')
					if (!acc[dateKey]) acc[dateKey] = []
						
					acc[dateKey].push({
						...objCur,
						oopCosts: arrTempCurCosts
					})
				}
			}
			return acc
		},{})

		return curHoursOutsideRange
	}
  )


export const getWidgetAnnualBaselineBudget = createSelector(
	[projectsByDate, agencyProjectsEntity, getDates],
	(curprojByDate, curAgencyProjects, curDates) => {
		
		if (!curDates) return 0

		
		//console.log(' -------------- START ---------------')
		let budget = curprojByDate.reduce((acc,cur) => {
			
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc
			
			let projectRatio = getRatios(curDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})
			acc += parseFloat(objCur.totalProjectBudget * projectRatio)
			
			
			//console.log(objCur.projectName + ' projectRatio: ' + projectRatio + ' totalBudget: ' + objCur.totalProjectBudget + ' totalAdded: ' + objCur.totalProjectBudget * projectRatio)
			//console.log('CURRENT RUNNING TOTAL: ' + acc)
			return acc
		},0)
		
		return budget
	}
)

export const getWidgetSpent = createSelector(
	[projectsByDate, agencyProjectsEntity, getDates, hoursOutsideRange],
	(curprojByDate, curAgencyProjects, curDates, curHoursOutsideRange) => {
		
		if (!curDates) return 0
		let curFromDate = curDates.startDate
		let curToDate = curDates.endDate

		//console.log(' -------------- START ---------------')
		let spent = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc
			let objRateMapping = fncGetRateMapping(objCur.projectBaselines, objCur.blendedRate)
			
			let curHours = objCur.projectHours || []
			let curCount = 0
			for (let curHourEntry of curHours) {
				let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
				
				if (_rate > 0) {
					
					let isStartInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(dayjs(objCur.startDate))
					let isEndInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(dayjs(objCur.endDate))
					
					let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(dayjs(curFromDate))
					let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(dayjs(curToDate))

					if (isStartInProjectRange && isEndInProjectRange) {
						if (isStartInRange && isEndInRange) {
							if (curHourEntry.state === 'add') {
								acc += _rate * curHourEntry.hours
								curCount += _rate * curHourEntry.hours
							} else if (curHourEntry.state === 'remove') {
								acc -= _rate * curHourEntry.hours
								curCount -= _rate * curHourEntry.hours
							}
						}
					} 
				} 
			}
			
			return acc
		},0)

		for (let curDateOutside in curHoursOutsideRange) {
				
			let isStartInRange = dayjs(curDateOutside).isSameOrAfter(dayjs(curFromDate))
			let isEndInRange = dayjs(curDateOutside).isSameOrBefore(dayjs(curToDate))

			if (isStartInRange && isEndInRange) {
				for (let curHourEntry of curHoursOutsideRange[curDateOutside]) {
					let objRateMapping = fncGetRateMapping(curHourEntry.projectBaselines, curHourEntry.blendedRate)
					let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
					
					if (curHourEntry.state === 'add') {
						spent += _rate * curHourEntry.hours
					} else if (curHourEntry.state === 'remove') {
						spent -= _rate * curHourEntry.hours
			
					}
				}
			} 
		}
		
		return spent
	}
)

export const getWidgetAnnualStaffingBaseline = createSelector(
	[agencyStaffEntity, getDates],
	(curAgencyProjectStaff, curDates) => {

		if (!curDates) return
		
		let endDateYear = curDates.endDate.year()
		let staffRatio = (curDates.endDate.diff(curDates.startDate,'month') + 1) / 12
		
		let staffBaseline = curAgencyProjectStaff.allIds.reduce((acc, cur) => {
			// just an alias to work with 
			let objCur = curAgencyProjectStaff.byId[cur] || {}
			if (objCur.status === 'deleted') return acc
			for (let curBrand in objCur.baselineHoursByBrand) {
				if (objCur.baselineHoursByBrand[curBrand][endDateYear]) {
					acc += objCur.baselineHoursByBrand[curBrand][endDateYear] * staffRatio
				}
			}
			return acc
		},0)

		return staffBaseline
	}
)

export const getWidgetAnnualStaffingUsed = createSelector(
	[agencyStaffEntity, getDates],
	(curAgencyProjectStaff, curDates) => {

		if (!curDates) return 0
		let curFromDate = curDates.startDate
		let curToDate = curDates.endDate
		let staffHours = curAgencyProjectStaff.allIds.reduce((acc, cur) => {
			// just an alias to work with 
			let objCur = curAgencyProjectStaff.byId[cur] || {}

			if (objCur.status === 'deleted') return acc

			let billedHours = 0
			for (let curHours of objCur.hours) {
				if (dayjs(curHours.dateEntered).isSameOrAfter(dayjs(curFromDate)) && dayjs(curHours.dateEntered).isSameOrBefore(dayjs(curToDate))) {
					if (curHours.state === 'add') {
						billedHours += curHours.hours
					} else if (curHours.state === 'remove') {
						billedHours -= curHours.hours
					}
				}
			}

			acc += billedHours
			return acc
		},0)

		return staffHours
	}
)

export const getWidgetOutOfPocketBaseline = createSelector(
	[projectsByDate, agencyProjectsEntity, getDates],
	(curprojByDate, curAgencyProjects, curDates) => {
		let budget = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc
			let projectRatio = getRatios(curDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})

			acc += parseFloat(objCur.passThrough * projectRatio)
			return acc
		},0)
		
		return budget
	}
)

export const getWidgetOutOfPocketUsed = createSelector( 
	[projectsByDate, agencyProjectsEntity, getDates, oopCostsOutsideRange],
	(curprojByDate, curAgencyProjects, curDates, curOopCostsOutsideRange) => {
		
		if (!curDates) return 0
		let curFromDate = curDates.startDate
		let curToDate = curDates.endDate
		
		let spent = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc
			let curCosts = objCur.oopCosts || []
			for (let curCostEntry of curCosts) {
				
				let isStartInRange = dayjs(curCostEntry.dateEntered).isSameOrAfter(dayjs(curFromDate))
				let isEndInRange = dayjs(curCostEntry.dateEntered).isSameOrBefore(dayjs(curToDate))

				let isStartInProjectRange = dayjs(curCostEntry.dateEntered).isSameOrAfter(dayjs(objCur.startDate))
				let isEndInProjectRange = dayjs(curCostEntry.dateEntered).isSameOrBefore(dayjs(objCur.endDate))

				if (isStartInProjectRange && isEndInProjectRange) {
					if (isStartInRange && isEndInRange) {
						acc += curCostEntry.costs
					}	
				}
			}
			return acc
		},0)

		for (let curDateOutside in curOopCostsOutsideRange) {
			
			let isStartInRange = dayjs(curDateOutside).isSameOrAfter(dayjs(curFromDate))
			let isEndInRange = dayjs(curDateOutside).isSameOrBefore(dayjs(curToDate))
			if (isStartInRange && isEndInRange) {
				for (let curProj of curOopCostsOutsideRange[curDateOutside]) {
					for (let curOopCost of curProj.oopCosts) {
					
						spent += curOopCost.costs
					}
				}
			}
		}

		return spent
	}
)



export const getSpendSummary = createSelector( 
	[projectsByDate, agencyProjectsEntity, getDates, projectsByYear, getFiscalDates, hoursOutsideRange],
	(curprojByDate, curAgencyProjects, curDates, curprojByYear, curFiscalDates, curHoursOutsideRange) => {
		
		if (!curDates || curAgencyProjects.allIds.length === 0) return 0
		let curFromDate = curDates.startDate
		let curToDate = curDates.endDate

		let fiscalDates = {
			startDate: curFiscalDates.startOfYear,
			endDate: curFiscalDates.endOfYear
		}

		let objOutsideRangeProjects = {}
		let statusDetails = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			let curHours = objCur.projectHours || []
			let projectRatio = getRatios(curDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})
			let projectFullYearRatio = getRatios(fiscalDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})
			
			if (!acc[objCur.status]) acc[objCur.status] = {
				spend: 0,
				budget: 0,
				totalCount: 0,
				projects: []
			}

			if (!objOutsideRangeProjects[objCur.projectID]) {
				objOutsideRangeProjects[objCur.projectID] = true
			}

			
			acc[objCur.status].totalCount++
			acc[objCur.status].budget += parseFloat(objCur.totalProjectBudget * projectRatio)
			let totalProjectSpend = 0

			let objRateMapping = fncGetRateMapping(objCur.projectBaselines, objCur.blendedRate)
			
			for (let curHourEntry of curHours) {
				let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
				
				if (_rate > 0) {
					let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(dayjs(curFromDate))
					let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(dayjs(curToDate))

					let isStartInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(dayjs(objCur.startDate))
					let isEndInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(dayjs(objCur.endDate))
					
					if (isStartInProjectRange && isEndInProjectRange) {
						if (isStartInRange && isEndInRange) {
							if (curHourEntry.state === 'add') {
								acc[objCur.status].spend += _rate *  curHourEntry.hours
								totalProjectSpend += _rate *  curHourEntry.hours
							} else if (curHourEntry.state === 'remove') {
								acc[objCur.status].spend -= _rate *  curHourEntry.hours
								totalProjectSpend -= _rate *  curHourEntry.hours
							}
						}
					}
				} 
			}
			acc[objCur.status].projects.push({
				...objCur,
				budget: parseFloat(objCur.totalProjectBudget * projectRatio),
				fiscalYearTotal: parseFloat(objCur.totalProjectBudget * projectFullYearRatio), 
				spend: totalProjectSpend
			})

			acc[objCur.status].projects.sort((a,b) => (a.projectName > b.projectName?1:-1)).sort((a,b) => (a.budget > b.budget?-1:1))
			
			return acc
		},{})


		
		
		for (let curDateOutside in curHoursOutsideRange) {
			
			let isStartInRange = dayjs(curDateOutside).isSameOrAfter(dayjs(curFromDate))
			let isEndInRange = dayjs(curDateOutside).isSameOrBefore(dayjs(curToDate))

			if (isStartInRange && isEndInRange) {
				for (let curHourEntry of curHoursOutsideRange[curDateOutside]) {
					
					let objRateMapping = fncGetRateMapping(curHourEntry.projectBaselines, curHourEntry.blendedRate)
					let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
					if (!statusDetails[curHourEntry.status]) statusDetails[curHourEntry.status] = {
						spend: 0,
						budget: 0,
						totalCount: 0,
						projects: []
					}

					if (curHourEntry.state === 'add') {
						statusDetails[curHourEntry.status].spend += _rate * curHourEntry.hours
					} else if (curHourEntry.state === 'remove') {
						statusDetails[curHourEntry.status].spend -= _rate * curHourEntry.hours
					}

					// Add on projects that have hours outside the scope
					if (!objOutsideRangeProjects[curHourEntry.projectID]) {
						objOutsideRangeProjects[curHourEntry.projectID] = true
						statusDetails[curHourEntry.status].totalCount++
						statusDetails[curHourEntry.status].projects.push({
							...curHourEntry,
							spend: 0
						})
					}
						
					if (curHourEntry.state === 'add') {
						statusDetails[curHourEntry.status].projects.find(cur => cur.projectID === curHourEntry.projectID).spend += _rate * curHourEntry.hours
					} else if (curHourEntry.state === 'remove') {
						statusDetails[curHourEntry.status].projects.find(cur => cur.projectID === curHourEntry.projectID).spend -= _rate * curHourEntry.hours
					}
				}
			} 
		}

		let statusDetailsByYear = curprojByYear.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			let projectRatio = getRatios(fiscalDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})
			if (!acc[objCur.status]) acc[objCur.status] = 0

			acc[objCur.status] += parseFloat(objCur.totalProjectBudget * projectRatio)
			return acc
		},{})
		
		let objOrdering = {
			active: 1,
			not_started: 2,
			hold: 3,
			canceled: 4,
			completed: 5
		}

		let arrStatusDetails = Object.keys(statusDetails).map(cur => {
			let objCur = statusDetails[cur]

			let curOrdering = objOrdering[cur] || 9
			return {
				status: cur,
				...objCur,
				yearBudget: statusDetailsByYear[cur] || 0,
				order: curOrdering
			}
		}).sort((a,b) => (a.order > b.order?1:-1))

		return arrStatusDetails
	}
)

export const getPriorityProjects = createSelector(
	[projectsByDate, agencyProjectsEntity, getDates],
	(curprojByDate, curAgencyProjects, curDates) => {
		
		if (!curDates || curAgencyProjects.allIds.length === 0) return []

		let statusDetails = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			
			acc.push(objCur)
			return acc
		},[]).sort((a,b) => (a.projectName > b.projectName?-1:1)).sort((a,b) => (a.brandName > b.brandName?1:-1))

		
		return statusDetails
	}
)

const projectsByMonth = createSelector(
	[agencyProjectsEntity, getFiscalDates],
	(curAgencyProjects, curFiscalDates) => {
		let objYears = {}
		for (let curIndex = 0; curIndex < 2; curIndex++) {
			let objMonth = {}
			for(let curMonth = 0; curMonth < 12;curMonth++) {

				let curFromDate = dayjs(curFiscalDates.startOfYear).add(curMonth, 'month').startOf('month')
				let curToDate = dayjs(curFiscalDates.startOfYear).add(curMonth, 'month').endOf('month')

				if (curIndex === 1) {
					curFromDate = dayjs(curFiscalDates.startOfPrevYear).add(curMonth, 'month').startOf('month')
					curToDate = dayjs(curFiscalDates.startOfPrevYear).add(curMonth, 'month').endOf('month')
				}

				let actualCurMonth = curFromDate.month()
				let curAgencyProjects_byDate = curAgencyProjects.allIds.reduce((acc,cur) => {
					let objCur = curAgencyProjects.byId[cur]
					if (!fncCheckStatus(objCur)) return acc
					
					if ((dayjs(objCur.startDate).isSameOrAfter(dayjs(curFromDate)) && dayjs(objCur.startDate).isSameOrBefore(dayjs(curToDate)))
						||
						(dayjs(objCur.endDate).isSameOrBefore(dayjs(curToDate)) && dayjs(objCur.endDate).isSameOrAfter(dayjs(curFromDate)))
						||
						(dayjs(curFromDate).isSameOrAfter(dayjs(objCur.startDate)) && dayjs(curFromDate).isSameOrBefore(dayjs(objCur.endDate))) 
						||
						(dayjs(curToDate).isSameOrAfter(dayjs(objCur.startDate)) && dayjs(curToDate).isSameOrBefore(dayjs(objCur.endDate))) 
					) {
						acc.push(cur)
					}
					
					return acc
				},[])
				
				objMonth[actualCurMonth] = curAgencyProjects_byDate
			}
			objYears[curIndex] = objMonth
		}

		return objYears
	}
)

export const getMonthlyBurnRate = createSelector(
	[projectsByMonth, agencyProjectsEntity, getDates, getFiscalDates, hoursOutsideRange],
	(curProjectsByMonthYear, curAgencyProjects, curDates, curFiscalDates, curHoursOutsideRange) => {
		if (!curDates) return {}
		
		let curProjectsByMonth = curProjectsByMonthYear[0]
		if (curDates.startDate === curFiscalDates.startOfPrevYear) curProjectsByMonth = curProjectsByMonthYear[1]
		
		//console.log('--------getMonthlyBurnRate -------',curProjectsByMonth)
		let arrMonthlyBudget = []
		let arrMonthlySpend = []
		let arrMonthlySpendOutside = []
		let arrMonthlyHours = []
		let arrMonthlyFullLog = []

		//todo: check this with fiscal
		let arrMonthlyNotInRange = curAgencyProjects.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			let curstartDate = dayjs(objCur.startDate).startOf('month')
			let curendDate = dayjs(objCur.endDate).endOf('month')
			let curHours = objCur.projectHours || []
			let objRateMapping = fncGetRateMapping(objCur.projectBaselines, objCur.blendedRate)
			for (let curHourEntry of curHours) {
				let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
				if (_rate > 0) {
					let isFullStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curstartDate)
					let isFullEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curendDate)

					if (!isFullStartInRange || !isFullEndInRange) {
						
						acc.push({
							projectName: objCur.projectName,
							startDate: curstartDate.format('MMMM DD, YYYY'),
							endDate: curendDate.format('MMMM DD, YYYY'),
							dateEntered: curHourEntry.dateEntered,
							hours: curHourEntry.hours,
							spend: _rate *  curHourEntry.hours,
							state: curHourEntry.state
						})
					}
				}
			}
			return acc
		},[])

		for(let curMonth = 0; curMonth < 12;curMonth++) {
			
			
			let curFromDate = dayjs(curDates.startDate).add(curMonth, 'month').startOf('month')
			let curToDate = dayjs(curDates.startDate).add(curMonth, 'month').endOf('month')
			let actualCurMonth = curFromDate.month()
			
			let curMonthlyDates = {
				startDate: curFromDate,
				endDate: curToDate
			}
			

			let budget = (curProjectsByMonth[actualCurMonth] || []).reduce((acc,cur) => {
				let objCur = curAgencyProjects.byId[cur]
				if (!fncCheckStatus(objCur)) return acc


				let curstartDate = dayjs(objCur.startDate).startOf('month')
				let curendDate = dayjs(objCur.endDate).endOf('month')

				let projectRatio = getRatios(curMonthlyDates,{startDate: curstartDate, endDate: curendDate})
				acc += parseFloat(objCur.totalProjectBudget * projectRatio)
				return acc
			},0)
			arrMonthlyBudget.push(budget)

			let objSpend = (curProjectsByMonth[actualCurMonth] || []).reduce((acc,cur) => {
				let objCur = curAgencyProjects.byId[cur]
				if (!fncCheckStatus(objCur)) return acc
				let objRateMapping = fncGetRateMapping(objCur.projectBaselines, objCur.blendedRate)
				let curHours = objCur.projectHours || []
				for (let curHourEntry of curHours) {
					let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
					if (_rate > 0) {
						let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curMonthlyDates.startDate)
						let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curMonthlyDates.endDate)
						if (isStartInRange && isEndInRange) {
							if (curHourEntry.state === 'add') {
								acc.spend += _rate *  curHourEntry.hours
								acc.hours += curHourEntry.hours
							} else if (curHourEntry.state === 'remove') {
								acc.spend -= _rate *  curHourEntry.hours
								acc.hours -= curHourEntry.hours
							}
							acc.fullLog.push({
								projectName: objCur.projectName,
								startDate: curMonthlyDates.startDate.format('MMMM DD, YYYY'),
								endDate: curMonthlyDates.endDate.format('MMMM DD, YYYY'),
								dateEntered: curHourEntry.dateEntered,
								hours: curHourEntry.hours,
								spend: _rate *  curHourEntry.hours,
								state: curHourEntry.state,
								currentSpend: acc.spend,
								currentHours: acc.hours
							})
							
							//console.log('ID for monthly (' + actualCurMonth + '): ' + cur + ' ' + acc)
						} 
					}
				}


				

				return acc
			},{spend: 0, hours: 0, fullLog: []})

			let outsideSpend = 0
			for (let curDateOutside in curHoursOutsideRange) {
				
				let isStartInRange = dayjs(curDateOutside).isSameOrAfter(dayjs(curFromDate))
				let isEndInRange = dayjs(curDateOutside).isSameOrBefore(dayjs(curToDate))
				

				if (isStartInRange && isEndInRange) {
					for (let curHourEntry of curHoursOutsideRange[curDateOutside]) {
						
						let objRateMapping = fncGetRateMapping(curHourEntry.projectBaselines, curHourEntry.blendedRate)
						let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
						if (curHourEntry.state === 'add') {
							//objSpend.spend += _rate * curHourEntry.hours
							outsideSpend += _rate * curHourEntry.hours
							//objSpend.hours += curHourEntry.hours
						} else if (curHourEntry.state === 'remove') {
							//objSpend.spend -= _rate * curHourEntry.hours
							outsideSpend -= _rate * curHourEntry.hours
							//objSpend.hours -= curHourEntry.hours
						}
					}
				} 
			}

			arrMonthlySpendOutside.push(outsideSpend)
			arrMonthlySpend.push(objSpend.spend)
			arrMonthlyHours.push(objSpend.hours)
			arrMonthlyFullLog.push(objSpend.fullLog)
		}

		return {
			budget: arrMonthlyBudget,
			actual: arrMonthlySpend,
			outside: arrMonthlySpendOutside,
			hours: arrMonthlyHours,
			fullLog: arrMonthlyFullLog,
			notInRange: arrMonthlyNotInRange,
			startingMonth: curFiscalDates.startOfYear.month()
		}
	}
)

export const getSpendByAudience = createSelector(
	[projectsByDate, agencyProjectsEntity, getFiscalDates],
	(curprojByDate, curAgencyProjects, curFiscalDates) => {
		
		let ytdCurDates = {
			startDate: curFiscalDates.startOfYear,
			endDate: curFiscalDates.endOfPrevMonth
		}

		let fullYearCurDates = {
			startDate: curFiscalDates.startOfYear,
			endDate: curFiscalDates.endOfYear
		}
		let totalSpend = 0
		let audienceBreakdown = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc
			let strCurrentAudienceName = 'Unbranded'

			let projectYtdRatio = getRatios(ytdCurDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})
			let projectFullYearRatio = getRatios(fullYearCurDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})

			if (objCur.audienceName !== '') strCurrentAudienceName = objCur.audienceName

			if (!acc[strCurrentAudienceName]) {
				acc[strCurrentAudienceName] = {
					audienceName: strCurrentAudienceName,
					annualBudget: 0,
					ytdBudget: 0, 
					spend: 0,
					percentSpend: 0
				}
			}

			acc[strCurrentAudienceName].ytdBudget += projectYtdRatio * objCur.totalProjectBudget
			acc[strCurrentAudienceName].annualBudget += projectFullYearRatio * objCur.totalProjectBudget
			let objRateMapping = fncGetRateMapping(objCur.projectBaselines, objCur.blendedRate)
			let curHours = objCur.projectHours || []
			
			for (let curHourEntry of curHours) {
				let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
				if (_rate > 0) {
					
					// this controls the range for the actual spend, it could either be full year or end of last month. Just change the dates here
					let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curFiscalDates.startOfYear)
					let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curFiscalDates.endOfPrevMonth)
					if (isStartInRange && isEndInRange) {
						let spendAmount = _rate *  curHourEntry.hours
						if (curHourEntry.state === 'add') {
							acc[strCurrentAudienceName].spend += spendAmount
							totalSpend += spendAmount
						} else if (curHourEntry.state === 'remove') {
							acc[strCurrentAudienceName].spend -= spendAmount
							totalSpend -= spendAmount
						}
					}
				}
			}
			
			return acc
		},{})

		// set the percent after done calcuting the audience
		let arrReturn = []
		for (let curAudience in audienceBreakdown) {
			audienceBreakdown[curAudience].percentSpend = Math.round((audienceBreakdown[curAudience].spend / totalSpend) * 100)
			audienceBreakdown[curAudience].differenceBudget = audienceBreakdown[curAudience].ytdBudget - audienceBreakdown[curAudience].spend
			
			arrReturn.push(audienceBreakdown[curAudience])
		}


		return arrReturn.sort((a,b) => (a.percentSpend > b.percentSpend?-1:1))
	}
)

export const getHoursByResource = createSelector(
	[projectsByDate, agencyProjectsEntity, agencyFunctionsEntity, getDates, hoursOutsideRange],
	(curprojByDate, curAgencyProjects, curAgencyFunctions, curDates, curHoursOutsideRange) => {

		if (!curDates) return []

		let resourceBreakdown = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc
			let curBaselines = objCur.projectBaselines || []
			let curHours = objCur.projectHours || []
			let projectFullYearRatio = getRatios(curDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})

			for (let curBaselineEntry of curBaselines) {
				if (curBaselineEntry.hours > 0) {
					if (!acc[curBaselineEntry.functionID]) {
						let objCurFunction = curAgencyFunctions.byId[curBaselineEntry.functionID] || {}
						
						acc[curBaselineEntry.functionID] = {
							functionID: curBaselineEntry.functionID, 
							budgetHours: 0,
							budgetYtdHours: 0,
							usedHours: 0,
							percentUsed: 0, 
							functionTitle: objCurFunction.functionTitle
						}
						
					}
					
					acc[curBaselineEntry.functionID].budgetHours += curBaselineEntry.hours * projectFullYearRatio
				}
			}
			
			for (let curHourEntry of curHours) {
				// this controls the range for the actual spend, based on the dates passed in
				let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curDates.startDate)
				let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curDates.endDate)
				let isStartInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(dayjs(objCur.startDate))
				let isEndInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(dayjs(objCur.endDate))
				
				if (isStartInProjectRange && isEndInProjectRange) {
					if (isStartInRange && isEndInRange && curHourEntry.hours > 0) {
					
						if (!acc[curHourEntry.functionID]) {
							let objCurFunction = curAgencyFunctions.byId[curHourEntry.functionID] || {}
							
							acc[curHourEntry.functionID] = {
								functionID: curHourEntry.functionID, 
								budgetHours: 0,
								budgetYtdHours: 0,
								usedHours: 0,
								percentUsed: 0, 
								functionTitle: objCurFunction.functionTitle
							}
						}

						if (curHourEntry.state === 'add') {
							acc[curHourEntry.functionID].usedHours += curHourEntry.hours
						} else if (curHourEntry.state === 'remove') {
							acc[curHourEntry.functionID].usedHours -= curHourEntry.hours
						}
					}
				} 
			}
			return acc
		},{})

		for (let curDateOutside in curHoursOutsideRange) {
				
			let isStartInRange = dayjs(curDateOutside).isSameOrAfter(curDates.startDate)
			let isEndInRange = dayjs(curDateOutside).isSameOrBefore(curDates.endDate)

			if (isStartInRange && isEndInRange) {
				for (let curHourEntry of curHoursOutsideRange[curDateOutside]) {
					
					
					if (!resourceBreakdown[curHourEntry.functionID]) {
						let objCurFunction = curAgencyFunctions.byId[curHourEntry.functionID] || {}
						
						resourceBreakdown[curHourEntry.functionID] = {
							functionID: curHourEntry.functionID, 
							budgetHours: 0,
							percentUsed: 0,
							usedHours: 0,
							functionTitle: objCurFunction.functionTitle
						}
					}
					
					if (curHourEntry.state === 'add') {
						resourceBreakdown[curHourEntry.functionID].usedHours += curHourEntry.hours
					} else if (curHourEntry.state === 'remove') {
						resourceBreakdown[curHourEntry.functionID].usedHours -= curHourEntry.hours
					}
				}
			} 
		}

		// set the percent after done calcuting the audience
		let arrReturn = []
		for (let curResource in resourceBreakdown) {
			if (resourceBreakdown[curResource].budgetHours > 0) {
				resourceBreakdown[curResource].percentUsed = (resourceBreakdown[curResource].usedHours / resourceBreakdown[curResource].budgetHours)
			}
			// Regular, green colored
			let varianceLevel = 'low'

			// A little Off either high or low, orange colored
			if (resourceBreakdown[curResource].percentUsed >= .8 && resourceBreakdown[curResource].percentUsed < .9) varianceLevel = 'medium'
			if (resourceBreakdown[curResource].percentUsed > 1.1 && resourceBreakdown[curResource].percentUsed <= 1.2) varianceLevel = 'medium'

			// Way off, either high or low, red colored
			if (resourceBreakdown[curResource].percentUsed < .8) varianceLevel = 'high'
			if (resourceBreakdown[curResource].percentUsed > 1.2) varianceLevel = 'high'

			resourceBreakdown[curResource].varianceLevel = varianceLevel
			
			arrReturn.push(resourceBreakdown[curResource])
		}
		
		return arrReturn
	}
)

export const getDepartmentalHours = createSelector(
	[projectsByDate, agencyProjectsEntity, agencyFunctionsEntity, getDates, hoursOutsideRange],
	(curprojByDate, curAgencyProjects, curAgencyFunctions, curDates, curHoursOutsideRange) => {
		
		if (!curDates) return []
		
		let resourceBreakdown = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc
			let curBaselines = objCur.projectBaselines || []
			let curHours = objCur.projectHours || []
			
			
			let projectRatio = getRatios(curDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})
			
			for (let curBaselineEntry of curBaselines) {
				if (curBaselineEntry.hours > 0) {
					if (!acc[curBaselineEntry.functionID]) {
						let objCurFunction = curAgencyFunctions.byId[curBaselineEntry.functionID] || {}
						
						acc[curBaselineEntry.functionID] = {
							functionID: curBaselineEntry.functionID, 
							budgetHours: 0,
							percentUsed: 0,
							usedHours: 0,
							outsideHours: 0,
							functionTitle: objCurFunction.functionTitle
						}
						
					}
					//console.log(cur + '  ' + projectRatio)
					acc[curBaselineEntry.functionID].budgetHours += curBaselineEntry.hours * projectRatio
				}
			}

			for (let curHourEntry of curHours) {
				let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curDates.startDate)
				let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curDates.endDate)

				let isStartInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(dayjs(objCur.startDate))
				let isEndInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(dayjs(objCur.endDate))

				if (isStartInProjectRange && isEndInProjectRange) {

					if (isStartInRange && isEndInRange && curHourEntry.hours > 0) {
					
						if (!acc[curHourEntry.functionID]) {
							let objCurFunction = curAgencyFunctions.byId[curHourEntry.functionID] || {}
							
							acc[curHourEntry.functionID] = {
								functionID: curHourEntry.functionID, 
								budgetHours: 0,
								percentUsed: 0,
								usedHours: 0,
								outsideHours: 0,
								functionTitle: objCurFunction.functionTitle
							}
						}

						if (curHourEntry.state === 'add') {
							acc[curHourEntry.functionID].usedHours += curHourEntry.hours
						} else if (curHourEntry.state === 'remove') {
							acc[curHourEntry.functionID].usedHours -= curHourEntry.hours
						}
					}
				}
			}
			return acc
		},{})

		for (let curDateOutside in curHoursOutsideRange) {
				
			let isStartInRange = dayjs(curDateOutside).isSameOrAfter(curDates.startDate)
			let isEndInRange = dayjs(curDateOutside).isSameOrBefore(curDates.endDate)

			if (isStartInRange && isEndInRange) {
				for (let curHourEntry of curHoursOutsideRange[curDateOutside]) {
					if (!resourceBreakdown[curHourEntry.functionID]) {
						let objCurFunction = curAgencyFunctions.byId[curHourEntry.functionID] || {}
						
						resourceBreakdown[curHourEntry.functionID] = {
							functionID: curHourEntry.functionID, 
							budgetHours: 0,
							percentUsed: 0,
							usedHours: 0,
							outsideHours: 0,
							functionTitle: objCurFunction.functionTitle
						}
					}
					
					if (curHourEntry.state === 'add') {
						resourceBreakdown[curHourEntry.functionID].outsideHours += curHourEntry.hours
					} else if (curHourEntry.state === 'remove') {
						resourceBreakdown[curHourEntry.functionID].outsideHours -= curHourEntry.hours
					}
				}
			} 
		}

		// set the percent after done calcuting the audience
		let arrReturn = []
		for (let curResource in resourceBreakdown) {
			if (resourceBreakdown[curResource].budgetHours > 0) {
				resourceBreakdown[curResource].percentUsed = (resourceBreakdown[curResource].usedHours + resourceBreakdown[curResource].outsideHours) / resourceBreakdown[curResource].budgetHours 
			}
			arrReturn.push(resourceBreakdown[curResource])
		}
		
		

		return arrReturn
	}
)

export const getActualVsBudgetStaffHours = createSelector(
	[agencyStaffEntity, getDates],
	(curAgencyProjectStaff, curDates) => {

		if (!curDates) return
		let endDateYear = curDates.endDate.year()
		
		let staffBaseline = curAgencyProjectStaff.allIds.reduce((acc, cur) => {
			// just an alias to work with 
			let objCur = curAgencyProjectStaff.byId[cur] || {}

			for (let curBaselineEntry in objCur.baselineHoursByBrand) {
				if (objCur.status === 'deleted') return acc
				if (objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]) {
					if (objCur.isSenior === 1) {
						acc.senior += objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]
					} else {
						acc.notSenior += objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]
					}
					acc.all += objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]
				}
			}
			return acc
		},{senior:0, notSenior:0, all: 0})

		let arrMonthlySeniorBudget = []
		let arrMonthlySeniorSpend = []
		let arrMonthlyNotSeniorBudget = []
		let arrMonthlyNotSeniorSpend = []
		let arrAllBudget = []
		let arrAllSpend = []


		for(let curMonth = 0; curMonth < 12;curMonth++) {
			
			
			let curFromDate = dayjs(curDates.startDate).add(curMonth, 'month').startOf('month')
			let curToDate = dayjs(curDates.startDate).add(curMonth, 'month').endOf('month')
			let actualCurMonth = curFromDate.month()
			
			let curMonthlyDates = {
				startDate: curFromDate,
				endDate: curToDate
			}
			
			
			arrMonthlySeniorBudget.push(staffBaseline.senior / 12)
			arrMonthlyNotSeniorBudget.push(staffBaseline.notSenior / 12)
			arrAllBudget.push(staffBaseline.all / 12)

			let monthlySeniorTotal = 0
			let monthlyNonSeniorTotal = 0
			let allTotal = 0
			
			for (let curID of curAgencyProjectStaff.allIds) {
				let objCur = curAgencyProjectStaff.byId[curID]
				for (let curHourEntry of objCur.hours) {
					
					let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curMonthlyDates.startDate)
					let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curMonthlyDates.endDate)
					if (isStartInRange && isEndInRange) {
						if (objCur.isSenior === 1) {
							if (curHourEntry.state === 'add') {
								monthlySeniorTotal += curHourEntry.hours
								allTotal += curHourEntry.hours
							} else if (curHourEntry.state === 'remove') {
								monthlySeniorTotal -= curHourEntry.hours
								allTotal -= curHourEntry.hours
							}
						} else {
							if (curHourEntry.state === 'add') {
								monthlyNonSeniorTotal += curHourEntry.hours
								allTotal += curHourEntry.hours
							} else if (curHourEntry.state === 'remove') {
								monthlyNonSeniorTotal -= curHourEntry.hours
								allTotal -= curHourEntry.hours
							}
						}
					}
				}
			}
			arrMonthlySeniorSpend.push(monthlySeniorTotal)
			arrMonthlyNotSeniorSpend.push(monthlyNonSeniorTotal)
			if (monthlySeniorTotal > 0 || monthlyNonSeniorTotal > 0 ) {
				arrAllSpend.push({
					month: dayjs().month(actualCurMonth).format('MMMM'),
					seniorActual: monthlySeniorTotal,
					seniorBudget: staffBaseline.senior / 12,
					seniorPercentage: Math.round(monthlySeniorTotal / (staffBaseline.senior / 12) * 100),
					otherActual: monthlyNonSeniorTotal,
					otherBudget: staffBaseline.notSenior / 12,
					otherPercentage: Math.round(monthlyNonSeniorTotal / (staffBaseline.notSenior / 12) * 100),
				})
			}
		}

		return {
			senior: {
				budget: arrMonthlySeniorBudget,
				used: arrMonthlySeniorSpend
			},
			notSenior: {
				budget: arrMonthlyNotSeniorBudget,
				used: arrMonthlyNotSeniorSpend
			},
			all: arrAllSpend
		}
	}
)

export const getStaffHours = createSelector(
	[agencyStaffEntity, agencyFunctionsEntity, getDates],
	(curAgencyProjectStaff, curAgencyFunctions, curDates) => {
		
		if (!curDates) return []
		let staffRatio = (curDates.endDate.diff(curDates.startDate,'month') + 1) / 12
		
		let arrStaffDetails = curAgencyProjectStaff.allIds.reduce((acc, cur) => {
			let objCur = curAgencyProjectStaff.byId[cur]
			if (objCur.status === 'deleted') return acc
			let objCurFunction = curAgencyFunctions.byId[objCur.functionID] || {}
			let spent = 0
			for (let curHourEntry of objCur.hours) {
				let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curDates.startDate)
				let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curDates.endDate)
				if (isStartInRange && isEndInRange && curHourEntry.hours > 0) {
					if (curHourEntry.state === 'add') {
						spent += curHourEntry.hours
					} else if (curHourEntry.state === 'remove') {
						spent -= curHourEntry.hours
					}
				}
			}
			
			let endDateYear = curDates.endDate.year()
			let baselineHours = 0
			for (let curBaselineEntry in objCur.baselineHoursByBrand) {
				if (objCur.status === 'deleted') return acc
				if (objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]) {
					baselineHours += objCur.baselineHoursByBrand[curBaselineEntry][endDateYear] * staffRatio
				}
			}
				
			let percentUsed = 0
			if (baselineHours > 0) percentUsed = (spent / baselineHours) * 100

			acc.push( {
				...objCur,
				baselineHours: baselineHours,
				functionTitle: objCurFunction.functionTitle,
				spent: spent,
				percentUsed: percentUsed
			})

			return acc
		},[])

		return arrStaffDetails
	}
)

export const getStaffChanges = createSelector(
	[agencyStaffEntity, agencyStaffLogsEntity, agencyFunctionsEntity, getDates],
	(curAgencyProjectStaff, curAgencyStaffLogs, curAgencyFunctions, curDates) => {
		
		if (!curDates) return []
		let objGroupedChanges = curAgencyStaffLogs.allIds.reduce((acc,cur, index) => {
			let objCur = curAgencyStaffLogs.byId[cur]

			let isStartInRange = dayjs(objCur.timeStamp).isSameOrAfter(curDates.startDate)
			let isEndInRange = dayjs(objCur.timeStamp).isSameOrBefore(curDates.endDate)

			if (isStartInRange && isEndInRange) {

				let tempKey = objCur.staffID + '_' + dayjs(objCur.timeStamp).format('YYYY-MM-DD')
				if (objCur.logType === 'addStaff' || objCur.logType === 'deleteStaff') {
					tempKey = objCur.staffID + '_' + objCur.logType
				} 

				if (!acc[tempKey]) {
					let objCurStaff = curAgencyProjectStaff.byId[objCur.staffID] || {}
					let objCurFunction = curAgencyFunctions.byId[objCurStaff.functionID] || {}
					let objCurDate = dayjs(objCur.timeStamp)

					
					let brandAudiences = []
					for (let curBrand in objCurStaff.audiences) {
						for (let curAudience of objCurStaff.audiences[curBrand]) {
							if (curAudience === 'Unbranded') brandAudiences.push(curBrand)
							else brandAudiences.push(curBrand + ': ' + curAudience)
						}
					}
					
					acc[tempKey] = {
						name: objCurStaff.name,
						title: objCurStaff.title,
						isSenior: objCurStaff.isSenior,
						staffType: objCurStaff.staffType,
						functionTitle: objCurFunction.functionTitle,
						logType: objCur.logType,
						comments: objCurStaff.comments,
						status:objCurStaff.status,
						timeStamp: objCurDate.format('MM/DD/YY'),
						brandAudiences: brandAudiences,
						logTypes: []
					}
				} 

				if (objCur.logType === 'addStaff' || objCur.logType === 'deleteStaff' || objCur.changes === '') {
					acc[tempKey].logTypes.push({
						logType: objCur.logType
					})
				} else {
					acc[tempKey].logTypes.push({
						logType: 'editDetails',
						fieldChange: objCur.changes,
						oldValue: objCur.oldValue,
						newValue: objCur.newValue,
						timeStamp: objCur.timeStamp
					})
				}

				
				
			}
			return acc
		},{})

		

		let arrStaffDetails = []

		for (let curChange in objGroupedChanges) {
			
			objGroupedChanges[curChange].logTypes.sort((a,b) => (a.timeStamp > b.timeStamp?-1:1))
			
			arrStaffDetails.push(objGroupedChanges[curChange])
		}

		return arrStaffDetails.sort((a,b) => (a.functionTitle > b.functionTitle?-1:1)).sort((a,b) => (a.logType > b.logType?1:-1)).sort((a,b) => (a.timeStamp > b.timeStamp?-1:1))
	}
)

export const getStaffByAudience = createSelector(
	[agencyStaffEntity, getDates],
	(curAgencyProjectStaff, curDates) => {
		if (!curDates) return []
		let endDateYear = curDates.endDate.year()
		let totalSpend = 0
		let audienceBreakdown = curAgencyProjectStaff.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjectStaff.byId[cur]

			if (objCur.status === 'deleted') return acc
			for (let curBaselineEntry in objCur.baselineHoursByBrand) {
				
				if (objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]) {
					let strCurrentAudienceName = 'Unbranded'
					let arrTemp = curBaselineEntry.split('|')

					if (arrTemp.length === 3 && arrTemp[2] !== '') {
						strCurrentAudienceName = arrTemp[2]
					}

					if (!acc[strCurrentAudienceName]) {
						acc[strCurrentAudienceName] = {
							audienceName: strCurrentAudienceName,
							budget: 0,
							spend: 0,
							percentSpend: 0
						}
					}

					acc[strCurrentAudienceName].budget += objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]
				}
			}

			let curHours = objCur.hours || []
			for (let curHourEntry of curHours) {
				
				let strCurrentAudienceName = 'Unbranded'
				if (curHourEntry.audienceName !== '') strCurrentAudienceName =curHourEntry.audienceName
				// this controls the range for the actual spend, it could either be full year or end of last month. Just change the dates here
				let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curDates.startDate)
				let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curDates.endDate)
				if (isStartInRange && isEndInRange) {
					
					if (!acc[strCurrentAudienceName]) {
						acc[strCurrentAudienceName] = {
							audienceName: strCurrentAudienceName,
							budget: 0,
							spend: 0,
							percentSpend: 0
						}
					}
					
					let spendHours = curHourEntry.hours
					if (curHourEntry.state === 'add') {
						acc[strCurrentAudienceName].spend += spendHours
						totalSpend += spendHours
					} else if (curHourEntry.state === 'remove') {
						acc[strCurrentAudienceName].spend -= spendHours
						totalSpend -= spendHours
					}
				}
			}
			
			
			return acc
		},{})
		

		// set the percent after done calcuting the audience
		let arrReturn = []
		for (let curAudience in audienceBreakdown) {
			audienceBreakdown[curAudience].percentSpend = Math.round((audienceBreakdown[curAudience].spend / totalSpend) * 100)
			
			arrReturn.push(audienceBreakdown[curAudience])
		}


		return arrReturn
	}
)

export const getFullStaffByAudience = createSelector(
	[agencyStaffEntity, agencyFunctionsEntity, getDates],
	(curAgencyProjectStaff, curAgencyFunctions, curDates) => {
		
		if (!curDates) return []
		let endDateYear = curDates.endDate.year()

		let arrStaffDetails = curAgencyProjectStaff.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjectStaff.byId[cur] || {}
			if (!objCur || objCur.status === 'deleted') return acc
			let objCurFunction = curAgencyFunctions.byId[objCur.functionID] || {}
			let objTotal = {
				total_utilized: 0,
				total_scoped: 0,
				total_percent: 0
			}
			let objSpent = {}
			for (let curHourEntry of objCur.hours) {
				let strCurrentAudienceName = 'Unbranded'
				if (curHourEntry.audienceName !== '') strCurrentAudienceName =curHourEntry.audienceName
				let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curDates.startDate)
				let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curDates.endDate)
				if (isStartInRange && isEndInRange && curHourEntry.hours > 0) {
					if (!objSpent[strCurrentAudienceName + '_utilized']) objSpent[strCurrentAudienceName + '_utilized'] =0
					if (curHourEntry.state === 'add') {
						objSpent[strCurrentAudienceName + '_utilized'] += curHourEntry.hours
						objTotal.total_utilized += curHourEntry.hours
					} else if (curHourEntry.state === 'remove') {
						objSpent[strCurrentAudienceName + '_utilized'] -= curHourEntry.hours
						objTotal.total_utilized -= curHourEntry.hours
					}
					
				}
			}
			
			let objBaselines = {}
			let objPercent = {}
			for (let curBaselineEntry in objCur.baselineHoursByBrand) {
				if (objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]) {
					let strCurrentAudienceName = 'Unbranded'
					let arrTemp = curBaselineEntry.split('|')

					if (arrTemp.length === 3 && arrTemp[2] !== '') {
						strCurrentAudienceName = arrTemp[2]
					}

					objBaselines[strCurrentAudienceName + '_scoped'] = objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]
					objTotal.total_scoped += objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]

					if (objSpent[strCurrentAudienceName + '_utilized']) {
						objBaselines[strCurrentAudienceName + '_percent'] = objSpent[strCurrentAudienceName + '_utilized'] / objBaselines[strCurrentAudienceName + '_scoped'] * 100
					}  else {
						objBaselines[strCurrentAudienceName + '_percent'] = 0
						objSpent[strCurrentAudienceName + '_utilized'] = 0
					}
					objTotal.total_percent = objTotal.total_utilized / objTotal.total_scoped * 100
				}
				
			}
			
			acc.push({
				...objCur,
				functionTitle: objCurFunction.functionTitle,
				...objBaselines,
				...objSpent,
				...objPercent,
				...objTotal
			})

			return acc
		},[])

		return arrStaffDetails
	}
)

export const getAudiences = createSelector(
	[agencyStaffEntity, getDates],
	(curAgencyProjectStaff, curDates) => {
		if (!curDates) return
		
		let endDateYear = curDates.endDate.year()
		let objAllAudiences = curAgencyProjectStaff.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjectStaff.byId[cur]

			for (let curBaselineEntry in objCur.baselineHoursByBrand) {
				if (objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]) {
					let strCurrentAudienceName = 'Unbranded'
					let arrTemp = curBaselineEntry.split('|')

					if (arrTemp.length === 3 && arrTemp[2] !== '') {
						strCurrentAudienceName = arrTemp[2]
					}

					if (!acc.includes(strCurrentAudienceName)) acc.push(strCurrentAudienceName)
				}
			}

			return acc

		},[])

		return objAllAudiences
	}
)

export const getAudiencesProject = createSelector(
	[projectsByDate, agencyProjectsEntity],
	(curprojByDate, curAgencyProjects) => {

		let objAllAudiences = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]

			if (objCur.audienceName !== '') acc[objCur.audienceName] = 1
			return acc

		},{})

		return Object.keys(objAllAudiences)
	}
)






export const getProjectChanges = createSelector(
	[agencyProjectsEntity, getDates, projectChangeType],
	(curAgencyProjects, curDates, curChangeType) => {
		
		if (!curDates) return []

		let projectChanges = curAgencyProjects.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]

			let curFromDate = curDates.startDate
			let curToDate = curDates.endDate
			
			// New Projects within the range
			let isStartInRange = dayjs(objCur.timeStamp).isSameOrAfter(dayjs(curFromDate))
			let isEndInRange = dayjs(objCur.timeStamp).isSameOrBefore(dayjs(curToDate))
			
			if (curChangeType === 'project' && isStartInRange && isEndInRange) {
				acc.push({
					logType: 'add',
					date: dayjs(objCur.timeStamp),
					projectID: objCur.projectID,
					projectName: objCur.projectName,
					comments: objCur.issues,
					startDate: objCur.startDate,
					endDate: objCur.endDate,
				})
			}

			let objGroupedChanges = {}
			for (let curChange of objCur.changes) {
				let isStartInRange = dayjs(curChange.timeStamp).isSameOrAfter(dayjs(curFromDate))
				let isEndInRange = dayjs(curChange.timeStamp).isSameOrBefore(dayjs(curToDate))
				
				// Only count changes with status or priority
				if (isStartInRange && isEndInRange ) {
					
					
					// New stuff
					if (curChange.newValue !== '') {
						
						let curFieldChange = curChange.changes
						

						if (curChangeType === 'budget' && (curFieldChange === 'passthrough' || curFieldChange === 'totalProjectBudget')) {
							if (!objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')]) objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')] = []
							objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')].push({
								...curChange,
								fieldChange:curChange.changes
							})
						} else if (curChangeType === 'project' && curFieldChange !== 'passthrough' && curFieldChange !== 'totalProjectBudget') {
							if (!objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')]) objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')] = []
							objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')].push({
								...curChange,
								fieldChange:curChange.changes
							})
						}
					} else { // few old stuff, will go away 
						let arrTemp = curChange.changes.split(',')
						for (let i of arrTemp) {
							if (curChangeType === 'budget') {
								if (i === 'passthrough' || i === 'totalProjectBudget') {
									if (!objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')]) objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')] = []
									objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')].push({
										fieldChange: 'Budget Edit',
										newValue: '',
										oldValue: '',
										timeStamp: curChange.timeStamp
									})
								}
							} else if (curChangeType === 'project') {
								if (!objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')]) objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')] = []
								objGroupedChanges[dayjs(curChange.timeStamp).format('YYYY-MM-DD')].push({
									fieldChange: 'Project Edit',
									newValue: '',
									oldValue: '',
									timeStamp: curChange.timeStamp
								})
							}
						}
					}
				}
			}
			for (let curChange in objGroupedChanges) {
				acc.push({
					logType: 'edit',
					date: dayjs(curChange),
					projectID: objCur.projectID,
					projectName: objCur.projectName,
					projectNumber: objCur.projectNumber,
					comments: objCur.issues,
					startDate: objCur.startDate,
					endDate: objCur.endDate,
					changes: objGroupedChanges[curChange]
				})
			}

			return acc
		},[])

		return projectChanges.sort((a,b) => (a.date > b.date?-1:1))
	}
)

export const getOutOfPocketChanges = createSelector(
	[projectsByDate, agencyProjectsEntity, getDates, oopCostsOutsideRange],
	(curprojByDate, curAgencyProjects, curDates, curOopCostsOutsideRange) => {
		
		if (!curDates) return []
		
		let budget = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc
			let projectRatio = getRatios(curDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})

			acc += parseFloat(objCur.passThrough * projectRatio)
			return acc
		},0)
		
		let curFromDate = curDates.startDate
		let curToDate = curDates.endDate
		
		let spent = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc
			let curCosts = objCur.oopCosts || []
			for (let curCostEntry of curCosts) {
				
				let isStartInRange = dayjs(curCostEntry.dateEntered).isSameOrAfter(dayjs(curFromDate))
				let isEndInRange = dayjs(curCostEntry.dateEntered).isSameOrBefore(dayjs(curToDate))

				let isStartInProjectRange = dayjs(curCostEntry.dateEntered).isSameOrAfter(dayjs(objCur.startDate))
				let isEndInProjectRange = dayjs(curCostEntry.dateEntered).isSameOrBefore(dayjs(objCur.endDate))
	
				if (isStartInProjectRange && isEndInProjectRange) {
					if (isStartInRange && isEndInRange) {
						
						let projectRatio = getRatios(curDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})
						let curBudget= parseFloat(objCur.passThrough * projectRatio)
						
						acc.push({
							dateEntered: dayjs(curCostEntry.dateEntered).format('MM/DD/YY'),
							dateEnteredSort: new Date(curCostEntry.dateEntered),
							costs: curCostEntry.costs,
							comments: curCostEntry.comments,
							passThroughDesc: objCur.passThroughDesc,
							projectName: objCur.projectName,
							passThrough: objCur.passThrough,
							budget: curBudget
						})
					}	
				}
			}
			return acc
		},[])

		// This one is different, I am calculating the DIFF and doesn't really work when I add in dates from other projects
		for (let curDateOutside in curOopCostsOutsideRange) {
			
			let isStartInRange = dayjs(curDateOutside).isSameOrAfter(dayjs(curFromDate))
			let isEndInRange = dayjs(curDateOutside).isSameOrBefore(dayjs(curToDate))
			if (isStartInRange && isEndInRange) {
				for (let curProj of curOopCostsOutsideRange[curDateOutside]) {
					
					for (let curOopCost of curProj.oopCosts) {
						
						let isEnteredStartDateInRange = dayjs(curOopCost.dateEntered).isSameOrAfter(dayjs(curFromDate))
						let isEnteredEndDateInRange = dayjs(curOopCost.dateEntered).isSameOrBefore(dayjs(curToDate))
						
						if (isEnteredStartDateInRange && isEnteredEndDateInRange) {
							
							spent.push({
								...curOopCost,
								projectName: curProj.projectName,
								passThroughDesc: curProj.passThroughDesc,
								outsideCost: true,
								dateEntered: dayjs(curOopCost.dateEntered).format('MM/DD/YY'),
								dateEnteredSort: new Date(curOopCost.dateEntered),
							})
						}
					}
				}
			}
		}
		

		spent = spent.sort((a,b) => (a.dateEnteredSort > b.dateEnteredSort?1:-1))

		spent = spent.map(cur => {
			budget -= cur.costs
			return {
				...cur,
				remaining: budget
			}
		}).sort((a,b) => (a.dateEnteredSort > b.dateEnteredSort?-1:1))

		
		return spent
	}
)

export const getOutOfPocketBudget = createSelector(
	[projectsByDate, agencyProjectsEntity, getDates, oopCostsOutsideRange],
	(curprojByDate, curAgencyProjects, curDates, curOopCostsOutsideRange) => {
		
		if (!curDates) return []
		let objOutsideRangeProjects = {}
		let curFromDate = curDates.startDate
		let curToDate = curDates.endDate
			
		let arrProjects = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			if (!fncCheckStatus(objCur)) return acc

			if (!objOutsideRangeProjects[objCur.projectID]) {
				objOutsideRangeProjects[objCur.projectID] = true
			}

			let curCosts = 0
			for (let curCostEntry of (objCur.oopCosts || [])) {
				
				let isStartInRange = dayjs(curCostEntry.dateEntered).isSameOrAfter(dayjs(curFromDate))
				let isEndInRange = dayjs(curCostEntry.dateEntered).isSameOrBefore(dayjs(curToDate))

				let isStartInProjectRange = dayjs(curCostEntry.dateEntered).isSameOrAfter(dayjs(objCur.startDate))
				let isEndInProjectRange = dayjs(curCostEntry.dateEntered).isSameOrBefore(dayjs(objCur.endDate))
	
				if (isStartInProjectRange && isEndInProjectRange) {
					if (isStartInRange && isEndInRange) {
						curCosts += curCostEntry.costs
					}
				}	
			}

			
			let projectRatio = getRatios(curDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})

			acc.push({
				...objCur,
				adjustedBudget: parseFloat(objCur.passThrough * projectRatio),
				costs: curCosts
			})

			return acc
		},[])


		for (let curDateOutside in curOopCostsOutsideRange) {
			
			let isStartInRange = dayjs(curDateOutside).isSameOrAfter(dayjs(curFromDate))
			let isEndInRange = dayjs(curDateOutside).isSameOrBefore(dayjs(curToDate))
			if (isStartInRange && isEndInRange) {
				for (let curProj of curOopCostsOutsideRange[curDateOutside]) {
					for (let curOopCost of curProj.oopCosts) {
							
						// Add on projects that have hours outside the scope
						if (!objOutsideRangeProjects[curOopCost.projectID]) {
							objOutsideRangeProjects[curOopCost.projectID] = true
							
							arrProjects.push({
								...curProj,
								adjustedBudget: 0,
								costs: 0
							})
						}
						arrProjects.find(cur => cur.projectID === curOopCost.projectID).costs += curOopCost.costs
					}
				}
			}
		}

		
		return arrProjects.sort((a,b) => (a.adjustedBudget > b.adjustedBudget?-1:1))
	}
)



export const getFullStaffByBrand = createSelector(
	[agencyStaffEntity, agencyFunctionsEntity, getDates, brandsEntity],
	(curAgencyProjectStaff, curAgencyFunctions, curDates, curBrands) => {
		
		if (!curDates) return []
		let endDateYear = curDates.endDate.year()
		let brandNames = {}
		let arrBrandDetails = curAgencyProjectStaff.allIds.reduce((acc,cur) => {
			let objCur = curAgencyProjectStaff.byId[cur] || {}
			if (!objCur || objCur.status === 'deleted') return acc

			let objCurFunction = curAgencyFunctions.byId[objCur.functionID] || {}
			let objTotal = {
				total_utilized: 0,
				total_scoped: 0,
				total_percent: 0
			}
			let objSpent = {}
			for (let curHourEntry of objCur.hours) {
				let intTempBrandID = objCur.brandAgencyLookup[curHourEntry.brandAgencyID] || 0
				let objCurBrand = curBrands.byId[intTempBrandID] || {}
				let strCurrentBrandName = objCurBrand.name
				brandNames[strCurrentBrandName] = true
				let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(curDates.startDate)
				let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(curDates.endDate)
				if (isStartInRange && isEndInRange && curHourEntry.hours > 0) {
					if (!objSpent[strCurrentBrandName + '_utilized']) objSpent[strCurrentBrandName + '_utilized'] =0
					if (curHourEntry.state === 'add') {
						objSpent[strCurrentBrandName + '_utilized'] += curHourEntry.hours
						objTotal.total_utilized += curHourEntry.hours
					} else if (curHourEntry.state === 'remove') {
						objSpent[strCurrentBrandName + '_utilized'] -= curHourEntry.hours
						objTotal.total_utilized -= curHourEntry.hours
					}
				}
			}
			
			let objBaselines = {}
			let objPercent = {}
			for (let curBaselineEntry in objCur.baselineHoursByBrand) {
				if (objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]) {
					let arrTemp = curBaselineEntry.split('|')
					let intTempBrandID = objCur.brandAgencyLookup[arrTemp[0]] || 0
					let objCurBrand = curBrands.byId[intTempBrandID] || {}
					let strCurrentBrandName = objCurBrand.name
					brandNames[strCurrentBrandName] = true
					objBaselines[strCurrentBrandName + '_scoped'] = objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]
					objTotal.total_scoped += objCur.baselineHoursByBrand[curBaselineEntry][endDateYear]

					if (objSpent[strCurrentBrandName + '_utilized'] ) {
						if (objBaselines[strCurrentBrandName + '_scoped'] === 0) objBaselines[strCurrentBrandName + '_percent'] = 0
						else objBaselines[strCurrentBrandName + '_percent'] = objSpent[strCurrentBrandName + '_utilized'] / objBaselines[strCurrentBrandName + '_scoped'] * 100
					}  else {
						objBaselines[strCurrentBrandName + '_percent'] = 0
						objSpent[strCurrentBrandName + '_utilized'] = 0
					}
					if (objTotal.total_scoped === 0) objTotal.total_percent = 0
					else objTotal.total_percent = objTotal.total_utilized / objTotal.total_scoped * 100
				}
				
			}

			acc.push({
				...objCur,
				functionTitle: objCurFunction.functionTitle,
				...objBaselines,
				...objSpent,
				...objPercent,
				...objTotal
			})

			return acc
		},[])

		return {
			fullDetails: arrBrandDetails,
			brandList: Object.keys(brandNames)
		}
	}
)

export const getProjectByBrand = createSelector(
	[projectsByDate, agencyProjectsEntity, getDates, projectsByYear, projectsByLastMonth, getFiscalDates, hoursOutsideRange],
	(curprojByDate, curAgencyProjects, curDates, curprojByYear, curprojByLastMonth, curFiscalDates, curHoursOutsideRange) => {
		

		if (!curDates || curAgencyProjects.allIds.length === 0) return 0
		let curFromDate = curDates.startDate
		let curToDate = curDates.endDate

		let fiscalDates = {
			startDate: curFiscalDates.startOfYear,
			endDate: curFiscalDates.endOfYear
		}

		
		let objOutsideRangeProjects = {}
		let brandDetails = curprojByDate.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]

			if (!fncCheckStatus(objCur)) return acc

			if (!objOutsideRangeProjects[objCur.projectID]) {
				objOutsideRangeProjects[objCur.projectID] = true
			}

			let curHours = objCur.projectHours || []
			let projectRatio = getRatios(curDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})
			
			
			if (!acc[objCur.brandName]) acc[objCur.brandName] = {
				spend: 0,
				outside: 0,
				budget: 0,
				totalCount: 0,
				projects: []
			}

			
			acc[objCur.brandName].totalCount++
			acc[objCur.brandName].budget += parseFloat(objCur.totalProjectBudget * projectRatio)
			let totalProjectSpend = 0
			let objRateMapping = fncGetRateMapping(objCur.projectBaselines, objCur.blendedRate)
			for (let curHourEntry of curHours) {
				let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
				if (_rate > 0) {
					let isStartInRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(dayjs(curFromDate))
					let isEndInRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(dayjs(curToDate))

					let isStartInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrAfter(dayjs(objCur.startDate))
					let isEndInProjectRange = dayjs(curHourEntry.dateEntered).isSameOrBefore(dayjs(objCur.endDate))
	
					if (isStartInProjectRange && isEndInProjectRange) {
					
						if (isStartInRange && isEndInRange) {
							if (curHourEntry.state === 'add') {
								acc[objCur.brandName].spend += _rate *  curHourEntry.hours
								totalProjectSpend += _rate *  curHourEntry.hours
							} else if (curHourEntry.state === 'remove') {
								acc[objCur.brandName].spend -= _rate *  curHourEntry.hours
								totalProjectSpend -= _rate *  curHourEntry.hours
							}
						}
					}
				} 
			}
			acc[objCur.brandName].projects.push({
				...objCur,
				budget: parseFloat(objCur.totalProjectBudget * projectRatio),
				spend: totalProjectSpend, 
				outside: 0
			})
			
			return acc
		},{})

		for (let curDateOutside in curHoursOutsideRange) {
				
			let isStartInRange = dayjs(curDateOutside).isSameOrAfter(curDates.startDate)
			let isEndInRange = dayjs(curDateOutside).isSameOrBefore(curDates.endDate)

			if (isStartInRange && isEndInRange) {
				for (let curHourEntry of curHoursOutsideRange[curDateOutside]) {
					if (!brandDetails[curHourEntry.brandName]) brandDetails[curHourEntry.brandName] = {
						spend: 0,
						budget: 0,
						outside: 0,
						totalCount: 0,
						projects: []
					}

					// Add on projects that have hours outside the scope
					if (!objOutsideRangeProjects[curHourEntry.projectID]) {
						objOutsideRangeProjects[curHourEntry.projectID] = true
						
						brandDetails[curHourEntry.brandName].projects.push({
							...curHourEntry,
							budget: 0,
							spend: 0, 
							outside: 0
						})
					}

					let objRateMapping = fncGetRateMapping(curHourEntry.projectBaselines, curHourEntry.blendedRate)
					let _rate = objRateMapping[curHourEntry.functionID] || objRateMapping['blendedRate']
						
					if (curHourEntry.state === 'add') {
						brandDetails[curHourEntry.brandName].spend += _rate * curHourEntry.hours
						brandDetails[curHourEntry.brandName].outside += _rate * curHourEntry.hours
						brandDetails[curHourEntry.brandName].projects.find(cur => cur.projectID === curHourEntry.projectID).spend += _rate * curHourEntry.hours
						brandDetails[curHourEntry.brandName].projects.find(cur => cur.projectID === curHourEntry.projectID).outside += _rate * curHourEntry.hours
					} else if (curHourEntry.state === 'remove') {
						brandDetails[curHourEntry.brandName].spend -= _rate * curHourEntry.hours
						brandDetails[curHourEntry.brandName].outside -= _rate * curHourEntry.hours
						brandDetails[curHourEntry.brandName].projects.find(cur => cur.projectID === curHourEntry.projectID).spend -= _rate * curHourEntry.hours
						brandDetails[curHourEntry.brandName].projects.find(cur => cur.projectID === curHourEntry.projectID).outside -= _rate * curHourEntry.hours
					}

					
				}
			} 
		}



		let brandDetailsByYear = curprojByYear.reduce((acc,cur) => {
			let objCur = curAgencyProjects.byId[cur]
			let projectFullYearRatio = getRatios(fiscalDates,{startDate: dayjs(objCur.startDate), endDate: dayjs(objCur.endDate)})
			
			if (!fncCheckStatus(objCur)) return acc
			if (!acc[objCur.brandName]) acc[objCur.brandName] = 0

			acc[objCur.brandName] += parseFloat(objCur.totalProjectBudget * projectFullYearRatio)
			return acc
		},{})

		let arrBrandDetails = Object.keys(brandDetails).map(cur => {
			let objCur = brandDetails[cur]

			return {
				brand: cur,
				...objCur,
				yearBudget: brandDetailsByYear[cur],
				difference: objCur.budget - objCur.spend
			}
		}).sort((a,b) => (a.brand > b.brand?1:-1))

		return arrBrandDetails
	}
)

export const getMonthlyOOP = createSelector(
	[projectsByMonth, agencyProjectsEntity, getDates, getFiscalDates, oopCostsOutsideRange],
	(curProjectsByMonthYear, curAgencyProjects, curDates, curFiscalDates, curOopCostsOutsideRange) => {
		if (!curDates) return {}

		let arrMonthlyBudget = []
		let arrMonthlySpend = []
		let arrMonthlyOutside = []

		let curProjectsByMonth = curProjectsByMonthYear[0]
		if (curDates.startDate === curFiscalDates.startOfPrevYear) curProjectsByMonth = curProjectsByMonthYear[1]

		for(let curMonth = 0; curMonth < 12;curMonth++) {
			
			let curFromDate = dayjs(curDates.startDate).add(curMonth, 'month').startOf('month')
			let curToDate = dayjs(curDates.startDate).add(curMonth, 'month').endOf('month')
			let actualCurMonth = curFromDate.month()
			
			let curMonthlyDates = {
				startDate: curFromDate,
				endDate: curToDate
			}
			
			let budget = (curProjectsByMonth[actualCurMonth] || []).reduce((acc,cur) => {
				let objCur = curAgencyProjects.byId[cur]
				if (!fncCheckStatus(objCur)) return acc

				let curstartDate = dayjs(objCur.startDate).startOf('month')
				let curendDate = dayjs(objCur.endDate).endOf('month')
				let projectRatio = getRatios(curMonthlyDates,{startDate: curstartDate, endDate: curendDate})

				acc += parseFloat(objCur.passThrough * projectRatio)
				return acc
			},0)
			arrMonthlyBudget.push(budget)
			

			let objSpend = (curProjectsByMonth[actualCurMonth] || []).reduce((acc,cur) => {
				let objCur = curAgencyProjects.byId[cur]
				if (!fncCheckStatus(objCur)) return acc
				
				for (let curCostEntry of (objCur.oopCosts || [])) {
					let isStartInRange = dayjs(curCostEntry.dateEntered).isSameOrAfter(curMonthlyDates.startDate)
					let isEndInRange = dayjs(curCostEntry.dateEntered).isSameOrBefore(curMonthlyDates.endDate)

					let isStartInProjectRange = dayjs(curCostEntry.dateEntered).isSameOrAfter(dayjs(objCur.startDate))
					let isEndInProjectRange = dayjs(curCostEntry.dateEntered).isSameOrBefore(dayjs(objCur.endDate))

					if (isStartInProjectRange && isEndInProjectRange) {

						if (isStartInRange && isEndInRange) {
							acc += curCostEntry.costs
						}
					}

				}
					
				return acc
			},0)

			let monthlyOutside = 0
			for (let curDateOutside in curOopCostsOutsideRange) {
			
				let isStartInRange = dayjs(curDateOutside).isSameOrAfter(dayjs(curFromDate))
				let isEndInRange = dayjs(curDateOutside).isSameOrBefore(dayjs(curToDate))
				if (isStartInRange && isEndInRange) {
					for (let curProj of curOopCostsOutsideRange[curDateOutside]) {
						for (let curOopCost of curProj.oopCosts) {
						
							monthlyOutside += curOopCost.costs
						}
					}
				}
			}


			arrMonthlySpend.push(objSpend)
			arrMonthlyOutside.push(monthlyOutside)
		}
			
		return {
			budget: arrMonthlyBudget,
			actual: arrMonthlySpend,
			outside: arrMonthlyOutside
		}
	}
)
