import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ZenLogo  from '../images/zenLogo.png'
import ArtLogo  from '../images/artLogo.png'

import AuthService from '../services/AuthService'

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

const AppTopbar = (props) => {

	const { pageName, clientName } = props
	
	const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
	const [currentBrand, setCurrentBrand] = useState('');
	const [currentProject, setCurrentProject] = useState('');
	const [currentClientName, setCurrentClientName] = useState('');
	const [showProjects, setShowProjects] = useState(false);
	const [showBrands, setShowBrands] = useState(false);
	const [isNew, setIsNew] = useState(true);
	

	const Auth = new AuthService();
	const profile = Auth.getProfile()
	const profileItemClassName = classNames('profile-item', { 'active-menuitem fadeInDown': topbarUserMenuActive });

	const objUserData = JSON.parse(localStorage.getItem('userData'))

	const onTopbarUserMenuButtonClick = (event) => {
    	setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);
		event.preventDefault();
    };

	const fncLogOut = () => {
		setTopbarUserMenuActive(false)
		Auth.logout().then(() => {
			window.location.hash = '/'
			window.location.reload()
		})
	}

	useEffect(() => {
		if (objUserData.clientName === 'Console'){
			if (clientName !== '') {
				setCurrentClientName('Admin ( viewing: ' + clientName + ' )')
			} else {
				setCurrentClientName('Admin')
			}
			
		} else if (objUserData.clientName) {
			setCurrentClientName(objUserData.clientName)
		} else {
			setCurrentClientName(objUserData.agencyName)
		} 
	},[objUserData, clientName]);

	useEffect(() => {
		if (currentBrand !== '') {
			if (currentBrand.toString().indexOf('franchise_') === 0) {
				window.location.hash = "/franchiseOverview/" + currentBrand.replace('franchise_','');
			} else {
				window.location.hash = "/newOverview/" + currentBrand;
			}
			
			
		}
	},[currentBrand]);

	useEffect(() => {
		if (currentProject !== '') {
			window.location.hash = "/projectDetails/" + currentProject;
		}
	},[currentProject]);

	useEffect(() => {
		let arrParts = document.location.hash.split('/')
		let _showProjectDropdown = false
		let _showBrandDropdown = false
		let _isNew = true
		if (arrParts.length === 3) {
			if (arrParts[1] === 'projectDetails') {
				setCurrentProject(parseInt(arrParts[2]))
				_showProjectDropdown = true
			} 
			if (arrParts[1] === 'newOverview') {
				setCurrentBrand(parseInt(arrParts[2]))
				_showBrandDropdown = true
			}
			if (arrParts[1] === 'franchiseOverview') {
				setCurrentBrand('franchise_' + arrParts[2])
				_showBrandDropdown = true
			}
			if (arrParts[1] === 'brandSummary') {
				setCurrentBrand(parseInt(arrParts[2]))
				_showBrandDropdown = true
				_isNew = false
			}
		}
		setShowProjects(_showProjectDropdown)
		setShowBrands(_showBrandDropdown)
		setIsNew(_isNew)
	},[pageName]);


	const brandOptionTemplate = (option) => {
       	return (
            <div className="country-item">
				{option.type === 'franchise' && 
					<div style={{fontWeight: '700',textTransform:'uppercase'}}>{option.label}</div>
				}
				{option.type === 'brand' && 
					<div className="pl-4 flex">
						<div>{option.label}</div>
						<div className="pl-2">({option.spendPercentage}%)</div>
					</div>
				}
            </div>
        );
    }

	const fncSetCurrentBrand = (e) => {
		if (e.value !== 0) setCurrentBrand(e.value)
	}

	return (
		<div className="layout-topbar">
			<div className="topbar-left">
				<div className="layout-breadcrumb zenLogo">
					<Link to="/" className="logo">
						<img alt="ZenSights" src={ZenLogo} />
					</Link>
				</div>
				<span className="topbar-separator"></span>
				<div className="layout-breadcrumb artLogo">
					<Link to="/" className="logo">
						<img alt="ART Tool" src={ArtLogo} />
					</Link>
				</div>
				<span className="topbar-separator"></span>
				<div className="layout-breadcrumb clientName">
					{currentClientName}
				</div>
				{profile.type === 'hybrid' && 
					<>
						<span className="topbar-separator"></span>
						{document.location.hash !== '#/' && 
							<Link to="/">
								<Button label="Return to Admin" className="p-button-outlined" onClick={(e) => Auth.logoutHybrid() }/>
							</Link>
						}

						{document.location.hash === '#/' && 
							<Button label="Return to Admin" className="p-button-outlined" onClick={(e) => Auth.logoutHybrid() }/>
						}
						
					</>
				}
				
			</div>
			<div className="topbar-right">
				
				<ul className="topbar-menu">
					{process.env.REACT_APP_RESTPATH === 'artdev' && 
						<>
							<span className="topbar-separator"></span>
							<div className="layout-breadcrumb clientName">DEVELOPMENT</div>
						</>
					}
					{process.env.REACT_APP_RESTPATH === 'artstaging' && 
						<>
							<span className="topbar-separator"></span>
							<div className="layout-breadcrumb clientName">STAGING</div>
						</>
					}
					{props.brands.length > 1  && showBrands && (objUserData.clientName !== 'Console' || clientName !== '') && 
						
						<li >
							
							<span className="topNavDropdownLabel mr-2">
								{currentBrand.toString().indexOf('franchise_') === 0 && 
									<span>Franchise:</span>
								}
								{currentBrand.toString().indexOf('franchise_') === -1 && 
									<>
										<span className="mr-2">
											
											{isNew === false && 
												<Link to={"/newOverview/" + currentBrand}>
													View New Dashboard
												</Link>
											}
											</span>
										<span>Brand:</span>
									</>
									
								}
							</span>
							<Dropdown itemTemplate={brandOptionTemplate} value={currentBrand} options={props.brands} onChange={(e) => fncSetCurrentBrand(e)} placeholder="Search Brand..."/>	
						</li>
						
					}
					{props.projects.length > 1  && showProjects && (profile.type === 'agency' || profile.type === 'hybrid') && 
						<li>
							<span className="topNavDropdownLabel mr-2">
								Project: 
							</span>
							<Dropdown value={currentProject} options={props.projects} onChange={(e) => setCurrentProject(e.value)} placeholder="Select Project..."/>	
						</li>
					}
					<li className={profileItemClassName}>
						<button type="button" className="p-link" onClick={onTopbarUserMenuButtonClick}>
							<div className="flex">
								<div className="mr-2">
									<i className="pi pi-user" style={{fontSize:'16px'}}></i>
								</div>
								<div>
									<span className="profile-name">{objUserData.firstname} {objUserData.lastname}</span>
								</div>
							</div>
							
						</button>
						<ul className="profile-menu fade-in-up">
							<li>
								<button type="button" className="p-link" onClick={(e) => {fncLogOut()}}>
									<i className="pi pi-power-off"></i>
									<span>Logout</span>
								</button>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default AppTopbar;
