import React, { useState } from 'react';
import { connect } from 'react-redux';

import ProjectChangeModal from '../../modals/projectChanges'
import StaffChangeModal from '../../modals/staffChanges'

// Selectors
import { getStaffChanges, getProjectChanges } from '../../selectors/dashboardWidgetsSelector'

// helpers
import { dateStringsMap } from '../../utilities/CommonSnippets'

const KeyChanges = (props) => {
	
	const { staffChanges, projectChanges, budgetChanges, dates } = props
	
	const [ displayProjectChanges, setDisplayProjectChanges] = useState(false);
	const [ displayStaffChanges, setDisplayStaffChanges] = useState(false);
	const [ displayBudgetChanges, setDisplayBudgetChanges] = useState(false);

	const fncHideModal = () => {
		setDisplayProjectChanges(false)
		setDisplayStaffChanges(false)
		setDisplayBudgetChanges(false)
	}

	let fncDisplayBudgetChanges = (arrLengh) => {
		if (arrLengh !== 0) setDisplayBudgetChanges(true)
	}

	let fncDisplayStaffChanges = (arrLengh) => {
		if (arrLengh !== 0) setDisplayStaffChanges(true)
	}

	let fncDisplayProjectChanges = (arrLengh) => {
		if (arrLengh !== 0) setDisplayProjectChanges(true)
	}

	return (
		<div className="flex justify-content-between keyChanges" >
			<div className="flex flex-column" >
				<div>
					Budget Changes
				</div>
				<div className="budgetChanges link" onClick={(e) => fncDisplayBudgetChanges(budgetChanges.length) }>
					{budgetChanges.length}
				</div>
				
			</div>
			<div className="flex flex-column" >
				<div>
					Staff Changes
				</div>
				<div className="staffChanges link" onClick={(e) => fncDisplayStaffChanges(staffChanges.length) }>
					{staffChanges.length}
				</div>
				
			</div>
			<div className="flex flex-column" >
				<div>
					Project Changes
				</div>
				<div className="projectChanges link" onClick={(e) => fncDisplayProjectChanges(projectChanges.length) }>
					{projectChanges.length}
				</div>
			</div>
			

			<ProjectChangeModal 
				modalType="project" 
				projectChanges={projectChanges} 
				displayChanges={displayProjectChanges} 
				fncHideModal={fncHideModal} 
				header={"Project Changes (" + dateStringsMap()[dates] + ")"}
			/>
			<StaffChangeModal 
				staffChanges={staffChanges} 
				displayStaffChanges={displayStaffChanges} 
				fncHideModal={fncHideModal} 
				header={"Staff Changes (" + dateStringsMap()[dates] + ")"}
			/>
			<ProjectChangeModal 
				modalType="budget" 
				projectChanges={budgetChanges} 
				displayChanges={displayBudgetChanges} 
				fncHideModal={fncHideModal} 
				header={"Budget Changes (" + dateStringsMap()[dates] + ")"}
			/>
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		staffChanges: getStaffChanges(state,ownProps),
		projectChanges: getProjectChanges(state,{...ownProps, changeType:'project'}),
		budgetChanges: getProjectChanges(state,{...ownProps, changeType:'budget'})
	}
}

const KeyChangesContainer = connect(mapStateToProps, null)(KeyChanges);
export default KeyChangesContainer;