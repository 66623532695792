import { createSelector } from 'reselect'
import dayjs from 'dayjs';

const brandsEntity = (state) => state.entities.brands
const clientsEntity = (state) => state.entities.clients
const commentsEntity = (state) => state.entities.comments
const brandAgenciesEntity = (state) => state.entities.brandAgencies
const insightTextEntity = (state) => state.entities.insightText

const fromDateUI = (state) => state.ui.fromDate
const toDateUI = (state) => state.ui.toDate
const currentClientIDUI = (state) => state.ui.currentClientID

const getBrandByID = (state, props) => {
	try {
		if (props.brandID) {
			return state.entities.brands.byId[parseInt(props.brandID)]
		} else if (parseInt(props.match.params.brandid) == props.match.params.brandid) {
			return state.entities.brands.byId[parseInt(props.match.params.brandid)]
		} else {
			return null
		}
	} catch (e) {
		return null
	}	
}

const getFranchiseByID = (state, props) => {
	try {
		if (props.match.params.franchiseid) {
			return parseInt(props.match.params.franchiseid)
		} else {
			return null
		}
	} catch (e) {
		return null
	}	
}

const getClientByID = (state, props) => {
	try {
		if (props.match.params.clientid) {
			return parseInt(props.match.params.clientid)
		} else {
			return null
		}
	} catch (e) {
		return null
	}	
}

const getSection = (state, props) => {
	try {
		if (props.section) {
			return props.section
		} else {
			return null
		}
	} catch (e) {
		return null
	}	
}

export const getCommentsSelector = createSelector(
	[brandsEntity, commentsEntity],
	(curBrands, curComments) => {
		return curComments.allIds.map((cur) => {
			let objCur = curComments.byId[cur] || {}
			let objCurBrand = curBrands.byId[objCur.brandID] || {}
			
			return {
				...objCur,
				brandName: objCurBrand.name,
				clientName: objCurBrand.clientName
			}
		})
	}
)

export const getInsightTextBySection = createSelector(
	[insightTextEntity, getSection],
	(curInsightText, curSection) => {
		let objTextBySection = curInsightText.allIds.reduce((acc,cur) => {
			acc[curInsightText.byId[cur].section] = curInsightText.byId[cur].insightText
			return acc
		},{})

	if (curSection && objTextBySection[curSection]) return objTextBySection[curSection]
		else return null
	}
)

export const getAllInsightText = createSelector(
	[insightTextEntity],
	(curInsightText) => {
		return curInsightText.allIds.reduce((acc,cur) => {
			acc[curInsightText.byId[cur].section] = curInsightText.byId[cur].insightText
			return acc
		},{})
	}
)



export const getCurrentClientID = createSelector(
	[getClientByID, currentClientIDUI],(curClientID, curClientIDUI) => {
		return curClientIDUI || curClientID
	}
)

export const getCurrentClientName = createSelector(
	[getClientByID, currentClientIDUI, brandsEntity],(curClientID, curClientIDUI, curBrands) => {
		let tempClientID = curClientIDUI || curClientID
		
		return curBrands.allIds.reduce((acc,cur) => {
			if (curBrands.byId[cur].clientID === tempClientID) acc = curBrands.byId[cur].clientName
			return acc
		},'')
	}
)
  
export const getBrandsSelector = createSelector(
	[getClientByID, brandsEntity],
	(curClientID, curBrands) => {

		let arrBrands = curBrands.allIds
		// If there is a clientID on the URL, we will filter the brands to only return those of that client. This is used by the admin.  clients will only have their own so no security risk
		if (curClientID) {
			arrBrands = arrBrands.filter(cur => curBrands.byId[cur].clientID === curClientID)
		}

		// We will only return the allocation and spend based on the current year
		let currentYear = new Date().getFullYear()

		if (new Date().getMonth() === 0) {
			currentYear = dayjs().subtract(1, 'year').year()
		}

		// Get a quick count of how many in each franchise, if only 1, we don't treat it as franchise
		let objFranchise = arrBrands.reduce((acc,cur) => {
			if (!acc[curBrands.byId[cur].franchiseName]) acc[curBrands.byId[cur].franchiseName] = 0
			acc[curBrands.byId[cur].franchiseName]++
			return acc
		},{})
		
		let arrReturn = arrBrands.reduce((acc,cur) => {
			
			let variance = 0
			let timePercentage = 0

			let curSpendingDetails = {
				percentage: 0,
				allocation: 0,
				spend: 0
			}
			
			if (curBrands.byId[cur].spendingDetailsByYear[currentYear]) {
				curSpendingDetails = curBrands.byId[cur].spendingDetailsByYear[currentYear]
			} 
				
			if (curBrands.byId[cur].startDate !== '' && curBrands.byId[cur].endDate !== ''){
				// Now we need to get the number of days between in order to figure out the variance
				let totalDays = dayjs(curBrands.byId[cur].endDate).diff(curBrands.byId[cur].startDate,"days")
				let daysSoFar = dayjs().diff(curBrands.byId[cur].startDate,"days")
				timePercentage = daysSoFar/totalDays*100
				variance = Math.abs(timePercentage - curSpendingDetails.percentage)
			}
			
			if (curBrands.byId[cur].franchiseName === 'General' || curBrands.byId[cur].franchiseName === 'None' || objFranchise[curBrands.byId[cur].franchiseName] === 1) {
				acc.push({
					name: curBrands.byId[cur].name,
					id: curBrands.byId[cur].id,
					spendPercentage: curSpendingDetails.percentage,
					spend: curSpendingDetails.spend,
					spendAllocation: curSpendingDetails.allocation,
					isFranchise: false,
					variance: variance,
					timePercentage: timePercentage
				})
			} else {
				if (!acc.find((curF) => curF.name === curBrands.byId[cur].franchiseName)) {
					acc.push({
						name: curBrands.byId[cur].franchiseName,
						id: 'franchise_' + curBrands.byId[cur].franchiseID,
						spendAllocation: 0,
						spendPercentageTotal: 0,
						spendPercentage: 0,
						spend: 0,
						varianceTotal: 0,
						variance: 0,
						timePercentageTotal: 0,
						timePercentage: 0,
						isFranchise: true,
						brands: []
					})
				}

				let curFranchise = acc.find((curF) => curF.name === curBrands.byId[cur].franchiseName)
				
				curFranchise.brands.push({
					name: curBrands.byId[cur].name,
					id: curBrands.byId[cur].id,
					spendPercentage: curSpendingDetails.percentage,
					spend: curSpendingDetails.spend,
					spendAllocation: curSpendingDetails.allocation,
					isFranchise: false,
					variance: variance,
					timePercentage: timePercentage
				})

				curFranchise.spendAllocation += curSpendingDetails.allocation
				curFranchise.spend += curSpendingDetails.spend
				curFranchise.spendPercentageTotal += curSpendingDetails.percentage
				curFranchise.spendPercentage = curFranchise.spendPercentageTotal / curFranchise.brands.length
				curFranchise.varianceTotal += variance
				curFranchise.variance = curFranchise.varianceTotal / curFranchise.brands.length
				curFranchise.timePercentageTotal += timePercentage
				curFranchise.timePercentage = curFranchise.timePercentageTotal / curFranchise.brands.length
			}
			
			return acc
		},[])

		return arrReturn
	}
)

export const getClientsSelector = createSelector(
	[clientsEntity],
	(curClients) => {
		let arrReturn = curClients.allIds.map((cur) => {
			return curClients.byId[cur]
		})

		return arrReturn
	}
)

export const getBrandsByFranchiseSelector = createSelector(
	[getFranchiseByID, brandsEntity],
	(curFranchiseID, curBrands ) => {
		let arrReturn = curBrands.allIds.reduce((acc,cur) => {
			let objCur = curBrands.byId[cur]
			if (objCur.franchiseID === curFranchiseID) acc.push(cur)
			return acc
		},[])
		
		return arrReturn
	}
)

export const getFranchiseDetailsSelector = createSelector(
	[getFranchiseByID, brandsEntity],
	(curFranchiseID, curBrands ) => {
		let objReturn = curBrands.allIds.reduce((acc,cur) => {
			let objCur = curBrands.byId[cur]
			if (objCur.franchiseID === curFranchiseID) {
				acc = {
					franchiseID: objCur.franchiseID,
					franchiseName: objCur.franchiseName,
					clientID: objCur.clientID,
					clientFiscalStartMonth: objCur.clientFiscalStartMonth,
				}
			}
			return acc
		},{})
		
		return objReturn
	}
)

export const getBrandsByIdSelector = createSelector(
	[getBrandByID, fromDateUI, toDateUI],
	(curBrand, curFromDate, curToDate) => {
		
		let variance = 0
		let timePercentage = 0

		if (curBrand) {
			if (curBrand.startDate !== '' && curBrand.endDate !== ''){
				// Now we need to get the number of days between in order to figure out the variance
				let totalDays = dayjs(curBrand.endDate).diff(curBrand.startDate,"days")
				let daysSoFar = dayjs().diff(curBrand.startDate,"days")
				timePercentage = daysSoFar/totalDays*100
				variance = Math.abs(timePercentage - curBrand.spendingDetails.percentage)
			}
			
			
			return {
				name: curBrand.name,
				clientID: curBrand.clientID,
				clientFiscalStartMonth: curBrand.clientFiscalStartMonth,
				id: curBrand.id,
				spendPercentage: curBrand.spendingDetails.percentage,
				spend: curBrand.spendingDetails.spend.toLocaleString('en-US', {style: 'currency',currency: 'USD',}),
				spendAllocation: curBrand.spendingDetails.allocation.toLocaleString('en-US', {style: 'currency',currency: 'USD',}),
				variance: variance,
				timePercentage: timePercentage
			}
		}
		else return {}
	}
)

export const getBrandsDropdown = createSelector(
	[brandsEntity, currentClientIDUI],
	(curBrands, curClientID) => {
		
		let curYear =  new Date().getFullYear()

		if (new Date().getMonth() === 0) {
			curYear = dayjs().subtract(1, 'year').year()
		}

		let objFranchise = curBrands.allIds.reduce((acc,cur) => {
			let curObj = curBrands.byId[cur]
			let spendPercentage = 0
			
			if (!curClientID || curClientID === curObj.clientID) {
				
				if (!acc[curObj.franchiseName]) {
					acc[curObj.franchiseName] = {
						value: 'franchise_' + curObj.franchiseID,
						label: curObj.franchiseName,
						brands: [],
						noFranchise: curObj.franchiseName === 'None' ? 1 : 0
					}
				}

				let curSpendNyYear = curObj.spendingDetailsByYear[curYear] || {}
				spendPercentage = curSpendNyYear.percentage || 0
				if (spendPercentage !== 0) spendPercentage = spendPercentage.toFixed(1)

				acc[curObj.franchiseName].brands.push({
					label: curBrands.byId[cur].name,
					value: curBrands.byId[cur].id,
					spendPercentage: spendPercentage
				})
			}

			return acc

		},{})

		let arrFranchiseOrder = Object.keys(objFranchise).sort((a,b) => (objFranchise[a].label > objFranchise[b].label?-1:1)).sort((a,b) => (objFranchise[a].noFranchise > objFranchise[b].noFranchise?1:-1))
		let arrDropdown = []
		for (let curFranchise of arrFranchiseOrder) {
			
			if (curFranchise !== 'None') {
				arrDropdown.push({
					label: objFranchise[curFranchise].label,
					value: objFranchise[curFranchise].value,
					type: 'franchise'
				})
			} else {
				arrDropdown.push({
					label: 'No Franchise',
					value: 0,
					type: 'franchise'
				})
			}
			for (let curBrand in objFranchise[curFranchise].brands) {
				arrDropdown.push({
					...objFranchise[curFranchise].brands[curBrand],
					type: 'brand'
				})
			}
		}
		return arrDropdown
	}
)

export const getBrandAgencyDropdown = createSelector(
	[brandAgenciesEntity],
	(curBrands) => {
		let arrBrands = curBrands.allIds.reduce((acc,cur) => {
			if(!acc.find(curF => curF.value === curBrands.byId[cur].brandAgencyID)) {
				acc.push({
					label: curBrands.byId[cur].brandName,
					value: curBrands.byId[cur].brandAgencyID,
					audiences: curBrands.byId[cur].audiences
				})
			}

			return acc
		},[])

		return arrBrands
	}
)

export const getBrandByAudienceAgencyDropdown = createSelector(
	[brandAgenciesEntity],
	(curBrands) => {
		let arrBrands = curBrands.allIds.reduce((acc,cur) => {
			if(!acc.find(curF => curF.value === curBrands.byId[cur].brandAgencyID)) {
				acc.push({
					label: curBrands.byId[cur].brandName + ' (Unbranded)',
					value: curBrands.byId[cur].brandAgencyID + '|0|',
					client: curBrands.byId[cur].clientName + ' (' + curBrands.byId[cur].currentyear + ')'
				})
				
				for (let curAudience of curBrands.byId[cur].audiences) {
					acc.push({
						label: curBrands.byId[cur].brandName + ' (' + curAudience.name + ')',
						value: curBrands.byId[cur].brandAgencyID  + '|' + curAudience.audienceID + '|' + curAudience.name,
						client: curBrands.byId[cur].clientName + ' (' + curBrands.byId[cur].currentyear + ')'
					})
				}
				
			}

			return acc
		},[])

		return arrBrands.sort((a,b) => (a.brandName > b.brandName?1:-1)).sort((a,b) => (a.client > b.client?1:-1))
	}
)

export const getBrandByAudienceExport = createSelector(
	[brandAgenciesEntity],
	(curBrands) => {
		let arrBrands = curBrands.allIds.reduce((acc,cur) => {
			acc[curBrands.byId[cur].brandName] = curBrands.byId[cur].brandAgencyID + '|0|'
			for (let curAudience of curBrands.byId[cur].audiences) {
				acc[curBrands.byId[cur].brandName + ' (' + curAudience.name + ')']  = curBrands.byId[cur].brandAgencyID  + '|' + curAudience.audienceID + '|' + curAudience.name
			}
			
			return acc
		},{})

		return arrBrands
	}
)