import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Chart as ChartJS, BarController, BarElement, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import dayjs from 'dayjs'

// Selectors
import { getMonthlyOOP, getWidgetOutOfPocketBaseline, getWidgetOutOfPocketUsed, getFiscalDates} from '../../selectors/dashboardWidgetsSelector'

// helpers
import { OOP_LIGHT_COLOR, OOP_DARK_COLOR, OOP_OUTSIDE_COLOR } from '../../constants/colors'

ChartJS.register(BarController)
ChartJS.register(CategoryScale)
ChartJS.register(LinearScale)
ChartJS.register(BarElement)
ChartJS.register(Tooltip)

ChartJS.defaults.font.size = 14;

const MonthlyOOP = (props) => {
	const { monthlyBurnRateData, loading, yearlyBudget, yearlySpent, fiscaldates, dates } = props
	const [ spentPercentage, setSpentPercentage] = useState(0);

	useEffect(() => {
		if (!loading) {
			const arrLabels = () => {
				let arrMonths = []
				let fiscalStartDate = fiscaldates.startOfYear
				if (dates === 'lastYear') fiscalStartDate = fiscaldates.startOfPrevYear
				for(let curMonth = 0; curMonth < 12;curMonth++) {
					let curFromDate = dayjs(fiscalStartDate).add(curMonth, 'month')
					arrMonths.push(curFromDate.format('MMM YY'))
				}

				

				return arrMonths;
			}
		
			const chartData = {
				labels: arrLabels(),
				datasets: [
					{
						label: 'Budget',
						data: monthlyBurnRateData.budget,
						borderColor: OOP_LIGHT_COLOR,
						backgroundColor: OOP_LIGHT_COLOR,
					  },
					{
						label: 'Actual',
						data: monthlyBurnRateData.actual,
						borderColor: OOP_DARK_COLOR,
						backgroundColor: OOP_DARK_COLOR,
					  }
					  ,
					{
						label: 'Outside',
						data: monthlyBurnRateData.outside,
						borderColor: OOP_OUTSIDE_COLOR,
						backgroundColor: OOP_OUTSIDE_COLOR,
					  }
					
					
				  ]
			};
		
			const chartOptions = {
				layout: {
					padding: {
						right: 20,
						left: 20,
						top: 65
					}
				},
				
				scales: {
					y: {
					  ticks: {
						// For a category axis, the val is the index so the lookup via getLabelForValue is needed
						callback: function(val, index) {
						  // Hide every 2nd tick label
							if (index !== 0 && index % 2 === 0) return '$' + this.getLabelForValue(val)
						}
					  }
					}
				  },
				plugins: {
					// Change options for ALL labels of THIS CHART
					datalabels: {
						anchor: 'end',
						align: 'end',
						font: {
							size: 14
						},
						
						rotation: -90,
						formatter: function(value, context) {
							//return context.dataset.data[context.dataIndex] 
							return '$' + Intl.NumberFormat('en-US').format(Math.round(context.dataset.data[context.dataIndex]))
						},
						display: function(context) {
							return context.dataset.data[context.dataIndex] > 0;
						}
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								return context.dataset.label + ': $' + Intl.NumberFormat('en-US').format(Math.round(context.raw))
							}
						}
					},
					legend: {
						position: 'bottom',
						
					  },
				  }
			};
			
			var myChart = new ChartJS(document.getElementById('monthlyBurnChart'), {
				type: "bar",
				data: chartData ,
				options: chartOptions
			});

			// when component unmounts
			return () => {
				myChart.destroy()
			}
		}
	}, [loading, monthlyBurnRateData])

	useEffect(() => {
		let _spentPercentage = Math.round((yearlySpent / yearlyBudget) * 100)
		
		setSpentPercentage(_spentPercentage)
	},[yearlyBudget, yearlySpent])

	return (
		<div className="flex flex-column spend">
			{!loading && 
				<>
					<div className="flex justify-content-center strong">
						<div>
							Annual Budget: ${new Intl.NumberFormat('en-US').format(Math.round(yearlyBudget))}
						</div>
						<div className="ml-4">
							Annual Spend: ${new Intl.NumberFormat('en-US').format(Math.round(yearlySpent))} ({spentPercentage}%)
						</div>
					</div>

					<div className="p-chart artCharts-bar">
						<canvas id="monthlyBurnChart"></canvas>
					</div>
				</>
			}
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		monthlyBurnRateData: getMonthlyOOP(state,{dates: ownProps.dates}),
		yearlySpent: getWidgetOutOfPocketUsed(state,{dates:ownProps.dates}),
		yearlyBudget: getWidgetOutOfPocketBaseline(state,{dates:ownProps.dates}),
		fiscaldates: getFiscalDates(state)
	}
}

const MonthlyOOPContainer = connect(mapStateToProps, null)(MonthlyOOP);
export default MonthlyOOPContainer;