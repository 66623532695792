import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Chart as ChartJS, BarController, BarElement, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import dayjs from 'dayjs'
import ExcelJS  from 'exceljs'
import saveAs from "file-saver";

// Selectors
import { getMonthlyBurnRate, getWidgetAnnualBaselineBudget, getWidgetSpent, getFiscalDates} from '../../selectors/dashboardWidgetsSelector'

// helpers
import { BUDGET_LIGHT_COLOR, BUDGET_DARK_COLOR, BUDGET_OUTSIDE_COLOR} from '../../constants/colors'
import { fncDrawHeaderCellBudget, fncDrawFooterCellBudget, fncDrawTitleCell} from '../../utilities/commonXslxFunctions'

ChartJS.register(BarController)
ChartJS.register(CategoryScale)
ChartJS.register(LinearScale)
ChartJS.register(BarElement)
ChartJS.register(Tooltip)

ChartJS.defaults.font.size = 14;

const MonthlyBurnRate = (props) => {
	const { monthlyBurnRateData, loading, yearlyBudget, yearlySpent, downloadClicked, fileName, dates, fiscaldates } = props
	const [ spentPercentage, setSpentPercentage] = useState(0);

	useEffect(() => {
		if (downloadClicked > 0) exportExcel()
	},[downloadClicked])

	

	useEffect(() => {
		if (!loading) {
			const arrLabels = () => {
				let arrMonths = []
				
				let fiscalStartDate = fiscaldates.startOfYear
				if (dates === 'lastYear') fiscalStartDate = fiscaldates.startOfPrevYear
				for(let curMonth = 0; curMonth < 12;curMonth++) {
					let curFromDate = dayjs(fiscalStartDate).add(curMonth, 'month')
					arrMonths.push(curFromDate.format('MMM YY'))
				}
			
		
				return arrMonths;
			}
		
			const chartData = {
				labels: arrLabels(),
				datasets: [
					{
						label: 'Budget',
						data: monthlyBurnRateData.budget,
						borderColor: BUDGET_LIGHT_COLOR,
						backgroundColor: BUDGET_LIGHT_COLOR,
						stack: 'Stack 0'
					  },
					{
						label: 'Actual',
						data: monthlyBurnRateData.actual,
						borderColor: BUDGET_DARK_COLOR,
						backgroundColor: BUDGET_DARK_COLOR,
						stack: 'Stack 1'
					  },
					  {
						label: 'Outside',
						data: monthlyBurnRateData.outside,
						borderColor: BUDGET_OUTSIDE_COLOR,
						backgroundColor: BUDGET_OUTSIDE_COLOR,
						stack: 'Stack 2'
					  }
					
					
				  ]
			};
		
			const chartOptions = {
				layout: {
					padding: {
						right: 20,
						left: 20,
						top: 65
					}
				},
				
				scales: {
					y: {
					  stacked: true,
						ticks: {
						// For a category axis, the val is the index so the lookup via getLabelForValue is needed
						callback: function(val, index) {
						  // Hide every 2nd tick label
							if (index !== 0 && index % 2 === 0) return '$' + this.getLabelForValue(val)
						}
					  }
					},
					x: {
						stacked: true
					}
				  },
				plugins: {
					// Change options for ALL labels of THIS CHART
					datalabels: {
						anchor: 'end',
						align: 'end',
						font: {
							size: 14
						},
						
						rotation: -90,
						formatter: function(value, context) {
							//return context.dataset.data[context.dataIndex] 
							return '$' + Intl.NumberFormat('en-US').format(Math.round(context.dataset.data[context.dataIndex]))
						},
						display: function(context) {
							return context.dataset.data[context.dataIndex] > 0;
						}
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								return context.dataset.label + ': $' + Intl.NumberFormat('en-US').format(Math.round(context.raw))
							}
						}
					},
					legend: {
						position: 'bottom',
						
					  },
				  }
			};
			
			var myChart = new ChartJS(document.getElementById('monthlyBurnChart'), {
				type: "bar",
				data: chartData ,
				options: chartOptions
			});

			// when component unmounts
			return () => {
				myChart.destroy()
			}
		}
	}, [loading, monthlyBurnRateData])

	useEffect(() => {
		let _spentPercentage = Math.round((yearlySpent / yearlyBudget) * 100)
		
		setSpentPercentage(_spentPercentage)
	},[yearlyBudget, yearlySpent])

	useEffect(() => {
		
		if (!loading && monthlyBurnRateData.notInRange) {
			let dataNotInRange = monthlyBurnRateData.notInRange.reduce((acc,cur) => {
				if (cur.state === 'add') {
					acc.hours += cur.hours
					acc.spend += cur.spend
				} else if (cur.state === 'remove') {
					acc.hours -= cur.hours
					acc.spend -= cur.spend
				}
				return acc
			},{hours: 0, spend: 0})


			console.log('%c Hours OUTSIDE project range and not accounted for: ' + dataNotInRange.hours, 'color: #FF0000; font-size: 18px');
			console.log('%c Spent amount OUTSIDE project range and not accounted for: ' + dataNotInRange.spend, 'color: #FF0000; font-size: 18px');
			console.log('%c Below is the full object with details for all projects.', 'color: #1c87c9; font-size: 18px');
			console.log(monthlyBurnRateData)

		}
	},[loading, monthlyBurnRateData])


	const exportExcel = () => {
       
		
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Monthly Burn Rate");
		worksheet.properties.defaultColWidth = 20;
		worksheet.properties.defaultRowHeight = 16;

		worksheet.mergeCells("A1:C1");
		fncDrawTitleCell(worksheet, 'A1', 'Monthly Burn Rate vs. Forecasted Spend')
	
		fncDrawHeaderCellBudget(worksheet, 'A3', 'Month')
		fncDrawHeaderCellBudget(worksheet, 'B3', 'Annual Spend')
		fncDrawHeaderCellBudget(worksheet, 'C3', 'Annual Budget')

		//monthlyBurnRateData.outside

		for(let curMonth = 0; curMonth < 12;curMonth++) {
			let dataRow = worksheet.addRow();
			
			let monthCell = dataRow.getCell(1);
			monthCell.value = dayjs().month(curMonth).format('MMMM YYYY')
			

			let spendCell = dataRow.getCell(2);
			spendCell.value = monthlyBurnRateData.actual[curMonth] + monthlyBurnRateData.outside[curMonth]
			spendCell.numFmt = '"$"#,##0.00'

			let budgetCell = dataRow.getCell(3);
			budgetCell.value = monthlyBurnRateData.budget[curMonth]	 
			budgetCell.numFmt = '"$"#,##0.00'
		}

		let dataFooterRow = worksheet.addRow();
		fncDrawFooterCellBudget(dataFooterRow,1,'Total')
		let spendTotal = monthlyBurnRateData.actual.reduce((acc,cur, index) => {
			return acc += cur + monthlyBurnRateData.outside[index]
		},0)
		let budgetTotal = monthlyBurnRateData.budget.reduce((acc,cur) => {
			return acc += cur
		},0)
		let objFooter1 = fncDrawFooterCellBudget(dataFooterRow,2,spendTotal)
		objFooter1.numFmt = '"$"#,##0.00'
		let objFooter2 = fncDrawFooterCellBudget(dataFooterRow,3,budgetTotal)
		objFooter2.numFmt = '"$"#,##0.00'

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  fileName + `.xlsx`
			);
		  });
    }

	return (
		<div className="flex flex-column spend">
			{!loading && 
				<>
					<div className="flex justify-content-center strong">
						<div>
							Annual Fiscal Budget: ${new Intl.NumberFormat('en-US').format(Math.round(yearlyBudget))}
						</div>
						<div className="ml-4">
							Annual Fiscal Spend: ${new Intl.NumberFormat('en-US').format(Math.round(yearlySpent))} ({spentPercentage}%)
						</div>
					</div>

					<div className="p-chart artCharts-bar">
						<canvas id="monthlyBurnChart"></canvas>
					</div>
				</>
			}
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		monthlyBurnRateData: getMonthlyBurnRate(state,{dates: ownProps.dates}),
		yearlyBudget: getWidgetAnnualBaselineBudget(state, {dates: ownProps.dates}),
		yearlySpent: getWidgetSpent(state,{dates: ownProps.dates}),
		fiscaldates: getFiscalDates(state)
	}
}

const MonthlyBurnRateContainer = connect(mapStateToProps, null)(MonthlyBurnRate);
export default MonthlyBurnRateContainer;