import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs'

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { dayTemplate, isTrue } from '../utilities/CommonSnippets'

const StaffChangesModal = (props) => {
	
	const { fncHideModal, displayStaffChanges, staffChanges, header} = props

	const nameTemplate = ( rowData ) => {
		return (
			<div className="flex flex-column">
				<div className="mb-2 name ">
					{rowData.name}
				</div>
				<div className="mb-2 dateRange">
					{rowData.functionTitle} 
				</div>

				<div className="mb-2 comments">
					{rowData.comments} 
				</div>

				
			</div>
		)
	}

	const changesTemplate = (rowData) => {
		
		let blnNoDupes = false

		let arrFields = rowData.logTypes.map((cur, index) => {
			if (blnNoDupes) return
			let strType = cur.logType
			
			if (strType === 'addStaff') {
				strType = 'Staff Added'
				blnNoDupes =  true
			}
			if (strType === 'deleteStaff') {
				strType = 'Staff Deleted'
				blnNoDupes =  true
			}
			if (strType === 'editStaff') {
				strType = 'Staff Edit'
				blnNoDupes =  true
			}
			if (strType === 'updateStatus') {
				strType = 'Staff Status Updated'
				blnNoDupes =  true
			}
			if (strType === 'editDetails') {
				
				let strOldValue = cur.oldValue
				let strNewValue = cur.newValue
				let strField = cur.fieldChange

				if (cur.fieldChange === 'isSenior') {
					strField = "Senior Staff"
					strOldValue = isTrue(strOldValue) ? 'Yes' : 'No'
					strNewValue = isTrue(strNewValue) ? 'Yes' : 'No'
				}
				
				strType = <div className="flex flex-column mb-3" >
					<div>
						{strField}
					</div>
					<div className="ml-3 mb-1" style={{fontWeight: '600', fontSize:'13px'}}>
						New: {strNewValue}
					</div>
					<div className="ml-3" style={{fontWeight: '600', fontSize:'13px'}}>
						Previous: {strOldValue}
					</div>
					<div className="ml-3" style={{fontWeight: '600', fontSize:'11px'}}>
						{dayjs(cur.timeStamp).format('h:mm A')}
					</div>
				</div>
			}
			
			
			return (
				<div key={"change_" + index} style={{textTransform: 'capitalize'}}>{strType}</div>
			)
		})

		return (
			<div className="flex flex-column " >
				{arrFields}
			</div>
		)
	}

	const brandAudienceTemplate = (rowData) => {
		let divBrandAudiences =  rowData.brandAudiences.map(cur => {
			return (
				<div>
					{cur}
				</div>
			)
		})

		return (
			<div className="flex flex-column ">
				{divBrandAudiences}
			</div>
		)
	}

	return (
		<Dialog header={header} visible={displayStaffChanges} className="changesModal"  onHide={fncHideModal}>
			<DataTable className="p-datatable-products" value={staffChanges} paginator
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5,10,25]}
					paginatorPosition="bottom"  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
				<Column field="timeStamp" header="Date" sortable body={dayTemplate} style={{verticalAlign:'top',width:'15%'}}></Column>
				<Column field="name" sortable header="Staff" body={nameTemplate} style={{verticalAlign:'top',width:'40%'}}></Column>
				<Column field="brandAudiences" header="Brands/Audience" body={brandAudienceTemplate} style={{width:'25%', verticalAlign: 'top'}}/>
				<Column field="changes" header="Changes" body={changesTemplate} style={{verticalAlign:'top',width:'20%'}}></Column>
			</DataTable>
		</Dialog>
	)
}

export default StaffChangesModal;