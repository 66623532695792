import React from 'react';
import { connect } from 'react-redux';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// Selectors
import { getStaffChanges} from '../../selectors/dashboardWidgetsSelector'

import { isTrue } from '../../utilities/CommonSnippets'
import dayjs from 'dayjs';

// helpers

const StaffChanges = (props) => {
	const {  staffChanges } = props

	const logTypeTemplate = (rowData) => {
		
		let blnNoDupes = false

		let arrFields = rowData.logTypes.map((cur, index) => {
			if (blnNoDupes) return
			let strType = cur.logType
			
			if (strType === 'addStaff') {
				strType = 'Staff Added'
				blnNoDupes =  true
			}
			if (strType === 'deleteStaff') {
				strType = 'Staff Deleted'
				blnNoDupes =  true
			}
			if (strType === 'editStaff') {
				strType = 'Staff Edit'
				blnNoDupes =  true
			}
			if (strType === 'updateStatus') {
				strType = 'Staff Status Updated'
				blnNoDupes =  true
			}
			if (strType === 'editDetails') {
				
				let strOldValue = cur.oldValue
				let strNewValue = cur.newValue
				let strField = cur.fieldChange

				if (cur.fieldChange === 'isSenior') {
					strField = "Senior Staff"
					strOldValue = isTrue(strOldValue) ? 'Yes' : 'No'
					strNewValue = isTrue(strNewValue) ? 'Yes' : 'No'
				}
				
				strType = <div className="flex flex-column mb-3" >
					<div>
						{strField}
					</div>
					<div className="ml-3 mb-1" style={{fontWeight: '600', fontSize:'13px'}}>
						New: {strNewValue}
					</div>
					<div className="ml-3" style={{fontWeight: '600', fontSize:'13px'}}>
						Previous: {strOldValue}
					</div>
					<div className="ml-3" style={{fontWeight: '600', fontSize:'11px'}}>
						{dayjs(cur.timeStamp).format('h:mm A')}
					</div>
					
				</div>
			}
			
			
			return (
				<div key={"change_" + index} style={{textTransform: 'capitalize'}}>{strType}</div>
			)
		})

		return (
			<div className="flex flex-column " >
				{arrFields}
			</div>
		)
	}

	const brandAudienceTemplate = (rowData) => {
		let divBrandAudiences =  rowData.brandAudiences.map(cur => {
			return (
				<div>
					{cur}
				</div>
			)
		})

		return (
			<div className="flex flex-column ">
				{divBrandAudiences}
			</div>
		)
	}

	const nameTemplate = (rowData) => {
		return (
			<div className="flex flex-column ">
				<div>
					{rowData.name}
				</div>
				<div style={{fontWeight:'600'}}>
					{rowData.functionTitle}
				</div>
			</div>
		)
	}

	return (
		<div className="flex staff">
			<DataTable className="tables" value={staffChanges}  style={{width:'100%'}}
				paginator paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,50,100]}
			>
				<Column field="timeStamp" sortable header="Date" style={{width:'15%', verticalAlign: 'top'}}/>
				<Column field="name" sortable header="Staff Name" style={{width:'25%', verticalAlign: 'top'}} body={nameTemplate} />
				<Column field="brandAudiences" header="Brands/Audience" body={brandAudienceTemplate} style={{width:'25%', verticalAlign: 'top'}}/>
				<Column field="logType" body={logTypeTemplate} header="Changes" style={{width:'35%',verticalAlign: 'top'}}/>
			</DataTable>
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		staffChanges: getStaffChanges(state,ownProps)
	}
}

const StaffChangesContainer = connect(mapStateToProps, null)(StaffChanges);
export default StaffChangesContainer;