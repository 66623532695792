import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Chart as ChartJS, BarController, LineController, BarElement, PointElement, LineElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

import dayjs from 'dayjs'
import ExcelJS  from 'exceljs'
import saveAs from "file-saver";

// Selectors
import { getActualVsBudgetStaffHours, getFiscalDates} from '../../selectors/dashboardWidgetsSelector'

import {InputSwitch} from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';


// helpers
import { STAFF_COLOR_ARRAY , GRAY_ARRAY} from '../../constants/colors'
import { percentTemplate, fncFormatPercentage, fixedTemplate } from '../../utilities/CommonSnippets'
import { fncDrawHeaderCell, fncDrawFooterCell, fncDrawTitleCell} from '../../utilities/commonXslxFunctions'

ChartJS.register(BarController)
ChartJS.register(LineController)
ChartJS.register(CategoryScale)
ChartJS.register(LinearScale)
ChartJS.register(BarElement)
ChartJS.register(PointElement)
ChartJS.register(LineElement)
ChartJS.register(Legend)
ChartJS.register(Tooltip)
ChartJS.defaults.font.size = 14;

const ActualVsBudgetStaff = (props) => {
	
	const [ seniorStaff, setSeniorStaff] = useState(false);
	const { actualVsBudgetStaffHours, loading, downloadClicked, fileName, dates, fiscaldates } = props

	useEffect(() => {
		if (downloadClicked > 0) exportExcel()
	},[downloadClicked])

	useEffect(() => {
		if (!loading) {
			const arrLabels = () => {
				let arrMonths = []
				
				let fiscalStartDate = fiscaldates.startOfYear
				if (dates === 'lastYear') fiscalStartDate = fiscaldates.startOfPrevYear
				for(let curMonth = 0; curMonth < 12;curMonth++) {
					let curFromDate = dayjs(fiscalStartDate).add(curMonth, 'month')
					arrMonths.push(curFromDate.format('MMM YY') + ' Senior')
					if (seniorStaff !== true) arrMonths.push(curFromDate.format('MMM YY') + ' All')
				}
			
				return arrMonths;
			}
		
			let seniorBudget = actualVsBudgetStaffHours.senior.budget.reduce((acc,cur) => {
				acc.push(cur)
				if (seniorStaff !== true) acc.push(null)
				return acc	
			},[])
		
			let seniorActual = actualVsBudgetStaffHours.senior.used.reduce((acc,cur) => {
				acc.push(cur)
				if (seniorStaff !== true) acc.push(null)
				return acc	
			},[])
		
			let allBudget = actualVsBudgetStaffHours.notSenior.budget.reduce((acc,cur) => {
				if (seniorStaff !== true) acc.push(null)
				acc.push(cur)
				return acc	
			},[])
		
			let allActual = actualVsBudgetStaffHours.notSenior.used.reduce((acc,cur) => {
				if (seniorStaff !== true) acc.push(null)
				acc.push(cur)
				return acc	
			},[])
		
			let dataSets = []
			dataSets.push({
				label: 'Senior Staff: Budgeted',
				data: seniorBudget,
				borderColor: STAFF_COLOR_ARRAY[2],
				backgroundColor: STAFF_COLOR_ARRAY[2],
				type: 'line',
				showLine: false,
				pointRadius: 7,
				pointHoverRadius: 7,
				order: 1,
				skipNull: true
			})
			dataSets.push({
				label: 'Senior Staff: Actual',
				data: seniorActual,
				borderColor: STAFF_COLOR_ARRAY[4],
				backgroundColor: STAFF_COLOR_ARRAY[4],
				order: 2,
				skipNull: true
			})
		
			if (seniorStaff !== true) {
				dataSets.push({
					label: 'All Other Staff: Budgeted',
					data: allBudget,
					borderColor: GRAY_ARRAY[1],
					backgroundColor: GRAY_ARRAY[1],
					type: 'line',
					showLine: false,
					pointRadius: 7,
					pointHoverRadius: 7,
					pointStyle: 'circle',
					order: 3,
					skipNull: true
				})
				dataSets.push({
					label: 'All Other Staff: Actual',
					data: allActual,
					borderColor: GRAY_ARRAY[0],
					backgroundColor: GRAY_ARRAY[0],
					order: 4,
					skipNull: true
				})
			}
			
			const chartData = {
				labels: arrLabels(),
				datasets: dataSets
			};
		
			const chartOptions = {
				layout: {
					padding: {
						right: 20,
						left: 20
					}
				},
				responsive: true,
				
				plugins: {
					// Change options for ALL labels of THIS CHART
					datalabels: {
						anchor: 'end',
						align: 'end',
						formatter: function(value, context) {
							return null
						}
					}
				  }
			};

			var myChart = new ChartJS(document.getElementById('actualVsBudgetChart'), {
				type: "bar",
				data: chartData ,
				options: chartOptions
			});

			// when component unmounts
			return () => {
				myChart.destroy()
			}
		}
	}, [actualVsBudgetStaffHours, loading, seniorStaff])

	const getTotals = (field) => {
        let total = 0;
        if (actualVsBudgetStaffHours.all) {
			for(let curTotal of actualVsBudgetStaffHours.all) {
				total += curTotal[field];
			}
		}

        return total.toLocaleString('en-US');
    }

	const getTotalAverageSenior = () => {
        let totalScoped = 0;
		let totalUsed = 0;
        if (actualVsBudgetStaffHours.all) {
			for(let curTotal of actualVsBudgetStaffHours.all) {
				totalScoped += curTotal['seniorBudget'];
				totalUsed += curTotal['seniorActual'];
			}
		}

        return fncFormatPercentage(totalUsed / totalScoped,1) + '%';
    }

	const getTotalAverageOther = () => {
        let totalScoped = 0;
		let totalUsed = 0;
        if (actualVsBudgetStaffHours.all) {
			for(let curTotal of actualVsBudgetStaffHours.all) {
				totalScoped += curTotal['otherBudget'];
				totalUsed += curTotal['otherActual'];
			}
		}

        return fncFormatPercentage(totalUsed / totalScoped,1) + '%';
    }

	let footerGroup = () => {
		
		return (
			<ColumnGroup>
			<Row>
				<Column footer="YTD" footerStyle={{color: 'white',textAlign: 'right'}}/>

				<Column footer={getTotals('seniorActual')} footerStyle={{color: 'white',textAlign: 'right'}}/>
				<Column footer={getTotals('seniorBudget')} footerStyle={{color: 'white',textAlign: 'right'}}/>
				<Column footer={getTotalAverageSenior()} footerStyle={{color: 'white',textAlign: 'right'}}/>

				<Column footer={getTotals('otherActual')} footerStyle={{color: 'white',textAlign: 'right'}}/>
				<Column footer={getTotals('otherBudget')} footerStyle={{color: 'white',textAlign: 'right'}}/>
				<Column footer={getTotalAverageOther()} footerStyle={{color: 'white',textAlign: 'right'}}/>
			</Row>
			</ColumnGroup>
		)
	}

	let headerGroup = <ColumnGroup>
			
			<Row>
				<Column header="Hours" className="talentWidth" style={{textAlign:'center'}}/>
				<Column header="Senior" colSpan={3} style={{textAlign:'center'}} />
				<Column header="All Other" colSpan={3} style={{textAlign:'center'}} />
			</Row>
			<Row>
				<Column  header="Month" style={{textAlign:'left'}}/>
				
				<Column field="seniorActual"  header="Actual" style={{textAlign:'center'}}/>
				<Column field="seniorBudget"  header="Forecast" style={{textAlign:'center'}}/>
				<Column field="seniorPercentage"  header="% Used" style={{textAlign:'center'}}/>

				<Column field="otherActual"  header="Actual" style={{textAlign:'center'}}/>
				<Column field="otherBudget"  header="Forecast" style={{textAlign:'center'}}/>
				<Column field="otherPercentage"  header="% Used" style={{textAlign:'center'}}/>
			</Row>
			
		</ColumnGroup>;

	const formatHours = (rowData, column) => {
		if (column) return rowData[column.field].toLocaleString('en-US')
		else return rowData.toLocaleString('en-US')
	}

	const exportExcel = () => {
       
		let arrColumns = ['month','seniorActual','seniorBudget','seniorPercentage','otherActual','otherBudget','otherPercentage']
		let ExcelJSWorkbook = new ExcelJS.Workbook();
		let worksheet = ExcelJSWorkbook.addWorksheet("Actual Vs Budget Staff");
		worksheet.properties.defaultColWidth = 20;
		worksheet.properties.defaultRowHeight = 16;
		
		worksheet.mergeCells("A1:G1");
		fncDrawTitleCell(worksheet, 'A1', 'Actual Versus Budgeted Staff Hours')
		
		// TOP BAR
		fncDrawHeaderCell(worksheet, 'A3', 'Hours')
		
		worksheet.mergeCells("B3:D3");
		fncDrawHeaderCell(worksheet, 'B3', 'Senior')

		worksheet.mergeCells("E3:G3");
		fncDrawHeaderCell(worksheet, 'E3', 'All Other')

		// 2nd TOP BAR
		fncDrawHeaderCell(worksheet, 'A4', 'Month')
		fncDrawHeaderCell(worksheet, 'B4', 'Actual')
		fncDrawHeaderCell(worksheet, 'C4', 'Forecast')
		fncDrawHeaderCell(worksheet, 'D4', '% Used')
		fncDrawHeaderCell(worksheet, 'E4', 'Actual')
		fncDrawHeaderCell(worksheet, 'F4', 'Forecast')
		fncDrawHeaderCell(worksheet, 'G4', '% Used')

		let startRow = null
		let endRow = null
		for (let curRow of actualVsBudgetStaffHours.all) {
			let dataRow = worksheet.addRow();
			if (!startRow) startRow = dataRow['_number']
			for (let curColumn in arrColumns) {
				let cell = dataRow.getCell(parseInt(curColumn) + 1);
				
				if ( arrColumns[curColumn] === 'seniorPercentage' || arrColumns[curColumn] === 'otherPercentage') {
					cell.value = curRow[arrColumns[curColumn]] / 100;
					cell.numFmt = '0.0%'
				} else {
					cell.value = curRow[arrColumns[curColumn]];
					cell.numFmt = '#,###.0#'
				}
				
			}
			endRow = dataRow['_number']
		}

		let dataFooterRow = worksheet.addRow();
		fncDrawFooterCell(dataFooterRow,1,'YTD')
		fncDrawFooterCell(dataFooterRow,2,getTotals('seniorActual'), 'SUM(B' + startRow + ':B' + endRow + ')')
		fncDrawFooterCell(dataFooterRow,3,getTotals('seniorBudget'), 'SUM(C' + startRow + ':C' + endRow + ')')
		fncDrawFooterCell(dataFooterRow,4,getTotalAverageSenior(), 'AVERAGE(D' + startRow + ':D' + endRow + ')')
		fncDrawFooterCell(dataFooterRow,5,getTotals('otherActual'), 'SUM(E' + startRow + ':E' + endRow + ')')
		fncDrawFooterCell(dataFooterRow,6,getTotals('otherBudget'), 'SUM(F' + startRow + ':F' + endRow + ')')
		fncDrawFooterCell(dataFooterRow,7,getTotalAverageOther(), 'AVERAGE(G' + startRow + ':G' + endRow + ')')
		

		ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
			saveAs(
			  new Blob([buffer], { type: "application/octet-stream" }),
			  fileName + `.xlsx`
			);
		  });
    }

	
	

	return (
		<div className="flex flex-column staff">
			
			<div className="flex mb-2">
				<div className="mr-2 ml-2"> 
					All Staff
				</div>
				<div className="mr-2"> 
					<InputSwitch checked={seniorStaff} onChange={(e) => setSeniorStaff(e.value)} />
				</div>
				<div>
					Senior Staff
				</div>
			</div>
			{!loading && 
				<div className="flex flex-column">
				
					<div className="p-chart artCharts-bar">
						<canvas id="actualVsBudgetChart"></canvas>
					</div>
					<div className="mt-4">
						<DataTable className="tables" value={actualVsBudgetStaffHours.all} headerColumnGroup={headerGroup} footerColumnGroup={footerGroup()}
							
						>
							<Column field="month"  header="Month" />
							<Column field="seniorActual" body={formatHours} header="" style={{textAlign:'right'}} />
							<Column field="seniorBudget" body={formatHours} header="" style={{textAlign:'right'}} />
							<Column field="seniorPercentage" header="" body={percentTemplate} style={{textAlign:'right'}} />
							<Column field="otherActual" body={formatHours} header="" style={{textAlign:'right'}} />
							<Column field="otherBudget" body={formatHours} header="" style={{textAlign:'right'}} />
							<Column field="otherPercentage" header="" body={percentTemplate} style={{textAlign:'right'}} />
						</DataTable>

					</div>
				</div>
			}
		</div>
	)
}


/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapStateToProps = (state, ownProps) => {
	
	return {
		actualVsBudgetStaffHours: getActualVsBudgetStaffHours(state,{dates: ownProps.dates}),
		fiscaldates: getFiscalDates(state)
	}
}

const ActualVsBudgetStaffContainer = connect(mapStateToProps, null)(ActualVsBudgetStaff);
export default ActualVsBudgetStaffContainer;