import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames'
import { Link } from 'react-router-dom';

// action files (get data from server and set up redux store)
import { getBrands } from '../actions/brands'
import { getAgenciesByFranchise, getAgencyFunctionsByFranchise, getAgencyProjectsByFranchise, getAgencyProjectStaffByFranchise, getAgencyStatusLogsByFranchise} from '../actions/franchises'

// selector files (transform data from redux store in to what we need for presentation)
import { getBrandsByFranchiseSelector, getFranchiseDetailsSelector } from '../selectors/brandsSelector'
import { getAudiences, getAudiencesProject, getWidgetAnnualStaffingBaseline, getWidgetAnnualBaselineBudget, getWidgetOutOfPocketBaseline } from '../selectors/dashboardWidgetsSelector'

import { setCurrentClientID } from '../actions/ui'
// PrimeReact Components
import { Button } from 'primereact/button';

// BreadCrumbs component
import BreadCrumbs from '../components/breadCrumbs/breadCrumbs-container'

// Widget components
import DashboardWidget from '../components/dashboardWidget'
import AnnualBaselineBudget from '../components/dashboardWidgets/annualBaselineBudget'
import ProjectSpendSummary from '../components/dashboardWidgets/projectSpendSummary'

const NewOverview = (props) => {
	
	const { getBrands, setCurrentClientID, getAgenciesByFranchise, getAgencyFunctionsByFranchise, 
		getAgencyProjectsByFranchise, getAgencyProjectStaffByFranchise, getAgencyStatusLogsByFranchise, 
		franchiseID, franchiseName, clientID, audienceList, audienceListProject,
		staffYearlyBudget, yearlyBudget, oopYearlyBudget, brands  } = props

	
	const dateDependencies = {
		'annualBaselineBudget_1': ['spendSummary_1'],
		'annualBaselineBudget_2': ['spendSummary_2'],
		'annualBaselineBudget_3': ['spendSummary_3']
	}

	const downloadWidgets = {
		actualVersusBudgetStaff: 0,
		monthlyBurnRate: 0,
		outOfPocketBudget: 0,
		outOfPocketCosts: 0,
		staffHours: 0
	}

	const [ selectedTab, setSelectedTab] = useState('budget'); // This controls which 'tab' is being looked at. The tab is the widget
	const [ widgetDates, setWidgetDates] = useState({}); // This holds all the dates for the widgets. when a date changes, it is stored here
	const [ loading, setLoading] = useState(false);
	const [ isDesktop, setIsDesktop] = useState(true);
	const [ download, setDownload] = useState(downloadWidgets);
	
	useEffect(() => {
		getBrands()
	},[getBrands]);

	useEffect(() => {
		let _widgetDates = {...widgetDates}
		_widgetDates['annualBaselineBudget_1'] = 'fullYear'
		_widgetDates['spendSummary_1'] = 'fullYear'

		_widgetDates['annualBaselineBudget_2'] = 'ytd'
		_widgetDates['spendSummary_2'] = 'ytd'

		_widgetDates['annualBaselineBudget_3'] = 'lastMonth'
		_widgetDates['spendSummary_3'] = 'lastMonth'
		
		setWidgetDates(_widgetDates)
	},[]);

	useEffect(() => {
		if (franchiseID) {
			setLoading(true)
			fncLoadNewBrands()
		}
	},[franchiseID]);

	useEffect(() => {
		setCurrentClientID(clientID)
	},[clientID]);

	useEffect(() => {
		if (!loading) handleResize()
	},[loading]);

	useEffect(() => {
		window.addEventListener("resize", handleResize, false);
	},[]);

	const fncDownloadClicked = (downloadKey) => {
		let _download = {...download}
		_download[downloadKey]++
		setDownload(_download)

	}

	const handleResize = () => {
		setIsDesktop(window.innerWidth > 1200)
	}
	
	const breadCrumbItems = [
        {label: 'Overview', command: (event) => {window.location.hash = "/franchiseOverview/" + franchiseID;}},
		{label: 'Compare'},
		{label: <div className="strong">{franchiseName} Dashboard</div> }
    ];

	let fncSetTab = (tab) => {
		setSelectedTab(tab)
	}

	let fncDates = (key,value) => {
		
		let _widgetDates = {...widgetDates}
		_widgetDates[key] = value

		// set any dependency widgets
		if (dateDependencies[key]) {
			for (let curDependent of dateDependencies[key]) {
				_widgetDates[curDependent] = value
			}
		}

		setWidgetDates(_widgetDates)
	}

	let fncLoadNewBrands = async () => {
		await getAgenciesByFranchise(brands)
		await getAgencyProjectsByFranchise(brands)
		await getAgencyProjectStaffByFranchise(brands)
		await getAgencyStatusLogsByFranchise(brands)
		await getAgencyFunctionsByFranchise(brands)
		setLoading(false)
	}

	return (
		<div className="flex flex-column newOverview" >
			
					<div className="mb-2 flex justify-content-between">
						<BreadCrumbs items={breadCrumbItems} />
						<div style={{zIndex: '999'}}>
							<Link to={"/franchiseOverview/" + props.franchiseID} >
								<Button label="Return" className="p-button-outlined" />
							</Link>
						</div>
					</div>
					<div className="flex mb-3 fullWidget">
						<div className="pr-2 align-self-stretch thirdWidget">
							<DashboardWidget 
								widgetKey="annualBaselineBudget_1"
								title="Baseline Budget" 
								viewDetails={() => fncSetTab('budget')} 
								isSelected={selectedTab === 'budget'?true:false}
								totalYearly={new Intl.NumberFormat('en-US',{style: 'currency',currency: 'USD',minimumFractionDigits: 2}).format(yearlyBudget)}
								
								activeClass={selectedTab === 'budget'?'budgetActive':null}
								onDateChange={fncDates}
								currentDateValue={widgetDates.annualBaselineBudget_1}
								zIndex={100}
							>
								<AnnualBaselineBudget dates={widgetDates.annualBaselineBudget_1} loading={loading} instance={1}/>
							</DashboardWidget>
						</div>
						<div className="pr-2 align-self-stretch thirdWidget">
							<DashboardWidget 
								widgetKey="annualBaselineBudget_2"
								title="Baseline Budget" 
								viewDetails={() => fncSetTab('budget')} 
								isSelected={selectedTab === 'budget'?true:false}
								totalYearly={new Intl.NumberFormat('en-US',{style: 'currency',currency: 'USD',minimumFractionDigits: 2}).format(yearlyBudget)}
								
								activeClass={selectedTab === 'budget'?'budgetActive':null}
								onDateChange={fncDates}
								currentDateValue={widgetDates.annualBaselineBudget_2}
								zIndex={100}
							>
								<AnnualBaselineBudget dates={widgetDates.annualBaselineBudget_2} loading={loading} instance={2}/>
							</DashboardWidget>
						</div>
						<div className="pr-2 align-self-stretch thirdWidget">
							<DashboardWidget 
								widgetKey="annualBaselineBudget_3"
								title="Baseline Budget" 
								viewDetails={() => fncSetTab('budget')} 
								isSelected={selectedTab === 'budget'?true:false}
								totalYearly={new Intl.NumberFormat('en-US',{style: 'currency',currency: 'USD',minimumFractionDigits: 2}).format(yearlyBudget)}
								
								activeClass={selectedTab === 'budget'?'budgetActive':null}
								onDateChange={fncDates}
								currentDateValue={widgetDates.annualBaselineBudget_3}
								zIndex={100}
							>
								<AnnualBaselineBudget dates={widgetDates.annualBaselineBudget_3} loading={loading} instance={3}/>
							</DashboardWidget>
						</div>
					</div>
					<div className="flex mb-3 fullWidget">
					<div className={classNames({'pr-2 align-self-stretch thirdWidget': isDesktop,'pb-2 fullWidget': !isDesktop})}>
							<DashboardWidget 
								widgetKey="spendSummary_1"
								title="Project Spend Summary"
								onDateChange={fncDates}
								currentDateValue={widgetDates.spendSummary_1}
								
							>
								<ProjectSpendSummary dates={widgetDates.spendSummary_1} entityName={franchiseName} instance={1}/>
							</DashboardWidget>
						</div>
						<div className={classNames({'pr-2 align-self-stretch thirdWidget': isDesktop,'pb-2 fullWidget': !isDesktop})}>
							<DashboardWidget 
								widgetKey="spendSummary_2"
								title="Project Spend Summary"
								onDateChange={fncDates}
								currentDateValue={widgetDates.spendSummary_2}
								
							>
								<ProjectSpendSummary dates={widgetDates.spendSummary_2} entityName={franchiseName} instance={2}/>
							</DashboardWidget>
						</div>
						<div className={classNames({'pr-2 align-self-stretch thirdWidget': isDesktop,'pb-2 fullWidget': !isDesktop})}>
							<DashboardWidget 
								widgetKey="spendSummary_3"
								title="Project Spend Summary"
								onDateChange={fncDates}
								currentDateValue={widgetDates.spendSummary_3} 
							>
								<ProjectSpendSummary dates={widgetDates.spendSummary_3} entityName={franchiseName} instance={1}/>
							</DashboardWidget>
						</div>
					</div>
					
				
			
		</div>
    );
}

/* 
	REDUX Store details - these are passed to the above component for drawing
*/

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		getBrands: () => dispatch(getBrands()),
		setCurrentClientID: (payload) => setCurrentClientID(payload),
		getAgenciesByFranchise: (brands) => dispatch(getAgenciesByFranchise(brands)),
		getAgencyFunctionsByFranchise: (brands) => dispatch(getAgencyFunctionsByFranchise(brands)),
		getAgencyProjectsByFranchise: (brands) => dispatch(getAgencyProjectsByFranchise(brands)),
		getAgencyStatusLogsByFranchise: (brands) => dispatch(getAgencyStatusLogsByFranchise(brands)),
		getAgencyProjectStaffByFranchise: (brands) => dispatch(getAgencyProjectStaffByFranchise(brands))
	}, dispatch);
};

const mapStateToProps = (state, ownProps) => {
	
	let objFranchiseDetails = getFranchiseDetailsSelector(state,ownProps)

	return {
		franchiseID: objFranchiseDetails.franchiseID,
		franchiseName: objFranchiseDetails.franchiseName,
		clientID: objFranchiseDetails.clientID,
		brands: getBrandsByFranchiseSelector(state,ownProps),
		audienceList: getAudiences(state, {dates:'fullYear'}),
		audienceListProject: getAudiencesProject(state, {dates:'fullYear'}),
		staffYearlyBudget: getWidgetAnnualStaffingBaseline(state, {dates:'fullYear'}),
		yearlyBudget: getWidgetAnnualBaselineBudget(state, {dates:'fullYear'}),
		oopYearlyBudget: getWidgetOutOfPocketBaseline(state,{dates:'fullYear'}),
	}
}

const NewOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(NewOverview);
//export default withAuth(OverviewContainer);
export default NewOverviewContainer;