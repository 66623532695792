import React from 'react';
import { ProgressBar } from 'primereact/progressbar';
import dayjs from 'dayjs'

export function isTrue(strValue) {
	if (strValue === 'Y' || strValue === 'Yes' || strValue === 1 || strValue === true || strValue === '1' ||  strValue === 'true') {
		return true 
	} else {
		return false
	}
}

export const fncFormatCurrency = (value) =>{
	return value.toLocaleString('en-US', {style: 'currency',currency: 'USD',})
}

export const fncFormatPercentage = (value, mode) => {
	if (isNaN(value)) value = 0

	if (mode === 1) return (value * 100).toFixed(1)
	else if (mode === 2) return (100 - value).toFixed(1)
}

export const dateTemplate = (rowData, column) => {
	if (column) return new Date(rowData[column.field]).toDateString()
	else return new Date(rowData).toDateString()
}

export const monthTemplate = (rowData, column) => {
	if (column) return dayjs(rowData[column.field]).format('MMMM YYYY')
	else return dayjs(rowData).format('MMMM YYYY')
}

export const dayTemplate = (rowData, column) => {
	if (column) return dayjs(rowData[column.field]).format('MMMM D, YYYY')
	else return dayjs(rowData).format('MMMM D, YYYY')
}



export const percentTemplate = (rowData, column) => {
	if (isNaN(rowData[column.field]))  return null
	if (column) return <span>{rowData[column.field].toFixed(1)}%</span>
	else return <span>{rowData.toFixed(1)}%</span>
}

export const percentTemplateNoDecimal = (rowData, column) => {
	if (isNaN(rowData[column.field]))  return null
	if (column) return <span>{Math.round(rowData[column.field])}%</span>
	else return <span>{Math.round(rowData)}%</span>
}

export const fixedTemplate = (rowData, column) => {
	if (column) return <span>{rowData[column.field].toFixed(2).toLocaleString('en-US')}</span>
	else return <span>{rowData.toFixed(1).toLocaleString('en-US')}</span>
}

export const defaultTitleTemplate = (rowData, column) => {
	if (column) return <span className="strong">{rowData[column.field]}</span>
	else return <span className="strong">{rowData}</span>
}



export const dollarTemplate = (rowData, column) => {
	
	
	if (column) {
		if (typeof rowData[column.field] === 'number') {
			return rowData[column.field].toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		}
		else return ''
	} else return rowData.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const dollarTemplateNoDecimal = (rowData, column) => {
	if (column) {
		if (typeof rowData[column.field] === 'number') {
			
			return '$' + Math.round(rowData[column.field]).toLocaleString('en-US');
			
			
		} else return ''
	} else return '$' + Math.round(rowData).toLocaleString('en-US');
}



export const numberTemplate = (rowData, column) => {
	
	if (column) {
		if (typeof rowData[column.field] === 'number') return rowData[column.field].toLocaleString('en-US');
		else return ''
	} else return rowData.toLocaleString('en-US');
}

export const booleanTemplate = (rowData, column) => {
	if (column) {
		if (isTrue(rowData[column.field])) {
			return (
				<i className="pi pi-check green strong"></i> 
			)
		}
		else return ''
	}
	else {
		if (isTrue(rowData)) return 'TRUE'
		else return ''
	}
}

export const percentageLineTemplateWithPercentage = (rowData,column) => {
	return (
		<>
			<div className="grid">
				<div className="col-10">
					<ProgressBar  className={(rowData.varianceLevel === 'low'?'progressBarGreen':rowData.varianceLevel === 'high'?'progressBarRed':'progressBarOrange')} value={rowData[column.field].toFixed(1)} showValue={false} />
				</div>
				<div className="col-2 strong">
					{rowData[column.field].toFixed(1)}%
				</div>
			</div>
		</>
	)
}

export const percentageLineTemplate = (rowData,column) => {
	
	let objCurBudget = rowData.datePicker || {}
	return (
				
		<div className="grid percentageToolTip" title={"Time Used: " + objCurBudget.timePercentage.toFixed(1) + "% Hours Used: " + objCurBudget.percentHoursUsed.toFixed(1) + "%"} data-pr-position="right"  >
			<div className="col-10">
				<ProgressBar  className={(objCurBudget.varianceLevel === 'low'?'progressBarGreen':objCurBudget.varianceLevel === 'high'?'progressBarRed':'progressBarOrange')} value={objCurBudget[column.field].toFixed(1)} showValue={false} />
			</div>
		</div>
	)
	
}

export const statusTemplate = (rowData, column) => {
	if (!rowData[column.field]) return null
	if (column) {
		return (
			<div className="flex flex-column">
				<div className="mb-2">
					<div className={"product-badge status-" + rowData[column.field].replace(/ /g,'')}>{rowData[column.field]}</div>
				</div>
			</div>
		)
	} else {
		return (
			<div className="flex flex-column">
				<div className={"mb-2 product-badge status-" + rowData}>{rowData}</div>
			</div>
		)
	}
}

export const varianceTemplate = (rowData, column) => {
	if (rowData.datePicker.varianceLevel === 'low') {
		return (
			<div className="flex flex-column">
				<div className={"mb-2 product-badge status-low"}>On Track</div>
			</div>
		)
	} else if (rowData.datePicker.varianceLevel === 'medium') {
		return (
			<div className="flex flex-column">
				<div className={"mb-2 product-badge status-medium"}>Off Track</div>
			</div>
		)
	} else if (rowData.datePicker.varianceLevel === 'high') {
		return (
			<div className="flex flex-column">
				<div className={"mb-2 product-badge status-high"}>Off Track</div>
			</div>
		)
	}
}

export const staffTemplate = ( rowData ) => {
	return (
		<div className="flex flex-column">
			<div className="mb-2 name">
				<div className="flex justify-content-between">
					<div className="mr-2">
						{rowData.name}
					</div>
					{rowData.isSenior === 1 && 
						<div className="mr-2">
							<i className="fa fa-user-plus briefcasemarg" title="Senior Staff"></i>
						</div>
					}
				</div>
				
			</div>
			<div className="mb-2 description">
				{rowData.title}
			</div>
			<div className="mb-2 description">
				{rowData.projectName}
			</div>
		</div>
	)
} 

export const dateStringsMap = ( ) => {
	
	const startOfPrevMonth = dayjs().subtract(1, 'month').startOf('month')
	const startOfTwoMonth = dayjs().subtract(2, 'month').startOf('month')
	
	let ytdDateString = 'Fiscal YTD to ' + startOfPrevMonth.format('MMM YYYY')
	let ytdDateTwoMonthsString = 'Fiscal YTD to ' + startOfTwoMonth.format('MMM YYYY')
	let lastMonthString = startOfPrevMonth.format('MMMM YYYY')
	let twoMonthString = startOfTwoMonth.format('MMMM YYYY')
	let lastYearString = 'Previous Fiscal Year'
	let thisYearString = 'Current Fiscal Year'

	return {
		ytd: ytdDateString,
		ytd2Month: ytdDateTwoMonthsString,
		lastMonth: lastMonthString,
		twoMonthago: twoMonthString,
		lastQuarter: 'Last Quarter',
		lastYear: lastYearString,
		fullYear: thisYearString
	}
}

export const fncCompareStaff = (databaseStaff, importStaff, departments) => {
	let arrFieldsToCompare = [
		{name: 'name', displayName:'Staff Name'},
		{name: 'title', displayName:'Title'},
		{name: 'status', displayName:'Status'},
		{name: 'isSenior', displayName:'Senior Staff'},
		{name: 'department', displayName:'Department'},
		{name: 'rate', displayName:'Rate'},
		{name: 'category', displayName:'Category'},
		{name: 'brandAgencies', displayName:'Brands'},
		{name: 'staffHours', displayName:'Staff Hours'},
		
	]

	let arrDifferences = arrFieldsToCompare.reduce((acc,cur) => {
		let databaseValue = databaseStaff[cur.name]
		let importValue =  importStaff[cur.name]
		
		
		
		//Some special cases for comparison
		if (cur.name === 'department') {
			databaseValue = departments[databaseStaff.functionID] || ''
		}

		if (cur.name === 'title') {
			if (!importValue) {
				importValue = 'ignore'
				databaseValue = 'ignore'
			}
		}

		if (cur.name === 'name') {
			if (!importValue) {
				importValue = 'ignore'
				databaseValue = 'ignore'
			}
		}

		if (cur.name === 'category') {
			if (!importValue) {
				importValue = 'ignore'
				databaseValue = 'ignore'
			}
		}

		if (cur.name === 'brandAgencies') { 
			
			let _newDatabaseValue = databaseValue.map((cur) => {
				let baseline = databaseStaff.baselineHoursByBrand[cur]

				return {
					brandAgencyID: cur, 
					isChecked: true,
					baseline: baseline
				}
			})

			databaseValue = JSON.stringify(_newDatabaseValue.sort((a,b) => (a.brandAgencyID > b.brandAgencyID?1:-1)))
			if (!importValue) importValue = []
			importValue =  JSON.stringify(importValue.sort((a,b) => (a.brandAgencyID > b.brandAgencyID?1:-1)))
		}

		if (cur.name === 'staffHours') {
			// There is no editing the hours that are entered. If they enter hours, they will be added
			databaseValue = false
			if (!importValue || importValue.length === 0) {
				importValue = false
			}
			
		}
		
		if (databaseValue !== importValue) {
			if (cur.displayName === 'Staff Hours') {
				if (importValue && importValue.length > 0) {
					for (let curImport of importValue) {
						
						let strAction = 'added'
						if (curImport.state === 'remove') strAction = 'removed'
						
						acc.push({
							type: {
								name: curImport.brandName + ' - ' + curImport.hours + ' ' + strAction + ' hours' ,
								displayName: curImport.brandName + ' - ' + curImport.hours + ' ' + strAction + ' hours',
								hideComparison: true
							},
							databaseValue: false,
							importValue: true
						})
						
					}
				}
			} else if (cur.displayName === 'Brands') {
				let objDatabase = JSON.parse(databaseValue).reduce((acc,cur) => {
					acc[cur.brandAgencyID] = {
						baseline: cur.baseline
					}
					return acc
				},{})
				let objImport = JSON.parse(importValue).reduce((acc,cur) => {
					acc[cur.brandAgencyID] = {
						baseline: cur.baseline,
						brandName: cur.brandName
					}
					return acc
				},{})

				for (let curBrandAgencyID in objImport) {
					if (!objDatabase[curBrandAgencyID]) {
						objDatabase[curBrandAgencyID] = {
							baseline: 0
						}
					}
					if (objImport[curBrandAgencyID].baseline !== objDatabase[curBrandAgencyID].baseline) {
						acc.push({
							type: {
								name: cur.name,
								displayName: objImport[curBrandAgencyID].brandName + ' Base Hours'
							},
							databaseValue: objDatabase[curBrandAgencyID].baseline,
							importValue: objImport[curBrandAgencyID].baseline
						})
					}
				}

				

			} else {
				acc.push({
					type: cur,
					databaseValue: databaseValue,
					importValue: importValue
				})
			}
		}
		
		
		return acc
	},[])

	//console.log('DIFF', arrDifferences)
	
	return arrDifferences
}

export const fncCompareProjects = (databaseProject, importProject, departments) => {
	let arrFieldsToCompare = [
		{name: 'brandName', displayName:'Brand Name'},
		{name: 'endDate',  displayName:'End Date'},
		{name: 'passThrough',  displayName:'Pass Through'},
		{name: 'oopCosts',  displayName:'Pass Through Costs'},
		{name: 'priority',  displayName:'Priority'},
		{name: 'projectName',  displayName:'Project Name'},
		{name: 'projectNumber',  displayName:'Project Number'},
		{name: 'poNumber',  displayName:'PO Number'},
		{name: 'startDate',  displayName:'Start Date'},
		{name: 'status', displayName:'Status'},
		{name: 'totalProjectBudget',  displayName:'Total Project Budget'},
		{name: 'projectBaselines', displayName:'Department Baselines'}, 
		{name: 'projectHours', displayName:'Department Hours'},
		{name: 'projectTypes', displayName:'Project Types'}
	]

	let arrDifferences = arrFieldsToCompare.reduce((acc,cur) => {
		let databaseValue = databaseProject[cur.name]
		let importValue =  importProject[cur.name]

		if (typeof databaseValue === 'string') databaseValue = databaseValue.trim()
		if (typeof importValue === 'string') importValue = importValue.trim()
		
		//Some special cases for comparison
		if (cur.name === 'endDate' || cur.name === 'startDate') {
			if (databaseValue && dayjs(databaseValue).isValid()) {
				databaseValue = dayjs(databaseValue).format('MM/YYYY')
			} 
			if (importValue && dayjs(importValue).isValid()) {
				importValue =  dayjs(importValue).format('MM/YYYY')
			} else {
				importValue = 'invalid date'
			}
		}

		if (cur.name === 'projectTypes') {
			databaseValue = databaseValue.join(',')
			importValue =  (importValue || []).join(',')
		}

		if (cur.name === 'projectNumber' || cur.name === 'poNumber') {
			if (typeof databaseValue === 'undefined') databaseValue = ''
			if (typeof importValue === 'undefined') importValue = ''

			databaseValue = databaseValue.toString()
			importValue =  importValue.toString()
		}

		if (cur.name === 'projectBaselines') {
			
			
			
			databaseValue = databaseValue.map(cur => {
				return {
					...cur, 
					department: departments[cur.functionID]
				}
			})

			importValue = (importProject['departmentHours'] || []).map(cur => {
				return {
					...cur, 
					department: departments[cur.functionID]
				}
			})
			
			
			databaseValue = JSON.stringify(databaseValue.sort((a,b) => (a.functionID > b.functionID?1:-1)))
			importValue =  JSON.stringify(importValue.sort((a,b) => (a.functionID > b.functionID?1:-1)))
		}

		if (cur.name === 'projectHours') {
			// There is no editing the hours that are entered. If they enter hours, they will be added
			databaseValue = false
			if (!importValue || importValue.length === 0) {
				importValue = false
			}
		}

		if (cur.name === 'oopCosts') {
			databaseValue = false
			if (!importValue || importValue === 0) {
				importValue = false
			}
		}

		if (databaseValue !== importValue) {
			
			if (cur.displayName === 'Pass Through Costs') {
				let strAction = 'added'
				if (importValue < 0) strAction = 'removed'
				let strDisplayName = cur.displayName + ' - ' + Math.abs(importValue) + ' ' + strAction + ' hours'
				acc.push({
					type: {
						name: strDisplayName, 
						displayName: strDisplayName,
						hideComparison: true
					},
					databaseValue: databaseValue,
					importValue: importValue
				})
			} else if (cur.displayName === 'Department Hours') {
				if (importValue && importValue.length > 0) {
					
					for (let curImport of importValue) {
						let strAction = 'added'
						if (curImport.state === 'remove') strAction = 'removed'
						
						acc.push({
							type: {
								name: departments[curImport.projectFunctionID] + ' - ' + Math.abs(curImport.hours) + ' ' + strAction + ' hours' ,
								displayName: departments[curImport.projectFunctionID] + ' - ' + Math.abs(curImport.hours) + ' ' + strAction + ' hours',
								hideComparison: true
							},
							databaseValue: false,
							importValue: true
						})
					}
				}
			} else if (cur.displayName === 'Department Baselines') {
				let objDatabase = JSON.parse(databaseValue).reduce((acc,cur) => {
					
					acc[cur.department] = {
						rate: cur.rate,
						hours: cur.hours
					}
					return acc
				},{})
				let objImport = JSON.parse(importValue).reduce((acc,cur) => {
					acc[cur.department] = {
						rate: cur.rate,
						hours: cur.hours
					}
					return acc
				},{})

				for (let curDepartment in objImport) {
					if (!objDatabase[curDepartment]) {
						objDatabase[curDepartment] = {
							rate: 0,
							hours: 0
						}
					}
					if (objImport[curDepartment].rate !== objDatabase[curDepartment].rate) {
						acc.push({
							type: {
								name: curDepartment + ' - Rate',
								displayName: curDepartment + ' - Rate (dept)'
							},
							databaseValue: objDatabase[curDepartment].rate,
							importValue: objImport[curDepartment].rate
						})
					}

					if (objImport[curDepartment].hours !== objDatabase[curDepartment].hours) {
						acc.push({
							type: {
								name: curDepartment + ' - Hours',
								displayName: curDepartment + ' - Hours (dept)'
							},
							databaseValue: objDatabase[curDepartment].hours,
							importValue: objImport[curDepartment].hours
						})
					}
				}
			} else {
				acc.push({
					type: cur,
					databaseValue: databaseValue,
					importValue: importValue
				})
			}
			
			
		}
		
		
		return acc
	},[])

	//console.log('DIFF', arrDifferences)
	
	return arrDifferences
}