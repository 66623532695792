import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs'
import { Link } from 'react-router-dom';

// PrimeReact Components
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import ColumnHeaderInfo from '../components/columnHeaderInfo'
import { dateStringsMap } from '../utilities/CommonSnippets'

const DashboardWidget = (props) => {
	// Not really used, always passing in the start date from parent now
	let defaultDateType = 'ytd'
	const { onDateChange, widgetKey, currentDateValue, zIndex } = props
 	const [ dateType, setDateType] = useState(defaultDateType);
	
	let dateMap = dateStringsMap()

	let datesSelectItems = [
		{label: dateMap['ytd'], value: 'ytd'},
		{label: dateMap['ytd2Month'], value: 'ytd2Month'},
		{label: dateMap['lastMonth'], value: 'lastMonth'},
		{label: dateMap['twoMonthago'], value: 'twoMonthago'},
		{label: dateMap['lastQuarter'], value: 'lastQuarter'},
		{label: dateMap['fullYear'], value: 'fullYear'},
		{label: dateMap['lastYear'], value: 'lastYear'}
	];

	let datesSelectItemsYearOnly = [
		{label: dateMap['fullYear'], value: 'fullYear'},
		{label: dateMap['lastYear'], value: 'lastYear'}
	];

	//If current date is January, need to rework the date dropdown for all widgets
	if (new Date().getMonth() === 0) {
		datesSelectItems = [
			{label: dateMap['lastYear'], value: 'lastYear'},
			{label: dateMap['lastMonth'], value: 'lastMonth'},
			{label: dateMap['twoMonthago'], value: 'twoMonthago'},
			{label: dateMap['lastQuarter'], value: 'lastQuarter'},
			{label: dateMap['fullYear'], value: 'fullYear'}
		];
		
	}
	//If current date is Febuary, need to rework the date dropdown for all widgets (remove the ytd2Month)
	if (new Date().getMonth() === 1) {
		datesSelectItems = [
		{label: dateMap['ytd'], value: 'ytd'},
		{label: dateMap['lastMonth'], value: 'lastMonth'},
		{label: dateMap['twoMonthago'], value: 'twoMonthago'},
		{label: dateMap['lastQuarter'], value: 'lastQuarter'},
		{label: dateMap['fullYear'], value: 'fullYear'},
		{label: dateMap['lastYear'], value: 'lastYear'}
		];
	}

	let widgetClassName = 'flex flex-column card desktopWidget';
	if (props.activeClass) widgetClassName += ' ' + props.activeClass

	let fncDateChanges = (e) =>{
		setDateType(e.value)
		onDateChange(widgetKey,e.value)
	}

	useEffect(() => {
		if (currentDateValue) {
			setDateType(currentDateValue)
		}
	},[currentDateValue]);
	
	
	return (       
		
		<div className={widgetClassName}>
			<div className="mb-2" style={{zIndex: '1000'}}>
				<div className="flex justify-content-between titleBar">
					<div className="title">{props.title}</div>
					{!props.hideDatePicker && !props.lockDates && 
						<div>
							<Dropdown value={dateType} options={datesSelectItems} onChange={(e) => fncDateChanges(e)} placeholder="Select Dates"/>
						</div>
					}
					{props.lockDates && 
						<div style={{fontWeight: '700'}}>
							<Dropdown value={dateType} options={datesSelectItemsYearOnly} onChange={(e) => fncDateChanges(e)} placeholder="Select Dates"/>
						</div>
					}
				</div>
			</div>
			<div className="mb-2" style={{zIndex: (zIndex?zIndex:'500')}}>
				{props.children}
			</div>
			<div className="flex justify-content-between mb-2" style={{height:'100%', zIndex: '1000'}}>
				<div className="align-self-end">
					{props.download && 
						<div onClick={(e) => props.download(props.widgetKey)} className="downloadIcon" title="Download Report">
							<i className="fa fa-download"></i>
						</div>
					}
					{props.compareLink && 
						<Link to={props.compareLink} >
							<i className="fas fa-sliders-h mr-1"></i>
							Compare...
						</Link>
					}
				</div>
				<div className="flex align-self-end" style={{width:'70%'}}>
					
					{props.viewDetails && !props.isSelected && 
						<Button style={{width:'100%'}} label="View Details" className=" p-button p-component p-button-outlined p-button-secondary" onClick={() => props.viewDetails()} />
					}

					{props.viewDetails && props.isSelected && 
						<div className="title" style={{width:'100%',textAlign:'center'}}>
							Fiscal Year Budget: {props.totalYearly}
						</div>
					}
					
				</div>
				
				<div className="align-self-end" >
					{props.infoKey && 
						<ColumnHeaderInfo columnKey={props.infoKey} />
					}
				</div>
			</div>
			
		</div>
	)
}

export default DashboardWidget;
