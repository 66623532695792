const fn =  (state = {}, action) => {
	switch (action.type) {
		case 'SET_BRAND_AGENCIES':
			return Object.assign({}, state, {
				allIds: action.payload.allIds,
				byId: action.payload.byId
			})
		default:
			return state
	}
}

export default fn